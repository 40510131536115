.microchart {
    display: flex;
    flex-direction: column;
    height: 20px;

    .bar {
        width: 2px;
        &:not(:last-child) {
            margin-right: 2px;
        }
    }
}

.microchart-positive,
.microchart-negative {
    display: flex;
}

.microchart-positive {
    align-items: flex-end;
    border-bottom: 1px solid $gray-base-70;
    .bar {
        background-color: map-get($colors, green);
    }
}

.microchart-negative {
    align-items: flex-start;
    .bar {
        background-color: map-get($colors, red);
    }
}
