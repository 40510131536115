
.public-list {

}

.public-list-item {
    margin-bottom: 9px;
    padding-bottom: 9px;
    &:not(:last-child) {
        border-bottom: 1px solid $gray-base-80;
    }

    p {
        margin-bottom: 5px;
    }

    .btn {
        float: right;
    }

    a:not([class*="btn"]) {
        color: $yc-blue;
    }

    .panel-data .panel-content &:first-child {
        margin-top: 9px;
    }

    .panel-data .panel-content & {
        @include media-breakpoint-up(lg) {
            margin-bottom: 4px;
            padding-bottom: 5px;
        }
    }
}

.public-list-title {
    min-height: $base-height;
    margin-top: -10px;
    margin-left: -$grid-gutter-width;
    margin-right: -$grid-gutter-width;
    margin-bottom: 9px;
    padding-top: 8px;
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
    border-bottom: 1px solid $gray-base-80;
    background-color: $gray-base-95;
}
