.side-menu {
    position: fixed;
    top: 50%;
    display: flex;
    z-index: $zindex-fixed;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow: 2px 0 4px 0 rgba(#000, .5);
    &:hover {
        box-shadow: 4px 0 7px 0 rgba(#000, .5);
    }
    &.right-0 {
    	right: -1px;
    }
    &.rotate-90 {
        transform-origin: right top;
        transform: rotate(90deg) translate(50%, 0);
    }

    .btn {
        display: flex;

        &:focus {
            outline: none !important;
        }
    }
}


.side-menu-item {
	&:first-child {
        .btn {
            border-bottom-left-radius: 3px;
        }
    }
    &:last-child {
        .btn {
            border-bottom-right-radius: 3px;
        }
    }
}