
.quickflows-section {
    padding-top: 15px;
    padding-left: $side-panel-padding;
    padding-right: $side-panel-padding;
    @include media-breakpoint-up(lg) {
        padding-top: 10px;
    }
    &:not(:first-child) {
        border-top: 1px solid $gray-base-80;
    }

    .get-started-small {
        margin-bottom: 15px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 10px;
        }
    }
}

.quickflows-section-header {
    padding-bottom: 15px;
    @include media-breakpoint-up(lg) {
        padding-bottom: 10px;
    }
    .feedback {
        margin-top: 12px;
        margin-bottom: 0;
        line-height: .8;
    }
}

.quickflows-section-title {
    @include headerDefault();
    color: $gray-base-10;
}

.quickflows-labels-list {
    padding-bottom: 9px;
    @include media-breakpoint-up(lg) {
        padding-bottom: 4px;
    }
    & > .btn {
        margin-bottom: 6px;
        &:not(:last-child) {
            margin-right: 6px;
        }
    }
}

.quickflows-list {
    margin-bottom: 10px;

    & > .tooltip {

        &[x-placement="bottom-start"] {
            .tooltip-arrow {
                left: 50% !important;
            }
        }

        .tooltip-inner {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .tooltip-inner {
        max-width: 260px;
        padding-left: 14px;
        padding-right: 14px;
    }

    .btn-i {
        display: none;
        margin-left: 5px;
        fill: $gray-base-50;
        &:hover {
            fill: $gray-base-10;
        }


        & + .tooltip {
            opacity: 1;
            white-space: normal;
            z-index: $zindex-tooltip + 1;

            .tooltip-arrow {
                display: none;
            }

            .tooltip-inner {
                @include box-shadow();
                padding-top: 10px;
                padding-bottom: 10px;
                background-color: #fff;
                color: $body-color;
                font-weight: 400;
                text-align: left;
            }
        }
    }

    &.options-list-skeleton {
        padding-top: 0;
    }
}

.quickflows-list-item {
    @include base-line-height();
    @include underline();
    display: flex;
    align-items: center;
    margin-left: -$side-panel-padding;
    margin-right: -$side-panel-padding;
    padding-left: $side-panel-padding;
    padding-right: $side-panel-padding;
    &:hover {
        background-color: $yc-blue-90;
        text-decoration: none;
        @include media-breakpoint-up(lg) {
        .btn-i {
                display: flex;
            }
        }
    }
}
