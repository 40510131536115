@use "sass:math";
@use "sass:list";

@mixin make-bg-colors() {
    // WARNING: DO NOT CHANGE THIS BECAUSE WE MANUALLY OVERRIDE IT TO CUSTOMIZE COLORS
    @each $color in map-values($colors) {
      .bg-#{index(map-values($colors), $color)} {
        background-color: $color;
      }
    }
}


@mixin make-bg-gradient-colors() {
  // WARNING: DO NOT CHANGE THIS BECAUSE WE MANUALLY OVERRIDE IT TO CUSTOMIZE COLORS
  @each $gradient-color in map-keys($gradient-colors) {
    .bg-gradient-#{$gradient-color} {
      background-color: map-get($gradient-colors, $gradient-color);
    }
  }
}

@mixin make-bg-positive-colors() {
  @each $color in map-keys($positive-colors) {
    .bg-positive-#{$color} {
      background-color: map-get($positive-colors, $color);
    }
    .chart-treemap .bg-positive-#{$color} {
      fill: map-get($positive-colors, $color) !important;
    }
  }
}

@mixin make-bg-negative-colors() {
  @each $color in map-keys($negative-colors) {
    .bg-negative-#{$color} {
      background-color: map-get($negative-colors, $color);
    }
    .chart-treemap .bg-negative-#{$color} {
      fill: map-get($negative-colors, $color) !important;
    }
  }
}

@mixin make-text-colors() {
    @each $color in map-keys($colors) {
        .text-#{"" + $color} {
            color: map-get($colors, $color);
        }
    }
    @each $color in map-keys($negative-colors) {
        .text-#{"negative-" + $color} {
            color: map-get($negative-colors, $color);
        }
    }
    @each $color in map-keys($positive-colors) {
        .text-#{"positive-" + $color} {
            color: map-get($positive-colors, $color);
        }
    }
    @each $color in map-keys($file-colors) {
        .file-#{$color} {
            color: map-get($file-colors, $color) !important;
        }
    }
    // WARNING: DO NOT CHANGE THIS BECAUSE WE MANUALLY OVERRIDE IT TO CUSTOMIZE COLORS
    @each $color in map-values($colors) {
        .text-#{index(map-values($colors), $color)} {
          color: $color;
        }
    }
    @each $gradient-color in map-keys($gradient-colors) {
        .text-gradient-#{$gradient-color} {
            color: map-get($gradient-colors, $gradient-color);
        }
    }
}

@mixin make-bullet-colors() {
    [class*=bullet-]:before {
        display: inline-block;
        border-radius: 50%;
        content: "";
    }
    // WARNING: DO NOT CHANGE THIS BECAUSE WE MANUALLY OVERRIDE IT TO CUSTOMIZE COLORS
    @each $color in map-values($colors) {
        .bullet-#{index(map-values($colors), $color)}:before {
          background-color: $color;
        }
    }

}

@mixin make-gradient-bullet-colors() {
  // WARNING: DO NOT CHANGE THIS BECAUSE WE MANUALLY OVERRIDE IT TO CUSTOMIZE COLORS
  @each $gradient-color in map-keys($gradient-colors) {
    .bullet-gradient-#{$gradient-color}:before {
      background-color: map-get($gradient-colors, $gradient-color);
    }
  }
}

@mixin make-positive-bullet-colors() {
  @each $positive-color in map-keys($positive-colors) {
    .bullet-positive-#{$positive-color}:before {
      background-color: map-get($positive-colors, $positive-color);
    }
  }
}

@mixin make-negative-bullet-colors() {
  @each $negative-color in map-keys($negative-colors) {
    .bullet-negative-#{$negative-color}:before {
      background-color: map-get($negative-colors, $negative-color);
    }
  }
}

.bullet-neutral:before {
    background-color: $gray-base-95;
}

@mixin make-chart-marker-colors() {

    .chart-marker {
        position: relative;
        color: $white;
        background-color: $gray-base-50;
        &:before {
            border-style: solid;
            border-color: transparent $gray-base-50 transparent transparent;
            content: "";
        }
    }

    @each $color in map-keys($colors) {
        .chart-marker-#{"" + $color} {
            background-color: map-get($colors, $color);
            &:before {
                border-right-color: map-get($colors, $color);
            }
        }
    }
}

@mixin make-border-colors() {
    @each $color in map-keys($colors) {
        .border-color-#{"" + $color} {
            border-color: map-get($colors, $color);
        }
    }
    @each $color in map-keys($positive-colors) {
        .border-color-positive-#{"" + $color} {
            border-color: map-get($positive-colors, $color);
        }
    }
    @each $color in map-keys($negative-colors) {
        .border-color-negative-#{"" + $color} {
            border-color: map-get($negative-colors, $color);
        }
    }
}

@mixin make-opacity() {
    @for $i from 0 through 10 {
        .opacity-#{$i * 10} {
            opacity: #{math.div($i, 10)};
        }
    }
}
@mixin make-darkmode-overrides() {
    .dark-mode :not(.no-highcharts-darkmode-override) > .highcharts-container .highcharts-series {
        @for $i from 0 through 26 {
            // highcharts colors are zero-indexed; sass lists are one-indexed
            .highcharts-color-#{$i} {
                fill: list.nth($dark-colors, $i + 1);
                stroke: list.nth($dark-colors, $i + 1);
                background-color: list.nth($dark-colors, $i + 1);
            }
        }
    }
}

@mixin bg-perlmutter() {
    background: linear-gradient(110deg, map-get($colors, 'purple-extralight') 4%, $ai-plate 70%);
}

@mixin bg-perlmutter-saturated() {
    background: linear-gradient(110deg, map-get($colors, 'purple-light') 4%, #BAD1EE 70%);
}

@mixin bg-sky() {
    background: linear-gradient(140deg, $yc-blue-ada 18%, #578CD8 47%, map-get($colors, 'light-blue') 80%);
}

@include make-gradient-bullet-colors();
@include make-positive-bullet-colors();
@include make-negative-bullet-colors();
@include make-bg-gradient-colors();
@include make-bg-positive-colors();
@include make-bg-negative-colors();
@include make-bullet-colors();
@include make-chart-marker-colors();
@include make-bg-colors();
@include make-text-colors();
@include make-border-colors();
@include make-opacity();
@include make-darkmode-overrides();

.text-white {
    color: #fff;
}

.bg-gray-base-80 {
    background-color: map-get($colors, gray-80);
}

.bg-neutral,
.bg-gray-base-90 {
    background-color: map-get($colors, gray-90);
}

.bg-gray-base-95 {
    background-color: map-get($colors, gray-95);
}

.bg-white {
    background-color: #fff;
}

.bg-panel {
    background-color: $panel-background;
}

.bg-yc-blue {
    background-color: $yc-blue;
}

.bg-yc-blue-dark {
    background-color: $yc-blue-dark;
}

.bg-yc-blue-ada {
    background-color: $yc-blue-ada;
}

.bg-yc-blue-25 {
    background-color: $yc-blue-25;
}

.bg-yc-blue-50 {
    background-color: $yc-blue-50;
}

.bg-yc-blue-75 {
    background-color: $yc-blue-75;
}

.bg-yc-blue-90 {
    background-color: $yc-blue-90;
}

.bg-yc-blue-95 {
    background-color: $yc-blue-95;
}

.bg-transparent {
    background-color: transparent;
}

.bg-ai-plate {
    background-color: $ai-plate;
}

.chart-treemap .bg-transparent {
    fill: transparent !important;
}

.bg-strips {
    background-image: linear-gradient(45deg, $gray-base-80 25%, #fff 25%, #fff 50%, $gray-base-80 50%, $gray-base-80 75%, #ffffff 75%, #ffffff 100%);
    background-size: 6px 6px;
}

.bg-ai,
.bg-perlmutter {
    @include bg-perlmutter();
}

.bg-sky {
    @include bg-sky();
}

.border-color-blue-ada {
    border-color: $yc-blue-ada !important;
}
