.public {
    @include bodyText();

    & > .container,
    .page-tabs > .container {
        max-width: 1020px;
    }

    .tabs.page-tabs {
        margin-bottom: 20px;
    }

    .page-body,
    .modal-content {
        .panel {
            margin-bottom: $grid-gutter-width;
            padding: $grid-gutter-width $grid-gutter-width 0;
        }

        .panel-header {
            width: auto;
            margin-top: -$grid-gutter-width;
            margin-left: -$grid-gutter-width;
            margin-right: -$grid-gutter-width;
            margin-bottom: $grid-gutter-width * 0.5;
            border-bottom: 1px solid $gray-base-80;
        }

        .panel-title {
            margin-bottom: 0;
        }

        h2 {
            @include h2();
        }

        h3 {
            @include h3();
            margin-bottom: 18px;
        }

        hr {
            margin-top: 18px;
            margin-bottom: 18px;
            border: 0;
            border-bottom: 1px solid $gray-base-90;
        }

        img {
            margin-top: 18px;
            margin-bottom: 18px;
            border: 1px solid $gray-base-90;
        }

        ul {
            margin-bottom: 18px;
            padding-left: 0;
            list-style: none;

            li {
                position: relative;
                margin-bottom: 10px;
                padding-left: 15px;

                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: inline-block;
                    width: 15px;
                    content: "•";
                }
            }
        }

        .public-reference {
            color: $gray-base-40;

            li {
                padding-left: 0;
                &:before {
                    content: none;
                }
            }
        }

        p:not(.public-list-item) {
            margin-bottom: 18px;
        }

        .public-list-item p {
            margin-bottom: 5px;
        }

        code {
            font-weight: 700;
        }

        h3 + ul,
        p + ul {
            margin-top: -9px;
        }

        .btn.btn-lg {
            @include callToAction();
            display: flex;
            height: 48px;
            line-height: inherit;
            padding-left: 30px;
            padding-right: 30px;
            text-transform: none;
        }

        a:not([class*="btn"]) {
            color: $yc-blue;
        }

        .form-inline > .btn-lg + * {
            margin-left: 18px;
        }
    }
}

.public-page-title {
    @include mainHeader();
    margin-bottom: 0;
}

.public-intro {
    @include largeParagraphText();
}

.public-bolded {
    @include largeBoldText();
}

.public-ul {
    margin-bottom: 18px;
    padding-left: 0;
    list-style: none;

    li {
        position: relative;
        margin-bottom: 10px;
        padding-left: 15px;

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            width: 15px;
            content: "•";
        }
    }

    &.text-small {
        li {
            margin-bottom: 0;
        }
    }
}


// Modal related styles

.public.modal-dialog {
    .modal-header {
        padding-top: 20px;
    }

    .modal-title {
        @include h2();
        color: $gray-base-10;
    }

    .modal-content {
        p {
            @include bodyText();
        }

        .public-intro {
            @include largeParagraphText();
        }
    }
}
