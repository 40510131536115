
@mixin textDefault() {                     // #13 #14 #15 --NEW #7
    font-family: $font-family-base;
    font-size: $font-size-base;         // 15px
    font-weight: $font-weight-base;
    line-height: $line-height-base;
    letter-spacing: normal;
    color: $body-color;
    @include media-breakpoint-up(lg) {
        font-size: 14px;
        line-height: $line-height-desktop;
    }
}

html {
    @include textDefault();
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin textSmall() {                     // #20 #21 #22 --NEW #9, #12
    font-size: .867rem; // 13px
    line-height: 1.692;
    @include media-breakpoint-up(lg) {
        font-size: .929rem; // 13px
        line-height: 1.4;
    }
}

@mixin headerDefault() {                 // #6 #7 #8 #9 --NEW #6
    @include textDefault();
    font-weight: 700;
}

@mixin headerMedium() {                  // #5 --NEW #4
    font-size: 1.133rem; // 17px
    font-weight: 700;
    @include media-breakpoint-up(lg) {
        font-size: 1.143rem; // 16px
    }
}

@mixin headerLarge() {                   // #2 #3 --NEW #2
    font-size: 1.333rem; // 20px
    font-weight: 700;
    letter-spacing: -0.1px;
    @include media-breakpoint-up(lg) {
        font-size: 1.429rem; // 20px
    }
}

@mixin headerHuge() {                   // #2 #3 --NEW #2
    font-size: 1.333rem; // 20px
    font-weight: 700;
    letter-spacing: 0.5px;
    @include media-breakpoint-up(lg) {
        font-size: 1.857rem; // 26px
    }
}

@mixin buttonTextDefault() {             // #11 #12 --NEW #8
    font-size: .933rem; // 14px
    font-weight: 700;
    text-transform: uppercase;
    @include media-breakpoint-up(lg) {
        font-size: .929rem; // 13px
    }
}

@mixin textLabel() {                      // #18 --NEW #11
    font-size: .933rem; // 13px
    font-weight: 700;
    line-height: 1.231;
    @include media-breakpoint-up(lg) {
        font-size: 1rem; // 13px
    }
}

@mixin textDirection() {                  // #19 NEW #11
    font-size: .933rem; // 13px
    line-height: 1.231;
}

@mixin emptyStateAdditional() {             // #3 NEW #4
    font-size: 1.2rem;
    line-height: 1.333;
    @include media-breakpoint-up(lg) {
        font-size: 1.214rem;
    }
}

@mixin emptyState() {                      // #4 NEW #3
    color: $gray-base-60;
    font-weight: 700;
    font-size: 1.6rem; // 24px
    line-height: $line-height-base;
    text-align: center;
    letter-spacing: -0.1px;
    @include media-breakpoint-up(lg) {
        font-size: 1.714rem; //24px
    }

    span {
        @include emptyStateAdditional();
    }
}

@mixin dataAboveChart {
    font-size: .867rem; // 13px
    line-height: 1.538; // 20px
    @include media-breakpoint-up(lg) {
        font-size: .857rem; // 12px
        line-height: 1.167;
    }
}

@mixin smallCapsLabel {                     // NEW #15
    @include dataAboveChart();
    text-transform: uppercase;
}

@mixin keyStatTitle {
    font-size: 1.333rem; // 17px
    letter-spacing: -0.1px;
    @include media-breakpoint-up(lg) {
        font-size: 1.429rem; // 20px
    }
}

@mixin chartAxis {
    font-size: .733rem; // 11px
    @include media-breakpoint-up(lg) {
        font-size: .786rem; // 11px
    }
}

@mixin chickletSecurityName {
    font-size: 1.333rem; // 20px
    line-height: 1.35;
    @include media-breakpoint-up(lg) {
        font-size: 1.286rem; // 18px
        line-height: 1.111;
    }
}

// Marketing Type Styles

@mixin mainHeader {                           // M1
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
}

@mixin h2 {                                   // M2
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
}

@mixin callToAction {                         // M3
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
}

@mixin largeParagraphText {                   // M4
    font-size: 18px;
    line-height: 26px;
}

@mixin h3 {                                   // M5
    font-size: 17px;
    line-height: 27px;
    font-weight: 700;
}

@mixin largeBoldText {                        // M6
    font-size: 16px;
    line-height: 26px;
    font-weight: 700;
}

@mixin largeText {                            // Desktop/D15 Text AI Message
    font-size: 16px;
    line-height: 26px;
}

@mixin bodyText {                             // M7
    font-size: 15px;
    line-height: 22px;
}

@mixin textOverflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-ellipsis {
    @include textOverflow();
}


// Line-clamps

[class*="line-clamp-"] {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  // we have to calculate height for each case by formula (line-clamp-number) * (line-height)
  // height: calc(5em*1.5);
}

.line-clamp-2 { -webkit-line-clamp: 2; }


// text block vertical space visual compensation

@mixin lineCropBefore($line-height: $line-height-base, $line-height-desktop: $line-height-desktop) {
  &:before {
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((0.7 - #{$line-height}) * 0.5em);
    content: '';
    @include media-breakpoint-up(lg) {
        margin-top: calc((0.7 - #{$line-height-desktop}) * 0.5em);
    }
  }
}

@mixin lineCropAfter($line-height: $line-height-base, $line-height-desktop: $line-height-desktop) {
  &:after {
    display: block;
    height: 0;
    width: 0;
    margin-bottom: calc((0.7 - #{$line-height}) * 0.5em);
    content: '';
    @include media-breakpoint-up(lg) {
        margin-bottom: calc((0.7 - #{$line-height-desktop}) * 0.5em);
    }
  }
}
