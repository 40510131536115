
@import "variables/_colors";
@import "variables/_gradient-colors";
@import "variables/_positive-colors";
@import "variables/_negative-colors";
@import "variables/_file-colors";

// Fonts
@font-face {
    font-family: 'Roboto Condensed';
    src: url('./fonts/RobotoCondensed-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: 'Roboto Condensed';
    src: url('./fonts/RobotoCondensed-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
}
$font-family-base : 'Roboto Condensed', sans-serif;
$font-size-base : 15px;
$font-size-desktop: $font-size-base - 2;
$font-weight-base : 400;
$line-height-base : 1.466;
$line-height-desktop : 1.429;

// Spacer

$spacer: 8px;

// Colors

$gray-base        : #000;
$gray-base-10     : lighten($gray-base, 10%); // #191919
$gray-base-20     : lighten($gray-base, 20%); // #333333
$gray-base-30     : lighten($gray-base, 30%); // #4c4c4c
$gray-base-40     : lighten($gray-base, 40%); // #666666
$gray-base-45     : lighten($gray-base, 45%); // #777777
$gray-base-50     : lighten($gray-base, 50%); // #7f7f7f
$gray-base-60     : lighten($gray-base, 60%); // #999999
$gray-base-70     : lighten($gray-base, 70%); // #b2b2b2
$gray-base-75     : lighten($gray-base, 75%); // #c1c1c1
$gray-base-80     : lighten($gray-base, 80%); // #cccccc
$gray-base-90     : lighten($gray-base, 90%); // #e5e5e5
$gray-base-95     : lighten($gray-base, 95%); // #f2f2f2

$white            : #fff;
$black            : #000;

$yc-blue-dark        : #0263b3;
$yc-blue-ada         : #0273cf;
$yc-blue             : #0282e8;
$yc-blue-25          : #41a1ed;
$yc-blue-50          : #80c0f3;
$yc-blue-75          : #c0dff9;
$yc-blue-90          : #e5f2fc;
$yc-blue-95          : #f2f8fd;
$yc-blue-800         : #014276;

$ai-plate         : #dde8f7;

$signal-green     : #008a00;

$body-color       : $gray-base-10;
$body-bg          : #fff;
$link-color       : $body-color;
$link-hover-color : $yc-blue;

$panel-background : #f7f8f8;

$pdf-page-gap: 12px;

$pdf-full-page: map-get($colors, orange);
$pdf-half-page: map-get($colors, purple);
$pdf-third-page: map-get($colors, lime);
$pdf-quarter-page: map-get($colors, blue);

$pdf-full-page-bg: map-get($gradient-colors, 2d);
$pdf-half-page-bg: map-get($colors, 'purple-extralight');
$pdf-third-page-bg: map-get($positive-colors, 8);
$pdf-quarter-page-bg: $yc-blue-90;

// dark mode color overrides for highcharts
$dark-colors: #5429A1, #893600, #214F8F, #566D20, #B11688, #765037, #205E2E, #A73213, #26292B, #16335D, #45203F, #5E0047, #1F2123, #26292B, #008200, #A81B22, #440F11, #141516, #3A3F41, #4D5356, #575E61, #60686C, #52585C, #43484B, #35393B, #26292B, #1F2223;

// Grid breakpoints

$grid-breakpoints: (
    xs: 0,
    sm: 700px,
    md: 960px,
    lg: 1025px,
    xl: 1440px
) !default;
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


// Grid containers

$container-max-widths: (
    sm: 700px,
    md: 960px,
    lg: 1024px,
    xl: 1600px,
    xxl: 2500px
) !default;
@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns

$grid-columns: 12 !default;
$grid-gutter-width: 20px;

$base-height:                       40px;
$base-height-desktop:               30px;

// Header
$header-height:                     48px;
$header-height-mobile:              $header-height;
$header-bg:                         $gray-base-10;
$header-color:                      $gray-base-70;
$header-color-medium:               $gray-base-60;
$header-color-dark:                 $gray-base-30;
$header-home-height:                88px;
$header-home-height-mobile:         60px;

// Page Header
$page-header-height:                70px;
$page-header-height-mobile:         64px;
$page-header-height-dashboard:      48px;

// Footer
$footer-height:                     28px;
$footer-height-mobile:              48px;

// Panel

$panel-margin: 16px;
$panel-margin-lg: 10px;


// Z-index master list

$zindex-dropdown:                   1000 !default;
$zindex-sticky:                     1020 !default;
$zindex-fixed:                      1030 !default;
$zindex-modal-backdrop:             1050 !default;
$zindex-modal:                      1050 !default;
$zindex-popover:                    1060 !default;
$zindex-tooltip:                    1070 !default;


// Arrows
$arrow-width:                       8px;
$arrow-width-lg:                    8px;

// Dropdowns
$caret-width:                       8px;
$caret-width-lg:                    8px;
$dropdown-item-hover-bg:            $yc-blue-90;


// Forms
$form-row-margin-vertical:          .933em;
$form-row-margin-vertical-desktop:  .923em;
$custom-checkbox-indicator-size:    12px;
$custom-checkbox-indicator-size-desktop: 10px;
$custom-radio-indicator-size:       16px;
$custom-radio-indicator-size-desktop: 14px;

// Modals
$modal-dialog-margin:               $grid-gutter-width;
$modal-content-bg:                  $white;
$modal-backdrop-bg:                 $gray-base-70;
$modal-backdrop-opacity:            .5;
$modal-header-padding:              $grid-gutter-width;
$modal-md:                          624px;
$modal-sm:                          488px;
$modal-lg:                          840px;


// Tooltips
$tooltip-max-width:                 300px !default;
$tooltip-color:                     $white !default;
$tooltip-bg:                        $gray-base-10;
$tooltip-opacity:                   .9 !default;

$tooltip-arrow-width:               .4rem !default;
$tooltip-arrow-height:              $tooltip-arrow-width;
$tooltip-arrow-color:               $tooltip-bg !default;


// Side-panel
$side-panel-width:                  270px;
$side-panel-padding:                $grid-gutter-width;
$side-panel-body-background:        #f7f8f8;

// Tables
$table-border: 1px solid $gray-base-90;
$table-accent-bg: map-get($colors, 'purple-extralight');
$table-hover-bg: $yc-blue-95;
$table-pin-bg: $gray-base-95;

// Quad table
$quad-table-height: 500px;
$quad-table-cell-width: 80px;
$quad-table-sidebar-width: 190px;
$quad-table-sidebar-width-desktop: 170px;
$quad-table-cell-name-width: 310px;

// Page Progress Bar
$page-progress-bar-width: 15%;
$page-progress-bar-animation-duration: 5s;
$page-progress-bar-before-background: linear-gradient(to right, $gray-base-30 0%, $yc-blue 50%, $yc-blue-50 100%);
$page-progress-bar-after-background: linear-gradient(to right, $yc-blue-50 0%, $yc-blue 50%, $gray-base-30 100%);

// Chart tooltip
$chart-tooltip-arrow-size: 10px;
$chart-tooltip-border-color: $gray-base-60;
$chart-tooltip-bg: $panel-background;
$chart-tooltip-bg-opacity: 1;

// Home page
$home-transition: .2s cubic-bezier(0.4, 0, 0.2, 1) all;
$home-card-box-shadow: 3px 4px 12px rgba(#000, .25);
$home-orange       : #bb732c;
$home-orange-light : #ffebc5;
$home-green        : #28753a;
$home-green-light  : #dfeae1;
$home-purple       : #552b9d;
$home-purple-light : #f3eff9;

$hero-device-delay:  .4s;
$hero-feature-delay: .15s;
