.toast {
	position: absolute;
	top: 8px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	padding: 10px 12px;

	.btn-close {
		flex: none;
		fill: $gray-base-50;
		&:first-child {
			margin-right: 10px;
		}
		&:last-child {
			margin-left: 10px;
		}

		&.btn-16 {
			margin-top: 2px;
			border-color: $gray-base-50;
			[class*=icon-] {
				font-size: 8px;
			}
		}
	}

	svg {
		flex: none;
	}
}

.toast-context {
	position: static;
	transform: none;
}

.toast-bottom {
	top: auto;
	bottom: 30px;
}

.toast-bottom-left {
	top: auto;
	bottom: 0;
	left: 0;
	transform: none;
}

.toast-error {
	color: map-get($negative-colors, 1);
	background-color: map-get($negative-colors, 6);
}

.toast-signal {
	color: map-get($colors, signal-orange-1);
	background-color: map-get($colors, signal-orange-2);
}

.toast-success {
	color: map-get($positive-colors, 1);
	background-color: map-get($positive-colors, 6);
}

.toast-neutral {
	color: $gray-base-10;
	background-color: $gray-base-95;
}

.toast-black {
	color: #fff;
	background-color: $gray-base-10;

	a {
		color: #fff;
	}

	.btn-close {
		fill: #fff;
	}

	.divider-vertical {
		border-color: $gray-base-40;
	}
}

.toast-text {
	align-self: center;
}
