@use "sass:math";
.icon-1-2 {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-1-3 {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-1-4 {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-add-circle {
    width: math.div(16px, $font-size-base) * 1em;
    height: math.div(16px, $font-size-base) * 1em;
}
.icon-add-left {
    width: math.div(21px, $font-size-base) * 1em;
    height: math.div(10px, $font-size-base) * 1em;
}
.icon-add-right {
    width: math.div(21px, $font-size-base) * 1em;
    height: math.div(10px, $font-size-base) * 1em;
}
.icon-add {
    width: math.div(11px, $font-size-base) * 1em;
    height: math.div(11px, $font-size-base) * 1em;
}
.icon-ai-a {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-ai-q {
    width: math.div(15px, $font-size-base) * 1em;
    height: math.div(15px, $font-size-base) * 1em;
}
.icon-alert {
    width: math.div(14px, $font-size-base) * 1em;
    height: math.div(15px, $font-size-base) * 1em;
}
.icon-apple-color {
    width: math.div(22px, $font-size-base) * 1em;
    height: math.div(22px, $font-size-base) * 1em;
}
.icon-arrow-up-right {
    width: math.div(14px, $font-size-base) * 1em;
    height: math.div(14px, $font-size-base) * 1em;
}
.icon-auth-app {
    width: math.div(16px, $font-size-base) * 1em;
    height: math.div(16px, $font-size-base) * 1em;
}
.icon-auth-email {
    width: math.div(16px, $font-size-base) * 1em;
    height: math.div(16px, $font-size-base) * 1em;
}
.icon-back {
    width: math.div(8px, $font-size-base) * 1em;
    height: math.div(14px, $font-size-base) * 1em;
}
.icon-benefit-1-color {
    width: math.div(26px, $font-size-base) * 1em;
    height: math.div(26px, $font-size-base) * 1em;
}
.icon-benefit-2-color {
    width: math.div(26px, $font-size-base) * 1em;
    height: math.div(26px, $font-size-base) * 1em;
}
.icon-benefit-3-color {
    width: math.div(26px, $font-size-base) * 1em;
    height: math.div(26px, $font-size-base) * 1em;
}
.icon-bird {
    width: math.div(15px, $font-size-base) * 1em;
    height: math.div(12px, $font-size-base) * 1em;
}
.icon-blank {
    width: math.div(16px, $font-size-base) * 1em;
    height: math.div(18px, $font-size-base) * 1em;
}
.icon-briefcase {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-browse {
    width: math.div(17px, $font-size-base) * 1em;
    height: math.div(16.6px, $font-size-base) * 1em;
}
.icon-calendar-event-add {
    width: math.div(16px, $font-size-base) * 1em;
    height: math.div(16px, $font-size-base) * 1em;
}
.icon-calendar-event {
    width: math.div(15px, $font-size-base) * 1em;
    height: math.div(15px, $font-size-base) * 1em;
}
.icon-calendar {
    width: math.div(13px, $font-size-base) * 1em;
    height: math.div(15px, $font-size-base) * 1em;
}
.icon-change-type {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-chart-bars {
    width: math.div(19px, $font-size-base) * 1em;
    height: math.div(16px, $font-size-base) * 1em;
}
.icon-chart-candlestick {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(21px, $font-size-base) * 1em;
}
.icon-chart-line {
    width: math.div(17px, $font-size-base) * 1em;
    height: math.div(18px, $font-size-base) * 1em;
}
.icon-chart-ohlc {
    width: math.div(21px, $font-size-base) * 1em;
    height: math.div(21px, $font-size-base) * 1em;
}
.icon-charts {
    width: math.div(13px, $font-size-base) * 1em;
    height: math.div(13px, $font-size-base) * 1em;
}
.icon-chat-small {
    width: math.div(21px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-chat {
    width: math.div(22px, $font-size-base) * 1em;
    height: math.div(22px, $font-size-base) * 1em;
}
.icon-check-rich-color {
    width: math.div(30px, $font-size-base) * 1em;
    height: math.div(30px, $font-size-base) * 1em;
}
.icon-check {
    width: math.div(16px, $font-size-base) * 1em;
    height: math.div(17px, $font-size-base) * 1em;
}
.icon-close-arrow {
    width: math.div(14px, $font-size-base) * 1em;
    height: math.div(13px, $font-size-base) * 1em;
}
.icon-close-circle {
    width: math.div(16px, $font-size-base) * 1em;
    height: math.div(16px, $font-size-base) * 1em;
}
.icon-close {
    width: math.div(13px, $font-size-base) * 1em;
    height: math.div(13px, $font-size-base) * 1em;
}
.icon-collapse {
    width: math.div(8px, $font-size-base) * 1em;
    height: math.div(14px, $font-size-base) * 1em;
}
.icon-condense {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-contact-color {
    width: math.div(48px, $font-size-base) * 1em;
    height: math.div(48px, $font-size-base) * 1em;
}
.icon-csv {
    width: math.div(22.4px, $font-size-base) * 1em;
    height: math.div(10px, $font-size-base) * 1em;
}
.icon-dark-mode {
    width: math.div(16px, $font-size-base) * 1em;
    height: math.div(16px, $font-size-base) * 1em;
}
.icon-data-color {
    width: math.div(48px, $font-size-base) * 1em;
    height: math.div(42px, $font-size-base) * 1em;
}
.icon-delete {
    width: math.div(13px, $font-size-base) * 1em;
    height: math.div(17px, $font-size-base) * 1em;
}
.icon-disable {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-dollar-cost-averaging {
    width: math.div(14px, $font-size-base) * 1em;
    height: math.div(18px, $font-size-base) * 1em;
}
.icon-dots-more {
    width: math.div(4px, $font-size-base) * 1em;
    height: math.div(16px, $font-size-base) * 1em;
}
.icon-down-arrow {
    width: math.div(13px, $font-size-base) * 1em;
    height: math.div(8px, $font-size-base) * 1em;
}
.icon-download-small {
    width: math.div(11px, $font-size-base) * 1em;
    height: math.div(12px, $font-size-base) * 1em;
}
.icon-download {
    width: math.div(13px, $font-size-base) * 1em;
    height: math.div(17px, $font-size-base) * 1em;
}
.icon-draft-color {
    width: math.div(33px, $font-size-base) * 1em;
    height: math.div(18px, $font-size-base) * 1em;
}
.icon-drag {
    width: math.div(9px, $font-size-base) * 1em;
    height: math.div(14px, $font-size-base) * 1em;
}
.icon-duplicate {
    width: math.div(18px, $font-size-base) * 1em;
    height: math.div(18px, $font-size-base) * 1em;
}
.icon-edit-module {
    width: math.div(22px, $font-size-base) * 1em;
    height: math.div(23px, $font-size-base) * 1em;
}
.icon-edit {
    width: math.div(15px, $font-size-base) * 1em;
    height: math.div(15px, $font-size-base) * 1em;
}
.icon-email-modules {
    width: math.div(22px, $font-size-base) * 1em;
    height: math.div(22px, $font-size-base) * 1em;
}
.icon-embed {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(13px, $font-size-base) * 1em;
}
.icon-esg-carbon {
    width: math.div(32px, $font-size-base) * 1em;
    height: math.div(34px, $font-size-base) * 1em;
}
.icon-esg-carbon3 {
    width: math.div(31.45px, $font-size-base) * 1em;
    height: math.div(21.14px, $font-size-base) * 1em;
}
.icon-esg-environmental {
    width: math.div(47px, $font-size-base) * 1em;
    height: math.div(32px, $font-size-base) * 1em;
}
.icon-esg-female {
    width: math.div(21px, $font-size-base) * 1em;
    height: math.div(31px, $font-size-base) * 1em;
}
.icon-esg-firearms {
    width: math.div(34px, $font-size-base) * 1em;
    height: math.div(24px, $font-size-base) * 1em;
}
.icon-esg-fossil {
    width: math.div(30px, $font-size-base) * 1em;
    height: math.div(30px, $font-size-base) * 1em;
}
.icon-esg-governance {
    width: math.div(37px, $font-size-base) * 1em;
    height: math.div(32px, $font-size-base) * 1em;
}
.icon-esg-independence {
    width: math.div(29px, $font-size-base) * 1em;
    height: math.div(27px, $font-size-base) * 1em;
}
.icon-esg-performance {
    width: math.div(30px, $font-size-base) * 1em;
    height: math.div(28px, $font-size-base) * 1em;
}
.icon-esg-rights {
    width: math.div(26px, $font-size-base) * 1em;
    height: math.div(35px, $font-size-base) * 1em;
}
.icon-esg-social {
    width: math.div(44px, $font-size-base) * 1em;
    height: math.div(27px, $font-size-base) * 1em;
}
.icon-esg-tobacco {
    width: math.div(40px, $font-size-base) * 1em;
    height: math.div(10px, $font-size-base) * 1em;
}
.icon-esg-warming {
    width: math.div(26px, $font-size-base) * 1em;
    height: math.div(30px, $font-size-base) * 1em;
}
.icon-esg-weapons {
    width: math.div(40px, $font-size-base) * 1em;
    height: math.div(12px, $font-size-base) * 1em;
}
.icon-excel {
    width: math.div(16px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-expand-content {
    width: math.div(15px, $font-size-base) * 1em;
    height: math.div(15px, $font-size-base) * 1em;
}
.icon-expand-full {
    width: math.div(16px, $font-size-base) * 1em;
    height: math.div(15px, $font-size-base) * 1em;
}
.icon-expand {
    width: math.div(14px, $font-size-base) * 1em;
    height: math.div(8px, $font-size-base) * 1em;
}
.icon-export {
    width: math.div(16px, $font-size-base) * 1em;
    height: math.div(16px, $font-size-base) * 1em;
}
.icon-file {
    width: math.div(18px, $font-size-base) * 1em;
    height: math.div(24px, $font-size-base) * 1em;
}
.icon-filter-bars {
    width: math.div(14px, $font-size-base) * 1em;
    height: math.div(16px, $font-size-base) * 1em;
}
.icon-filter {
    width: math.div(13px, $font-size-base) * 1em;
    height: math.div(14px, $font-size-base) * 1em;
}
.icon-folder-add {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-folder-move {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-folder {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-friend {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(16px, $font-size-base) * 1em;
}
.icon-gain {
    width: math.div(8px, $font-size-base) * 1em;
    height: math.div(12px, $font-size-base) * 1em;
}
.icon-gear-outline {
    width: math.div(20.5px, $font-size-base) * 1em;
    height: math.div(20.5px, $font-size-base) * 1em;
}
.icon-gear {
    width: math.div(16px, $font-size-base) * 1em;
    height: math.div(17px, $font-size-base) * 1em;
}
.icon-go {
    width: math.div(11px, $font-size-base) * 1em;
    height: math.div(6px, $font-size-base) * 1em;
}
.icon-google-color {
    width: math.div(22px, $font-size-base) * 1em;
    height: math.div(22px, $font-size-base) * 1em;
}
.icon-hamburger {
    width: math.div(26px, $font-size-base) * 1em;
    height: math.div(26px, $font-size-base) * 1em;
}
.icon-info {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-key-outline {
    width: math.div(20.5px, $font-size-base) * 1em;
    height: math.div(20.5px, $font-size-base) * 1em;
}
.icon-left-arrow {
    width: math.div(8px, $font-size-base) * 1em;
    height: math.div(13px, $font-size-base) * 1em;
}
.icon-lightbulb {
    width: math.div(21px, $font-size-base) * 1em;
    height: math.div(21px, $font-size-base) * 1em;
}
.icon-link {
    width: math.div(14px, $font-size-base) * 1em;
    height: math.div(14px, $font-size-base) * 1em;
}
.icon-list {
    width: math.div(14px, $font-size-base) * 1em;
    height: math.div(12px, $font-size-base) * 1em;
}
.icon-lock {
    width: math.div(12px, $font-size-base) * 1em;
    height: math.div(16px, $font-size-base) * 1em;
}
.icon-loss {
    width: math.div(8px, $font-size-base) * 1em;
    height: math.div(12px, $font-size-base) * 1em;
}
.icon-magnify {
    width: math.div(22px, $font-size-base) * 1em;
    height: math.div(24px, $font-size-base) * 1em;
}
.icon-mail {
    width: math.div(18px, $font-size-base) * 1em;
    height: math.div(14px, $font-size-base) * 1em;
}
.icon-modify {
    width: math.div(18px, $font-size-base) * 1em;
    height: math.div(16px, $font-size-base) * 1em;
}
.icon-module-chart-area-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-chart-bar-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-chart-column-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-chart-line-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-chart-pie-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-chart-scatter-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-chart-stacked-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-email-calendar {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-email-chart {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-email-commentary {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-email-review {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-email-summary {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-heatmap-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-image-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-landscape-eighth-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-landscape-full-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-landscape-half-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-landscape-multicomp-eighth-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-landscape-multicomp-full-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-landscape-multicomp-half-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-landscape-multicomp-quarter-horizontal-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-landscape-multicomp-quarter-vertical-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-landscape-quarter-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-landscape-sixteenth-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-portrait-eighth-horizontal-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-portrait-eighth-vertical-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-portrait-full-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-portrait-half-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-portrait-multicomp-full-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-portrait-multicomp-half-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-portrait-multicomp-quarter-horizontal-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-portrait-multicomp-quarter-vertical-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-portrait-multicomp-sixteenth-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-portrait-quarter-horizontal-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-portrait-quarter-vertical-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-portrait-sixteenth-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-proposal-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-stress-test-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-table-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-tear-sheet-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-module-text-color {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-more {
    width: math.div(21px, $font-size-base) * 1em;
    height: math.div(4px, $font-size-base) * 1em;
}
.icon-move {
    width: math.div(22px, $font-size-base) * 1em;
    height: math.div(22px, $font-size-base) * 1em;
}
.icon-my-ycharts {
    width: math.div(23px, $font-size-base) * 1em;
    height: math.div(22px, $font-size-base) * 1em;
}
.icon-new {
    width: math.div(41px, $font-size-base) * 1em;
    height: math.div(21px, $font-size-base) * 1em;
}
.icon-newspaper {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-notes-add {
    width: math.div(12px, $font-size-base) * 1em;
    height: math.div(14px, $font-size-base) * 1em;
}
.icon-notes {
    width: math.div(13px, $font-size-base) * 1em;
    height: math.div(15px, $font-size-base) * 1em;
}
.icon-notification {
    width: math.div(22px, $font-size-base) * 1em;
    height: math.div(22px, $font-size-base) * 1em;
}
.icon-open-arrow {
    width: math.div(14px, $font-size-base) * 1em;
    height: math.div(13px, $font-size-base) * 1em;
}
.icon-open-new-window {
    width: math.div(14px, $font-size-base) * 1em;
    height: math.div(14px, $font-size-base) * 1em;
}
.icon-open {
    width: math.div(19px, $font-size-base) * 1em;
    height: math.div(14px, $font-size-base) * 1em;
}
.icon-outlook-color {
    width: math.div(22px, $font-size-base) * 1em;
    height: math.div(22px, $font-size-base) * 1em;
}
.icon-overview-chart {
    width: math.div(23px, $font-size-base) * 1em;
    height: math.div(17px, $font-size-base) * 1em;
}
.icon-overview {
    width: math.div(16px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-password-no-show {
    width: math.div(22px, $font-size-base) * 1em;
    height: math.div(23px, $font-size-base) * 1em;
}
.icon-password-show {
    width: math.div(22px, $font-size-base) * 1em;
    height: math.div(22px, $font-size-base) * 1em;
}
.icon-pendo {
    width: math.div(22px, $font-size-base) * 1em;
    height: math.div(22px, $font-size-base) * 1em;
}
.icon-phone {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-pin-big {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-pin-filled {
    width: math.div(10px, $font-size-base) * 1em;
    height: math.div(11px, $font-size-base) * 1em;
}
.icon-pin {
    width: math.div(10px, $font-size-base) * 1em;
    height: math.div(11px, $font-size-base) * 1em;
}
.icon-portfolio-type-blended {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-portfolio-type-client {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-portfolio-type-household {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-portfolio-type-model {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-preview-medium {
    width: math.div(14px, $font-size-base) * 1em;
    height: math.div(17.5px, $font-size-base) * 1em;
}
.icon-preview-small {
    width: math.div(14px, $font-size-base) * 1em;
    height: math.div(18px, $font-size-base) * 1em;
}
.icon-preview {
    width: math.div(16px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-print {
    width: math.div(19px, $font-size-base) * 1em;
    height: math.div(16px, $font-size-base) * 1em;
}
.icon-pulling-down-index {
    width: math.div(17px, $font-size-base) * 1em;
    height: math.div(17px, $font-size-base) * 1em;
}
.icon-pulling-up-index {
    width: math.div(17px, $font-size-base) * 1em;
    height: math.div(17px, $font-size-base) * 1em;
}
.icon-quick-chart-color {
    width: math.div(12px, $font-size-base) * 1em;
    height: math.div(15px, $font-size-base) * 1em;
}
.icon-quickflows {
    width: math.div(24px, $font-size-base) * 1em;
    height: math.div(24px, $font-size-base) * 1em;
}
.icon-remove-circle {
    width: math.div(16px, $font-size-base) * 1em;
    height: math.div(16px, $font-size-base) * 1em;
}
.icon-reorder-leftright {
    width: math.div(16px, $font-size-base) * 1em;
    height: math.div(14px, $font-size-base) * 1em;
}
.icon-reorder-up {
    width: math.div(12.2px, $font-size-base) * 1em;
    height: math.div(14px, $font-size-base) * 1em;
}
.icon-report {
    width: math.div(15px, $font-size-base) * 1em;
    height: math.div(18px, $font-size-base) * 1em;
}
.icon-reset-zoom {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-reset {
    width: math.div(14px, $font-size-base) * 1em;
    height: math.div(16px, $font-size-base) * 1em;
}
.icon-resources-color {
    width: math.div(48px, $font-size-base) * 1em;
    height: math.div(40px, $font-size-base) * 1em;
}
.icon-retirement-withdrawals {
    width: math.div(19px, $font-size-base) * 1em;
    height: math.div(16px, $font-size-base) * 1em;
}
.icon-return {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-right-arrow {
    width: math.div(8px, $font-size-base) * 1em;
    height: math.div(13px, $font-size-base) * 1em;
}
.icon-save {
    width: math.div(16px, $font-size-base) * 1em;
    height: math.div(16px, $font-size-base) * 1em;
}
.icon-search {
    width: math.div(15px, $font-size-base) * 1em;
    height: math.div(16px, $font-size-base) * 1em;
}
.icon-send {
    width: math.div(29.5px, $font-size-base) * 1em;
    height: math.div(29.5px, $font-size-base) * 1em;
}
.icon-shape-circle {
    width: math.div(10px, $font-size-base) * 1em;
    height: math.div(10px, $font-size-base) * 1em;
}
.icon-shape-cross {
    width: math.div(11px, $font-size-base) * 1em;
    height: math.div(11px, $font-size-base) * 1em;
}
.icon-shape-diamond {
    width: math.div(10px, $font-size-base) * 1em;
    height: math.div(13px, $font-size-base) * 1em;
}
.icon-shape-triangle {
    width: math.div(10px, $font-size-base) * 1em;
    height: math.div(10px, $font-size-base) * 1em;
}
.icon-share {
    width: math.div(13px, $font-size-base) * 1em;
    height: math.div(16px, $font-size-base) * 1em;
}
.icon-shrink-content {
    width: math.div(15px, $font-size-base) * 1em;
    height: math.div(15px, $font-size-base) * 1em;
}
.icon-soon {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-sparkle {
    width: math.div(16px, $font-size-base) * 1em;
    height: math.div(16px, $font-size-base) * 1em;
}
.icon-square {
    width: math.div(10px, $font-size-base) * 1em;
    height: math.div(10px, $font-size-base) * 1em;
}
.icon-star {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-subscription-color {
    width: math.div(42px, $font-size-base) * 1em;
    height: math.div(48px, $font-size-base) * 1em;
}
.icon-substract {
    width: math.div(12px, $font-size-base) * 1em;
    height: math.div(12px, $font-size-base) * 1em;
}
.icon-team {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(17px, $font-size-base) * 1em;
}
.icon-template-alerts-color {
    width: math.div(48px, $font-size-base) * 1em;
    height: math.div(38px, $font-size-base) * 1em;
}
.icon-template-benchmark-portfolio-color {
    width: math.div(38px, $font-size-base) * 1em;
    height: math.div(48px, $font-size-base) * 1em;
}
.icon-template-chart-color {
    width: math.div(48px, $font-size-base) * 1em;
    height: math.div(38px, $font-size-base) * 1em;
}
.icon-template-client-portfolio-color {
    width: math.div(38px, $font-size-base) * 1em;
    height: math.div(48px, $font-size-base) * 1em;
}
.icon-template-comp-color {
    width: math.div(38px, $font-size-base) * 1em;
    height: math.div(48px, $font-size-base) * 1em;
}
.icon-template-custom-text-color {
    width: math.div(48px, $font-size-base) * 1em;
    height: math.div(38px, $font-size-base) * 1em;
}
.icon-template-economic-indicator-list-color {
    width: math.div(48px, $font-size-base) * 1em;
    height: math.div(38px, $font-size-base) * 1em;
}
.icon-template-heatmap-color {
    width: math.div(48px, $font-size-base) * 1em;
    height: math.div(38px, $font-size-base) * 1em;
}
.icon-template-household-portfolio-color {
    width: math.div(38px, $font-size-base) * 1em;
    height: math.div(48px, $font-size-base) * 1em;
}
.icon-template-model-portfolio-color {
    width: math.div(38px, $font-size-base) * 1em;
    height: math.div(48px, $font-size-base) * 1em;
}
.icon-template-multicomp-color {
    width: math.div(48px, $font-size-base) * 1em;
    height: math.div(38px, $font-size-base) * 1em;
}
.icon-template-news-ai-color {
    width: math.div(48px, $font-size-base) * 1em;
    height: math.div(38px, $font-size-base) * 1em;
}
.icon-template-news-color {
    width: math.div(48px, $font-size-base) * 1em;
    height: math.div(38px, $font-size-base) * 1em;
}
.icon-template-overview-color {
    width: math.div(38px, $font-size-base) * 1em;
    height: math.div(48px, $font-size-base) * 1em;
}
.icon-template-presentation-color {
    width: math.div(54px, $font-size-base) * 1em;
    height: math.div(44px, $font-size-base) * 1em;
}
.icon-template-proposal-color {
    width: math.div(44px, $font-size-base) * 1em;
    height: math.div(51px, $font-size-base) * 1em;
}
.icon-template-scatterplot-color {
    width: math.div(48px, $font-size-base) * 1em;
    height: math.div(38px, $font-size-base) * 1em;
}
.icon-template-security-list-color {
    width: math.div(48px, $font-size-base) * 1em;
    height: math.div(38px, $font-size-base) * 1em;
}
.icon-template-stress-test-color {
    width: math.div(38px, $font-size-base) * 1em;
    height: math.div(48px, $font-size-base) * 1em;
}
.icon-template-tearsheet-color {
    width: math.div(48px, $font-size-base) * 1em;
    height: math.div(38px, $font-size-base) * 1em;
}
.icon-template-tech-chart-color {
    width: math.div(48px, $font-size-base) * 1em;
    height: math.div(38px, $font-size-base) * 1em;
}
.icon-template {
    width: math.div(16px, $font-size-base) * 1em;
    height: math.div(18px, $font-size-base) * 1em;
}
.icon-thumb-down-active {
    width: math.div(18px, $font-size-base) * 1em;
    height: math.div(18px, $font-size-base) * 1em;
}
.icon-thumb-down {
    width: math.div(18px, $font-size-base) * 1em;
    height: math.div(18px, $font-size-base) * 1em;
}
.icon-thumb-up-active {
    width: math.div(18px, $font-size-base) * 1em;
    height: math.div(18px, $font-size-base) * 1em;
}
.icon-thumb-up {
    width: math.div(18px, $font-size-base) * 1em;
    height: math.div(18px, $font-size-base) * 1em;
}
.icon-tier-analyst {
    width: math.div(12px, $font-size-base) * 1em;
    height: math.div(12px, $font-size-base) * 1em;
}
.icon-tier-enterprise {
    width: math.div(12px, $font-size-base) * 1em;
    height: math.div(12px, $font-size-base) * 1em;
}
.icon-tier-presenter {
    width: math.div(12px, $font-size-base) * 1em;
    height: math.div(12px, $font-size-base) * 1em;
}
.icon-tier-professional {
    width: math.div(14px, $font-size-base) * 1em;
    height: math.div(12px, $font-size-base) * 1em;
}
.icon-tool-adv-fibonacci {
    width: math.div(16px, $font-size-base) * 1em;
    height: math.div(17px, $font-size-base) * 1em;
}
.icon-tool-adv-parallel-channel {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-tool-adv-pitchfork {
    width: math.div(13px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-tool-arrow-line {
    width: math.div(17px, $font-size-base) * 1em;
    height: math.div(16px, $font-size-base) * 1em;
}
.icon-tool-arrow-ray {
    width: math.div(19px, $font-size-base) * 1em;
    height: math.div(19px, $font-size-base) * 1em;
}
.icon-tool-arrow-segment {
    width: math.div(19px, $font-size-base) * 1em;
    height: math.div(19px, $font-size-base) * 1em;
}
.icon-tool-circle-outline {
    width: math.div(19px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-tool-circle {
    width: math.div(16px, $font-size-base) * 1em;
    height: math.div(16px, $font-size-base) * 1em;
}
.icon-tool-counter-arrow {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-tool-counter-label {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(23px, $font-size-base) * 1em;
}
.icon-tool-counter-vertical {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(23px, $font-size-base) * 1em;
}
.icon-tool-crooked-3 {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-tool-crooked-5 {
    width: math.div(23px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-tool-crooked-elliot-3 {
    width: math.div(24px, $font-size-base) * 1em;
    height: math.div(24px, $font-size-base) * 1em;
}
.icon-tool-crooked-elliot-5 {
    width: math.div(24px, $font-size-base) * 1em;
    height: math.div(24px, $font-size-base) * 1em;
}
.icon-tool-crosshair {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-tool-cursor {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-tool-dragger {
    width: math.div(9px, $font-size-base) * 1em;
    height: math.div(14px, $font-size-base) * 1em;
}
.icon-tool-flag-circle {
    width: math.div(19px, $font-size-base) * 1em;
    height: math.div(21px, $font-size-base) * 1em;
}
.icon-tool-flag-diamond {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(21px, $font-size-base) * 1em;
}
.icon-tool-flag-simple {
    width: math.div(19px, $font-size-base) * 1em;
    height: math.div(17px, $font-size-base) * 1em;
}
.icon-tool-flag-square {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(21px, $font-size-base) * 1em;
}
.icon-tool-horizontal-line {
    width: math.div(21px, $font-size-base) * 1em;
    height: math.div(9px, $font-size-base) * 1em;
}
.icon-tool-line-color {
    width: math.div(14px, $font-size-base) * 1em;
    height: math.div(21px, $font-size-base) * 1em;
}
.icon-tool-line {
    width: math.div(16px, $font-size-base) * 1em;
    height: math.div(16px, $font-size-base) * 1em;
}
.icon-tool-measure-x {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(21px, $font-size-base) * 1em;
}
.icon-tool-measure-xy {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(21px, $font-size-base) * 1em;
}
.icon-tool-measure-y {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(21px, $font-size-base) * 1em;
}
.icon-tool-parallel-channel {
    width: math.div(21px, $font-size-base) * 1em;
    height: math.div(25px, $font-size-base) * 1em;
}
.icon-tool-ray {
    width: math.div(19px, $font-size-base) * 1em;
    height: math.div(19px, $font-size-base) * 1em;
}
.icon-tool-rectangle-outline {
    width: math.div(16px, $font-size-base) * 1em;
    height: math.div(12px, $font-size-base) * 1em;
}
.icon-tool-rectangle {
    width: math.div(16px, $font-size-base) * 1em;
    height: math.div(12px, $font-size-base) * 1em;
}
.icon-tool-text-color {
    width: math.div(19px, $font-size-base) * 1em;
    height: math.div(21px, $font-size-base) * 1em;
}
.icon-tool-text {
    width: math.div(13px, $font-size-base) * 1em;
    height: math.div(15px, $font-size-base) * 1em;
}
.icon-tool-trend-segment {
    width: math.div(24px, $font-size-base) * 1em;
    height: math.div(22px, $font-size-base) * 1em;
}
.icon-tool-vertical-line {
    width: math.div(10px, $font-size-base) * 1em;
    height: math.div(21px, $font-size-base) * 1em;
}
.icon-tools-color {
    width: math.div(48px, $font-size-base) * 1em;
    height: math.div(42px, $font-size-base) * 1em;
}
.icon-tp-donut-chart-color {
    width: math.div(48px, $font-size-base) * 1em;
    height: math.div(38px, $font-size-base) * 1em;
}
.icon-tp-number-chart-color {
    width: math.div(48px, $font-size-base) * 1em;
    height: math.div(38px, $font-size-base) * 1em;
}
.icon-tp-number-text-color {
    width: math.div(48px, $font-size-base) * 1em;
    height: math.div(38px, $font-size-base) * 1em;
}
.icon-tp-range-color {
    width: math.div(48px, $font-size-base) * 1em;
    height: math.div(38px, $font-size-base) * 1em;
}
.icon-tweet {
    width: math.div(10px, $font-size-base) * 1em;
    height: math.div(14px, $font-size-base) * 1em;
}
.icon-uneditable {
    width: math.div(12px, $font-size-base) * 1em;
    height: math.div(12px, $font-size-base) * 1em;
}
.icon-update {
    width: math.div(13px, $font-size-base) * 1em;
    height: math.div(17px, $font-size-base) * 1em;
}
.icon-upload {
    width: math.div(13px, $font-size-base) * 1em;
    height: math.div(17px, $font-size-base) * 1em;
}
.icon-user {
    width: math.div(22px, $font-size-base) * 1em;
    height: math.div(22px, $font-size-base) * 1em;
}
.icon-video {
    width: math.div(15px, $font-size-base) * 1em;
    height: math.div(12px, $font-size-base) * 1em;
}
.icon-warning-octagon {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-warning-triangle {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-warning {
    width: math.div(12px, $font-size-base) * 1em;
    height: math.div(12px, $font-size-base) * 1em;
}
.icon-workflows {
    width: math.div(24px, $font-size-base) * 1em;
    height: math.div(24px, $font-size-base) * 1em;
}
.icon-y-logo-sm {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-y-logo {
    width: math.div(40.13px, $font-size-base) * 1em;
    height: math.div(40px, $font-size-base) * 1em;
}
.icon-zoom-x {
    width: math.div(20px, $font-size-base) * 1em;
    height: math.div(16px, $font-size-base) * 1em;
}
.icon-zoom-y {
    width: math.div(16px, $font-size-base) * 1em;
    height: math.div(20px, $font-size-base) * 1em;
}
.icon-zoom {
    width: math.div(16px, $font-size-base) * 1em;
    height: math.div(16px, $font-size-base) * 1em;
}
