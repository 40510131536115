.thickness {
    width: 40px;
    &:not([class*=bg]) {
        background-color: $gray-base-40;
    }

    &.thickness-thin {
        height: 1px;
    }

    &.thickness-medium {
        height: 3px;
    }

    &.thickness-thick {
        height: 5px;
    }

    &.thickness-unselected {
        height: 3px;
        background-color: $gray-base-20;
    }

    .dropdown & {
        width: 30px;
        margin-right: 12px;
        @include media-breakpoint-up(lg) {
            margin-right: 9px;
        }
    }

    .dropdown-item & {
        width: 20px;
        margin-left: 12px;
        margin-right: 12px;
        @include media-breakpoint-up(lg) {
            margin-left: 9px;
            margin-right: 9px;
        }
    }

    .custom-checkbox & {
        margin-left: 0;
    }
}

.thickness-wrap {
    @include base-height;
    display: flex;
    align-items: center;
}
