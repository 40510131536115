// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.input-group {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;

  .form-control {
    // Ensure that the input is always above the *appended* addon button for
    // proper border colors.
    position: relative;
    z-index: 1;
    flex: 1 1 auto;
    // Add width 1% and flex-basis auto to ensure that button will not wrap out
    // the column. Applies to IE Edge+ and Firefox. Chrome does not require this.
    width: 1%;
    margin-bottom: 0;

    // Bring the "active" form control to the front
    @include hover-focus-active {
      z-index: 2;
    }
    &.is-invalid {
        z-index: 2;
    }
  }
}

.input-group-addon,
.input-group-btn,
.input-group .form-control,
.input-group .custom-select,
.input-group .custom-file {
  display: flex;
  align-items: center;
}

.input-group .custom-file {
  display: flex;
  align-items: center;
}

.input-group .custom-select,
.input-group .custom-file {
  width: 100%;
}

.input-group-addon,
.input-group-btn {
  white-space: nowrap;
}

//
// Text input groups
//

.input-group-addon {
  // padding: $input-padding-y $input-padding-x;
  // margin-bottom: 0; // Allow use of <label> elements by overriding our default margin-bottom
  // font-size: $font-size-base; // Match inputs
  // font-weight: $font-weight-normal;
  // line-height: $input-line-height;
  // color: $input-group-addon-color;
  // text-align: center;
  // background-color: $input-group-addon-bg;
  // border: $input-border-width solid $input-group-addon-border-color;

  // Sizing
  &.form-control-sm {
    // font-size: $font-size-sm;
  }

  &.form-control-lg {
    // font-size: $font-size-lg;
  }

  // Nuke default margins from checkboxes and radios to vertically center within.
  input[type="radio"],
  input[type="checkbox"] {
    margin-top: 0;
  }
}

.form-control,
.custom-select,
.custom-file {
  + .input-group-addon:not(:first-child) {
    border-left: 0;
  }
}

//
// Button input groups
//

.input-group-btn {
  position: relative;
  align-items: stretch;
  white-space: nowrap;

  // Negative margin for spacing, position for bringing hovered/focused/actived
  // element above the siblings.
  > .btn {
    position: relative;

    + .btn {
      margin-left: -1px;
    }

    // Bring the "active" button to the front
    @include hover-focus-active {
      z-index: 2;
    }
  }

  &:first-child > .btn + .btn {
    margin-left: 0;
  }

  // Negative margin to only have a single, shared border between the two
  &:not(:last-child) {
    > .btn,
    > .btn-group {
      margin-right: -1px;
    }
  }
  &:not(:first-child) {
    > .btn,
    > .btn-group {
      z-index: 1;
      // remove nagative margin ($input-border-width) to solve overlapping issue with button.
      margin-left: 0;

      // When input is first, overlap the right side of it with the button(-group)
      &:first-child {
        margin-left: -1px;
      }

      // Because specificity
      @include hover-focus-active {
        z-index: 2;
      }
    }
  }
}


// Symbol switcher
.symbol-switcher {
    .form-control {
        width: 112px;
        padding-right: 25px;
    }

    .btn {
        &.arrow-right {
            width: auto;
        }
    }
}
