.page-tiers {
}

.tier-panel {
	.panel-header {
		flex-wrap: wrap;
	    padding-top: 12px;
	    padding-bottom: $spacer;
	    border-bottom: 1px solid $gray-base-80;
	    @include media-breakpoint-up(lg) {
	    	flex-direction: column-reverse;
	    	align-items: flex-start;
	    }
	}

	.tier-label {
		margin-left: 10px;
	    @include media-breakpoint-up(lg) {
	    	margin-left: 0;
			margin-bottom: $spacer;
	    }
	}

	.panel-content {
		padding-top: $spacer * 3;
		padding-bottom: 10px;
	}
}

.tier-label {
    display: inline-flex;
    align-items: center;
    padding: 4px 8px;
    white-space: nowrap;

    .tiers-icon:not(:last-child) {
        margin-right: 6px;
        fill: currentColor;
    }
}

.tier-label-orange {
	background-color: #FFDECB;
	color: #B64903;
}

.tier-label-blue {
	background-color: #C0DCF3;
	color: #114D7E;
}

.tier-label-purple {
	background-color: #E1DAEE;
	color: #3F246D;
}

.tier-label-green {
	background-color: #CFE6A8;
	color: #405A16;
}

.tier-purposes {
	padding-left: $spacer * 2;

	li:not(:last-child) {
		margin-bottom: 3px;
	}
}

.tier-features {
	margin-bottom: 0;
	padding-left: 0;
	list-style: none;

	li {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 3px;
		padding-bottom: 3px;

		.icon-check {
			flex: none;
			margin-left: 10px;
			fill: currentColor;
			color: $yc-blue;
		}
	}
}

.tier-table {
	border-collapse: separate;
	border-spacing: 0px;

	thead {
		position: sticky;
		top: -1px;
		&.is-pinned {
			th:first-child:before {
				box-shadow: 0px 4px 4px 0px rgba(#000, .25);
			}
		}

		th {
			&:first-child:before {
				position: absolute;
				top: 0;
				left: 50%;
				transform: translate(-50%, 0);
				display: block;
				width: var(--body-width, 100vw);
				transition: .3s ease box-shadow;
				z-index: -1;
				height: 100%;
				content: "";
				// hardcode for Safari
				max-height: 70px;
				@include media-breakpoint-up(lg) {
					max-height: 50px;
				}
			}
		}
	}

	th, td {
		&:first-child {
			padding-left: 12px;
		}
	}

	tbody {
		th, td {
			color: $gray-base;
		}
	}
}

.tier-table-2 {
	thead th:first-child:before {
		// hardcode for Safari
		max-height: 74px;
		@include media-breakpoint-up(lg) {
			max-height: 65px;
		}
	}
}

.tier-table-cta {
	display: flex;
	align-items: center;
	font-size: 12px;
	color: inherit;
	&:hover {
		color: inherit;
		text-decoration: underline;
	}
	@include media-breakpoint-up(lg) {
		font-size: 13px;
	}

	span {
		margin-right: $spacer;
		padding: 0 !important;
	}

	svg {
		fill: currentColor;
		font-size: 1em;
	}
}

.tier-table-titles {
	width: 250px;
}
