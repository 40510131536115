.collapse {
    display: none;

    &.show {
        display: block;
    }
}

.collapse-toggler {
    cursor: pointer;
    
    // Generate the caret automatically
    &:after {
        @include caret;
        border-top-color: $gray-base-50 !important;
    }

    .collapse-header.show &:after {
        transform: rotate(180deg);
    }
}

.collapse-header {
    cursor: pointer;
}

.collapse-mark {
    position: relative;
    width: 14px;
    height: 14px;
    border: 1px solid $yc-blue;
    background-color: $yc-blue;
    cursor: pointer;
    @include media-breakpoint-up(lg) {
        width: 10px;
        height: 10px;
    }
    &:before,
    &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        width: 8px;
        height: 0;
        border-bottom: 1px solid $gray-base-60;
        @include media-breakpoint-up(lg) {
            width: 6px;
        }
    }
    &:before {
        border-color: #fff;
        content: "";
    }
    &.collapsed {
        border-color: $gray-base-70;
        background-color: transparent;
        outline: 1px solid transparent;
        outline-offset: 1px;
        &:before,
        &:after {
            border-color: $gray-base-60;
        }
        &:after {
            transform: translate(-50%, -50%) rotate(90deg);
            content: "";
        }    
        // states
        &:hover {
            background-color: $yc-blue-90;
        }
        &:focus-visible {
            border-color: $yc-blue;
            outline-color: $yc-blue;
        }
    }
}

.collapse-chevron {
    width: 20px;
    height: 20px;
    background-color: transparent;
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%228%22%20height%3D%2216%22%20viewBox%3D%220%200%208%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M7.5%207.99999L2.19547%2015.1154C1.85609%2015.5686%201.25114%2015.6308%200.844651%2015.2526C0.438159%2014.8733%200.383513%2014.1983%200.721936%2013.7418L5.00353%207.99999L0.721936%202.25815C0.383513%201.80065%200.438159%201.12671%200.844651%200.747425C1.25114%200.369207%201.85609%200.431351%202.19547%200.884569L7.5%207.99999Z%22%20fill%3D%22%23B2B2B2%22%2F%3E%3C%2Fsvg%3E);
    background-position: center;
    background-repeat: no-repeat;

    &.collapsed {
        transform: rotate(90deg);
    }
}

.collapse-chevron-blue {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%228%22%20height%3D%2216%22%20viewBox%3D%220%200%208%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M7.5%207.99999L2.19547%2015.1154C1.85609%2015.5686%201.25114%2015.6308%200.844651%2015.2526C0.438159%2014.8733%200.383513%2014.1983%200.721936%2013.7418L5.00353%207.99999L0.721936%202.25815C0.383513%201.80065%200.438159%201.12671%200.844651%200.747425C1.25114%200.369207%201.85609%200.431351%202.19547%200.884569L7.5%207.99999Z%22%20fill%3D%22%230273CF%22%2F%3E%3C%2Fsvg%3E);
}

.show, .active {
    & > .collapse-chevron {
        transform: rotate(90deg);
    }
}
