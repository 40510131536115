.tippy-box[data-animation=fade][data-state=hidden] {
  opacity: 0
}

[data-tippy-root] {
  max-width: $tooltip-max-width;
}

.tippy-box {
  @include reset-text();
  @include textLabel();
  position: relative;
  background-color: $tooltip-arrow-color;
  outline: 0;
  opacity: $tooltip-opacity;
  transition-property: transform, visibility, opacity
}

.tippy-box[data-reference-hidden] {
  visibility: hidden;
  pointer-events: none;
}

.tippy-box[data-placement^=top]>.tippy-arrow {
  bottom: 0
}

.tippy-box[data-placement^=top]>.tippy-arrow:before {
  top: 100%;
  left: 0;
  border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
  border-top-color: initial;
  transform-origin: center top
}

.tippy-box[data-placement^=bottom]>.tippy-arrow {
  top: 0
}

.tippy-box[data-placement^=bottom]>.tippy-arrow:before {
  bottom: 100%;
  left: 0;
  border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
  border-bottom-color: initial;
  transform-origin: center bottom
}

.tippy-box[data-placement^=left]>.tippy-arrow {
  right: 0
}

.tippy-box[data-placement^=left]>.tippy-arrow:before {
  border-width: $tooltip-arrow-width 0 $tooltip-arrow-width $tooltip-arrow-width;
  border-left-color: initial;
  left: 100%;
  transform-origin: center left
}

.tippy-box[data-placement^=right]>.tippy-arrow {
  left: 0
}

.tippy-box[data-placement^=right]>.tippy-arrow:before {
  right: 100%;
  border-width: $tooltip-arrow-width $tooltip-arrow-width $tooltip-arrow-width 0;
  border-right-color: initial;
  transform-origin: center right
}

.tippy-box[data-inertia][data-state=visible] {
  transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11)
}

.tippy-arrow {
  width: $tooltip-arrow-width * 2;
  height: $tooltip-arrow-width * 2;
  color: $tooltip-arrow-color;
}

.tippy-arrow:before {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid
}

.tippy-content {
  position: relative;
  padding: 2px 8px 3px;
  color: $tooltip-color;
  // text-align: center;
  z-index: 1;
}


// Popover-like style
.tippy-box.popover {
  display: block;
  transform: none;
  margin: 0;
  background-color: $panel-background;
  opacity: 1;
  font-weight: inherit;
  white-space: normal;
  &.bg-semi-transparent {
    padding: 10px;
    background-color: rgba(#fff, .6);
    backdrop-filter: blur(3px);
    .tippy-content {
      max-width: 216px;
      padding: 0;
    }
  }

  .tippy-arrow {
    display: none;
  }

  .tippy-content {
    text-align: left;
    background-color: transparent;
    color: $gray-base-10;
  }

  .text-thin {
    color: $gray-base-30;
  }
}

.tooltip-miltiline,
.tooltip-multiline,
.multi-line-tooltip {
    padding-top: 4px;
    padding-bottom: 4px;
    white-space: normal;
    text-align: left;
}


.tooltip-row {
  font-size: 12px;
  &:not(:last-child) {
    margin-bottom: 6px;
  }
}


//

.popover[x-placement="right"] {
  margin-left: $spacer * 0.5;
}


// Tooltip in PDF Report Builder

.tippy-box.tooltip-pdf-preview {
  max-width: none !important;
  width: 356px;
  @include media-breakpoint-up(lg) {
    width: 460px;
  }

  .tippy-content {
    padding: 24px;
    border: 1px solid $gray-base-70;
  }

  .tippy-arrow {
    left: 1px;
    display: block;
    z-index: 1;
  }

  &[data-placement^=right] > .tippy-arrow:before {
    border-right-color: $panel-background;
  }
  &[data-placement^=right] > .tippy-arrow:after {
    position: absolute;
    left: -5px;
    top: 1px;
    transform: rotate(-45deg);
    display: block;
    width: .6rem;
    height: .6rem;
    border-top: 1px solid $gray-base-70;
    border-left: 1px solid $gray-base-70;
    content: "";
  }
}


// These tooltip styles are used when we need to display a tooltip in Django
// without using tippy.

.tooltip-bottom {
  position: relative;
}

.tooltip-bottom:hover::after {
  content: attr(tooltip-title);
  @include reset-text();
  @include textLabel();
  text-align: center;
  z-index: 1;
  position: absolute;
  top: calc(100% + 11px);
  color: $tooltip-color;
  background-color: $tooltip-arrow-color;
  opacity: $tooltip-opacity;
  transition-property: transform, visibility, opacity;
  padding: 2px 8px 3px;
  max-width: $tooltip-max-width;
}

.tooltip-bottom:hover::before {
  content: "";
  position: absolute;
  bottom: -11px;
  border-width: $tooltip-arrow-width;
  border-style: solid;
  border-color: transparent transparent $tooltip-arrow-color transparent;
  opacity: $tooltip-opacity;
}
