
// Chart
.chart-cover,
.chart-interactive,
.chart-presentation {
    .panel-fc-chart & {
        display: flex;
        width: 100%;
        min-height: 350px;
    }
}

.chart-interactive,
.chart-presentation {
    flex-direction: column;

    .chart-options {
        display: block;
    }

    .hide-data-labels {
        .highcharts-data-label {
            visibility: hidden !important;
        }
    }
}

.chart-item {
    position: relative;
    flex: none;
}

.chart-wrap {
    display: flex;
    @include media-breakpoint-up(md) {
        flex: auto;
    }
}

.chart-wrap-absolute {
    position: relative;
    height: 100%;

    .highcharts-container {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        max-width: 100%;
        max-height: 100%;
    }
}

.chart-legend {
    flex: none;
    height: fit-content;
    width: 100%;
    padding: 4px 14px;
    border-top: 1px solid $gray-base-70;
    border-bottom: 1px solid $gray-base-70;
    background-color: $gray-base-95;
    z-index: 1;

    &[class*=col-] {
        @include media-breakpoint-up(lg) {
            position: absolute;
            top: 0;
            left: 0;
            border-color: transparent;
            background-color: rgba(#000, 0.03);
        }
    }

    &.empty {
        background-color: transparent;
        padding: 0;
        border: 0;
    }

    .panel-fc-chart & {
        position: absolute;
        top: 0;
        left: 0;
    }

    .custom-checkbox {
        vertical-align: middle;

        .custom-control-description {
            font-size: 12px;
            text-transform: uppercase;
            color: $gray-base-50;
        }
        .custom-control-indicator {
            top: 2px;
        }
    }
}

.chart-legend-item {
    display: flex;
    padding-top: 1px;
    padding-bottom: 1px;
    color: $gray-base-10;
    &:first-child {
        padding-top: 6px;
        @include media-breakpoint-up(lg) {
            padding-top: 4px;
        }
    }
    &:last-child {
        padding-bottom: 6px;
        @include media-breakpoint-up(lg) {
            padding-bottom: 4px;
        }
    }

    div {
        min-width: 0;
        padding: 0;
    }

    svg {
        fill: currentColor;
    }
}

$chart-legend-date: 70px;

.chart-legend-grid {
    @include media-breakpoint-up(lg) {
        font-size: 11px;
    }
    @include media-breakpoint-up(xl) {
        font-size: 14px;
    }

    .chart-legend-item {
        display: grid;
        grid-template-columns: repeat(3, 1fr) $chart-legend-date;
        padding-left: 14px;
        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(4, 1fr) $chart-legend-date;
        }
        @include media-breakpoint-up(lg) {
            grid-template-columns: 2fr 4fr $chart-legend-date;
            padding-left: 0;
        }
        .chart-legend-item-title:first-child {
            grid-column: 1 / span 2;
            margin-left: -14px;
            @include media-breakpoint-up(lg) {
                grid-column: auto;
                margin-left: 0;
            }
        }
        .chart-legend-price {
            @include media-breakpoint-up(md) {
                grid-column: 3 / span 2;
            }
            @include media-breakpoint-up(lg) {
                grid-column: auto;
            }
        }
    }

    .chart-legend-item-multiple {
        grid-template-columns: repeat(3, 1fr);
        margin-right: $chart-legend-date;
        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(4, 1fr);
        }
        @include media-breakpoint-up(lg) {
            grid-template-columns: 33% repeat(4, 1fr);

            .chart-legend-item-title:first-child {
                grid-row: 1 / -3;
            }
        }
    }
}

.chart-legend-ohlc-item,
.chart-legend-ichimoku-item {
    position: relative;
    white-space: nowrap;
    &::before {
        position: absolute;
        left: 1px;
        font-weight: 700;
        content: attr(data-code);
    }
}

.chart-legend-ohlc-item {
    margin-left: 14px;
    padding-left: 14px;
}

.chart-legend-ichimoku-item[data-code] {
    padding-left: 20px;
}

.chart-legend-item-multiple {
    .chart-legend-item-title {
        white-space: normal;
        overflow: visible;
    }
}

.chart-legend-item-name {
    @extend .col-6;
}

.chart-legend-item-title {
    position: relative;
    display: inline-block;

    @include media-breakpoint-down(sm) {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: bottom;
    }
    &[class*="bullet-"], &.chart-bullet {
        padding-left: 14px;
    }

    &:before, .chart-bullet-point {
        position: absolute;
        border-radius: 50%;
        top: 6px;
        left: 0;
        width: 7px;
        height: 7px;
        margin-right: 6px;
        @include media-breakpoint-up(lg) {
            width: 6px;
            height: 6px;
        }
    }
}

.chart-legend-item-title.bullet-lg,
.bullet-lg {
    padding-left: 18px;
    &:before {
        top: 3px;
        width: 10px;
        height: 10px;
    }
}

a.chart-legend-item-title {
    @include underline();
}

.chart-legend-item-title.multiline,
strong.chart-legend-item-title {
    @include media-breakpoint-down(sm) {
        white-space: normal;
        overflow: visible;
    }
}

.chart-legend-item-date {
    @extend .col-3;
    @extend .col-i-lg-1;
    @extend .text-right;
}

.chart-legend-item-value {
    @extend .col-3;
    @extend .col-i-lg-1;
    @extend .text-right;
}

.chart-legend-annualized-data {

    .chart-legend-item-value {
        display: flex;
    }

    @include media-breakpoint-down(sm) {
        .chart-legend-item-name,
        .chart-legend-item-date {
            flex: 0 0 25%;
            max-width: 25%;
        }

        .chart-legend-item-value {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}

.chart-canvas {
    position: relative;
    flex: auto;
    min-height: 325px;
    max-width: 100%;

    .chart-control {
        position: absolute;
        top: 0;
        left: 0;
        width: auto;
        border: 0;
        z-index: 1;
    }
    .chart-control-item {
        padding-right: 17px;
        padding-left: 17px;
        &:first-child {
            padding-left: 12px;
        }
        &:last-child {
            padding-right: 12px;
        }
        @include media-breakpoint-up(lg) {
            padding-right: 8px;
            padding-left: 8px;
        }
    }
    .panel-data-empty-message {
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.chart-preview {
    max-width: 100%;
}

.chart-control {
    @include base-height();
    display: flex;
    justify-content: space-around;
    max-width: 100%;
    width: 100%;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    border: 1px solid $gray-base-90;
    &.chart-control-compact {
        .chart-control-item {
            padding-right: 8px;
            padding-left: 8px;
        }
    }
}

.chart-control-responsive {
    position: absolute;
    width: auto;
    border-width: 0;
    z-index: 1;
    @include media-breakpoint-down(md) {
        position: static;
        border-width: 0 0 1px;

        .panel-content & {
            max-width: none;
            margin-left: -$grid-gutter-width;
            margin-right: -$grid-gutter-width;
            padding-left: $grid-gutter-width;
            padding-right: $grid-gutter-width;
        }
    }

    &.chart-control-compact {
        .chart-control-item {
            padding-right: 8px;
            padding-left: 8px;
        }
    }
}

.chart-control-item {
    text-align: center;
    &.current {
        .chart-control-link {
            border-top-color: $yc-blue;
            color: $yc-blue;
            font-weight: 700;
        }
    }
}

.chart-control-link {
    font-size: .85em;
    display: inline-block;
    padding-top: 7px;
    border-top: 3px solid transparent;
    text-transform: uppercase;
    @include media-breakpoint-up(lg) {
        font-size: .9em;
        padding-top: 4px;
    }
}

.chart-axis-x {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.chart-axis-x-item {
    color: $gray-base-40;
    font-weight: 400;
    font-size: 10px;
    text-transform: uppercase;
    @include media-breakpoint-up(lg) {
        font-size: 11px;
    }
    padding-top: 10px;
    line-height: 1;
}

.chart-axis-y {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 10px;

    & + .chart-axis-y {
        padding-left: 14px;
        margin-left: 14px;
        border-left: 1px solid $gray-base-90;
        @include media-breakpoint-up(lg) {
            padding-left: 12px;
            margin-left: 12px;
        }
    }
    &:not(.text-left) {
        .chart-axis-y-item {
            padding-left: 10px;
            text-align: right;
        }
    }
}

.chart-axis-y-item {
    color: $gray-base-40;
    font-weight: 400;
    font-size: 10px;
    text-transform: uppercase;
    @include media-breakpoint-up(lg) {
        font-size: 11px;
    }
}

.chart-marker {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    height: 14px;
    margin-left: 5px;
    padding-right: 4px;
    padding-left: 2px;
    font-weight: 700;
    font-size: 11px;
    line-height: 1em;
    transition: .1s ease all;
    &:before {
        position: absolute;
        top: 0;
        right: 100%;
        width: 0;
        height: 0;
        border-width: 7px 5px 7px 0;
    }
}

.chart-separator {
    position: absolute;
    left: 1px;
    z-index: 2;
    width: 100%;
    border-bottom: 1px solid $gray-base-90;
}

// Chart inside .panel-data
.panel-data {

    .chart {
        padding-right: 0px;
    }

    .chart-canvas {
        height: 160px;
        min-height: 0;

        @include media-breakpoint-up(lg) {
            height: 210px;
        }

        &.chart-canvas-real {
            height: 187px;
            @include media-breakpoint-up(lg) {
                height: 234px;
            }
        }
    }

    .chart-item {
        &:last-child {
            .chart-wrap {
                padding-bottom: 5px;
                @include media-breakpoint-up(lg) {
                    padding-bottom: 3px;
                }
            }
        }
    }

    .chart-cover {
        @include media-breakpoint-up(lg) {
            margin-top: 0;
        }

        &.chart-tall {
            .chart-canvas {
                height: 210px;
            }

            @include media-breakpoint-up(lg) {
                .chart-canvas {
                    height: 325px;
                }

                .chart-control {
                    margin-right: 60px;
                    /* Class to prevent performance chart "select area to zoom" overlap */
                    &.chart-control-inline {
                        margin-right: 150px;
                    }
                }
            }
        }
    }

    .chart-pie {
        width: 100%;
        height: 225px;
        margin: 15px auto;
        &.empty {
            position: relative;
            &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: block;
                width: 205px;
                height: 205px;
                border: 1px solid $gray-base-60;
                border-radius: 50%;
                content: "";
            }
        }
        &.chart-sm.empty {
            height: 90px;
            &:before {
                height: 90px;
                width: 90px;
            }
        }

        img{
            display: block;
            max-width: 100%;
            height: auto;
        }

        .highcharts-container {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .chart-legend-item {
        .chart-legend-item-title {
            &:before, .chart-bullet {
                @include media-breakpoint-down(md) {
                  top: 7px;
                }
            }
        }
    }

    .chart-interactive {
        .chart-options {
            .chart-legend {
                .chart-legend-item-title {
                    font-size: .867rem; // 13px
                    @include media-breakpoint-up(lg) {
                        font-size: .857rem; // 12px
                    }
                    &:before, &.chart-bullet {
                        top: 5px;
                    }
                }
            }
        }
        .chart-legend {
            border-top: 0;

            &:not([class*=col]) {
                max-width: none;
            }

            &[class*=col] {
                @include media-breakpoint-down(md) {
                    margin-left: -20px;
                    margin-right: -20px;
                    width: auto;
                }
            }

            &.legend-off-chart {
                width: auto;
            }

            & + .chart-wrap .chart-control {
                @include media-breakpoint-up(lg) {
                    left: auto;
                    right: 0;
                }
            }
        }

        .chart-legend-item-title {
            width: auto;
            overflow: hidden;
            text-overflow: inherit;
            vertical-align: top;

            @include media-breakpoint-down(lg) {
                width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        &.empty {
            position: relative;

            .chart-wrap {
                padding-bottom: 20px;
            }

            .chart-canvas {
                outline: 1px solid $gray-base-90;
                background: linear-gradient(180deg, $gray-base-90 0, transparent 1px),
                            linear-gradient(90deg, $gray-base-90 0, transparent 1px);
                background-clip: border-box, border-box;
                background-size: 100% 25%, 33.4% 100%;
                background-position: left bottom, left -25% bottom;
            }

            .chart-axis-x-item {
                flex: 1;
                text-align: center;
            }

            .chart-axis-y {
                margin-bottom: -.5em;
                margin-top: -.5em;
            }

            .panel-ad-cta {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                white-space: nowrap;
                background-color: #fff;
                z-index: 1;
            }
        }
    }

    .chart-options {
        padding-top: 10px;
        padding-bottom: 10px;

        .chart-legend {
            position: static;
            display: inline-block;
            border: 0;
            padding: 0;
            background-color: transparent;
        }

        .chart-legend-item {
            display: inline-block;
            padding-bottom: 0;
            &:not(:last-child) {
                margin-right: 20px;
            }
        }

        .chart-legend-item-title {
            width: auto;
            overflow: visible;
            text-overflow: inherit;
        }

        .data-range-cover {
            margin-bottom: 0;

            .form-label {
                @include smallCapsLabel();
                max-width: none;
                font-weight: 400;
                color: $gray-base-40;
            }

            .form-control {
                max-width: 125px;
            }

            & ~ .btn:last-child {
                margin-left: auto;
            }
        }

        & + .chart-options {
            border-top: 1px solid $gray-base-90;
            border-bottom: 1px solid $gray-base-90;
        }
    }

    .col-chart-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .chart-wrap {
            flex: none;
        }

        & + [class*='col'] {
            @include media-breakpoint-up(lg) {
                margin-top: 5px;
            }
        }
    }
}

.panel-header {
    .panel-action.chart-options {
        padding-top: 0;
        padding-bottom: 0;
    }
}

// Highcharts styles

.highcharts-tooltip-box {
    fill: transparent !important;
}

.highcharts-tooltip-inner {
    @include box-shadow;
    padding: 10px;
    font-size: 13px;
    border: 1px solid $gray-base-80;
    color: $gray-base-50;
    background-color: rgba(#fff, .95);

    .hightcharts-header {
        color: $gray-base-10;
    }

    &.bg-black {
        border: 0;
        background: $gray-base;
        color: $gray-base-80;

        .hightcharts-header {
            color: #fff;
        }
    }
}

// HighCharts Tooltip styles
.highcharts-tooltip {
    @include box-shadow;
    stroke-width: 0;
}

.highcharts-tooltip-box {
    fill: #fff;
    stroke-width: 0;
}

.highcharts-tooltip-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    line-height: 20px;
}

.highcharts-tooltip-item-name  {
    margin-right: 10px;
    &:before {
        width: 7px;
        height: 7px;
    }
}

.highcharts-tooltip-item-value {
    font-weight: 700;
}

.highcharts-tooltip-bullet {
    display: inline-block;
    width: 7px;
    height: 7px;
    margin-right: 7px;
    border-radius: 50%;
    background-color: $gray-base-90;
    vertical-align: middle;
    @include media-breakpoint-up(lg) {
        width: 6px;
        height: 6px;
        margin-right: 6px;
    }
}

.highcharts-plot-line-label {
    background-color: rgba(255, 255, 255, 0.9);
    font-weight: 700;
    padding: 3px;
}

.chart-interactive {
    .flag-tooltip {
        background-color: rgba(#f2f2f2, 0.9);
        border: 1px solid #7f7f7f;
        padding: 8px;

        .info-label {
            text-decoration: underline;
        }
    }
}

.highcharts-tooltip-table {
    font-weight: 400;

    td:not(:last-child) {
        padding-right: 3px;
    }
}

// Custom icon bullets

.custom-bullet {
    padding-left: 26px;
}

.chart-horizontal-bars {

    .chart-bar {
        flex: 1;
        display: flex;
        align-items: flex-end;
        height: 10px;
        background-color: $gray-base-90;
        transition: .3s ease opacity;
        &:hover {
            .chart-bar-fill {
                opacity: .75;
            }
        }
    }

    .chart-bar-fill {
        position: relative;
        height: 100%;
        transition: .3s ease width;
    }

    .chart-bar-value {
        @include chartAxis();
        position: absolute;
        top: 100%;
        width: 100%;
        margin-top: 3px;
        text-align: center;
    }
}

.chart-treemap {
    .highcharts-label > span {
        padding: 10px;
        font-family: inherit !important;
    }

    .highcharts-label-title {
        display: block;
        font-size: 14px;
        font-weight: 700;
        text-decoration: underline;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .highcharts-label-value {
        margin-top: 2px;
        font-size: 13px;
        font-weight: 400;
    }
}

rect.highcharts-point.bg-transparent {
    stroke-width: 0;
    outline: 1px solid $gray-base-90;
    outline-offset: -2px;
}

.highcharts-navigator-handle {
    stroke: $gray-base-40;
    &:active {
        fill: $yc-blue;
    }
}

/**
Dark mode modifications
*/
.dark-mode {
    .highcharts-grid-line, .highcharts-plot-border, .highcharts-axis-line, .highcharts-tick {
        stroke: rgb(55, 60, 62);
    }
    .flag-tooltip {
        color: #e8e6e3 !important;
    }
    .highcharts-tooltip-title, .highcharts-tooltip-table {
        color: #e8e6e3;
    }
    .highcharts-plot-band:not([fill="transparent"]) {
        fill: rgb(88, 88, 88) !important;
    }

    // Tech Chart-specific modifications
    .highcharts-scrollbar-track {
        fill: $gray-base-20;
        stroke: $gray-base-30;
    }
    .highcharts-scrollbar-button {
        fill: $gray-base-40;
        stroke: $gray-base-30;
    }
    .highcharts-navigator-outline {
        stroke: $gray-base-30;
    }

    .highcharts-hollowcandlestick-series .highcharts-point-up[stroke="black"] {
        stroke: $gray-base-90;
    }

    .highcharts-hollowcandlestick-series .highcharts-point-down-bearish-up {
        stroke: $gray-base-90;
        fill: $gray-base-90;
    }

    .highcharts-volume-series .highcharts-point[fill="#1A1A1A"][stroke="#ffffff"] {
        fill: $gray-base-90;
        pointer-events:none
    }

    .highcharts-series.highcharts-heatmap-series path {
        stroke: $gray-base-10;
    }
}
