
.data-range-cover {
    // flex-basis: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:not(.panel-action) {
        margin-top: .05rem;
    }

    &.data-range-cover-rich {
        padding: 0 7px;
        & > .dropdown {
            align-self: stretch;
            display: flex;

            .dropdown-menu {
                left: auto;
            }

            .form-label {
                display: flex;
                align-items: center;
                max-width: none;
            }
        }
    }

    .inputs-wrap {
        @include make-row;
        margin-left: -7px;
        margin-right: -7px;
        flex-grow: 1;
    }

    .form-label {
        max-width: 40px;
        margin-right: 12px;
        text-align: right;
        @include media-breakpoint-up(md) {
            max-width: none;
            white-space: nowrap;
        }
    }

    .form-group {
        padding-left: 7px;
        padding-right: 7px;
        margin-left: 0;
        &:not(:only-child) {
            @include make-col(6);
        }
        &:only-child {
            flex: auto;
        }

        &.show > .dropdown-toggle {
            background-color: #fff;
        }
    }
}
