
.panel-landing {
    .panel-header {
        padding-top: 14px;
        padding-bottom: 14px;

        .dropdown {
            display: block;

            .dropdown-toggle {
                white-space: nowrap; // todo -- fix col max-width
            }
        }
    }
}

.landing-list {
    margin-left: $grid-gutter-width;
    margin-right: $grid-gutter-width;

    .modal-body & {
        .landing-list-item {
            border-top-color: $gray-base-90;
        }
    }
}

.landing-list-item {
    display: flex;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 14px;
    border-top: 1px solid $gray-base-80;
    @include media-breakpoint-up(lg) {
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .btn-transparent {
        fill: $gray-base-50;
        &:hover,
        &:focus,
        &:active {
            background-color: $yc-blue-90;
        }
        &.disabled {
            fill: $gray-base-60;
            &:hover,
            &:focus,
            &:active {
                background-color: transparent;
            }
        }
    }
}

.landing-list-item-image {
    flex: none;
    width: 80px;
    height: 100px;
    margin-right: $grid-gutter-width;
    border: 1px solid $gray-base-80;
    background-color: $gray-base-90;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iZW1iZWQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iODBweCIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iNjAgNTAgODAgMTAwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDYwIDUwIDgwIDEwMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHRpdGxlPnBsYWNlaG9sZGVyLXJlcG9ydDwvdGl0bGU+PHJlY3QgeD0iNzkuODE1IiB5PSI4Mi45MzYiIGZpbGw9IiNCMkIyQjIiIHdpZHRoPSI0MC42MTUiIGhlaWdodD0iNC40ODYiLz48cmVjdCB4PSI3OS44MTUiIHk9IjkzLjY5MiIgZmlsbD0iI0IyQjJCMiIgd2lkdGg9IjQwLjYxNSIgaGVpZ2h0PSI0LjQ4NiIvPjxyZWN0IHg9Ijc5LjgxNSIgeT0iMTAzLjkzOCIgZmlsbD0iI0IyQjJCMiIgd2lkdGg9IjQwLjYxNSIgaGVpZ2h0PSI0LjQ4NiIvPjxyZWN0IHg9Ijc5LjgxNSIgeT0iMTEzLjk4NyIgZmlsbD0iI0IyQjJCMiIgd2lkdGg9IjE5Ljc4NSIgaGVpZ2h0PSI0LjQ4NiIvPjwvc3ZnPg==);
    @include media-breakpoint-up(lg) {
        width: 65px;
        height: 81px;
    }
}

.landing-list-item-body {
    flex: auto;
}

.landing-list-item-header {

}

.landing-list-item-title {
    @include headerMedium();
}

.landing-list-item-title-icon {
    margin-left: 3px;
    fill: $gray-base-50;
    vertical-align: middle;
}

.landing-list-item-info {
    display: flex;
    flex-wrap: wrap;
}

.landing-list-item-info-item {
    &:not(:last-child) {
        margin-right: $grid-gutter-width * 0.5;
        &:after {
            display: inline-block;
            padding-left: $grid-gutter-width * 0.5;
            content: "|";
        }
    }
}

.landing-list-item-content {
    padding-top: 4px;
    &.empty {
        color: $gray-base-60;
    }
}

.landing-list-item-controls {
    margin-left: $grid-gutter-width;

    .btn-group {
        display: flex;
        justify-content: flex-end;

        .dropdown {
            display: block;

            .dropdown-toggle {
                border-left: 0;
                &:after {
                    margin-left: 0;
                }
            }
        }
    }

    .dropdown-menu {
        min-width: 0;
    }

    yc-simple-dropdown {
        display: block;
    }


    .arrow-bottom:after {
        border-top-color: $gray-base-40;
    }

    & > *:last-child {
        margin-top: $grid-gutter-width;
    }

    .btn-secondary:not(.disabled) {
        .icon-check {
            fill: $yc-blue;
        }

        .icon-edit {
            fill: $gray-base-40;
        }
    }
}

.landing-list-item-status {
    text-align: right;
    font-weight: 700;
}
