.chart-progress {
    display: flex;
    height: 20px;

    &.chart-progress-md,
    &.chart-progress-sm {
        position: relative;
        align-items: center;
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 0;
            height: 100%;
            border-left: 1px solid $gray-base-70;
            content: "";
        }
    }

    .table & {
        height: 20px;
    }

    &.chart-progress-vertical {
        height: 100%;
        width: 40px;
        flex-direction: column;
    }

    &.empty {
        position: relative;
        border: 1px solid $gray-base-80;
    }

    .panel-data-empty-message {
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.chart-progress-bar {
    flex: none;
    height: 16px;
    transition: .3s ease width;

    .chart-progress-md & {
        height: 16px;
    }

    .chart-progress-sm & {
        height: 10px;
    }
}
