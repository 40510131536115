
.options-list {
    border-top: 1px solid $gray-base-90;

    .input-group + & {
        border: 0;
    }
}

ul.options-list {
    padding-left: 0;
    margin-bottom: 0;
}

.options-list-hover {
    .options-list-item {
        &:hover,
        &.active {
            background-color: $yc-blue-95;
        }
    }

    .options-list-item-title {
        padding-left: 12px;
    }
}

.options-list-item {
    @include base-min-height();
    border-bottom: 1px solid $gray-base-90;
}

.options-list-item-header {
    display: flex;
    min-height: $base-height;
    padding-top: 5px;
    padding-bottom: 5px;
    @include media-breakpoint-up(lg) {
        min-height: 34px;
    }

    .form-control {
        width: 55px;
        margin-top: 6px;
        margin-left: 18px;
        margin-right: 18px;
        @include media-breakpoint-up(lg) {
            margin-left: 16px;
            margin-right: 16px;
        }

        & + .options-list-item-controls {
            margin-top: 10px;
        }
    }
}

.options-list-item-body {
    padding-bottom: 6px;

    .btn-group {
        .form-control {
            color: $yc-blue;
            background-color: $yc-blue-95;
            border-color: $gray-base-75;
            font-weight: 700;
        }

        .btn:first-child,
        .btn:last-child {
            flex: none;
            width: $base-height;
            padding: 0;
            @include media-breakpoint-up(lg) {
                width: $base-height-desktop;
            }
        }
    }
}

.options-list-item-title {
    display: flex;
    align-items: center;
    flex: auto;
    flex-basis: 0;

    .disabled & {
        color: $gray-base-70;
    }

    .custom-control {
        margin-right: $spacer;
    }
}

.options-list-item-title-labels {
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;

    & > .btn {
        padding-right: 0;
        &:not(:last-child) {
            margin-right: 5px;
        }
    }

    .btn-close {
        fill: $gray-base-70;
        font-size: 10px;
    }
}

%options-list-control {
    fill: $gray-base-50;
    &:hover,
    &:focus,
    &:active {
        background-color: $gray-base-90;
    }
    &.disabled {
        fill: $gray-base-70;
        cursor: not-allowed;
        &:hover,
        &:focus,
        &:active {
            background-color: transparent;
        }
    }
}

.options-list-item-controls {
    white-space: nowrap;
    transition: .1s ease opacity;

    .panel-sidebar-section & {
        margin-right: -8px;
    }

    .btn {
        display: inline-flex;
    }

    .btn-xs {
        @include media-breakpoint-up(lg) {
            margin-top: 2px;
        }

        &.btn.btn-circle + .btn-xs {
            margin-left: 0;
        }
    }

    .btn-transparent { 
        @extend %options-list-control;
    }
}

.options-list-item-remove {
    display: flex;
    width: 1.5rem;

    .panel-sidebar-section & {
        margin-left: -4px;
        margin-right: 4px;
    }

    .btn-xs {
        @include media-breakpoint-up(lg) {
            margin-top: 2px;
        }
    }
    
    .btn-transparent {
        @extend %options-list-control;
    }
}

.options-list-skeleton {
    position: relative;
    overflow: hidden;

    padding-top: 6px;
    @include media-breakpoint-up(lg) {
        padding-top: 3px;
    }

    .skeleton {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}
