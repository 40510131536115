
// Toggler

.panel-sidebar-toggler {
    fill: $gray-base-80;
    @include media-breakpoint-up(lg) {
    }
    &:focus {
        outline: none;
    }

    &.panel-sidebar-toggler-open {
        align-self: center;
        position: relative;
        margin-right: $grid-gutter-width * 0.5;
        margin-left: $grid-gutter-width * 0.5;
        &:after {
            @include base-height();
            position: absolute;
            top: 50%;
            right: -10px;
            transform: translateY(-50%);
            width: 0;
            border-right: 1px solid $gray-base-90;
            content: "";
        }
    }

    &.panel-sidebar-toggler-close {
        position: absolute;
        top: 10px;
        right: 20px;
        transform: rotate(90deg);
    }
}


// Layout

.panel-sidebar-wrap {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-right: $grid-gutter-width * 0.5;
    padding-left: $grid-gutter-width * 0.5;
    width: 41.66667%;
    transition: .1s ease all;
    z-index: 2;
    opacity: 1;
    @include media-breakpoint-up(md) {
        width: 33.33333%;
    }
    @include media-breakpoint-up(lg) {
        width: 25%;
    }
    @include media-breakpoint-up(xl) {
        width: 355px;
    }

    .panel-content {

    }
}

.panel-sidebar {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    border-right: 1px solid $gray-base-90;

    .nav.nav-tabs {
        margin-top: -6px;
        padding-right: 60px;
        background-color: $gray-base-80;
        @include media-breakpoint-up(lg) {
            padding-right: 50px;
        }

        .nav-link {
            border-top-width: 6px;
            &.active {
                background-color: $panel-background;
            }
        }
    }

    .tab-pane {
        padding-left: 0;
        padding-right: 0;
    }

    .tab-content + .panel-sidebar-toggler.btn-transparent.btn-lightgray {
        &:hover {
            background-color: transparent;
        }
    }

    .tab-content.panel-sidebar-scroll + .panel-sidebar-toggler {
        margin-top: -6px;
    }
}

.panel-sidebar-scroll {
    overflow-y: auto;
}


.is-panel-sidebar-shown {
    .panel-content-wrap {
        @extend .col-7;
        @extend .col-md-8;
        @extend .col-lg-9;
        @include media-breakpoint-up(xl) {
            flex: 0 0 calc(100% - 355px);
            max-width: calc(100% - 355px);
        }
    }

    &.panel-with-sidebar {
        .panel-content {
            padding-left: 0;
        }
    }

    .panel-sidebar-toggler-open {
        display: none;
    }
}

.is-panel-sidebar-hidden {
    .panel-sidebar-wrap {
        transform: translate3d(-100%, 0, 0);
        opacity: 0;
    }
}


// Custom width

.panel-with-narrow-sidebar,
.portfolios {
    &.is-panel-sidebar-shown .panel-content-wrap {
        flex: 0 0 65%;
        max-width: 65%;
        @include media-breakpoint-up(md) {
            flex: 0 0 70%;
            max-width: 70%;
        }
        @include media-breakpoint-up(lg) {
            flex: 0 0 80%;
            max-width: 80%;
        }
    }

    .panel-wrap.row {
        width: calc(100% + #{$grid-gutter-width});
    }

    .panel-sidebar-wrap {
        width: 35%;
        @include media-breakpoint-up(md) {
            width: 30%;
        }
        @include media-breakpoint-up(lg) {
            width: 20%;
        }
    }
}

.panel-sidebar-220 {
    &.is-panel-sidebar-shown .panel-content-wrap {
        flex: 0 0 auto;
        max-width: calc(100% - 220px);
    }
    
    .panel-sidebar-wrap {
        width: 220px;
    }
}

.panel-width-fixed-width {
    &.is-panel-sidebar-shown .panel-content-wrap {
        flex: 0 0 calc(100% - 235px - #{$grid-gutter-width});
        max-width: calc(100% - 235px - #{$grid-gutter-width});
        @include media-breakpoint-up(xl) {
            flex: 0 0 calc(83.4% - #{$grid-gutter-width});
            max-width: calc(83.4% - #{$grid-gutter-width});
        }
    }

    .panel-wrap.row {
        width: calc(100% + #{$grid-gutter-width});
    }

    .panel-sidebar-wrap {
        width: calc(235px + #{$grid-gutter-width});
        @include media-breakpoint-up(xl) {
            width: calc(16.6% + #{$grid-gutter-width});
        }
    }
}

.panel-sidebar-tabs.tabs.tabs-vertical {
    border-right: 1px solid $gray-base-70;
    background-color: $gray-base-80;

    .nav-item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: $base-height;
        width: $base-height;
        padding-left: 0;
        border-color: $gray-base-70;

        &:first-child {
            border-top: 0;
        }
        &:not(:first-child) {
            margin-top: -1px;
        }
    }

    .nav-link {
        padding: 0;
        text-align: center;
        &:before {
            content: none;
        }
        &.active {
            fill: $yc-blue;
            background-color: #fff;
        }
        &:hover {
            background-color: $yc-blue-90;
        }
        &:focus {
            outline: 1px solid $yc-blue;
            z-index: 1;
        }
        &:active {
            position: relative;
            z-index: 2;
            outline: 2px solid $yc-blue;
        }
    }

    &,
    & + .tab-content {

        .nav {
            margin-top: -5px;
            padding-left: 0;
            padding-right: 0;
        }
    }
}

// Sections

.panel-sidebar-section {
    padding-top: 14px;
    padding-bottom: 6px;
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
    @include media-breakpoint-up(lg) {
        padding-top: 10px;
        padding-bottom: 4px;
    }
    &:not(:first-of-type) {
        border-top: 4px solid $gray-base-90;
    }

    .divider-vertical {
        margin-left: 9px;
    }

    &.panel-annotation-option:last-child {
        padding-bottom: 40px;
    }

    &.pt-0 {
        padding-top: 0;
    }
}

.panel-sidebar-section-header {
    padding-bottom: 8px;
    @include media-breakpoint-up(lg) {
        padding-bottom: 6px;
    }
    &.pb-0,
    &:only-child {
        padding-bottom: 0;
    }
}

.panel-sidebar-section-body {
    padding-top: 6px;
    padding-bottom: 2px;
    @include media-breakpoint-up(lg) {
        padding-top: 4px;
    }
}


.panel-sidebar-section-title  {
    @include headerDefault();
    color: $gray-base-10;

    & + .panel-sidebar-section-row {
        padding-top: 14px;
        @include media-breakpoint-up(lg) {
            padding-top: 10px;
        }
    }

    &.chart-legend-item-title + .divider-vertical {
        margin-right: 9px;
    }
}

.panel-sidebar-section-row {
    display: flex;

    .panel-sidebar-section-title {
        flex: auto;
        align-self: center;
    }

    .custom-radio:last-child {
        margin-right: 0;
    }
}

.panel-sidebar-section-control {
    align-self: center;

    .btn-secondary,
    a.btn-secondary {
        @extend .btn-transparent2;
    }

    .btn-transparent,
    a.btn-transparent {
        @extend .btn-transparent3;
    }
}

.panel-sidebar-subsection {
    position: relative;
    margin-left: -$grid-gutter-width;
    margin-right: -$grid-gutter-width;    

    .panel-sidebar-section {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.panel-sidebar-levels {
    padding-left: 14px;
    font-size: 13px;
    line-height: 1.538;
    color: $gray-base-40;
}

.panel-category {
    position: relative;
    &.border-t {
        border-top-width: 0;
        &:before {
            position: absolute;
            top: 0;
            display: block;
            height: 0;
            border-top: 1px solid $gray-base-90;
            content: "";
        }
    }

    .pdf-widgets {
        margin-left: -6px;
        margin-right: -6px;
    }
}

.pdf-widgets-search {
    margin-left: -6px;
    margin-right: -6px;
}

.panel-category-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    padding-bottom: 12px;

    &.collapse-toggler.show:after {
        transform: rotate(180deg);
    }
}

.panel-category-body {

}

.panel-sidebar-section .panel-category:before {
    left: -$grid-gutter-width;
    right: -$grid-gutter-width;
}

.panel-toolbar {
    position: relative;
    &:before,
    &:after {
        position: absolute;
        left: 0;
        display: block;
        width: 100%;
        height: 0;
        border-top: 1px solid $gray-base-70;
        content: "";
    }
    &:after {
        top: calc(100% - 1px);
    }
    &:before {
        top: 0;
    }

    .btn-group {
        z-index: 1;
    }

    .btn {
        height: $base-height;
        padding-left: 0;
        padding-right: 0;
        &:not(.disabled) {
            fill: $gray-base-40;
        }
        &.disabled {
            pointer-events: none;
        }
    }
}
