/*  Quad table */

.quad-table {
    display: flex;
    flex-direction: column;

    .table {
        table-layout: fixed;

        td, th {

            .results-table & {
                min-width: $quad-table-cell-width;
                width: $quad-table-cell-width;
            }

            &.colTxtShort {
                min-width: $quad-table-cell-width;;
                width: $quad-table-cell-width;
            }
            &.colTxtMed {
                min-width: 101px;
                width: 101px;
            }
            &.colTxtLong {
                min-width: 151px;
                width: 151px;
            }
            &.colTxtXLong {
                min-width: 201px;
                width: 201px;
            }

            &.quad-table-fund-name {
                min-width: $quad-table-cell-name-width;
                width: $quad-table-cell-name-width;
            }

            .panel-data .panel-content & {
                &:last-child {
                    width: auto;
                    padding-right: 10px;
                }
            }
        }

        td {
            padding-top: 3px;
            padding-bottom: 2px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .results-table & {
                @include base-height;
                @include base-min-height;
            }
        }

        thead th {
            position: relative;
            padding: 0;
            vertical-align: bottom;
            color: $gray-base-10;
            @include media-breakpoint-up(lg) {
                .th-controls.show {
                    opacity: 0;
                }

                &:hover .th-controls {
                    opacity: 1;
                }
            }

            .th-controls.show + span {
                margin-top: 48px;
            }

            .th-controls + span {
                margin-top: 30px;
            }

            span {
                display: block;
                padding-left: 6px;
                padding-right: 6px;
                transition: .1s ease margin;
                @include media-breakpoint-up(lg) {
                    padding-left: 9px;
                    padding-right: 9px;
                }
            }

            &:not(.unselectable) {
                &:hover {
                    cursor: pointer;
                    span {
                        text-decoration: underline;
                    }
                }
                &:active,
                &:focus {
                    color: $yc-blue;
                }
            }

            &.th-sort-asc,
            &.th-sort-desc {
                span {
                    padding-right: 20px;
                }

                &.text-right {
                    span {
                        padding-right: 10px;
                        padding-left: 20px;
                    }
                    &:last-child span {
                        padding-right: 0;
                    }
                }
            }
        }

        td {
            &:not(.unselectable) {
                cursor: pointer;
                &:active,
                &:focus {
                    span {
                        text-decoration: underline;
                    }
                }
            }
        }

        th:not(:last-child),
        td:not(:last-child) {
            border-right: $table-border;
        }

        th:last-child,
        td:last-child{
            width: auto;
        }

        .th-controls {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            width: 100%;
            justify-content: space-between;
            padding: 10px;
            transition: .1s ease opacity;

            @include media-breakpoint-up(md) {
                opacity: 0;

                &.show {
                    opacity: 1;
                }
            }

            @include media-breakpoint-down(md) {
                opacity: 1;
            }

            .btn-transparent {
                flex: none;
                fill: $gray-base-50;
                &:hover {
                    background-color: $yc-blue-90;
                }
                &:first-child {
                    margin-left: -5px;
                }
                &.btn-close {
                    margin-right: -5px;
                    order: 1;
                }
            }
        }

        tbody td {
            padding-left: $grid-gutter-width * 0.5;
            padding-right: $grid-gutter-width * 0.5;
            color: $gray-base-10;

            a {
                @include underline();
            }
        }

        .td-controls {
            .btn-transparent {
                fill: $yc-blue;
                &:hover {
                    background-color: $yc-blue-90;
                }
            }
        }

        tr.pinned {
            position: absolute;
            width: 100%;
            background-color: $gray-base-95;
        }
    }
}

.quad-table-header-right,
.quad-table-content {
    display: flex;
    overflow: hidden;
}

.quad-table-header {
    position: relative;
}

.quad-table-header-left {
    position: absolute;
    top: 0;
    left: 0;
    width: $quad-table-sidebar-width;
    height: 100%;
    background-color: #fff;
    @include media-breakpoint-up(lg) {
        width: $quad-table-sidebar-width-desktop;
    }

    table, thead, tr {
        display: block;
        height: 100%;
    }

    .table {
        th, td {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 100%;
            width: auto;
        }
    }

    .table {
        border-right: $table-border;
    }
}

.quad-table-header-right {
    margin-left: $quad-table-sidebar-width;
    @include media-breakpoint-up(lg) {
        margin-left: $quad-table-sidebar-width-desktop;
    }
}

.quad-table-content {
    position: relative;
    display: flex;
    max-height: $quad-table-height;

    .table {
        border-bottom: 0;

        tr:first-child td {
            border-top: 0;
        }
    }
}

.quad-table-sidebar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: $quad-table-sidebar-width;
    z-index: 1;
    @include media-breakpoint-up(lg) {
        width: $quad-table-sidebar-width-desktop;
    }

    .table {
        border-right: $table-border;
        background-color: #fff;

        tr:first-child td {
            border-top: 0;
        }

        td {
            width: auto;
        }
    }
}

.quad-table-body {
    padding-left: $quad-table-sidebar-width;
    overflow: auto;
    @include media-breakpoint-up(lg) {
        padding-left: $quad-table-sidebar-width-desktop;
    }
}

// Countries table
.quad-table-countries.quad-table {
    .quad-table-header-right,
    .quad-table-body {
        .table td {
            &,
            &:last-child {
                width: 115px;
                @include media-breakpoint-up(md) {
                    width: auto;
                }
            }
        }
    }
}

.quad-table-header .table td {
    padding-top: $spacer * 1.5;
    padding-bottom: $spacer;
}

.quad-table-pinned {
    position: relative;

    .quad-table-sidebar {
        .table {
            border-bottom-color: $gray-base-60;
        }
    }

    .quad-table-body {
        overflow: hidden;

        .table {
            border-bottom-color: $gray-base-60;
            tr:first-child td {
                border-top: 0;
            }
        }
    }

    tr {
        background-color: $table-pin-bg;
    }
}
