@use "sass:math";

.pdf-preview {
	min-width: 0;

	&:not(.empty) {
		padding-top: 0;
	}
}

.pdf-preview-header {
	@include chartAxis();
	display: flex;
	align-items: baseline;
	padding: 6px 0 8px;
}

.pdf-preview-title {
	flex: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 0;
	text-align: center;
}

.pdf-preview-counter {
	margin-right: $spacer;
	color: $gray-base-60;
	text-transform: uppercase;
	white-space: nowrap;
}

.pdf-builder-wrap {
	flex: auto;
	position: relative;
	overflow: auto;
}

.pdf-builder {
	@extend .border-get-started;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	margin-bottom: 20px;
	padding: 20px;
	overflow: auto;
}

.pdf-page {
	position: relative;
	display: flex;
	max-width: 534px;
	margin-left: auto;
	margin-right: auto;
	padding: 6px 12px;
	border: 1px solid $gray-base-70;
	box-shadow: 0 2px 4px rgba($gray-base, .25);
	&:before {
	  padding-top: calc(11 / 8.5 * 100%);
	  width: 0;
	  flex: none;
	  content: "";
	}
	&:not(:first-child) {
		margin-top: $pdf-page-gap;
	}
	&:not(:last-child) {
		margin-bottom: $pdf-page-gap;
	}
	@media (min-width: 1600px) {
		max-width: 1000px;
	}
	&.dir-landscape {
		max-width: 692px;
		&:before {
			padding-top: calc(8.5 / 11 * 100%);
		}
		@media (min-width: 1600px) {
			max-width: 1000px;
		}
	}
	&.long-page {
		flex-direction: column;
		gap: $spacer * 2;
		height: 100%;
		padding-top: $spacer * 2;
		padding-bottom: $spacer * 2;
		overflow: auto;
		&:before {
			content: none;
		}

		.pdf-modules {
			flex: none;
		}

		.pdf-module {
			height: 420px;
			&.module-empty {
				flex: none;

				.pdf-module-title {
					height: 310px;
				}
			}
		}
	}
}

.pdf-modules {
	flex: auto;
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100%;
}

.pdf-module-loading {
	border: 1px solid $gray-base-70;
	height: 420px;
	padding-top: 30%;
}

.pdf-module {
	flex: none;
	position: relative;
	height: 25%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	&:before {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 2px;
		left: 0;
		display: block;
		border: 1px solid $gray-base-70;
		content: "";
        cursor: pointer;
	}

	&.selected {
		&:before {
			border-color: $yc-blue;
		}
	}

    &.full-page {
    	height: 100%;
    }

    &.half-page {
    	height: 50%;
    }

    &.third-page {
    	height: calc(100%/3);
    }

    &.quarter-page {
    	height: 25%;
    }

    &.preview-image,
    &.loading {
		&:before {
			background-color: #fff;
			background-image: var(--preview-image-url);
			background-size: contain;
			background-position: top;
			background-repeat: no-repeat;
		}
		&:not(.selected):before {
			border-color: #fff;
		}
	}

    &.module-empty {
    	flex: auto;
    	min-height: 60px;
        & > .sortable-ghost {
			display: none;
		}

    	&:before {
    		background: #fff;
		}

        &.active:before {
			background: $yc-blue-90 !important;
		}

		.pdf-module-title {
			@include buttonTextDefault();
			align-self: flex-start;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			max-height: 310px;
			text-align: center;
            cursor: pointer;
		}
    }

    &.loading {
    	&:before {
    		background-image: url(../../images/empty_module.png);
    	}

    	.dir-landscape & {
	    	&:before {
	    		background-image: url(../../images/empty_module-landscape.png);
	    	}
    	}

    	.loader {
    		display: block;
    	}

    	.pdf-module-title {
    		display: none;
    	}
    }

    .pdf-module-icon {
		margin-left: 12px;
	}

	[class*="col-"] {
    	.pdf-module-icon {
			margin-left: 0;
	    }
    }

    .loader {
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
		display: none;
		width: 100%;
    }
}

.pdf-module-title {
	position: relative;
	@include chartAxis();
}

.pdf-module-icon {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	border: 1px solid $gray-base-80;
	vertical-align: middle;
    &.full-page {
    	background-color: $pdf-full-page-bg;
    }

    &.half-page {
    	background-color: $pdf-half-page-bg;
    }

    &.third-page {
    	background-color: $pdf-third-page-bg;
    }

    &.quarter-page {
    	background-color: $pdf-quarter-page-bg;
    }
}

.dark-mode {
	.pdf-page {
		background-color: white !important;
	}
	.pdf-module {
		&.preview-image,
		&.loading {
			&:before {
				background-color: #fff !important;
			}
			&:not(.selected):before {
				border-color: #fff !important;
			}
		}
		&.module-empty {
			&:before {
				background-color: #fff;
			}
			&.active:before {
				background-color: $yc-blue-90 !important;
			}
		}
        &.selected {
			&:before {
				border-color: $yc-blue !important;
			}
		}
		&.loading {
			&:before {
				background-color: #fff !important;
				background-image: url(../../images/empty_module.png) !important;
			}

			.dir-landscape & {
				&:before {
					background-image: url(../../images/empty_module-landscape.png) !important;
				}
			}
		}
	}
	.pdf-module-title {
		color: $body-color !important;
	}
	.pdf-module-icon {
		&.full-page {
			background-color: $pdf-full-page-bg !important;
		}

		&.half-page {
			background-color: $pdf-half-page-bg !important;
		}

		&.third-page {
			background-color: $pdf-third-page-bg !important;
		}

		&.quarter-page {
			background-color: $pdf-quarter-page-bg !important;
		}
	}
}

// Tearsheet grid system

.grid-stack.pdf-grid-modules {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	.grid-stack-item {
		&.is-highlighted {
			background-color: $yc-blue-95;
		}
	}

	.grid-stack-item-content,
	.placeholder-content {
		inset: 4px;
	}
}


.rows-count-4 {
	.grid-stack-item {
		height: 25%;

		&[gs-y="1"] {
			top: 25%;
		}
		&[gs-y="2"] {
			top: 50%;
		}
		&[gs-y="3"] {
			top: 75%;
		}

		&[gs-h="1"] {
			height: 25%;
		}
		&[gs-h="2"] {
			height: 50%;
		}
		&[gs-h="3"] {
			height: 75%;
		}
		&[gs-h="4"] {
			height: 100%;
		}
	}
}

.rows-count-8 {
	.grid-stack-item {
		height: 12.5%;

		&[gs-y="1"] {
			top: 12.5%;
		}
		&[gs-y="2"] {
			top: 25%;
		}
		&[gs-y="3"] {
			top: 37.5%;
		}
		&[gs-y="4"] {
			top: 50%;
		}
		&[gs-y="5"] {
			top: 62.5%;
		}
		&[gs-y="6"] {
			top: 75%;
		}
		&[gs-y="7"] {
			top: 87.5%;
		}

		&[gs-h="1"] {
			height: 12.5%;
		}
		&[gs-h="2"] {
			height: 25%;
		}
		&[gs-h="3"] {
			height: 37.5%;
		}
		&[gs-h="4"] {
			height: 50%;
		}
		&[gs-h="5"] {
			height: 62.5%;
		}
		&[gs-h="6"] {
			height: 75%;
		}
		&[gs-h="7"] {
			height: 87.5%;
		}
		&[gs-h="8"] {
			height: 100%;
		}
	}
}

.pdf-grid-modules {
}

.pdf-grid-module {
	cursor: pointer;
	&:hover {
		background-color: $yc-blue-95;
	}

	&.module-empty {
		border: 1px dashed $gray-base-90;
		&:hover {
			.pdf-grid-module-empty-message {
				display: block;
			}
		}
	}

	&.selected {
		outline: 1px solid $yc-blue;
	}

	.pdf-grid-module-empty-message {
		display: none;
	}

    &.preview-image {
		background-color: #fff !important;
		background-image: var(--preview-image-url) !important;
		background-position: center top !important;
		background-repeat: no-repeat !important;
		background-size: 100% auto !important;
	}

	&.preview-image-custom-image-tearsheet-module {
		background-color: #fff !important;
		background-image: var(--preview-image-url) !important;
		background-position: left top !important;
		background-repeat: no-repeat !important;
		background-size: contain !important;
	}

	.loader {
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
		width: 100%;
    }
}

.pdf-grid-module-empty-message {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
    flex-direction: column;
	width: 100%;
	height: 100%;
	cursor: pointer;

	.pdf-module-title {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		max-height: 310px;
		text-align: center;
		text-transform: uppercase;
	}
}

.example-modules {
	padding: 0;
	svg {
		font-size: 8px;
	}
}

@include media-breakpoint-up(lg) {
	.pdf-grid-module-empty-message {
		.pdf-module-title {
			@include headerDefault();
			span {
				margin-bottom: 8px;
			}
		}
	}
	.example-modules {
		padding: 0 5px;
		svg {
			font-size: 0.907rem;
		}
	}
}

.ql-editor {
	padding : 0 !important;
	line-height: 1.2 !important;

    p {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}

.ql-container {
	.ql-editor {
		padding : 12px 15px !important;
	}
}
