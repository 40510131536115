.score-manager {
    min-height: 160px;
    padding-left: $grid-gutter-width * 0.5;
    padding-right: $grid-gutter-width * 0.5;
    border: 1px solid $gray-base-80;
}

.score-manager-header {
    @include textLabel();
    height: $base-height;
    align-items: center;
    border-bottom: 1px solid $gray-base-80;
    color: $gray-base-40;
    @include media-breakpoint-up(lg) {
        height: $base-height-desktop;
    }
}

.score-manager-list {
    padding-left: $grid-gutter-width * 0.5;
    padding-right: $grid-gutter-width * 0.5;
    .row {
        align-items: center;
        border-bottom: 1px solid $gray-base-80;
    }

    [class*="col"] {
        padding-top: $grid-gutter-width * 0.5;
        padding-bottom: $grid-gutter-width * 0.5;
        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
            text-align: right;
        }
    }

    .btn-transparent {
        &:hover {
            background-color: $yc-blue-90;
        }
    }
}

.score-manager-value-input {
    width: 45px;
    @include media-breakpoint-up(lg) {
        width: 37px;
    }
}

.score-manager-footer {
    font-weight: 700;

    .row {
        height: $base-height;
        align-items: center;
        @include media-breakpoint-up(lg) {
            height: $base-height-desktop;
        }
    }
}

.score-manager-add-link {
    @include textLabel();
    color: $yc-blue;
}
