.text-clipped {
    max-width: 100%;
    display: inline-flex;
    white-space: nowrap;

    & > *:first-child {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    & > *:last-child {

    }

    &.text-underline {
        position: relative;
        text-decoration: none;
        &:after {
            position: absolute;
            bottom: .267em;
            left: 0;
            right: 0;
            display: block;
            height: 0;
            border-bottom: 1px solid currentColor;
            content: "";
            @include media-breakpoint-up(lg) {
                bottom: .214em;
            }
        }
    }
}