
// Here will be parents independed grid generator

[class*="col-i"] {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: $grid-gutter-width * 0.5;
    padding-left: $grid-gutter-width * 0.5;
    flex-basis: 0;
    flex-grow: 1;
    min-width: 100%;
}

.col-i-2 {
    flex: none;
    width: calc(((100vw - #{$grid-gutter-width}) / #{$grid-columns} ) * 2);
    min-width: calc(((700px - #{$grid-gutter-width}) / #{$grid-columns} ) * 2);
    @include media-breakpoint-up(xl) {
        width: calc(((#{map-get($grid-breakpoints, xl)} - #{$grid-gutter-width}) / #{$grid-columns} ) * 2);
    }
}

.col-i-3 {
    flex: none;
    width: calc(((100vw - #{$grid-gutter-width}) / #{$grid-columns} ) * 3);
    min-width: calc(((700px - #{$grid-gutter-width}) / #{$grid-columns} ) * 3);
    @include media-breakpoint-up(xl) {
        width: calc(((#{map-get($grid-breakpoints, xl)} - #{$grid-gutter-width}) / #{$grid-columns} ) * 3);
    }
}

.col-i-4 {
    flex: none;
    width: calc(((100vw - #{$grid-gutter-width}) / #{$grid-columns} ) * 4);
    min-width: calc(((700px - #{$grid-gutter-width}) / #{$grid-columns} ) * 4);
    @include media-breakpoint-up(xl) {
        width: calc(((#{map-get($grid-breakpoints, xl)} - #{$grid-gutter-width}) / #{$grid-columns} ) * 4);
    }
}

@include media-breakpoint-up(md) {
    .col-i-md-1 {
        flex: none;
        width: calc(((100vw - #{$grid-gutter-width}) / #{$grid-columns} ) * 1);
        min-width: calc(((700px - #{$grid-gutter-width}) / #{$grid-columns} ) * 1);
        @include media-breakpoint-up(xl) {
            width: calc(((#{map-get($grid-breakpoints, xl)} - #{$grid-gutter-width}) / #{$grid-columns} ) * 1);
        }
    }
    .col-i-md-2 {
        flex: none;
        width: calc(((100vw - #{$grid-gutter-width}) / #{$grid-columns} ) * 2);
        min-width: calc(((700px - #{$grid-gutter-width}) / #{$grid-columns} ) * 2);
        @include media-breakpoint-up(xl) {
            width: calc(((#{map-get($grid-breakpoints, xl)} - #{$grid-gutter-width}) / #{$grid-columns} ) * 2);
        }
    }
    .col-i-md-3 {
        flex: none;
        width: calc(((100vw - #{$grid-gutter-width}) / #{$grid-columns} ) * 3);
        min-width: calc(((700px - #{$grid-gutter-width}) / #{$grid-columns} ) * 3);
        @include media-breakpoint-up(xl) {
            width: calc(((#{map-get($grid-breakpoints, xl)} - #{$grid-gutter-width}) / #{$grid-columns} ) * 3);
        }
    }
    .col-i-md-4 {
        flex: none;
        width: calc(((100vw - #{$grid-gutter-width}) / #{$grid-columns} ) * 4);
        min-width: calc(((700px - #{$grid-gutter-width}) / #{$grid-columns} ) * 4);
        @include media-breakpoint-up(xl) {
            width: calc(((#{map-get($grid-breakpoints, xl)} - #{$grid-gutter-width}) / #{$grid-columns} ) * 4);
        }
    }
    .col-i-md-8 {
        flex: none;
        width: calc(((100vw - #{$grid-gutter-width}) / #{$grid-columns} ) * 8);
        min-width: calc(((700px - #{$grid-gutter-width}) / #{$grid-columns} ) * 8);
        @include media-breakpoint-up(xl) {
            width: calc(((#{map-get($grid-breakpoints, xl)} - #{$grid-gutter-width}) / #{$grid-columns} ) * 8);
        }
    }
}

@include media-breakpoint-up(lg) {
    .col-i-lg-1 {
        flex: none;
        width: calc(((100vw - #{$grid-gutter-width}) / #{$grid-columns} ) * 1);
        min-width: calc(((700px - #{$grid-gutter-width}) / #{$grid-columns} ) * 1);
        @include media-breakpoint-up(xl) {
            width: calc(((#{map-get($grid-breakpoints, xl)} - #{$grid-gutter-width}) / #{$grid-columns} ) * 1);
        }
    }
    .col-i-lg-2 {
        flex: none;
        width: calc(((100vw - #{$grid-gutter-width}) / #{$grid-columns} ) * 2);
        min-width: calc(((700px - #{$grid-gutter-width}) / #{$grid-columns} ) * 2);
        @include media-breakpoint-up(xl) {
            width: calc(((#{map-get($grid-breakpoints, xl)} - #{$grid-gutter-width}) / #{$grid-columns} ) * 2);
        }
    }
    .col-i-lg-3 {
        flex: none;
        width: calc(((100vw - #{$grid-gutter-width}) / #{$grid-columns} ) * 3);
        min-width: calc(((700px - #{$grid-gutter-width}) / #{$grid-columns} ) * 3);
        @include media-breakpoint-up(xl) {
            width: calc(((#{map-get($grid-breakpoints, xl)} - #{$grid-gutter-width}) / #{$grid-columns} ) * 3);
        }
    }
    .col-i-lg-4 {
        flex: none;
        width: calc(((100vw - #{$grid-gutter-width}) / #{$grid-columns} ) * 4);
        min-width: calc(((700px - #{$grid-gutter-width}) / #{$grid-columns} ) * 4);
        @include media-breakpoint-up(xl) {
            width: calc(((#{map-get($grid-breakpoints, xl)} - #{$grid-gutter-width}) / #{$grid-columns} ) * 4);
        }
    }
    .col-i-lg-5 {
        flex: none;
        width: calc(((100vw - #{$grid-gutter-width}) / #{$grid-columns} ) * 5);
        min-width: calc(((700px - #{$grid-gutter-width}) / #{$grid-columns} ) * 5);
        @include media-breakpoint-up(xl) {
            width: calc(((#{map-get($grid-breakpoints, xl)} - #{$grid-gutter-width}) / #{$grid-columns} ) * 5);
        }
    }
    .col-i-lg-6 {
        flex: none;
        width: calc(((100vw - #{$grid-gutter-width}) / #{$grid-columns} ) * 6);
        min-width: calc(((700px - #{$grid-gutter-width}) / #{$grid-columns} ) * 6);
        @include media-breakpoint-up(xl) {
            width: calc(((#{map-get($grid-breakpoints, xl)} - #{$grid-gutter-width}) / #{$grid-columns} ) * 6);
        }
    }
}
