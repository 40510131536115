.rating {
    display: inline-flex;
    min-width: 59px;
}

.rating-bar {
    flex: 1;
    height: 10px;
    min-width: 5px;
    background-color: $gray-base-80;
    &:not(:last-child) {
        margin-right: 1px;
    }
    &.bg-1 {
        background-color: map-get($gradient-colors, 1a);
    }
    &.negative {
        background-color: map-get($colors, red);
    }
    &.positive {
        background-color: map-get($colors, green);
    }
    &.average {
        background-color: map-get($gradient-colors, 2c);
    }
    &.neutral {
        background-color: $gray-base-40;
    }
}

// rating section
.rating-section {
    display: flex;
    width: 100%;
    padding-top: $spacer;
    padding-bottom: $spacer;

    td &:first-child {
        padding-top: 0;
    }

    td &:last-child {
        padding-bottom: 0;
    }
}

.rating-wrap {
    flex: none;
    margin-right: 30px;
}

.rating-descr {
    flex: auto;
}

.rating-big {
    min-width: 86px;

    .rating-bar {
        height: 14px;
        min-width: 14px;
    }
}

.rating-logo {
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    margin-right: 30px;
    background-color: $yc-blue;
    fill: #fff;
    &.positive {
        background-color: map-get($colors, green);
    }
    &.negative {
        background-color: map-get($colors, red);
    }
}

.rating-value {
    @include keyStatTitle();
    min-width: 128px;
}

.rating-formula {
    padding: $grid-gutter-width * 0.5 $grid-gutter-width;
    background-color: $gray-base-95;

    table {
        td {
            border: 0;
        }
    }

    span, tbody td {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        &:not(:first-child) {
            padding-left: $grid-gutter-width * 0.25;
        }
        &:not(:last-child) {
            padding-right: $grid-gutter-width * 0.25;
        }
    }
}
