.key-stats {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 6px;
    &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        background-color: #fff;
        content: "";
    }

    &.key-stats-small {
        .key-stat-wrap {
            width: 50%;
        }
    }
}

.key-stat-wrap {
    display: flex;
    width: 25%;
    padding-left: 10px;
    padding-right: 10px;
}

.key-stat {
    flex: none;
    width: 100%;
    padding-top: 14px;
    padding-bottom: 14px;
    border-bottom: 1px solid $gray-base-90;
}

.key-stat-title {
    @include keyStatTitle();
}

.key-stats-date {
    margin-top: 5px;
    font-size: 11px;
    color: $gray-base-40;
}


// Table
.key-stats-table {
    table-layout: fixed;
    width: 100%;

    tbody + tbody {
        th {
            position: relative;
            &:after {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                display: block;
                height: 0;
                border-bottom: 1px solid $gray-base-90;
                content: "";
            }
            &:not(:first-child) {
                &:after {
                    left: 10px;
                }
            }
            &:not(:last-child) {
                &:after {
                    right: 10px;
                }
            }
        }
    }

    td, th {
        &:not(:first-child) {
            padding-left: 10px;
        }
        &:not(:last-child) {
            padding-right: 10px;
        }
    }

    td {
        padding-bottom: 14px;
        vertical-align: top;
        word-break: break-word;
    }

    th {
        padding-top: 14px;
        vertical-align: top;
        font-weight: 400;
        a {
            @include underline();
        }
    }

    tbody:last-child td {
        padding-bottom: 10px;
    }
}
