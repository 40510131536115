// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and stuff


// Kill the scroll on the body
.modal-open {
  overflow: hidden;
}

// Container that the modal scrolls within
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal;
  display: none;
  padding-right: $grid-gutter-width * 2;
  padding-left: $grid-gutter-width * 2;
  overflow: hidden;
  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;
  // We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
  // gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
  // See also https://github.com/twbs/bootstrap/issues/17695

  .modal-open & {
    overflow-x: hidden;
    overflow-y: auto;
  }

  // open multiple modals
  &.show ~ .modal.show {
    z-index: $zindex-modal + 10;
  }

    // Modal with fixed header & footer and scrollable body
    &.modal-fix-scroll {
        padding-top: 0;
        padding-bottom: 0;
        @media (min-height: 500px) {
            padding-top: $header-height-mobile + $grid-gutter-width * 2;
            padding-bottom: $header-height-mobile + $grid-gutter-width * 2;
        }
        @media (min-width: map-get($grid-breakpoints, sm)) and (min-height: 500px) {
            padding-top: $header-height + $grid-gutter-width * 2;
            padding-bottom: $header-height + $grid-gutter-width * 2;
        }

        .modal-open & {
            @media (min-height: 500px) {
                overflow: auto;
            }
        }

        .modal-dialog {
            @media (min-height: 500px) {
                max-height: 100%;
                margin: 0 auto;
            }
        }

        .modal-body {
            @media (min-height: 500px) {
                overflow: auto;
            }
        }

        .modal-footer {
            border-top: 1px solid $gray-base-90;
        }
    }
}

.modal-draggable {
    right: auto;
    width: 100%;
    pointer-events: none;
}

.modal-minimized {
    right: auto;
    bottom: auto;
    width: auto;

    .modal-dialog {
        width: auto;
    }
    .modal-body,
    .modal-footer {
        display: none;
    }
}

// Shell div to position the modal with bottom padding
.modal-dialog {
    position: relative;
    display: flex;
    width: $modal-md;
    border: 1px solid $gray-base-90;
    background-color: $modal-content-bg;
    margin: $header-height-mobile + $grid-gutter-width * 2 auto;
    // allow clicks to pass through for custom click handling to close modal
    pointer-events: none;
    @media (min-width: map-get($grid-breakpoints, sm)) and (min-height: 500px) {
        margin: $header-height + $grid-gutter-width * 2 auto;
    }
}
// Scale up the modal
// Automatically set modal's width for larger viewports
.modal-content {
    @include box-shadow;
}

.modal-sm { max-width: $modal-sm; }
.modal-lg { 
    @include media-breakpoint-up(lg) {
        width: $modal-lg;
    }
}



// Actual modal
.modal-content {
    flex: 1 1 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    // counteract the pointer-events: none; in the .modal-dialog
    pointer-events: auto;
    // background-color: $modal-content-bg;
    background-clip: padding-box;
    border: 0;
    // Remove focus outline from opened modal
    outline: 0;

    h4 {
        font-size: 19px;
    }

    h5 {
        font-size: 15px;
    }

    p {
        font-size: 14px;
    }
}

// Modal background
.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-modal-backdrop;
    background-color: $modal-backdrop-bg;

    // Fade for backdrop
    &.show { opacity: $modal-backdrop-opacity; }

    // open multiple modals
    &.show ~ .modal-backdrop.show {
      z-index: $zindex-modal-backdrop + 10;
    }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 48px;
    padding-top: 10px;
    padding-left: $modal-dialog-margin;
    padding-right: $modal-dialog-margin - $spacer;
    border-bottom: 1px solid $gray-base-90;
    @include media-breakpoint-up(lg) {
        min-height: $base-height;
        padding-top: 8px;
    }
}

.modal-no-border {
    .modal-header {
        border-bottom: 0;
    }

    .modal-body {
        padding-top: 0;
    }
}

.modal-header-actions {
    display: flex;
    margin-left: auto;
}

.modal-header-action {
    display: flex;

    & + * {
        margin-left: 7px;
        padding-left: 7px;
        border-left: 1px solid $gray-base-90;
    }

    .btn-text {
        height: 28px;
        color: $gray-base-50;
        @include media-breakpoint-up(lg) {
            height: 22px;
        }

        &.close {
            padding-right: 6px;
            padding-left: 6px;
            @include media-breakpoint-up(lg) {
                padding-right: 5px;
                padding-left: 5px;
            }
        }
    }
}

// Title text within header
.modal-title {
    @include headerMedium();
    align-self: flex-end;
    margin-bottom: 0;
    padding-bottom: 10px;
    color: $gray-base-30;
    @include media-breakpoint-up(lg) {
        padding-bottom: 8px;
    }
    &:not(:only-child) {
        margin-right: $spacer * 2;
    }
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
    position: relative;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    min-height: 0;
    // overflow-y: auto;
    &:last-child {
        .tab-content {
            border-bottom: 0;
        }
    }

    .form-row {
        margin-bottom: 0;
        &:not(:last-child) {
            margin-bottom: 16px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 10px;
            }
        }
    }
}

.modal-section {
    padding-left: $modal-dialog-margin;
    padding-right: $modal-dialog-margin;

    &.section-info {
        .modal-section-title {
            padding-top: 0;
            padding-bottom: 5px;
            color: $gray-base-20;
        }
        ul, p {
            color: $gray-base-20;
        }

        ul {
            padding-left: 20px;
        }
    }

    &.section-invite {
        padding-bottom: 20px;
        text-align: center;
        .modal-section-title {
            @include emptyStateAdditional();
            margin-bottom: 0;
            padding-top: 30px;
            padding-bottom: 4px;
            color: $yc-blue;
        }
    }
}

.modal-section-wrap {
    border: 1px solid $gray-base-90;
    padding-left: $modal-dialog-margin;
    padding-right: $modal-dialog-margin;
}

.modal-section-title {
    padding-top: $modal-dialog-margin * 0.5;
    padding-bottom: $modal-dialog-margin * 0.5;
    font-weight: 700;
    color: $gray-base-10;

    a {
        color: $yc-blue;
    }
}

.modal-section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: $spacer;
    padding-bottom: $spacer;
}

.modal-section-title-medium {
    @include headerMedium();
}

.modal-sidebar {
    flex: none;
    width: 190px;
    @include media-breakpoint-up(lg) {
        width: 150px;
    }
}


// Footer (for actions)
.modal-footer {
    flex: none;
    display: flex;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: $modal-dialog-margin;
    padding-right: $modal-dialog-margin;
    @include media-breakpoint-up(lg) {
        padding-top: 16px;
        padding-bottom: 16px;
    }
}

.modal-footer-actions {
    display: flex;
    margin-left: auto;

    .btn,
    .dropdown {
        min-width: 80px;
        &:not(:first-child) {
            margin-left: 10px;
            @include media-breakpoint-up(lg) {
                margin-left: 8px;
            }
        }
    }

    button {
        display: block;
    }
}

.modal-footer-info {
    @include textDefault();
    margin-right: auto;
    margin-left: 0;
    color: $gray-base-50;
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.modal-message {
    padding-top: 10px;
    padding-bottom: 6px;
    @include media-breakpoint-up(lg) {
        padding-top: 6px;
        padding-bottom: 4px;
    }
}

.modal-body {
    .tab-content {
        border-bottom: 1px solid $gray-base-90;

        .modal-section {
            position: relative;
            padding: $modal-dialog-margin 0;
            @include media-breakpoint-up(lg) {
                padding-top: 16px;
                padding-bottom: 16px;
            }
            &:not(:last-child) {
                border-bottom: 1px solid $gray-base-90;
            }
        }
    }

    .flat-list {
        margin: 0 $grid-gutter-width $grid-gutter-width;
    }
}

.modal-content-help {
    color: $gray-base-20;

    h2 {
        @include emptyState();
        color: inherit;
        text-align: inherit;
    }

    h3 {
        @include headerDefault();
        color: inherit;
    }

    p {
        @include textDefault();
        color: inherit;
    }
}

// unprecented audacity
#helpMeChoose {
    .divider-vertical {
        padding-right: 10px;
        margin-right: 10px;
    }
}

.email-preview-modal {
    @include media-breakpoint-up(md) {
        width: 682px;
    }
}

.benefit {
    display: flex;
    align-items: center;
}

.benefit-icon {
    flex: none;
    margin-right: $spacer * 2;
    font-size: 15px;
}

.benefit-text {
    color: $gray-base-30;
}
