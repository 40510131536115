
.datepicker {
    padding: 16px $grid-gutter-width;
}

.datepicker-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 16px;
    @include media-breakpoint-up(lg) {
        padding-bottom: 10px;
    }
}

.datepicker-nav {
    &.datepicker-nav-prev {
        @extend .btn, .arrow-left;
        &:after {
            border-right-color: $gray-base-70;
        }
    }
    &.datepicker-nav-next {
        @extend .btn, .arrow-right;
        &:after {
            border-left-color: $gray-base-70;
        }
    }
}

.datepicker-title {
    display: flex;
    flex: auto;

    .dropdown {
        flex: auto;
        &:not(:last-child) {
            margin-right: 24px;
            @include media-breakpoint-up(lg) {
                margin-right: 20px;
            }
        }
    }

    .dropdown-menu {
        min-width: 0;
    }

    .dropdown-item.custom-checkbox {
        padding-right: $grid-gutter-width;
    }
}

.datepicker-calendar {
    width: 100%;
    text-align: center;

    th, td {
        padding: 0;
    }

    thead {
        position: relative;
        &:before,
        &:after {
            position: absolute;
            left: -$grid-gutter-width;
            right: -$grid-gutter-width;
            display: block;
            height: 0;
            border-bottom: 1px solid $gray-base-90;
            content: "";
        }

        a, span {
            height: 22px;
            padding-top: 0;
            padding-bottom: 0;
            text-transform: none;
            cursor: default;
            @include media-breakpoint-up(lg) {
                height: 20px;
            }
        }
    }

    th {
        text-align: center;
        font-weight: 400;
        color: $gray-base-70;
    }

    td.highlight-yc-blue,
    td.highlight-range {
        position: relative;
        &:after {
            position: absolute;
            display: block;
            width: 100%;
            z-index: -1;
            content: "";
        }
    }

    td.highlight-yc-blue {
        &:after {
            top: 0;
            height: 100%;
            background-color: $yc-blue;
            border-radius: 50%;
        }

        a {
            color: #fff;
        }
    }

    td.highlight-range {
        &:after {
            top: 50%;
            transform: translate(0, -50%);
            height: calc(100% - 6px);
            background-color: $yc-blue-90;
        }
    }

    // make offset for range highlights
    td.highlight-yc-blue + td.highlight-range {
        &:after {
            left: -50%;
            width: 150%;
            z-index: -2;
        }
    }
    td.highlight-range + td.highlight-yc-blue {
        &:before {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            display: block;
            width: 50%;
            height: calc(100% - 6px);
            background-color: $yc-blue-90;
            z-index: -2;
            content: "";
        }
    }

    a, span {
        @extend .btn;
        @extend .btn-transparent;
        width: 40px;
        height: 40px;
        padding-right: 0;
        padding-left: 0;
        font-size: $font-size-base;
        font-weight: 400;
        @include media-breakpoint-up(lg) {
            width: 30px;
            height: 30px;
            font-size: $font-size-desktop;
        }

        &.state-highlight {
            @extend .btn-circle;
            @extend .btn-primary;
        }

        &.state-disabled {
            color: $gray-base-70;
            cursor: not-allowed;
        }
    }
}

.datepicker-week-end {

}

.datepicker-other-month {

}

.datepicker-unselectable {

}


.datepicker-today {

}
