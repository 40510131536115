
.pdf-thumb {
	display: flex;
	margin-bottom: 0;
	padding-left: 0;
	counter-reset: li-count;
	list-style: none;
}

.pdf-thumb-item {
	flex: none;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 88px;
	height: 88px;
	padding-top: 8px;
	padding-bottom: 8px;
	counter-increment: li-count;
	cursor: pointer;

	&:before {
		right: 100%;
		top: 6px;
		margin-right: 6px;
		content: counter( li-count) '. ';
	}
	&:before,
	&:after {
		position: absolute;
		display: block;
	}
	&:after {
		top: 50%;
		left: 50%;
		width: 100%;
		height: 100%;;
		transform: translate(-50%, -50%);
		border: 1px solid transparent;
		content: "";
	}

	&:hover {
		&:after {
			background-color: $gray-base-90;
		}
	}

	&:active, &:focus {
		&:after {
			border-color: $yc-blue;
		}
	}

	&.current:after {
		border-color: $yc-blue;
		background-color: $yc-blue-90;
	}
}

.pdf-thumb-page {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 55px;
	height: 72px;
	border: 1px solid $gray-base-70;
	background-color: #fff;
	z-index: 1;

	&.dir-landscape {
		height: 55px;
		width: 72px;
	}
}

.module-thumb {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 56px;
	height: 56px;
	border: 1px solid $gray-base-70;
	border-top-width: 4px;
	background: #fff center top no-repeat;
	background-size: contain;
	z-index: 1;
}

.pdf-divider {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 0;
	width: 130px;
	padding-top: 6px;
	padding-bottom: 6px;
	cursor: pointer;
	opacity: 0;
	&:before {
		display: block;
		width: 100%;
		height: 0;
		border-bottom: 3px solid $yc-blue;
		content: "";
	}

	&:first-child {
		flex: none;
		justify-content: flex-end;
		height: 30px;
	}

	&:last-child {
		flex: auto;
		justify-content: flex-start;
		min-height: 30px;
	}

	&.show {
		opacity: 1;
	}
}

.dark-mode {
	.pdf-thumb-page {
		background-color: #fff !important;
	}
}
