.notifications {
    flex-direction: column;
    width: 100%;
    &.collapse {
        @include box-shadow;
        position: absolute;
        top: 100%;
        right: 0;
        width: 315px;
        min-height: 155px;
        max-height: 322px;
        background: #fff;
        &.show {
            display: flex;
        }
    }
}

.notification {
}

.notifications-header {
    flex: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 20px;
    border-bottom: 1px solid $gray-base-90;
    @include media-breakpoint-up(lg) {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.notifications-header-title {
    @include headerDefault();
}

.notifications-header-options {
    display: flex;
    a {
        &:not(.disabled) {
            color: $yc-blue;
            &:hover, &:focus, &:active {
                text-decoration: underline;
            }
        }
        &.disabled {
            color: $gray-base-70;
            pointer-events: none;
        }
    }
}

.notification-title {
    position: relative;
    margin-bottom: 2px;
    padding-right: 15px;
    color: $gray-base-10;
    &:before {
        position: absolute;
        border-radius: 50%;
        top: 6px;
        left: -16px;
        width: 10px;
        height: 10px;
        margin-right: 6px;
        background-color: $gray-base-70;
        content: "";
        @include media-breakpoint-up(lg) {
            left: -15px;
            width: 8px;
            height: 8px;
        }
    }
    &.unread {
        &:before {
            background-color: #d12229;
        }
    }
}

.notification-descr {
    margin-top: 5px;
    margin-bottom: 10px;
    $color: $gray-base-40;
}

.notification-date {
    $color: $gray-base-60;
}

.notifications-body {
    position: relative;
    flex: auto;
    padding-left: 20px;
    padding-right: 20px;
    overflow: auto;

    .table {
        td {
            &:first-child {
                padding-left: 15px;
                padding-right: 0;
            }
            &:last-child {
                position: relative;
                padding: 0;
            }
        }

        tr:first-child td {
            border-top: 0;
        }


        .btn-close {
            position: absolute;
            top: $spacer;
            right: 0;
            opacity: 0;
            &:hover {
                fill: $yc-blue;
            }
        }

        tr:hover .btn-close {
            opacity: 1;
        }
    }
}

.notifications-footer {
    border-top: 1px solid $gray-base-90;
    flex: none;
    padding: 8px 20px;

    a {
        color: $yc-blue;
        &:hover, &:focus, &:active {
            text-decoration: underline;
        }
    }
}

.notifications-empty {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: $gray-base-40;
}
