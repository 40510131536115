.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

.sticky {
	position: sticky;
}

.z-1 {
	z-index: 1;
}

.z-2 {
	z-index: 2;
}

.left-0 {
	left: 0;
}

.right-0 {
	right: 0;
}

.top-0 {
	top: 0;
}

.bottom-0 {
	bottom: 0;
}