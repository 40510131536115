$colorpicker-border-color: $gray-base-50;
$colorpicker-border: 1px solid $colorpicker-border-color;

.colorpicker {
    display: block;
    width: 192px;
    padding: 10px;
    background-color: $gray-base-90;
}

.colorpicker-row {
    display: flex;
    margin-bottom: 5px;
    &:last-child {
        justify-content: flex-end;
        margin-bottom: 0;
    }

    .btn {
        margin-left: 5px;
    }
}

.colorpicker-guide {
    position: absolute;
    border: $colorpicker-border;
}

.colorpicker-saturation {
    position: relative;
    flex: none;
    width: 140px;
    height: 140px;
    margin-right: 5px;
    border: $colorpicker-border;
    background: linear-gradient(to bottom, transparent 0%, black 100%), linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
    background-color: red;
    .colorpicker-guide {
        top: 20px;
        left: 30px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: 12px;
            height: 12px;
            border: 3px solid #fff;
            border-radius: 50%;
            box-shadow: 0 0 0 1px $colorpicker-border-color;
            content: "";
        }
    }
}

.colorpicker-hue {
    position: relative;
    flex: none;
    width: 25px;
    height: 140px;
    border: $colorpicker-border;
    background: linear-gradient(to top, red 0%, #ff8000 8%, yellow 17%, #80ff00 25%, lime 33%, #00ff80 42%, cyan 50%, #0080ff 58%, blue 67%, #8000ff 75%, magenta 83%, #ff0080 92%, red 100%);

    .colorpicker-guide {
        top: 10px;
        left: -1px;
        right: -1px;
        height: 5px;
        background-color: #fff;
    }
}

.colorpicker-alpha {
    position: relative;
    width: 100%;
    height: 8px;
    border: $colorpicker-border;
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 0.1) 0), linear-gradient(45deg, rgba(0, 0, 0, 0.1) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 0.1) 0), white;
    background-size: 20px 20px;
    background-position: 0 0, 10px 10px;

    .colorpicker-guide {
        top: -3px;
        bottom: -3px;
        left: 10px;
        width: 8px;
        background-color: #fff;
    }
}

.colorpicker-alpha-color {
    width: 100%;
    height: 100%;
    background: linear-gradient(to left, rgb(255, 0, 0) 0%, transparent 100%);
}

.colorpicker-preview {
    @include base-height;
    flex: none;
    width: 60px;
    margin-right: 5px;
    border: $colorpicker-border;
    background-color: red;
}

.colorpicker-input {
    .form-inline & {
        width: 100%;
    }
}
