.table-dynamic-wrap {
    position: relative;
    z-index: 0;
    border-top: $table-border;
    border-bottom: $table-border;
}

.table-dynamic {
    margin-left: 120px;
    overflow-x: auto;
    overflow-y: hidden;

    background-image:
    linear-gradient(to left, rgba(#fff, 0), #d8d8d8),
    linear-gradient(to right, rgba(#fff, 0), #d8d8d8);
    // background-color: white;
    background-repeat: no-repeat;
    background-size: 25px 100%;
    background-position: left center, right center;

    & > .table {
        max-width: none;
        border-bottom: 0;
        background-image:
        linear-gradient(to left, #fff, rgba(#fff, 0)),
        linear-gradient(to right, #fff, rgba(#fff, 0));
        background-size: 50px 100%;
        background-repeat: no-repeat;
        background-position: right center, left center;
    }

    td, th {
        &.is-active {
            @extend .highlight-blue;
            background-clip: padding-box;
        }
        &.disabled {
            color: $gray-base-60;
        }
    }

    thead {
        tr:first-child {
            .table-dynamic-date {
                border-right: $table-border;
            }
        }

        tr:not(:first-child) {
            th {
                border-top: $table-border;
            }
            th:not(:last-child) {
                border-right: $table-border;
            }
        }

        th {
            padding-top: 4px;
            padding-bottom: 4px;
        }

        th.table-dynamic-symbol {
            display: flex;
            align-items: center;
            height: 50px;
            @include media-breakpoint-up(lg) {
                height: 48px;
            }
        }

        .table-dynamic-date {
            padding-bottom: 0;
        }
    }

    tbody {
        td {
            height: 50px;
            @include media-breakpoint-up(lg) {
                height: 30px;
            }
            &:not(:first-child) {
                position: relative;
                padding: 0;
            }
            &:not(:last-child) {
                border-right: $table-border;
            }

            // form elements
            .form-control-wrap {
                position: relative;
                height: 100%;
            }

            .input-group {
                position: absolute;
                top: -1px;
                left: 0;
                right: 0;
                bottom: -1px;
                width: auto;
                background-color: transparent;
            }

            .form-control, .field-input-group-actions {
                height: 100%;
                background-color: transparent;
                border-color: transparent;
            }

            .form-control {
                font-size: 1rem;
                @include placeholder {
                    font-size: 1rem;
                }
                &:focus {
                    border-color: $yc-blue;
                    background-color: #fff;
                }
                &:disabled,
                &[readonly] {
                    border-color: transparent;
                    color: $gray-base-60;
                    background-color: transparent;
                    @include placeholder {
                        color: $gray-base-60;
                    }
                }
                &.is-invalid {
                    border-color: map-get($colors, red);
                }
                &.is-valid {
                    border-color: map-get($colors, green);
                }
            }

            .field-input-group-actions {
                display: flex;
                align-items: center;

                .btn {
                    flex: none;
                }

                .btn-delete {
                    margin-right: 10px;
                    &:hover, &:focus {
                        background-color: $gray-base-90;
                    }
                }
            }

            &.table-dynamic-symbol {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    tfoot {
        td, th {
            border-top: $table-border;
            font-weight: 700;
            color: $gray-base-60;
            font-size: 1rem;

            &.is-active {
                color: $gray-base-10;
            }
        }
    }

    .th-controls {
        .btn-transparent {
            fill: $gray-base-50;
        }
    }
}

.table-dynamic-symbol {
    position: absolute;
    left: 0;
    top: auto;
    width: 120px;
    padding-left: 20px;
    font-size: 1rem;

    span {
        display: inline-block;
        padding-top: 14px;
        padding-bottom: 14px;
        @include media-breakpoint-up(lg) {
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }
}

.table-dynamic-name {
    width: 270px;
    min-width: 270px;

    .dropdown {
        margin-bottom: 8px;
    }

    .btn-secondary {
        background-color: transparent;
        &:active,
        &.active,
        &:hover,
        &:focus {
            background-color: $yc-blue-90;
        }
    }
}

.table-dynamic-name-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.table-dynamic-name-middle {
    width: 190px;
    min-width: 190px;
}

.table-dynamic-date {
    width: 130px;
    min-width: 130px;

    .table-dynamic-hover & {
        position: relative;
        &:after {
            position: absolute;
            top: -5000px;
            left: 0;
            height: 10000px;
            width: 100%;
            background-color: transparent;
            z-index: -1;
            content: "";
        }
        &:not(.disabled) {
            &:hover {
                &:after {
                    background-color: $yc-blue-90;
                }
            }
        }
    }
}

td.table-dynamic-date {
    vertical-align: bottom;
}

.table-dynamic-date-wrap {
    position: relative;
    margin-left: -10px;
    margin-right: -10px;

    .th-controls {
        position: absolute;
        top: -10px;
        right: 10px;

        .btn-transparent {
            &:focus,
            &:hover,
            &:active,
            &.active {
                background-color: $gray-base-90;
            }
        }
    }

    .form-label {
        margin-top: 10px;
        margin-bottom: 10px;
        padding-right: 10px;
        padding-left: 10px;
        color: $gray-base-60;
    }

    .form-control {
        border-color: transparent;
        background-color: transparent;
        font-weight: 700;
        color: $gray-base-10;
        &:focus {
            border-color: $yc-blue;
            background-color: #fff;
        }
        &:disabled {
            border-color: $gray-base-80;
        }
        &.is-invalid {
            border-color: map-get($colors, red);
        }
        &.is-valid {
            border-color: map-get($colors, green);
        }
        &[readonly] {
            border-color: transparent;
            background-color: transparent;
            color: $gray-base-60;
        }
    }
}

.table-dynamic-weight-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
        @include smallCapsLabel();
        font-weight: 400;
        color: $yc-blue;
    }
}
