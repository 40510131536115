
@mixin table-striped-after {
    position: absolute;
    left: -$grid-gutter-width;
    right: -$grid-gutter-width;
    top: 0;
    bottom: 0;
    display: block;
    background-color: $panel-background;
    z-index: -1;
    content: "";
}