.panels-row {
    min-height: 50vh;

    .panel {
        height: 50vh;
    }

    .panel-data {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .panel-header {
        flex: none;
    }

    .panel-scroll {
        flex: auto;
        overflow: auto;
    }
}