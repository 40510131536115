.page-error {
    background-color: #fafafa;

    &.page > .container:only-child {
        display: block;
        padding-top: 90px;
    }

    .panel {
        width: 550px;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
        padding: 40px 80px;
        border-top-width: 1px;
        background-color: #fff;
        @include media-breakpoint-up(md) {
            width: 610px;
        }
        @include media-breakpoint-up(lg) {
            width: 650px;
        }
        &.panel-sm {
            width: 450px;
        }
        &.panel-md {
            width: 580px;
        }
    }

    h1 {
        margin-bottom: 10px;
        font-size: 26px;
        color: $yc-blue;
    }

    hr {
        border: 0;
        margin: 1rem 0;
        border-bottom: 1px solid $gray-base-80;
    }

    .form-control {
        @include placeholder {
            color: $gray-base-60;
        }
    }
}

.page-error-link {
    [class*="icon"] {
        margin-left: 10px;
        fill: currentColor;
        vertical-align: middle;
    }

    span {
        text-decoration: underline;
    }
}
