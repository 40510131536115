.news {
    position: relative;
}

.news-header {
    @include smallCapsLabel();
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    color: $gray-base-40;

    .news-cell {
        padding-top: 15px;
        padding-bottom: 15px;
        @include media-breakpoint-up(lg) {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
}

.news-body {
    max-height: 200px;
    overflow: auto;
}

.news-row {
    @include base-min-height();
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    &.disabled {
        pointer-events: none;

        .news-source:after {
            content: none;
        }
    }
    &:hover {
        background-color: $yc-blue-95;
    }

    &.news-row-highlighted {
        background-color: rgba(map-get($colors, purple-light), .5);
    }
}

.news-cell {
    padding: 8px 10px;
    border-bottom: 1px solid $gray-base-90;
    @include media-breakpoint-up(lg) {
        padding-top: 5px;
        padding-bottom: 5px;
    }

}

.news-date {
    order: -1;
    flex: none;
    width: 94px;
    padding-left: 0;
    color: $gray-base-40;
    @include media-breakpoint-up(lg) {
        width: 86px;
    }
}

.news-title {
    flex: auto;

    a {
        @include underline();
        display: block;
    }
}

.news-source {
    flex: none;
    width: 130px;
    padding-right: 0;
    color: $gray-base-40;
    @include media-breakpoint-up(lg) {
        width: 120px;
    }
}

.news-cell-summary {
    position: relative;
    width: 0;
}

@mixin news-vertical {
    .news-header {
        display: none;
    }

    .news-body {
        min-height: 530px;
    }

    .news-row {
        position: relative;
        display: block;
        padding-top: 8px;
        padding-bottom: 8px;
        &:after {
            position: absolute;
            bottom: 0;
            left: 20px;
            right: 20px;
            border-bottom: 1px solid $gray-base-90;
            content: "";
        }
    }

    .news-cell {
        min-height: 0;
        padding: 0;
        border-bottom: 0;
    }

    .news-date {
        display: inline-block;
        width: auto;
        padding-left: 5px;
    }

    .news-source {
        display: inline-block;
        width: auto;
        padding-left: 0;
        &:after {
            content: ",";
        }
    }

    .news-title {
        padding-right: 0;
        padding-left: 0;
        padding-bottom: 0;
    }
}

.news-vertical {
    @include news-vertical;
}

.news-vertical-md {
    @include media-breakpoint-up(md) {
        @include news-vertical;
    }
}

.news-striped {
    .news-row:nth-child(even) {
        background-color: $panel-background;
    }
}

// News pages styles

.news-app {

    .news-page-symbol {
        width: 120px;
        flex: none;

        a {
            margin-right: 5px;
        }
    }

    .news-page-source {
        width: 150px;
        flex: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }


    .news-page-name {
        margin-left: 20px;
    }

    .news-page-filters {
        @include textLabel();
        margin-left: 10px;

        .news-page-filters-clear a {
            text-decoration: underline;
            color: $yc-blue;
        }

    }

    .news-page-breadcrumbs {
        margin-left: 10px;
        margin-top: 10px;
        margin-bottom: 10px;

        .news-page-breadcrumbs-label {
            color: $gray-base-50;
        }
    }

    .news-page-body-headers {
        @include smallCapsLabel();
        flex: none;
        display: flex;
        padding-left: 20px;
        padding-right: 20px;
        color: $gray-base-50;
        margin-left: 0px;

        .news-cell {
            padding-top: 15px;
            padding-bottom: 15px;
            @include media-breakpoint-up(lg) {
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }
    }

    .news-page-body {
        overflow: auto;
        scrollbar-gutter: stable;
    }

}

.news-story-page {

    margin-top: 30px;

    .news-story-subhead {
        margin: 0 0 5px 0;
    }

    .news-story-by-line {
        margin: 0 0 15px 0;
        color: $gray-base-50;
    }

    .news-story-related {
        color: $gray-base-20;

        .news-story-related-source {
            color: $gray-base-60;
        }
    }
}
