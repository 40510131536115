@use "sass:math";

.page-message {
    position: relative;
    font-size: 17px;
    @include media-breakpoint-up(lg) {
        font-size: 16px;
    }

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 50px;
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: transparent;
        @include media-breakpoint-up(lg) {
            min-height: 40px;
        }
    }
}

.page-message-error {
    background-color: #fde7e7;

    .page-message-text {
        color: map-get($negative-colors, 1);
    }

    .page-message-actions {
        .btn-transparent {
            fill: $gray-base-50;
            &:hover,
            &:focus,
            &:active {
                background-color: $gray-base-90;
            }
        }
    }
}

.page-message-info,
.page-message-info-light {
    background-color: $yc-blue;

    .page-message-text {
        color: #fff;
    }

    .page-message-actions {
        .btn-transparent {
            fill: #fff;
            &:hover,
            &:focus,
            &:active {
                fill: $yc-blue;
                background-color: $gray-base-90;
            }
        }
    }
}

.page-message-info {
    background-color: $yc-blue;
}

.page-message-info-light {
    background-color: $yc-blue-25;
}

.page-message-text {
    &:not(:last-child) {
        margin-right: $grid-gutter-width;
    }

    .page-message-btn {
        margin-left: $grid-gutter-width * 0.5;
    }

    a {
        color: inherit;
        text-decoration: underline;
    }
}


.page-message-actions {
    .btn-transparent {
        vertical-align: middle;
        &.disabled {
            fill: $gray-base-70;
            cursor: not-allowed;
            &:hover,
            &:focus,
            &:active {
                background-color: transparent;
            }
        }
    }

    .page-message-text {
        display: inline-block;
        margin-left: $grid-gutter-width * 0.5;
    }
}

.page-message-sticked {
    position: fixed;
    right: 0;
    padding: 12px 0;
    transform: translate3d(21px, 0, 0);
    z-index: $zindex-sticky;
    transition: .1s ease all;
    @include media-breakpoint-up(lg) {
        padding: 8px 0;
    }
    &:hover {
        transform: translate3d(0, 0, 0);
        .page-message-sticker {
            background-color: $yc-blue-25;
        }
    }
}

.page-message-sticker {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 65px;
    padding-right: 21px;
    background-color: $yc-blue-50;
    transition: .1s ease all;
    @include box-shadow;
    @include media-breakpoint-up(lg) {
        height: 38px;
    }

    .icon-open-arrow {
        transform: rotate(180deg);
        fill: #fff;
    }

}

// Page progress

.page-progress {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 12px;
    background-color: $gray-base-30;
}

.page-progress-bar {
    position: relative;
    top: 3px;
    height: 6px;
    overflow: hidden;
    &:before,
    &:after {
        position: absolute;
        top: 0;
        display: block;
        height: 100%;
        width: $page-progress-bar-width;
        animation-duration: $page-progress-bar-animation-duration;
        animation-fill-mode: both;
        animation-timing-function: cubic-bezier(0, 0, .7, 1);
        animation-iteration-count: infinite;
        content: "";
    }
    &:before {
        right: 100% + $page-progress-bar-width;
        animation-name: shake-before;
        transform-origin: 0 0 0;
        background: $page-progress-bar-before-background;
    }
    &:after {
        left: 100% + $page-progress-bar-width;
        animation-name: shake-after;
        transform-origin: 100% 0 0;
        background: $page-progress-bar-after-background;
    }
}

@keyframes shake-before {
    0%, 100% {
        width: $page-progress-bar-width;
        right: 100% + $page-progress-bar-width;
        background: $page-progress-bar-before-background;
    }
    #{50% - $page-progress-bar-width} {
        width: $page-progress-bar-width;
    }
    50% {
        width: math.div($page-progress-bar-width, 1.5);
        right: 49.9%;
        background: $page-progress-bar-before-background;
    }
    #{50% + $page-progress-bar-width} {
        width: $page-progress-bar-width;
    }
    50.001% {
        background: $page-progress-bar-after-background;
    }
    99.999% {
        background: $page-progress-bar-after-background;
    }
}
@keyframes shake-after {
    0%, 100% {
        left: 100% + $page-progress-bar-width;
        background: $page-progress-bar-after-background;
    }
    #{50% - $page-progress-bar-width * 0.5} {
        width: $page-progress-bar-width;
    }
    50% {
        width: math.div($page-progress-bar-width, 1.5);
        left: 49.9%;
        background: $page-progress-bar-after-background;
    }
    #{50% + $page-progress-bar-width * 0.5} {
        width: $page-progress-bar-width;
    }
    50.001% {
        background: $page-progress-bar-before-background;
    }
    99.999% {
        background: $page-progress-bar-before-background;
    }
}

.page-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-color: $modal-backdrop-bg;
    opacity: $modal-backdrop-opacity;
}

.page-backdrop ~ .page-message {
    z-index: 10;
}
