

.template-module {
    position: relative;
    display: block;
    margin-right: 0;
    padding: 8px $grid-gutter-width 14px $spacer * 6;
    border: 1px solid $gray-base-80;
    border-radius: 2px;
    background-color: #fff;
    cursor: pointer;
    &:hover {
        border-color: $yc-blue;
    }
    @include media-breakpoint-up(lg) {
        &:hover {
            .btn-delete {
                display: flex;
            }
        }
        .btn-delete {
            display: none;
        }
    }

    .custom-control-indicator {
        position: absolute;
        top: 18px;
        left: 16px;
    }
}

.template-module-content {
}

.template-module-header {
    position: relative;
    padding-bottom: 10px;
    @include lineCropAfter();
}

.template-module-title {
    @include base-min-height();
    @include headerDefault();
    display: flex;
    align-items: center;
    @include media-breakpoint-up(lg) {
        padding-right: $base-height-desktop;
    }
}

.template-module-subtitle {
    margin-top: -$spacer;
    @include media-breakpoint-up(lg) {
        margin-top: -$spacer * 0.5;
    }
}

.template-module-controls {
    float: right;
    margin-left: $grid-gutter-width;
    @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: 0;
    }

    .btn-preview {
        fill: $gray-base-30;
        &:hover {
            background-color: $gray-base-90;
        }
    }
}

.template-module-body {
    @include textSmall();
    @include lineCropBefore();

    p {
        margin-bottom: 0;
    }

    hr.hr {
        border: 0;
        border-top: 1px solid $gray-base-90;
    }
}

.template-module-info {
    @include lineCropBefore();

    font-style: italic;
    font-size: 11px;
    padding-top: $spacer * 0.5;  // 4px
}

// Report templates

.template-module-report {
    max-width: 160px;
    padding-top: 10px;
    padding-bottom: 10px;
    outline: 2px solid transparent;
    text-align: center;
    cursor: pointer;

    .template-module-title {
        display: block;
        padding-right: 0;
    }

    .template-module-subtitle {
        @include textSmall();
        padding-right: $spacer;
        padding-left: $spacer;
    }

    &:hover,
    &.is-hovered {
        background-color: $yc-blue-90;
    }

    &.is-checked {
        outline-color: $yc-blue;
    }
}

.dark-mode {
    .template-module-report {
        &:hover,
        &.is-hovered {
            background-color: $gray-base-20;
        }
    }
}

.template-module-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 56px;
    margin-bottom: $spacer * 1.5;
    &.mb-1 {
        margin-bottom: $spacer;
    }

    svg {
        flex: none;
        font-size: 1rem;
    }
}
