.notes {
    &.table-hover {
        tbody tr {
            &:hover {
                cursor: default;
            }
        }
    }

    tbody td {
        vertical-align: top;
        color: $gray-base-10;
    }
}

.note {

}

.note-text {
}

.note-shared {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;

    .form-label {
        margin-left: 7px;
    }
}

.note-date {
    @include textSmall();
    white-space: nowrap;
}

.note-author {
    width: 75px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.note-company {
    @include textSmall();
    color: $gray-base-45;
}

.note-controls {
    display: flex;
    &.text-right {
        justify-content: flex-end;
    }

    .btn-transparent {
        fill: $gray-base-50;
        &:hover,
        &:focus,
        &:active {
            background-color: $gray-base-90;
        }
        &.disabled {
            fill: $gray-base-70;
            cursor: not-allowed;
            &:hover,
            &:focus,
            &:active {
                background-color: transparent;
            }
        }
    }

    .btn.btn-circle.btn-xs + .btn-xs {
        margin-left: 12px;
    }
}

.btn-notes {
    position: relative;
    &:after {
        position: absolute;
        top: 0;
        right: -3px;
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: map-get($colors, red);
    }

    &.is-new {
        &:after {
            content: "";
        }
    }
}
