.border {
	border-width: 1px;
	border-style: solid;
}
.border-l {
	border-left-width: 1px;
	border-left-style: solid;
}
.border-r {
	border-right-width: 1px;
	border-right-style: solid;
}
.border-t {
	border-top-width: 1px;
	border-top-style: solid;
}
.border-b {
	border-bottom-width: 1px;
	border-bottom-style: solid;
}

.border-get-started {
	border: 2px dashed $gray-base-80
}

.border-top-0 {
	border-top: none !important;
}

.border-bottom-0 {
	border-bottom: none !important;
}

.border-0 {
	border: none !important;
}

.rounded-4 {
	border-radius: 4px !important;
}

.rounded-8 {
	border-radius: 8px !important;
}