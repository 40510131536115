.popover-wrap {
    position: relative;
}

.popover {
    @include box-shadow();
    position: absolute;
    bottom: 100%;
    left: 0;
    display: none;
    transform: translateX(-50%);
    margin-bottom: 10px;
    margin-left: 50%;
    background-color: #fff;
    z-index: 1;

    &.show {
        display: block;
    }

    .btn-close {
        position: absolute;
        top: 5px;
        right: 5px;
        fill: $gray-base-60;
    }
}

.popover-header {
    display: flex;
    align-items: center;
    padding: 6px 3px;
    background-color: $panel-background;
    border-bottom: 1px solid $gray-base-90;

    .btn-back {
        margin-right: 5px;
    }
}

.popover-title {
    @include headerDefault();
}

.popover-body {
    display: flex;
}

.popover-large {
    min-width: 200px;
    padding: 12px 15px;
    border: 1px solid $gray-base-90;
    box-shadow: 0 2px 4px 0 rgba(#000, .5);
}

.popover-over {
    top: 0;
    right: 0;
    left: auto;
    bottom: auto;
    transform: none;
}

.popover-footer {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    border-top: 1px solid $gray-base-90;
}
