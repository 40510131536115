@use "sass:math";

@include make-table-cols();

.panel-table {
    &:not(:last-child) {
        .table {
            border-bottom: 1px solid $gray-base-90;
        }
    }
}

.table-header {
    @include headerDefault();
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 0;
    color: $gray-base-10;
    @include media-breakpoint-up(lg) {
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

.table {
    width: 100%;
    border-bottom: $table-border;

    th {
        @include headerDefault();
        vertical-align: bottom;
    }

    thead {
        th, td {
            padding-top: 15px;
            padding-bottom: 15px;
            @include media-breakpoint-up(lg) {
                padding-top: 10px;
                padding-bottom: 10px;
            }
            &:not(:first-child) {
                padding-left: $grid-gutter-width * 0.5;
            }
            &:not(:last-child) {
                padding-right: $grid-gutter-width * 0.5;
            }
            &.text-thin {
                @include smallCapsLabel();
                vertical-align: bottom;
                color: $gray-base-30;
            }

            span {
                display: inline-block;
                padding-top: 10px;
                padding-bottom: 10px;
            }

            span.help-toggler,
            span.pt-0.pb-0,
            .custom-control span {
                padding-top: 0;
                padding-bottom: 0;
            }

            span.pr-0 {
                padding-right: 0;
            }

            &.vertical-align-top {
                vertical-align: top;
            }

            &.disabled {
                color: $gray-base-60;
            }
            
            .text-clipped span {
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        .table-th-label {
            position: relative;
            padding-top: 20px;
            vertical-align: bottom;

            & ~ td {
                vertical-align: bottom;
            }

            .form-label {
                position: absolute;
                top: 5px;
                left: 12px;
                white-space: nowrap;
            }
        }
    }

    tbody {
        th,
        td {
            padding-top: 9px;
            padding-bottom: 9px;
            border-top: $table-border;
            vertical-align: middle;
            color: $gray-base-50;
            fill: $gray-base-50;
            &:not(:first-child) {
                padding-left: $grid-gutter-width * 0.5;
            }
            &:not(:last-child) {
                padding-right: $grid-gutter-width * 0.5;
            }
            &.pl-0 {
                padding-left: 0;
            }
            @include media-breakpoint-up(lg) {
                padding-top: 7px;
                padding-bottom: 7px;
            }
            &.vertical-align-bottom {
                vertical-align: bottom;
            }
            &.vertical-align-top {
                vertical-align: top;
            }
            &.vertical-align-middle {
                vertical-align: middle;
            }

            .icon-team {
                display: inline-block;
                margin-left: 10px;
            }

            .dropdown-item, .btn {
                .icon-team {
                    margin-left: 0;
                }
            }

            .btn-edit {
                fill: $gray-base-50;
            }

            .btn-check {
                position: absolute;
                top: 6px;
                left: 10px;
                fill: $yc-blue;
            }
        }

        th {
            font-weight: 400;
            color: $gray-base-10;
        }

        .icon-charts {
            fill: $yc-blue;
        }
    }

    &.align-top tbody td {
        vertical-align: top;
    }

    td, th {
        &.col-6 {
            width: 50%;
        }
        &.col-3 {
            width: 25%;
        }
        &.col-2 {
            width: 16.6666666667%;
        }
        &.col-1 {
            width: 8.3333333333%;
        }
        &.col-md-2 {
            @include media-breakpoint-up(md) {
                width: 16.6666666667%;
                max-width: 16.6666666667%;
            }
        }
        &.col-lg-1 {
            @include media-breakpoint-up(lg) {
                width: 8.3333333333%;
                max-width: 8.3333333333%;
            }
        }
        &.colTxtShort {
            min-width: $quad-table-cell-width;;
            width: $quad-table-cell-width;
        }
        &.colTxtMed {
            min-width: 101px;
            width: 101px;
        }
        &.colTxtLong {
            min-width: 151px;
            width: 151px;
        }
        &.colTxtXLong {
            min-width: 201px;
            width: 201px;
        }
        &.is-highlighted {
            position: relative;
            &:after {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: block;
                background-color: $table-accent-bg;
                background-clip: padding-box;
                content: "";
                z-index: -1;
            }
        }
        &.td-details {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    tr.is-accented {
        background-color: $table-accent-bg;
    }

    tr.is-error {
        background-color: map-get($negative-colors, 6);
    }

    tr.bg-signal {
        background-color: map-get($colors, signal-orange-2);
    }

    tr.is-selected {
        td {
            position: relative;
            &:before {
            }
            &:after {
                position: absolute;
                top: 0;
                bottom: -1px; // compensate border
                left: 0;
                display: block;
                width: 100%;
                border-top: 1px solid $yc-blue;
                border-bottom: 1px solid $yc-blue;
                content: "";
            }
            &:first-child,
            &:last-child {
                &:before {
                    position: absolute;
                    top: 0;
                    bottom: -1px !important;
                    display: block;
                    width: $grid-gutter-width;
                    border-top: 1px solid $yc-blue;
                    border-bottom: 1px solid $yc-blue;
                    content: "";
                }
            }
            &:first-child:before {
                left: -$grid-gutter-width;
                border-left: 1px solid $yc-blue;
            }
            &:last-child:before {
                right: -$grid-gutter-width;
                border-right: 1px solid $yc-blue;
            }
        }
    }

    tr.bg-panel {
        td, th {
            position: relative;
            &:after {
                @include table-striped-after();
            }
        }
    }

    tr.disabled td {
        color: $gray-base-70;
        fill: currentColor;
    }

    tfoot {

        td {
            border-top: $table-border;
            padding-top: 9px;
            padding-bottom: 9px;
            &:not(:first-child) {
                padding-left: $grid-gutter-width * 0.5;
            }
            &:not(:last-child) {
                padding-right: $grid-gutter-width * 0.5;
            }
            @include media-breakpoint-up(lg) {
                padding-top: 7px;
                padding-bottom: 7px;
            }
        }
    }

    &.table-airy {
        tbody td {
            padding-top: 15px;
            padding-bottom: 15px;
            @include media-breakpoint-up(lg) {
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }
    }

    &.table-dense {
        tbody td {
            padding-top: 10px;
            padding-bottom: 10px;
            @include media-breakpoint-up(lg) {
                padding-top: 8px;
                padding-bottom: 8px;
            }
        }
    }

    .chart-legend-item:first-child {
        padding-top: 0;
    }
    .chart-legend-item:last-child {
        padding-bottom: 0;
    }

    .collapse-mark {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
    }
}

.table-hover {
    td, th {
        &:first-child,
        &:last-child {
            padding-right: 10px;
            padding-left: 10px;
        }
    }
    tbody tr {
        &:hover {
            background-color: $table-hover-bg;
            cursor: pointer;

            th {
                color: $yc-blue;
                fill: $yc-blue;
            }
        }
    }

    .panel-content & {
        tbody tr:hover {
            td {
                &:first-child,
                &:last-child {
                    position: relative;
                    &:before {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        display: block;
                        width: $grid-gutter-width;
                        background-color: $yc-blue-95;
                        content: "";
                    }
                }
                &:first-child:before {
                    left: -$grid-gutter-width;
                }
                &:last-child:before {
                    right: -$grid-gutter-width;
                }
            }
        }
    }
}

.table-sortable {
    thead th {
        &.th-sort {
            cursor: pointer;
        }
    }
}

.table-checkable {
    tr {
        td:first-child,
        th:first-child {
            position: relative;
            padding-left: 48px;
        }
        &.table-row-checked {
            .btn-check {
                display: block;
            }
        }

        .btn-check {
            display: none;
        }
    }
}

.table-bordered {
    border: $table-border;
}

.table-bordered-inside {
    td, th {
        &:not(:last-child) {
            border-right: $table-border;
        }
    }
}

.table-striped {
    td, th {
        position: relative;
    }
    tbody {
        tr:nth-child(odd) {
            td, th {
                &:after {
                    @include table-striped-after();
                }
            }
        }
    }
}

tbody.table-striped tr:nth-child(odd) {
    td, th {
        &:after {
            @include table-striped-after();
        }
    }
}

.table-striped-inversed {
    td, th {
        position: relative;
    }
    tbody {
        tr:nth-child(even) {
            td, th {
                &:after {
                    position: absolute;
                    left: -$grid-gutter-width;
                    right: -$grid-gutter-width;
                    top: 0;
                    bottom: 0;
                    display: block;
                    background-color: $panel-background;
                    z-index: -1;
                    content: "";
                }
            }
        }
    }
}

.table-no-striped {
    tbody {
        td, th {
            &:after {
                background-color: transparent !important;
            }
        }
    }
}

.td-wrap {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.td-controls {
    order: 1;

    .btn-transparent {
        fill: $gray-base-40;
        &:hover,
        &:focus,
        &.active {
            background-color: $yc-blue-90;
        }
    }

    .btn-xs {
        margin-top: -16%;
        @include media-breakpoint-up(lg) {
            margin-top: 0;
        }
    }
}

.table-link {
    @include underline();
    color: $gray-base-10;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    &:hover,
    &:focus,
    &:active {
        color: $yc-blue;
        text-decoration: underline;
        text-decoration-color: $yc-blue;
    }
    @include media-breakpoint-up(lg) {
        font-size: 13px;
        line-height: 15px;
    }
    white-space: nowrap; // temp
}

.table-apply-link {
    @include base-line-height();
    font-weight: normal;
    color: $yc-blue;
    &:hover {
        text-decoration: underline;
    }
    &.disabled {
        color: $gray-base-60;
        cursor: default;
        text-decoration: none;
    }
}

.table-value,
.table-grade {
    color: $gray-base-10;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    @include media-breakpoint-up(lg) {
        font-size: 13px;
        line-height: 15px;
    }
}

.table-value {
    vertical-align: text-bottom;
    white-space: nowrap;
}

.table-grade {
    width: 50%;
}

.table-file-open {
    margin-bottom: $grid-gutter-width;

    thead th {
        color: $gray-base-60;
         a[href] span {
            @include underline();
         }
    }

    tbody {
        th {
            width: 50%;
        }

        td {
            .icon-team {
                margin-right: .625em;
            }
        }
    }

    .btn-transparent:hover {
        background-color: $yc-blue-90;
    }
}

.th-date {
    width: 25%;
}

.th-control {
    width: 5%;
}

.th-sort-asc,
.th-sort-desc {
    position: relative;
    color: $gray-base-40;

    span,
    .sort-mark {
        position: relative;
        &:after {
            position: absolute;
            bottom: 17px;
            right: 10px;
            display: block;
            width: 0;
            height: 0;
            margin-left: .533em;
            vertical-align: middle;
            content: "";
            @include media-breakpoint-up(lg) {
                margin-left: .538em;
            }
        }
    }

    span {
        padding-right: 20px;
    }

    .sort-mark {
        display: inline-block;
    }

    .sort-mark:after {
        position: static;
        display: inline-block;
    }

    &.text-right {
        span {
            padding-right: 10px;
            padding-left: 20px;
            &:after {
                right: auto;
                left: 10px;
                margin-left: 0;
                margin-right: .533em;
                @include media-breakpoint-up(lg) {
                    margin-right: .538em;
                }
            }
        }
    }
}

.th-sort-asc {
    span:after,
    .sort-mark:after {
        border-bottom: math.div($caret-width, 1.5) solid $gray-base-40;
        border-right: $caret-width * 0.5 solid transparent;
        border-left: $caret-width * 0.5 solid transparent;
        @include media-breakpoint-up(lg) {
            border-bottom: math.div($caret-width - 1, 1.5) solid $gray-base-40;
            border-right: ($caret-width - 1) * 0.5 solid transparent;
            border-left: ($caret-width - 1) * 0.5 solid transparent;
            }
        }
    }

.th-sort-desc {
    .sort-mark:after,
    span:after {
        border-top: math.div($caret-width, 1.5) solid $gray-base-40;
        border-right: $caret-width * 0.5 solid transparent;
        border-left: $caret-width * 0.5 solid transparent;
        @include media-breakpoint-up(lg) {
            border-top: math.div($caret-width - 1, 1.5) solid $gray-base-40;
            border-right: ($caret-width - 1) * 0.5 solid transparent;
            border-left: ($caret-width - 1) * 0.5 solid transparent;
        }
    }
}


/* Pseudo table */


.tr {
    display: flex;
    width: 100%;
}

.th,
.td {
    flex: none;
    padding-top: 5px;
    padding-bottom: 5px;
    border-top: $table-border;
    vertical-align: middle;
    color: $gray-base-50;
    fill: $gray-base-50;
    &:not(:first-child) {
        padding-left: $grid-gutter-width * 0.5;
    }
    &:not(:last-child) {
        padding-right: $grid-gutter-width * 0.5;
    }
    &.flex-auto {
        flex: auto;
    }
}

.th {
    @include headerDefault();
}


// scrollable TBODY
.table-scroll-wrap {
    overflow: auto;

    table {
        border-top: 0;
        border-left: 0;
        border-right: 0;
    }

    thead tr {
        td, th {
            position: sticky;
            top: 0;
            background-color: #fff;
        }
    }

    .col-1 {
        width: 8.33333%;
    }

    .col-3 {
        width: 25%;
    }
    .col-3 {
        width: 50%;
    }
}

// Hide cells on tablet
.hide-sm-2 {
    td, th, .td {
        &:nth-child(2) {
            display: none;
            @include media-breakpoint-up(lg) {
                display: table-cell;
            }
        }
    }
}

.hide-sm-3 {
    td, th, .td {
        &:nth-child(3) {
            display: none;
            @include media-breakpoint-up(lg) {
                display: table-cell;
            }
        }
    }
}

.hide-sm-4 {
    td, th, .td {
        &:nth-child(4) {
            display: none;
            @include media-breakpoint-up(lg) {
                display: table-cell;
            }
        }
    }
}

.hide-sm-5 {
    td, th, .td {
        &:nth-child(5) {
            display: none;
            @include media-breakpoint-up(lg) {
                display: table-cell;
            }
        }
    }
}

// Ellipsis
.td-ellipsis {
    display: inline-flex;
    max-width: 100%;
}

.td-ellipsis-content {
    @include textOverflow();

    a {
        display: inline-block;
    }
}

// .panel-data tables
.panel-data {
    .panel-content .table,
    .table.table-panel {
        thead {
            td, th {
                &.th-sort-asc,
                &.th-sort-desc {
                    span {
                        padding-top: 0;
                        padding-bottom: 0;
                        &:after {
                            right: 0;
                            bottom: .5em;
                        }
                    }
                }
            }
        }

        tbody {
            td, th {
                vertical-align: top;
            }

            tr.vertical-align-middle {
                td, th {
                    vertical-align: middle;
                }
            }

            td {
                padding-top: 10px;
                padding-bottom: 10px;
                line-height: 1.35;
                color: $gray-base-10;
                @include media-breakpoint-up(lg) {
                    padding-top: 5px;
                    padding-bottom: 5px;
                    line-height: 1.429;
                }

                .chart-legend-item {
                    line-height: inherit;
                    .chart-legend-item-title {
                      &:before,.chart-bullet {
                        top: 6px;
                      }
                    }
                }

                a:not(.text-blue) {
                    @include underline();
                }

                small {
                    color: $gray-base-50;
                }
            }
        }

        &:first-child {
            tbody:first-child {
                tr:first-child {
                    td {
                        border-top: 0;
                    }
                }
            }
            td, th {
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
            }
        }

        .icon-check {
            vertical-align: bottom;
            fill: map-get($colors, green);
        }

        .icon-square {
            fill: currentColor;
            width: 10px;
            height: 10px;
        }

        .chart-horizontal-bars {
            display: inline-flex;
            width: 100%;
        }

        .table-label {
            .icon-square {
                width: 15px;
                height: 15px;
                vertical-align: middle;
                @include media-breakpoint-up(lg) {
                    width: 14px;
                    height: 14px;
                }
                &:not(:first-child) {
                    margin-left: 4px;
                }
                &:not(:last-child) {
                    margin-right: 4px;
                }
            }

        }
    }
}

// .panel-complex tables
.panel-complex {
    .table {
        tbody {
            td {
                color: $gray-base-10;
            }
        }
        &.table-dense {
            tbody {
                td {
                    padding-top: 5px;
                    padding-bottom: 5px;
                    line-height: 1.35;
                }
            }

        }
    }
}

.td-placeholder {
    position: relative;
    width: 0;
    padding: 0 !important;
}

.table {
    .btn-i {
        position: absolute;
        right: 0;
        display: none;
        background-color: $gray-base-90;
        fill: $yc-blue;
        z-index: 1;
    }

    tr:hover {
        .btn-i {
            display: inline-flex;
        }
    }
}

.table-fixed {
    table-layout: fixed;
}

.table-auto-lg {
    @include media-breakpoint-up(lg) {
        table-layout: auto;
    }
}

.table-fixed-md {
    table-layout: fixed;
}

.table-status {
    display: flex;
    align-items: center;
    color: $gray-base-60;

    svg {
        margin-right: $spacer;
        font-size: 1rem;
        fill: currentColor;
    }
}

.table-logo {
    display: flex;
    align-items: center;

    img {
        width: 42px;
        height: 42px;
        margin-right: $spacer * 2;
    }
}

.td-index {
    max-width: 240px;
    min-width: 240px;
}

// Subtable
.subtable-toggle {
    cursor: pointer;

    // using for Region Exposure tables only for now
    td:first-child {
        padding-right: 0;
    }

    .dropdown-toggle {
        display: flex;
        align-items: center;
        &:after {
            border-top-color: $gray-base-50;
        }
    }

    &.active {
        .dropdown-toggle:after {
            transform: rotate(180deg);
        }
    }
}

.subtable {
    &.table-striped {
        tr:nth-child(odd) {
            td {
                background-color: $panel-background;
            }
        }

        td {
            padding-left: $grid-gutter-width * 0.5 !important;
            padding-right: $grid-gutter-width * 0.5 !important;
            border-top: 0;
        }
    }
    &:not(.show) {
        display: none;
    }
}

.country-cell-wrap {
    display: flex;
    width: 100%;

    .flag-icon {
        position: relative;
        top: 3px;
        flex: none;
        margin-right: 10px;
    }
}

.country-cell-title {
    flex: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
