.badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 17px;
    min-width: 17px;
    line-height: 1;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 11px;
    font-weight: 700;
    border-radius: 2rem;
    &:not(:first-child) {
        margin-left: 4px;
    }

    &.badge-sm {
        height: 14px;
        min-width: 14px;
        line-height: 1;
        padding-left: 1px;
        padding-right: 1px;
        font-size: 10px;
        @include media-breakpoint-up(lg) {
            height: 12px;
            min-width: 12px;
            padding-left: 1px;
            padding-right: 1px;
            font-size: 8px;
        }
    }

    &.badge-lg {
        height: 26px;
        min-width: 28px;
        font-size: 16px;
        font-weight: 400;
    }

    &.badge-left {
        right: auto;
        left: 0;
    }

    &.bg-red {
        background-color: map-get($colors, red);
        color: #fff;
    }

    &.bg-yc-blue-90 {
        background-color: $yc-blue-90;
        color: $gray-base-20;
        fill: $gray-base-40;
    }

    &.border-gray-base-90 {
        border: 1px solid $gray-base-90;
    }

    [class*="icon"] {
        &:not(:first-child) {
            margin-left: 4px;
            font-size: 9px;
        }
    }

    // contexts
    .nav-link.active & {
        display: none;
    }
    .table-alerts &,
    .options-list-item-title & {
        font-weight: 400;
    }
}
