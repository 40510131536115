.form-range {
  position: relative;
  width: 100%;
}

.form-range-input {
  position: relative;
  -webkit-appearance: none;
  display: block;
  height: $base-height-desktop;
  width: 100%;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

@mixin slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 6px;
  height: 6px;
  border-radius: 50%; 
  background-color: #000;  
}

.form-range-input::-webkit-slider-thumb {
  @include slider-thumb;
}

.form-range-input::-moz-range-thumb {
  @include slider-thumb;
}

.form-range-track {
  position: absolute;
  top: 50%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1px;
  width: 100%;
  margin-bottom: 0;
  padding-left: 0;
  background-color: #000;
  pointer-events: none;

  li {
    list-style: none;
    width: 4px;
    height: 4px;
    background-color: #000;
    border-radius: 50%;
  }
}

.form-range-track-highlight {
  position: absolute;
  top: 50%;
  left: 1px;
  height: 2px;
  background-color: #000;
}
