
.tabs {
    .nav {
        padding-left: $grid-gutter-width;
        padding-right: $grid-gutter-width;
    }

    &.tabs-bordered {
        .nav {
            padding-left: 0;
            padding-right: 0;
            border-left: 1px solid $gray-base-90;
            border-right: 1px solid $gray-base-90;
        }

        & + .tab-content {
            border: 1px solid $gray-base-90;
            border-top: 0;

            .tab-pane {
                padding-left: 0;
                padding-right: 0;

                .modal-section {
                    padding-left: $grid-gutter-width;
                    padding-right: $grid-gutter-width;
                }
            }
        }
    }

    &.page-tabs {
        width: 100%;
        max-width: map-get($container-max-widths, xxl);
        margin-left: auto;
        margin-right: auto;
        background-color: $gray-base-90;

        .nav {
            justify-content: flex-start;
            padding-left: 0;
            padding-right: 0;
        }
        .container {
            background-color: transparent;
        }
    }

    &.page-subtabs {
        border-bottom: 1px solid $gray-base-80;

        .nav {
            justify-content: flex-start;
            padding-left: 0;
            padding-right: 0;
            background-color: transparent;
        }

        .nav-link {
            height: $base-height;
            border: 0;
            @include media-breakpoint-up(lg) {
                height: $base-height-desktop + 4;
            }
            &.active {
                position: relative;
                &:after {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translate(-50%, 0);
                    display: block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 5px 5px 5px;
                    border-color: transparent transparent $gray-base-80 transparent;
                    content: "";
                }
            }
            &:not(.active) {
                font-weight: 400;
            }
        }
    }

    &.tabs-vertical {
        .nav {
            flex-direction: column;
            padding-right: 0;
            background-color: transparent;

            &.nav-sticky {
                position: sticky;
                top: 0;
                max-height: 100vh;
                overflow: auto;
            }
        }

        .nav-item {
            display: block;
            padding-left: 4px;
            &:first-child {
                border-top: 1px solid $gray-base-90;
            }
        }

        .nav-link {
            position: relative;
            justify-content: flex-start;
            padding-left: 10px;
            border-top: 0;
            border: 0;
            &:before {
                position: absolute;
                top: -1px;
                bottom: -1px;
                right: 100%;
                display: block;
                width: 4px;
                background-color: transparent;
                content: "";
            }
            &.active {
                background-color: $yc-blue-95;
                color: inherit;
                &:before {
                    background-color: $yc-blue;
                }

                & + ul {
                    display: block;
                }
            }
        }

        .nav-link-circle {
            justify-content: center;
        }

        // second level
        .nav-item ul {
            display: none;
            padding-top: $spacer;
            padding-left: $spacer;
            list-style: none;

            .nav-link {
                @include headerDefault();
                min-height: auto;
                &.active {
                    border-top-color: transparent;
                    border-bottom-color: transparent;
                    background-color: transparent;
                    color: $yc-blue;
                    &:before {
                        content: none;
                    }
                }
            }

            // third level
            ul {
                padding-top: 0;
                padding-left: $spacer * 4;

                li {
                    @include textDefault();
                    margin-bottom: $spacer;
                }

                a {
                    &:hover {
                        text-decoration: underline;
                    }
                    &.active {
                        color: $yc-blue;
                    }
                }
            }
        }

        & + .tab-content {
            flex: 1;
            border-bottom: 0;
        }
    }
}

.tabs-vertical-wrap {
    display: flex;
    width: 100%;
    border-top: 1px solid $gray-base-90;
    border-bottom: 1px solid $gray-base-90;

    .nav-item {
        border-bottom: 1px solid $gray-base-90;
    }

    .modal-body & {
        .tabs {
            max-width: 33.3%;
            margin-top: 14px;;
            margin-bottom: 14px;
        }

        .tab-content {
            background-color: $panel-background;
        }

        .tab-pane {
            padding: 0;

            .tab-pane-inner {
                height: 510px;
                padding-left: $grid-gutter-width;
                padding-right: $grid-gutter-width;

                .footnote {
                    @include chartAxis();
                    font-weight: 400;
                    padding-top: 6px;
                    color: $gray-base-40;
                }
            }
        }
    }
}

.nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    background-color: $gray-base-90;

    &.nav-width-equal {
        .nav-item {
            flex: 1;
        }
    }
}

.nav-item {
    display: flex;
}

.nav-link {
    @include headerDefault();
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: $base-height + 4;
    padding-left: 16px;
    padding-right: 16px;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    color: $gray-base-30;
    white-space: nowrap;
    @include media-breakpoint-up(md) {
        padding-left: 18px;
        padding-right: 18px;
    }
    @include media-breakpoint-up(lg) {
        height: $base-height-desktop + 4;
        padding-left: 16px;
        padding-right: 16px;
    }
    &:active,
    &:focus,
    &:hover {
        @include underline();
        color: $gray-base-10;
        outline: none;
    }
    &.active {
        border-top-color: $yc-blue;
        background-color: $white;
        color: $yc-blue;
        &:active,
        &:focus,
        &:hover {
            text-decoration: none;
        }
    }
    &.disabled {
        color: $gray-base-50;
        fill: $gray-base-50;
        pointer-events: none;
    }
    &.nav-link-multiline {
        height: auto;
        min-height: $base-height + 4;
        padding-top: 10px;
        padding-bottom: 10px;
        white-space: normal;
        @include media-breakpoint-up(lg) {
            min-height: $base-height-desktop + 4;
            padding-top: 6px;
            padding-bottom: 6px;
        }
    }
}

.nav-link-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

a.nav-link * {
    pointer-events: none;
}


.tab-pane {
    display: none;
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
    &.active {
        display: block;
        &.tab-pane-flex {
            display: flex;
            flex-direction: column;
        }
    }

    .page-header {
        display: flex;
        margin-top: -$panel-margin;
        @include media-breakpoint-up(lg) {
            margin-top: -14px;
        }
    }
}

.tab-content {
    &.page-tabs {
        padding-top: $panel-margin;
        @include media-breakpoint-up(lg) {
            padding-top: 14px;
        }
        &.pt-0 {
            padding-top: 0;
        }
        .tab-pane {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .page-subtabs {
        margin-top: -$panel-margin;
        margin-bottom: $panel-margin;
        @include media-breakpoint-up(lg) {
            margin-top: -14px;
            margin-bottom: 14px;
        }
        &.mb-1 {
            margin-bottom: 8px;
        }
    }
}

.page-tabs.tabs-long {
    background-color: $gray-base-90;

    .container {
        position: relative;
        display: flex;
        justify-content: space-between;
    }

    .nav-tabs {
        flex: auto;
        width: auto;
        min-width: 0;
        background-color: transparent;
    }

    .nav-link {
        @include media-breakpoint-up(lg) {
            padding-left: 14px;
            padding-right: 14px;
        }
    }
}

.nav-item-text {
    pointer-events: none;
}

.nav-item-more {
    display: none;
    cursor: pointer;

    .dropdown-toggle.nav-link {
        &.active {
            color: $yc-blue;
            &:after {
                transform: rotate(180deg);
            }
        }
    }

    .dropdown-menu {
        padding: 0;

        .nav-item:not(:last-child) {
            position: relative;
            &:after {
                position: absolute;
                bottom: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 0;
                border-bottom: 1px solid $gray-base-70;
                content: "";
            }
        }

        .nav-link {
            position: relative;
            justify-content: flex-start;
            padding-left: 30px;
            background-color: $gray-base-90;
        }
    }

    .nav-link {
        &:hover,
        &:active,
        &.active {
            border-top-color: transparent;
            background-color: $yc-blue-90;
            color: $gray-base-10;
            text-decoration: none;
        }
    }
}

.panel-tabs {
    border-bottom: 1px solid $gray-base-80;

    .tab-pane {
        padding-left: 0;
        padding-right: 0;
    }

    .nav {
        justify-content: flex-start;
        background-color: #fff;
    }

    .nav-item {
        min-width: 0;
    }

    & > .nav-tabs .nav-link {
        position: relative;
        height: 48px;
        padding-left: 30px;
        padding-right: 30px;
        @include media-breakpoint-up(lg) {
            height: 38px;
        }
        &.active {
            border-color: #fff;
            background-color: $yc-blue-90;
        }
        &:not(.active) {
            color: $gray-base-40;
        }
        &.active:focus {
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                height: 100%;
                width: 100%;
                border: 2px solid $yc-blue;
                content: "";
            }
        }
    }

    &.tab-content {
        border-bottom: 0;
    }

    &.panel-tabs-narrow {
        .nav-link {
            @include media-breakpoint-down(md) {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}

.panel-tabs-bordered {
    border-bottom: 0;

    .nav-tabs {
        padding: $spacer * 0.5;
        padding-left: $grid-gutter-width;
        padding-right: $grid-gutter-width;
        border-top: 1px solid $gray-base-90;
        border-bottom: 1px solid $gray-base-90;

        .nav-link {
            height: $base-height-desktop;
            border: 0;
        }
    }

    .panel-tabs-wrap {
        margin-left: -$grid-gutter-width;
        margin-right: -$grid-gutter-width;

        .nav-link {
            padding-left: $spacer * 2;
            padding-right: $spacer * 2;
        }
    }
}

%tabs-support-style {
    .nav-item {
        border-top: 0;
        border-bottom: 0;
    }

    .nav-link {
        @include largeBoldText();
        min-height: $base-height;
        height: auto;
        line-height: 20px;
        padding-top: 7px;
        padding-bottom: 7px;
        padding-left: 20px;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        white-space: normal;
        &,
        &.active {
            color: $body-color;
        }
        &.active {
            border-top-color: $gray-base-90;
            border-bottom-color: $gray-base-90;
        }
    }
}
