.page-content {

}

.quote {}
.quote-header {
    padding-top: 12px;
    padding-bottom: 12px;
    @include media-breakpoint-up(lg) {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
.quote-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.quote-header-main {

}

.quote-header-sidebar {
    text-align: right;
}

.sidebar-search {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    @include media-breakpoint-up(lg) {
        margin-bottom: 10px;
    }
}

.search-label {
    color: $gray-base-30;
    font-weight: 700;
    font-size: 13px;
    @include media-breakpoint-up(lg) {
        font-size: 11px;
    }
    margin-right: 6px;
}

.search-input {
    width: 70px;
}

.search-submit {

}

.quote-title {
    margin: 0;
    color: $gray-base-10;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    @include media-breakpoint-up(lg) {
        font-size: 24px;
    }
}

.quote-price {
    display: flex;
    align-items: baseline;
    @include media-breakpoint-up(lg) {
        display: inline-flex;
    }
}

.quote-rank {
    color: $gray-base-10;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
}

.quote-change {
    margin-left: 12px;
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    @include media-breakpoint-up(lg) {
        margin-left: 10px;
        line-height: 27px;
    }
}

.quote-change-img {
    width: 9px;
    height: auto;
    margin-right: 6px;
    vertical-align: baseline;
    @include media-breakpoint-up(lg) {
        width: 6px;
        margin-right: 6px;
    }
}

.quote-change-positive {
    color: map-get($colors, green);
}

.quote-change-negative {
    color: map-get($colors, red);
}

.quote-info {
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    @include media-breakpoint-up(lg) {
        font-size: 11px;
        line-height: 27px;
        display: inline;
        margin-left: 2px;
    }
}


.sidebar-search {

}

.search-label {

}

.search-input {

}

.search-submit {
    img {
        width: 6px;
        @include media-breakpoint-up(lg) {
            width: 4px;
        }
    }
}

.sidebar-actions {
    .btn {
        vertical-align: top;
        &:not(:last-child) {
            margin-right: 12px;
            @include media-breakpoint-up(lg) {
                margin-right: 10px;
            }
        }

        .icon-notes {
            &:not(:only-child) {
                margin-right: 6px;
            }
        }
    }
}
