[id^="hChartScatterPlot"] .highcharts-tooltip {
    z-index: 3;
}

.scatter-tooltip {
    @include box-shadow;
    padding: 5px;
    border: 1px solid $chart-tooltip-border-color;
    font-size: 11px;
    font-weight: 400;
    background-color: rgba($chart-tooltip-bg, $chart-tooltip-bg-opacity);
}

.scatter-datalabel {
    position: absolute;
    transform: translate(0, -50%);
    padding: 5px;
    border: 1px solid $chart-tooltip-border-color;
    background-color: rgba($chart-tooltip-bg, $chart-tooltip-bg-opacity);
    &:before {
        position: absolute;
        top: 50%;
        width: $chart-tooltip-arrow-size;
        height: $chart-tooltip-arrow-size;
        margin-top: -$chart-tooltip-arrow-size * 0.5;
        transform-origin: center;
        border-width: 0 0 1px 1px;
        border-style: solid;
        border-color: $chart-tooltip-border-color;
        background-color: $chart-tooltip-bg;
        content: "";
    }
    &.align-left {
        left: $chart-tooltip-arrow-size;
        &.align-top {
            margin-top: $chart-tooltip-arrow-size;
            top: $chart-tooltip-arrow-size;
            &:before {
                top: 30%;
            }
        }
        &.align-bottom {
            margin-top: -$chart-tooltip-arrow-size;
            top: -$chart-tooltip-arrow-size;
            &:before {
                top: 70%;
            }
        }
        &:before {
            right: 100%;
            transform: rotate(45deg);
            margin-right: -$chart-tooltip-arrow-size * 0.5 + 1;
        }
    }
    &.align-right {
        right: $chart-tooltip-arrow-size;
        &.align-top {
            margin-top: $chart-tooltip-arrow-size;
            top: $chart-tooltip-arrow-size;
            &:before {
                top: 30%;
            }
        }
        &.align-bottom {
            margin-top: -$chart-tooltip-arrow-size;
            top: -$chart-tooltip-arrow-size;
            &:before {
                top: 70%;
            }
        }
        &:before {
            left: 100%;
            transform: rotate(225deg);
            margin-left: -$chart-tooltip-arrow-size * 0.5 + 1;
        }
    }
}

.highcharts-tooltip-title  {
    @include textOverflow();
    font-weight: 700;
}

.highcharts-point.highcharts-point-select {
    &.efficient-frontier-series {
        filter: drop-shadow(2px 0 0 $yc-blue) drop-shadow(-2px 0 0 $yc-blue) drop-shadow(0 -2px 0 $yc-blue)
            drop-shadow(0 2px 0 $yc-blue);
    }
}

.legend-hover-actions {
    .btn {
        opacity: 0;
    }
    &:hover {
        .btn {
            opacity: 1;
        }
    }
}