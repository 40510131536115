@use "sass:math";

html .btn[type="button"] {
    -webkit-appearance: none;
}

.btn {
    @include base-height();
    @include buttonTextDefault();
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    padding: 9px 12px 8px;
    letter-spacing: .02em;
    text-align: center;
    border-style: solid;
    border-radius: 0;
    cursor: pointer;
    transition: .1s ease all;
    user-select: none;
    @include media-breakpoint-up(lg) {
        padding: 5px 9px 4px;
    }

    &.btn-square {
        width: $base-height;
        padding: 0; // to prevent icons squishing
        @include media-breakpoint-up(lg) {
            width: $base-height-desktop;
        }
    }

    &.btn-xxl {
        height: 60px;
        padding-left: 30px;
        padding-right: 30px;
        font-size: 17px;
    }

    &.btn-xl {
        height: 48px;
        padding-left: 30px;
        padding-right: 30px;
        font-size: 18px;
        line-height: 1.556;
        text-transform: none;
    }

    &.btn-sm {
        height: $base-height;
        @include media-breakpoint-up(lg) {
            height: $base-height-desktop - 4px;
        }
    }

    &.btn-24 {
        height: 24px;
        @include media-breakpoint-up(lg) {
            height: 24px;
        }
    }

    &.btn-30 {
        height: 30px;
        @include media-breakpoint-up(lg) {
            height: 30px;
        }
    }

    &.btn-32 {
        height: 32px;
        @include media-breakpoint-up(lg) {
            height: 32px;
        }
    }
    &.btn-16 {
        height: 16px;
        @include media-breakpoint-up(lg) {
            height: 16px;
        }
    }

    &.btn-xs {
        height: 30px;
        padding-right: .735em;
        padding-left: .735em;
        font-size: .733em;
        @include media-breakpoint-up(lg) {
            height: 20px;
            padding-right: .454em;
            padding-left: .454em;
            font-size: .769em;
        }
        &.btn-square {
            width: 30px;
            padding: 0; // to prevent icons squishing
            @include media-breakpoint-up(lg) {
                width: 20px;
            }
        }
    }
    &.disabled {
        cursor: default;
        pointer-events: none;
    }

    [class*="icon"] + * {
        margin-left: 8px;
        line-height: 1;
        @include media-breakpoint-up(lg) {
            margin-left: 7px;
        }
    }

    .badge {
        margin-left: 4px;
    }
}

.btn-block {
    display: block;
    width: 100%;
}

.btn.btn-circle {
    border-radius: 50%;
    line-height: 0;
    width: $base-height;
    padding: 0; // to prevent icons squishing
    @include media-breakpoint-up(lg) {
        width: $base-height-desktop;
    }

    &.btn-xs {
        width: 30px;
        @include media-breakpoint-up(lg) {
            width: 20px;
        }
        & + .btn-xs {
            margin-left: 6px;
        }
    }

    &.btn-24 {
        width: 24px;
        @include media-breakpoint-up(lg) {
            width: 24px;
        }
        & + .btn-24 {
            margin-left: 6px;
        }
        [class*=icon-] {
            font-size: 13px;
        }
    }
    &.btn-32 {
        width: 32px;
        @include media-breakpoint-up(lg) {
            width: 32px;
        }
    }
    &.btn-16 {
        width: 16px;
        @include media-breakpoint-up(lg) {
            width: 16px;
        }
    }
    &:focus {
        outline: none;
    }

    &.dropdown-toggle {
        &:after {
            content: none;
        }
    }
}

// Modification

.btn-primary,
a.btn-primary {
    @include buttonTextDefault();
    color: #fff;
    fill: #fff;
    border-color: $yc-blue;
    background-color: $yc-blue;
    &:hover {
        border-color: $yc-blue-25;
        background-color: $yc-blue-25;
    }
    &:active,
    &.active {
        border-color: $yc-blue-dark;
        background-color: $yc-blue-dark;
    }
    &:focus {
        outline: 1px solid $yc-blue-75;
    }
    &.disabled {
        color: $gray-base-60;
        border-color: $gray-base-95;
        background-color: $gray-base-95;
    }
}

.btn-blue-ada,
a.btn-blue-ada {
    @include buttonTextDefault();
    color: #fff;
    fill: #fff;
    border-color: $yc-blue-ada;
    background-color: $yc-blue-ada;
    &:hover {
        border-color: $yc-blue-25;
        background-color: $yc-blue-25;
    }
    &:active,
    &.active {
        border-color: $yc-blue-dark;
        background-color: $yc-blue-dark;
    }
    &:focus {
        outline: 1px solid $yc-blue-75;
    }
    &.disabled {
        color: $gray-base-60;
        border-color: $gray-base-95;
        background-color: $gray-base-95;
    }
}

.btn-secondary,
a.btn-secondary {
    @include buttonTextDefault();
    color: $gray-base-30;
    fill: $gray-base-30;
    border-color: $gray-base-75;
    background-color: #fff;
    &:active,
    &:hover,
    &:focus,
    &.active {
        // border-color: $yc-blue;
        background-color: $yc-blue-90;
        outline: none;
    }
    &:active {
        border-color: $yc-blue;
    }
    &.disabled {
        color: $gray-base-60;
        fill: $gray-base-60;
        border-color: $gray-base-75;
        &:hover,
        &:focus {
            background-color: transparent;
        }
    }

    &.dropdown-toggle {
        border-color: $gray-base-75;
        &:active,
        &.active,
        &:hover,
        &:focus {
            border-color: $gray-base-80;
            background-color: $yc-blue-90;
        }

        &.disabled,
        &:disabled {
            &:hover,
            &:focus {
                background-color: #fff;
            }
        }

        // states
        &.is-invalid {
            border-color: map-get($colors, red);
        }
        &.is-valid {
            border-color: map-get($colors, green);
        }
    }

    &.arrow-right:after {
        border-left-color: $gray-base-50;
    }
    &.arrow-left:after {
        border-right-color: $gray-base-50;
    }

    &.style-underline {
        padding: 0;
        border-color: transparent;
        border-bottom: 2px solid $gray-base-75;
        background-color: transparent;
        white-space: nowrap;
        text-transform: none;
        letter-spacing: normal;
        text-align: left;

        &:hover {
            border-color: transparent;
            border-bottom: 2px solid $gray-base-75;
            background-color: transparent;
        }
        &:focus,
        &:active,
        &.active {
            border-color: transparent;
            border-bottom-color: $yc-blue;
            background-color: transparent;
        }
    }
}

.btn-flat-white,
a.btn-flat-white {
    @include buttonTextDefault();
    color: $yc-blue-ada;
    border-color: transparent;
    background-color: #fff;
    @include media-breakpoint-up(lg) {
        color: $gray-base-30;
    }
    &:active,
    &:hover,
    &:focus,
    &.active {
        color: $yc-blue-ada;
        outline: none;
    }
    &:active {
        text-decoration: underline;
    }
    &.disabled {

    }
}

.btn-text,
a.btn-text,
.btn-text-copy,
a.btn-text-copy {
    @include buttonTextDefault();
    line-height: 100%;
    color: $gray-base-30;
    fill: $gray-base-30;
    border-color: transparent;
    background-color: transparent;
    &:hover,
    &:active,
    &.active {
        border-color: $gray-base-90;
        background-color: $gray-base-90;
    }
    &:active,
    &.active {
        @include underline();
    }
    &:focus {
        border-style: dashed;
        border-color: $gray-base-75;
        outline: none;
    }
    &.disabled {
        color: $gray-base-60;
        fill: $gray-base-60;
        border-color: $gray-base-95;
        background-color: $gray-base-95;
        text-decoration: none;
    }
}

.btn-link,
a.btn-link {
    @include buttonTextDefault();
    text-transform: none;
    line-height: 100%;
    color: $yc-blue;
    fill: $yc-blue;
    border-color: transparent;
    background-color: transparent;
    &:hover,
    &:active,
    &:focus,
    &.active {
        border-color: $yc-blue-90;
        background-color: $yc-blue-90;
    }
    &:active,
    &.active {

    }
    &:focus {
        outline: none;
    }
    &.disabled {
        color: $gray-base-60;
        fill: $gray-base-60;
    }
}


.btn-purple-tint,
a.btn-purple-tint {
    @include textDefault();
    color: $gray-base-20;
    border-color: $gray-base-70;
    background-color: map-get($colors, 'purple-tint');
    &:hover,
    &:active,
    &.active,
    &:focus {
        background-color: $yc-blue-75;
        outline: none;
    }
    &.disabled {
        color: $gray-base-60;
        border-color: $gray-base-95;
        background-color: $gray-base-95;
    }

    &.is-invalid {
        background-color: map-get($colors, pink);
        border-color: map-get($colors, red);
    }

    .btn-close {
        margin-left: 5px;
        fill: $gray-base-50;
    }
}


.btn-success,
a.btn-success {
    @include buttonTextDefault();
    color: #fff;
    border-color: map-get($colors, green);
    background-color: map-get($colors, green);
    &:hover {
        border-color: #5dbe44;
        background-color: #5dbe44;
    }
    &:active,
    &.active {
        border-color: #309d11;
        background-color: #309d11;
    }
    &:focus {
        outline: 1px solid $yc-blue-75;
    }
    &.disabled {
        color: $gray-base-60;
        border-color: $gray-base-95;
        background-color: $gray-base-95;
    }
}

.btn-success-outline,
a.btn-success-outline {
    border: 2px solid #fff;
    color: #fff;
    text-transform: none;
    &:hover,
    &:focus,
    &:active,
    &.active {
        border-color: #fff;
    }
}


.btn-gray,
a.btn-gray {
    color: $gray-base-20;
    border-color: $panel-background;
    background-color: $panel-background;
    &:hover,
    &:active,
    &.active {
        background-color: $gray-base-90;
    }
    &:focus {
        outline: 2px solid $yc-blue-75;
    }
}


.btn-modal-header {
    height: 28px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: .933em;
    font-weight: 700;
    letter-spacing: .02em;
    text-transform: uppercase;
    background-color: transparent;
    border-color: transparent;
    color: $gray-base-50;
    fill: $gray-base-50;
    @include media-breakpoint-up(lg) {
        height: 22px;
        font-size: .923em;
    }
    &:focus {
        border-color: $gray-base-75;
        border-style: dashed;
        outline: none;
    }
    &:active,
    &.active,
    &:hover {
        background-color: $gray-base-90;
        border-color: $gray-base-90;
        outline: none;
    }

    [class*="icon"] + * {
        margin-left: .571em;
        @include media-breakpoint-up(lg) {
            margin-left: .636em;
        }
    }

    &.close,
    &.btn-circle {
        width: 28px;
        font-size: 12px;
        padding-right: 5px;
        padding-left: 5px;
        border-radius: 50%;
        @include media-breakpoint-up(lg) {
            width: 22px;
            font-size: 10px;
        }
    }
}

.btn-full-width {
    width: 100%;
}

.btn-outline,
a.btn-outline {
    background-color: $white;

    &.btn-blue {
        color: $yc-blue;
        fill: $yc-blue;
        border-color: $yc-blue;
        background-color: $yc-blue-95;
        &:hover {
            color: $yc-blue;
            border-color: $yc-blue;
            background-color: $yc-blue-90;
        }
        &:active,
        &.active {
            border-color: $yc-blue;
            background-color: $yc-blue-75;
        }
        &:focus {
            color: $yc-blue;
            outline: 2px solid $yc-blue-75;
        }
    }

    &.btn-gray {
        color: $gray-base-20;
        border-color: $gray-base-70;
        background-color: $panel-background;
        &:hover,
        &:active,
        &.active {
            background-color: $gray-base-90;
        }
        &:focus {
            outline: 2px solid $yc-blue-75;
        }
    }
    &.btn-lightgray {
        color: $gray-base-30;
        border-color: $gray-base-90;
        &:active,
        &.active,
        &:hover {
            border-color: $gray-base-80;
            background-color: $yc-blue-90;
        }
        &:focus {
            border-color: $yc-blue;
            outline: none;
        }
    }
    &.btn-ghost {
        color: $yc-blue;
        fill: $yc-blue;
        border-color: $yc-blue;
        &:not(.disabled) {
            &:hover {
                border-color: $yc-blue;
                background-color: $yc-blue-90;
            }
            &:active,
            &.active {
                border-color: $yc-blue;
                background-color: $yc-blue-90;
            }
            &:focus {
                outline: 2px solid $yc-blue-75;
            }
        }
        &.disabled {
            color: $gray-base-60;
            fill: $gray-base-60;
            border-color: $gray-base-60;
        }
    }
    &.btn-danger {
        color: map-get($colors, red);
        fill: map-get($colors, red);
        border-color: map-get($colors, red);
        background-color: #fff;
        &:focus {
            outline: none;
        }
    }
}

.btn-transparent {
    border-color: transparent;
    background-color: transparent;

    &.btn-blue {
        color: $yc-blue;
        &:focus,
        &:hover,
        &:active,
        &.active {
            border-color: $yc-blue-90;
            background-color: $yc-blue-90;
        }
    }

    &.btn-lightgray {
        color: $gray-base-50;
        fill: $gray-base-50;
        &:focus,
        &:hover,
        &:active,
        &.active {
            background-color: $gray-base-90;
        }
    }
}

.btn-transparent2 {
    padding-right: 1em;
    padding-left: 1em;
    border-color: transparent;
    background-color: transparent;
    color: $gray-base-30;
    fill: $gray-base-30;
    white-space: nowrap;

    &:focus {
        @include underline();
    }
    &.active,
    &:active,
    &:hover,
    &:focus {
        background-color: $yc-blue-90;
        fill: $gray-base-10;
        color: $gray-base-30;
        outline: none;
    }
    &.disabled {
        background-color: transparent;
        color: $gray-base-60;
        fill: currentColor;
    }
}

.btn-transparent3 {
    fill: $gray-base-50;
    background-color: transparent;
    &:active,
    &:hover {
        background-color: $gray-base-90;
    }
}

.btn-support {
    height: $base-height;
    min-width: 148px;
}

.btn-transparent-rounded {
    padding-right: 12px;
    padding-left: 12px;
    border-width: 2px;
    border-color: transparent;
    background-color: transparent;
    color: $gray-base-30;
    fill: $gray-base-30;
    white-space: nowrap;
    font-weight: 400;
    text-transform: none;
    border-radius: 4px;

    &:focus {
        border-color: $yc-blue;
    }
    &.active,
    &:active,
    &:hover,
    &:focus {
        background-color: $yc-blue-90;
        outline: none;
    }
    &.disabled {
        background-color: transparent;
        color: $gray-base-60;
        fill: currentColor;
    }
}

.btn-quick-chart {
    @include bg-perlmutter();
    position: relative;
    padding-left: 16px;
    padding-right: 16px;
    border-width: 0;
    border-radius: 4px;
    color: $gray-base-30;
    text-transform: none;
    letter-spacing: -0.01px;
    &:after {
        position: absolute;
        top: 2px;
        left: 2px;
        bottom: 2px;
        right: 2px;
        display: block;
        background: $yc-blue-95;
        border-radius: 2px;
        content: "";
    }

    & * {
        z-index: 1;
    }

    svg {
        font-size: 14px;
        margin-left: 8px;
        fill: $gray-base-40;
    }

    &:focus {
        @include bg-sky();
        outline: none;
    }
    &.active,
    &:active,
    &:hover,
    &:focus {
        &:after {
            background: $yc-blue-90;
        }
    }
    &.disabled {
        color: $gray-base-60;
        background: $gray-base-90;
        &:after {
            background: $gray-base-90;
        }

        svg {
            filter: saturate(0);
        }
    }
}


// Buttons with arrows

// Arrows
.btn {
    &.arrow-right,
    &.arrow-left,
    &.arrow-top,
    &.arrow-bottom {
        @include base-width();

        &:after {
            display: inline-block;
            width: 0;
            height: 0;
            content: "";
        }
    }

    &.arrow-right:after {
        border-top: $arrow-width * 0.5 solid transparent;
        border-bottom: $arrow-width * 0.5 solid transparent;
        border-left: math.div($arrow-width, 1.5) solid;
        @include media-breakpoint-up(lg) {
            border-top: ($arrow-width * 0.5) - 0.5 solid transparent;
            border-bottom: ($arrow-width * 0.5) - 0.5 solid transparent;
            border-left: math.div($arrow-width - 1, 1.5) solid;
        }
    }

    &.arrow-left:after {
        border-top: $arrow-width * 0.5 solid transparent;
        border-bottom: $arrow-width * 0.5 solid transparent;
        border-right: math.div($arrow-width, 1.5) solid;
        @include media-breakpoint-up(lg) {
            border-top: ($arrow-width * 0.5) - 0.5 solid transparent;
            border-bottom: ($arrow-width * 0.5) - 0.5 solid transparent;
            border-right: math.div($arrow-width - 1, 1.5) solid;
        }
    }

    &.arrow-none:after {
        content: none;
    }

    &.dropdown-toggle.arrow-bottom {
        @include base-width();
    }
}

.btn-close,
.btn-edit {
    span {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.btn-reorder,
.btn-reorder-up {
    cursor: move;
}


// Very custom button
.btn-upload {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 1.6;
    color: $gray-base-30;
    fill: $gray-base-30;
    border: 1px solid $gray-base-75;
    border-radius: 0;
    background-color: #fff;
    letter-spacing: .02em;
    cursor: pointer;
    transition: .1s ease all;
    user-select: none;
    &:active,
    &:hover,
    &:focus,
    &.active {
        border-color: $gray-base-80;
        background-color: $yc-blue-90;
        outline: none;
    }
    &:active {
        border-color: $yc-blue;
    }
    &.disabled {
        color: $gray-base-60;
        fill: $gray-base-60;
        border-color: $gray-base-75;
        cursor: default;
        pointer-events: none;
        &:hover {
            background-color: transparent;
        }
    }
}

.btn-upload-line {
    display: flex;
    align-items: center;
    justify-content: center;

    [class*="icon-"] {
        flex: none;

        & + * {
            margin-left: 10px;
        }
    }
}

.btn {
    .icon-calendar-event {
        align-self: baseline;
        height: 1.3em;
    }
}

.btn-ai-prompt {
    border: 0;
    background: linear-gradient(139.92deg, #0273CF 17.87%, #578CD8 46.69%, #A9C4EB 79.83%);
    color: #fff;
    &:hover {
        transform: scale(1.0625);
        box-shadow: 0px 2px 4px 0px #00000026;
        [class*="icon"] {
            transform: scale(1.1);
        }
    }

    [class*="icon"] {
        fill: #fff !important;
    }

    &[disabled],
    &.disabled {
        background: $gray-base-80;
    }
}

.btn-ai-submit {
    @include buttonTextDefault();
    @include bg-perlmutter();
    color: $gray-base-30;
    border-color: $gray-base-90;
    border-color: $gray-base-90;
    background-color: $yc-blue;
    &:hover {
        @include bg-perlmutter-saturated();
    }
    &:active,
    &.active {
        @include bg-perlmutter-saturated();
    }
    &:focus {
        @include bg-perlmutter-saturated();
        outline: 2px solid $yc-blue;
    }
    &.disabled {
        color: $gray-base-60;
        border-color: $gray-base-90;
        background: $gray-base-90;
    }
}
