.page-sidebar {
	display: flex;
	flex-direction: column;
	box-shadow: 0px 3px 8px 0px #00000040;
	transform: translate(-100%, 0);
	opacity: 0;
	transition: .1s ease all;
	&.is-opened {
		transform: none;
		opacity: 1;
	}
}

.page-side-sticker {
	position: absolute;
	top: 20px;
	padding: $spacer $spacer $spacer 20px;
	background: #fff;
	border-radius: 0px 4px 4px 0px;
	box-shadow: 0px 3px 8px 0px #00000040;
	transition: .1s ease all;

	&.left-0 {
		left: 0;
		transform: translate(-12px, 0);
		&:hover {
			transform: none;
		}
	}
}

.page-sidebar-section {
	width: 100%;
	padding: $spacer 20px;
	&:first-child {
		padding-top: $spacer * 2;
	}
	&:last-child {
		padding-bottom: $spacer * 2;
	}
}

.page-sidebar-title {
	@include headerMedium();
	color: $gray-base-30;
}

.page-sidebar-subtitle {
	@include buttonTextDefault();
	color: $gray-base-60;
}

.page-sidebar-footer {
	margin-top: auto;
}
