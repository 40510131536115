.form-row {
    flex: none;
    margin-bottom: $form-row-margin-vertical;
    @include media-breakpoint-up(lg) {
        margin-bottom: $form-row-margin-vertical-desktop;
    }

    .custom-control:last-child {
        margin-right: 0;
    }
}

.input-group {
    position: relative;
    display: inline-flex;
}

.input-group-item {
    &:not(:last-child) {
        border-right: 0;
    }
}

%form-control-base-style-padding {
    padding: .547rem .923em;
    @include media-breakpoint-up(lg) {
        padding: .345em .727em;
    }
}

%form-control-lg-padding {
    padding: 14px 16px;
}

%form-control-base-style {
    @extend %form-control-base-style-padding;
    @include textSmall();
    width: 100%;
    line-height: 1.692em;
    transition: .1s ease all;
    border-radius: 0;
    -webkit-appearance: none;
    @include placeholder {
        @include textSmall();
        color: #74747f;
    };
}

%form-control-colors {
    border: 1px solid $gray-base-80;
    &:focus,
    &:focus-within {
        border-color: $yc-blue;
        outline: none;
    }
    &:disabled,
    &[readonly] {
        background-color: $gray-base-95;
        border-color: $gray-base-80;
        opacity: 1;
    }

    // Use the disabled class in combination with the readonly attribute to make
    // a field appear disabled, but still include it when submitting a form.
    &.disabled {
        color: $gray-base-60;
    }

    // states
    &.is-invalid {
        border-color: map-get($colors, red);
    }

    &.is-valid {
        border-color: map-get($colors, green);
    }
}

.form-control {
    @extend %form-control-base-style;
    @extend %form-control-colors;
}

.form-control-lg {
    @extend %form-control-lg-padding;
    font-size: 16px;
    line-height: 1.66;
    @include placeholder {
        font-size: inherit;
        line-height: inherit;
    }
}

// hidden input
input.hidden {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
}

// states
.feedback {
    @include textSmall();
    // margin-top: 8px;
    margin-top: 4px;
    margin-bottom: -8px;
    @include media-breakpoint-up(lg) {
        // margin-top: 4px;
        margin-bottom: -4px;
    }
    &.mb-0 {
        margin-bottom: 0;
    }
}
.feedback-invalid {
    color: map-get($colors, red);
}

.feedback-valid {
    color: map-get($colors, green);
}

.feedback-neutral {
    color: $gray-base-70;
}

input.form-control {
    @include base-height();
}

.form-label {
    @include textLabel();
    display: block;
    margin-bottom: 14px;
    color: $gray-base-40;

    &.form-label-large {
        font-size: 1rem;
    }

    &.form-label-thin {
        @include smallCapsLabel();
        display: inline-block;
        margin-bottom: 0;
        font-weight: 400;
        color: $gray-base-40;
        &.text-black {
            color: $gray-base-10;
        }

        span {
            font-size: $font-size-base;         // 15px
            font-weight: $font-weight-base;
            line-height: 1;
            text-transform: none;
            color: $gray-base-70;
            @include media-breakpoint-up(lg) {
                font-size: 14px;
            }
            &.text-black {
                color: $gray-base-10;
            }
            &.text-gray-40 {
                color: $gray-base-40;
            }
        }
    }

    &.form-label-small {
        margin-bottom: 10px;
        font-size: 11px;
        font-weight: 400;
        text-transform: uppercase;
        color: $gray-base-40;
    }
}

.form-title {
    @include headerLarge();
}

.col-form-label {
    margin-bottom: 0;
    padding-top: .547rem;
    padding-bottom: .547rem;
    @include media-breakpoint-up(lg) {
        padding-top: .345em;
        padding-bottom: .345em;
    }
}

.form-direction {
    @include textDirection();
    color: $gray-base-70;
}

// Readonly controls as plain text

.form-control-plaintext {
  display: block;
  width: 100%;
  margin-bottom: 0; // match inputs if this class comes on inputs with default margins
  background-color: transparent;
  border: 1px solid transparent;
}

.form-status {
    @extend %form-control-base-style-padding;
    @include base-height();
    @include textSmall();
    align-items: center;
    color: $gray-base-60;
}

.form-divider {
    border: 0;
    border-bottom: 1px solid $gray-base-90;
    margin-top: 0;
    margin-bottom: 0;
}

.form-inline {
    display: flex;
    align-items: center;

    & > * + * {
        margin-left: .8rem;
        @include media-breakpoint-up(lg) {
            margin-left: 0.769em;
        }
    }

    .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0;
    }

    .form-label {
        margin-bottom: 0;
    }

    // Allow folks to *not* use `.form-group`
    .form-control {
      display: inline-block;
      width: auto; // Prevent labels from stacking above inputs in `.form-group`
      vertical-align: middle;
    }

    // Make static controls behave like regular ones
    .form-control-plaintext {
      display: inline-block;
      width: auto;
    }

    .input-group {
      width: auto;
    }
}

.form-inline-md {
    @include media-breakpoint-up(md) {
        display: flex;

        & > * + * {
            margin-left: 0.769em;
        }
    }
}


.form-inline-block {
    display: flex;
    align-items: center;

    & > * + * {
        margin-left: .8rem;
        @include media-breakpoint-up(lg) {
            margin-left: 0.769em;
        }
    }
}

// Search like input
.field-input-group {

    &.input-group {
        background-color: $white;
    }

    .field-input {
        padding-right: 36px;
    }

    &.form-control-autosize:after {
        padding-right: 36px;
    }

    &.field-input-group-lg {
        .field-input {
            padding-right: 66px;
        }

        &.form-control-autosize {
            &:after {
                padding-right: 66px;
            }
        }
    }

    .form-control[readonly] {
        background-color: #fff;
    }

    .field-input-group-prepend {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 100%;
        @include media-breakpoint-up(lg) {
            width: 30px;
        }

        & + .form-control {
            padding-left: 40px;
            @include media-breakpoint-up(lg) {
                padding-left: 30px;
            }
        }
    }

    .field-input-group-prepend-icon {
        fill: currentColor;
    }

    .field-input-group-actions {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 3;
        display: flex;

        .btn,
        a.btn {
            border-color: transparent;
            background-clip: padding-box;
            &:focus {
                outline: none;
            }
            &:hover {
                border-color: transparent;
            }
            & + .btn {
                position: relative;
                &:before {
                    position: absolute;
                    top: 15%;
                    left: 0;
                    display: block;
                    width: 0;
                    height: 70%;
                    border-right: 1px solid $gray-base-80;
                    content: "";
                    transition: .1s ease border-color;
                }
                &:hover {
                    &:before {
                        border-color: transparent;
                    }
                }
            }

            &.btn-transparent {
                transition: none;
            }
        }

        [class*=icon] {
            fill: $gray-base-50;
        }

        .icon-search {
            transition: .1s ease opacity;
        }

        .btn-clear {
            &:hover {
                background-color: $yc-blue-90;
            }
        }
    }

    &.field-input-group-static {
        .field-input-group-actions {
            position: static;
        }
    }

    &.field-input-group-password {
        @include media-breakpoint-up(lg) {
            [class*="icon-"] {
                font-size: 1rem;
            }
        }
    }
}

.field-search-browse-wrap {
    position: relative;

    .field-input-group .field-input-group-actions {
        right: 99px;
        @include media-breakpoint-up(lg) {
            right: 88px;
        }
    }

    .btn-browse {
        position: absolute;
        top: 1px;
        bottom: 1px;
        right: 1px;
        z-index: 3;
        height: auto;
        border-color: transparent;
        background-color: transparent;
        &:before {
            position: absolute;
            top: 15%;
            left: 0;
            display: block;
            width: 0;
            height: 70%;
            border-right: 1px solid #cccccc;
            content: "";
            transition: .1s ease border-color;
        }
        &:active,
        &:hover,
        &:focus,
        &.active {
            background-color: $yc-blue-90;
            &:before {
                border-color: transparent;
            }
        }
    }
}


// Editable form group (form-value and edit button + form-control)


.form-group-editable {
    @include base-min-height();

    .form-value {
        @include base-min-height();
        display: flex;
        align-items: center;
        word-break: break-word;
    }

    .input-group,
    .form-group {
        display: none;
    }

    .btn-edit {
        flex: none;
        margin-left: 4px;
        padding: 0;

        &.btn-transparent {
            fill: $gray-base-30;
            &:not(.disabled) {
                &:hover,
                &:focus,
                &:active {
                    background-color: $gray-base-90;
                }
            }
            &.disabled {
                fill: $gray-base-60;
            }
        }
    }

    &.is-editing {
        .form-value {
            display: none;
        }

        .input-group {
            display: flex;
        }

        .form-group {
            display: block;
        }
    }

    &.disabled {
        .btn-edit {
            pointer-events: none;
            fill: $gray-base-60;
        }
    }
}

.form-group-editable-bordered {
    border-top: 1px solid $gray-base-90;
    border-bottom: 1px solid $gray-base-90;
    &.is-editing {
        border-color: transparent;
    }
}

.form-group-frequency {
    @include base-min-height();
    display: flex;
    align-items: center;
    margin-top: 3px;
    margin-bottom: 3px;

    @include media-breakpoint-up(lg) {
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .form-label {
        margin-right: $grid-gutter-width;
        margin-bottom: 0;
    }
}


// Customize
.form-label-select-watchlist {
    color: $gray-base-10;
    &:not(:first-child) {
        padding-top: 20px;
    }

    + .linked-lists .linked-list-item-header {
        font-weight: 400;
    }
}

// Accept row
.form-row-accept {
    .custom-control {
        display: block;
        width: 70%;
    }

    .custom-control-description {
        display: inline;
    }
}

// Native select styles
.select {
    @extend .dropdown-toggle;
    position: relative;
    &:after {
        position: absolute;
        top: 50%;
        right: 10px;
    }
    &:hover {
        &:after {
            border-top-color: $yc-blue;
        }
    }
}

select.form-control {
    @include base-height();
    padding-right: $spacer * 3;
    cursor: pointer;
}


// Shape size select
.shape-size-select {
    @include base-height();
    display: flex;
}

.shape-select-choice {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    &:not(:last-child) {
        margin-right: 6px;
    }
    @include media-breakpoint-up(lg) {
        font-size: 10px;
    }

    &:not([class*='text-']) {
        color: #d8d8d8;
    }

    svg {
        font-size: 1em;
        fill: currentColor;
    }

    &.size-sm {
        font-size: 15px;
    }
    &.size-md {
        font-size: 21px;
    }
    &.size-lg {
        font-size: 30px;
    }

    .custom-bullet & {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translate(-50%, -50%) scale(.8);
    }
}

// Placement Select
.placement-select {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 56px;
    height: 50px;
    padding: $spacer;
    background-color: $gray-base-95;

    .shape-select-choice {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.placement-choice {
    position: relative;
    width: 10px;
    height: 10px;
    &:after,
    &:before {
        position: absolute;
        display: block;
        content: "";
    }
    &:after {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 6px;
        height: 1px;
        background-color: $gray-base-70;
    }

    &:before {
        width: 100%;
        height: 100%;
        border: 1px solid transparent;
    }
    &:nth-child(odd) {
        margin-right: 4px;
    }
    &:nth-child(even) {
        margin-left: 4px;
        &:before {
            right: 0;
        }
    }
    &.current {
        &:after {
            height: 2px;
            background-color: $yc-blue;
        }
        &:before {
            border: 1px solid $yc-blue;
            background-color: $yc-blue-90;
        }
    }
}

// Reasonable for textareas
.no-resize {
    resize: none;
}


// Scrollable textarea-like block
.form-control-scroll {
    height: 92px;
    font-size: 14px;
    line-height: 1.429;
    overflow: auto;

    a {
        @include underline();
    }
}

// Resizable textarea
.form-control-autosize {
    display: grid;
    
    &:after {
        content: attr(data-replicated-value) " ";
        white-space: pre-wrap;
        pointer-events: none;
        visibility: hidden;
    }

    & > textarea {
        resize: none;
        @include placeholder {
            color: $gray-base-60;
        }
    }

    & > textarea,
    &:after {
        @extend %form-control-base-style;
        @extend %form-control-colors;
        max-height: 85px;
        grid-area: 1 / 1 / 2 / 2;
    }

    .field-input-group-actions {
        @extend %form-control-lg-padding;
        top: auto;
        bottom: 0;
    }
}

.form-control-autosize-lg {
    & > textarea,
    &:after {
        @extend .form-control-lg;
        @extend %form-control-colors;
        max-height: 110px;
    }
}

.is-alerted {
    background-color: $yc-blue-90;
    box-shadow: 0 0 0 4px $yc-blue-90;
    outline: 1px solid $yc-blue;
    outline-offset: 4px;
}
