$matrix-positive-high:     map-get($gradient-colors, 3a);
$matrix-positive-moderate: map-get($gradient-colors, 3b);
$matrix-neutral:		   $gray-base-90;
$matrix-negative-moderate: map-get($gradient-colors, 2b);
$matrix-negative-high: 	   map-get($gradient-colors, 2a);

.matrix {
	.bg-positive-high {
		background-color: $matrix-positive-high;
	}
	.bg-positive-moderate {
		background-color: $matrix-positive-moderate;
	}
	.bg-neutral {
		background-color:$matrix-neutral;
	}
	.bg-negative-moderate {
		background-color: $matrix-negative-moderate;
	}
	.bg-negative-high {
		background-color: $matrix-negative-high;
	}
}

.matrix-wrap {
	display: flex;
}

.matrix-area {
	display: flex;
	flex-direction: column;
	min-width: fit-content;
}

.matrix-labels {
	display: flex;
}

.matrix-labels-y {
	flex: none;
	flex-direction: column;
	padding-top: 60px;

	.matrix-label {
		flex: 1;
		display: flex;
		align-items: center;
		min-height: 30px;
		@include media-breakpoint-up(lg) {
			min-height: 16px;		
		}
	}
}

.matrix-labels-x {
	flex: none;
	height: 60px;
	min-width: fit-content;

	.matrix-label {
		position: relative;
		min-width: 30px;
		@include media-breakpoint-up(lg) {
			min-width: 16px;		
		}

		span {
			position: absolute;
			top: 100%;
			left: 50%;
			transform: rotate(-90deg) translate(0, -50%);
			transform-origin: top left;
		}
	}
}

.matrix-label {
	flex: 1;
	
	span {
		display: block;
		padding-left: 10px;
		padding-right: 10px;
		font-size: 12px;
		line-height: 16px;
	}
}

.matrix-row {
	display: flex;
	flex: 1;
}

.matrix-element {
	position: relative;
	flex: 1;
	min-width: 36px;
	min-height: 36px;
	max-height: 300px;
	border: 2px solid #fff;
	background-color: $gray-base-90;
	@include media-breakpoint-up(lg) {
		min-width: 16px;
		min-height: 16px;
	}
	&:before {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: block;
		border: 2px solid transparent;
		content: "";
	}
	&:hover {
		&:before {
			border-color: $yc-blue-ada;
		}
	}
	&[aria-describedby] {
		&:before {
			background-color: rgba($yc-blue-ada, .42);
		}
	}
}

.matrix-element-value {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.matrix-legend {
	flex: none;
	display: flex;
	padding-left: 10px;
	padding-top: 60px;
}

.matrix-legend-labels {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.matrix-legend-item {
	padding-top: 60px;
	padding-bottom: 60px;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 12px;
	text-align: right;
	text-transform: uppercase;
}

.matrix-legend-gradient {
	flex: none;
	width: 14px;
	background: linear-gradient(180deg, map-get($gradient-colors, 3a) 0%, $gray-base-90 49.83%, map-get($gradient-colors, 2a) 100%);
}
