.queue {
	@extend .form-control;
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	min-height: $spacer * 9;
	padding: 3px 5px;
	overflow: auto;

	.queue-item {
		@extend .btn-24;
		@extend .btn-outline;
		@extend .btn-gray;
		margin: 5px;
		padding: 2px 6px;
		text-transform: none;
		font-weight: 400;
	}

	.btn-close {
		margin-left: 8px;
	}
}

.queue-input {
	@extend %form-control-base-style;
	flex: auto;
	width: auto;
	margin: 5px;
	padding-left: 0;
	border: 0;
	outline: none;
}