.d-none         { display: none !important; }
.d-inline       { display: inline !important; }
.d-inline-block { display: inline-block !important; }
.d-block        { display: block !important; }
.d-table        { display: table !important; }
.d-table-row    { display: table-row !important; }
.d-table-cell   { display: table-cell !important; }
.d-flex         { display: flex !important; }
.d-inline-flex  { display: inline-flex !important; }

.visible-sm-block,
.visible-md-block,
.visible-lg-block,
.visible-xl-block,
.visible-sm-inline-block,
.visible-md-inline-block,
.visible-lg-inline-block,
.visible-sm-inline,
.visible-md-inline,
.visible-lg-inline,
.visible-sm-flex,
.visible-md-flex,
.visible-lg-flex,
.visible-xl-flex,
.visible-sm-inline-flex,
.visible-md-inline-flex,
.visible-lg-inline-flex,
.visible-sm-table-cell,
.visible-md-table-cell,
.visible-lg-table-cell,
.visible-lg-thead {
    display: none!important;
}

@include media-breakpoint-up(sm) {
    .hidden-sm {
        display: none!important;
    }

    .visible-sm-block {
        display: block!important;
    }

    .visible-sm-inline {
        display: inline!important;
    }

    .visible-sm-inline-block {
        display: inline-block!important;
    }

    .visible-sm-flex {
        display: flex!important;
    }

    .visible-sm-inline-flex {
        display: inline-flex!important;
    }

    .visible-sm-table-cell {
        display: table-cell!important;
    }
}

@include media-breakpoint-up(md) {
    .hidden-md {
        display: none!important;
    }

    .visible-md-block {
        display: block!important;
    }

    .visible-md-inline {
        display: inline!important;
    }

    .visible-md-inline-block {
        display: inline-block!important;
    }

    .visible-md-flex {
        display: flex!important;
    }

    .visible-md-inline-flex {
        display: inline-flex!important;
    }

    .visible-md-table-cell {
        display: table-cell!important;
    }
}

@include media-breakpoint-up(lg) {
    .hidden-lg {
        display: none!important;
    }

    .visible-lg-block {
        display: block!important;
    }

    .visible-lg-inline {
        display: inline!important;
    }

    .visible-lg-inline-block {
        display: inline-block!important;
    }

    .visible-lg-flex {
        display: flex!important;
    }

    .visible-lg-inline-flex {
        display: inline-flex!important;
    }

    .visible-lg-table-cell {
        display: table-cell!important;
    }

    .visible-lg-thead {
        display: table-header-group!important;
    }
}

@include media-breakpoint-up(xl) {
    .hidden-xl {
        display: none!important;
    }

    .visible-xl-block {
        display: block!important;
    }

    .visible-xl-flex {
        display: flex!important;
    }
}

.invisible {
    visibility: hidden;
}

.fade-out {
    opacity: 0;
    transition: .25s ease opacity;
}

