.linked-lists {
    display: flex;
    width: 100%;
    min-height: 0;
    &.linked-lists-no-border {
        .linked-list {
            border: 0;
            & + .linked-list {
                border-left: 1px solid $gray-base-80;
            }
        }
    }
}

.linked-list {
    flex: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid $gray-base-80;

    & + .linked-list {
        border-left: 0;
    }

}

.linked-list-header {
    @include smallCapsLabel();
    @include base-min-height();
    flex: none;
    display: flex;
    align-items: center;
    padding: 6px $grid-gutter-width;
    border-bottom: 1px solid $gray-base-90;
    color: $gray-base-30;
}

.linked-list-body {
    & > .linked-list-item:first-child {
        & > .linked-list-item-header {
            border-top: 0;
        }
    }
}

.linked-list-body-content {
    padding: $grid-gutter-width * 0.5 $grid-gutter-width;

    .field-input-group + .options-list .options-list-item:first-child .options-list-item-header {
        border-top: 0;
    }
}

// item

.linked-list-item {
    position: relative;
    display: flex;
    flex-direction: column;

    &.disabled {
        & > .linked-list-item-header {
            color: $gray-base-60;
            cursor: default;
            &:hover {
                background-color: inherit;
            }
        }
    }
    &.selected {
        & > .linked-list-item-header {
            background-color: $yc-blue-90;
            color: $gray-base-10;
            &:hover {
                background-color: $yc-blue-90;
            }
        }
    }

    &.parent {
        & > .linked-list-item-header {
            color: $gray-base-40;
        }
    }
}

.linked-list-item-header {
    @include headerDefault();
    @include base-min-height();
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 4px $grid-gutter-width;
    color: $gray-base-30;
    cursor: pointer;
    transition: .1s ease background-color;

    &:after {
        position: absolute;
        bottom: 0;
        left: $grid-gutter-width;
        right: $grid-gutter-width;
        display: block;
        border-bottom: 1px solid $gray-base-90;
        content: "";
    }

    &:active {
        background-color: $yc-blue-90;
    }

    .linked-list-text-overflow & span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-width: 0;
    }
}

.linked-list-item-header,
.file-td-name {
    .btn.btn-i {
        fill: $gray-base-50;
        width: 18px;
        height: 18px;
        font-size: 18px;
        margin-left: 5px;
        border: 0;
        vertical-align: middle;
        @include media-breakpoint-up(lg) {
            opacity: 0;
        }
        &:hover {
            background-color: $yc-blue-90;
        }

        .icon-info {
            width: 1em;
            height: 1em;
            font-size: 1em;
        }
    }

    &:hover {
        .btn-i {
            @include media-breakpoint-up(lg) {
                opacity: 1;
            }
        }
    }
}

.linked-list-item-header-wrap {
    line-height: 18px;
    text-wrap: pretty;

    span {
        vertical-align: middle;
    }
}

.linked-list-search {
    width: 100%;
    padding: 8px $grid-gutter-width 10px;
}

.linked-list-text-overflow {
    overflow: hidden;
}

.linked-list-item-controls {
    flex: none;

    .btn-transparent {
        fill: $yc-blue;
    }
}

.linked-list-item-content {
    margin-left: $grid-gutter-width + 8px;
    padding: 6px $grid-gutter-width;
    color: $gray-base-30;

    ul {
        padding-left: $grid-gutter-width;
        margin-bottom: 6px;
    }
}

.linked-list-item-content-title {
    color: $gray-base-40;
}

// 2nd level
.linked-list-sublist {

    .linked-list-item-header {
        font-weight: 400;
        color: $body-color;
    }

    .linked-list-item-body .linked-list-item-header {
        padding-left: $grid-gutter-width * 2 + 8px;
        &:after {
            left: $grid-gutter-width * 2 + 8px;
        }
    }
}

.linked-list-item-dropdown {
    & > .linked-list-item-header {
        position: relative;
        padding-left: $grid-gutter-width * 2 + 8px;
        .dropdown-toggler {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: $grid-gutter-width * 2 + 8px;
            background-color: transparent;
            border: 0;
            &:after {
                position: absolute;
                top: .933em;
                left: $grid-gutter-width;
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 5px 0 5px 7px;
                border-color: transparent transparent transparent $gray-base-45;
                transition: .1s ease transform;
                content: "";
                @include media-breakpoint-up(lg) {
                    top: .846em;
                    border-width: 3.5px 0 3.5px 5px;
                }
            }
            &:focus {
                outline: none;
            }

            & + span {
                margin-right: auto;
            }
        }
    }
    & > .linked-list-item-body {
        display: none;
    }

    &.open {
        & > .linked-list-item-header {
            .dropdown-toggler:after {
                transform: rotate(90deg);
            }
            &:after {
                opacity: 0;
            }
        }
        & > .linked-list-item-body {
            display: block;
        }
    }
}

// 3rd level
.linked-list-sublist {
    .linked-list-item .linked-list-item-body {
        .linked-list-item-content + .linked-list-item,
        .linked-list-item:first-child {
            .linked-list-item-header {
                &:before {
                    position: absolute;
                    top: 0;
                    left: $grid-gutter-width * 2 + 8px;
                    right: $grid-gutter-width;
                    display: block;
                    border-bottom: 1px solid $gray-base-90;
                    content: "";
                }
            }
        }
    }
}


// Customize

.linked-list-body {
    flex: auto;
    max-height: 320px;
    overflow-y: auto;
    @media (min-height: 700px) {
        max-height: 400px;
    }
}

.list-metric-counter {
    color: $gray-base-40;
    &.is-empty {
        color: $gray-base-60;
    }
}

// file table in linked list

%linked-list-item-selection {
    position: absolute;
    top: 0;
    left: -100px;
    right: -100px;
    display: block;
    height: 100%;
    z-index: -1;
    content: "";
}

.linked-list {
    .file-table {
        padding-left: 20px;
        padding-right: 20px;
        overflow: hidden;

        .linked-list-item-header {
            position: relative;
            width: auto;
            padding-left: 0;
            padding-right: 0;
            z-index: 1;
            &:after {
                content: none;
            }
            &:before {
                @extend %linked-list-item-selection;
            }
        }

        .file-td-name {
            padding-left: 0;
        }

        .file-icon {
            margin-right: 8px;
        }

        .linked-list-item.selected .linked-list-item-header:before,
        .linked-list-item-header:active:before {
            background-color: $yc-blue-90;
        }
    }
}

.linked-list-collapse {
    position: relative;
    cursor: pointer;
    z-index: 1;
    &:before {
        @extend %linked-list-item-selection;
    }
    &:active:before {
        background-color: $yc-blue-90;
    }
}

.file-table {

    .level-1 td:first-child {
    	position: relative;
    	padding-left: 20px;
    	border-top: 0;
    	border-bottom: 0;
    }

    // in-app

    &.tbody {
        td[colspan] {
            padding-top: 0;
            padding-bottom: 0;
            border-top: 0;
        }

        .file-title {
            @include headerMedium();
        }

        .file-td-grip {
            // flex: 0;
            // max-width: none;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-right: 0;

            .dropdown {
                width: 80px;
                margin-left: auto;
                @include media-breakpoint-up(lg) {
                    width: 60px;
                }
            }

            .btn-group .dropdown {
                width: auto;
            }
        }
    }

    // pseudotable

    .td {
		color: $gray-base-10;
    }

    .file-td-name,
    thead th.file-td-name {
    	flex: auto;
        padding-left: 22px!important;

    	.file-icon {
    		// margin-left: 6px;
            height: 20px;
            width: 20px;
            line-height: 0;

            svg {
                font-size: 20px;
            }
    	}
    }

    .file-td-author {
    	width: 100px;
        .is-panel-sidebar-shown & {
            width: 150px;
        }
        @include media-breakpoint-up(lg) {
        	width: 20%;
            .is-panel-sidebar-shown & {
                width: 20%;
            }
        }

    }
    .file-td-type {
        width: 150px;
        @include media-breakpoint-up(lg) {
        	width: 20%;
        }

    }
    .file-td-date {
        width: 130px;
        @include media-breakpoint-up(lg) {
        	width: 20%;
        }

    }
    .file-td-control {
        width: 40px;
        @include media-breakpoint-up(lg) {
        	width: 30px;
        }
    	.btn {
    		display: flex;
    	}
    }

	.is-panel-sidebar-shown & {
        .file-td-type,
        .file-td-date {
            display: none;
            @include media-breakpoint-up(lg) {
                display: block;
            }
        }
    }

    .file-td-wrap {
        display: flex;
        align-items: self-start;
        min-height: 30px;
        @include media-breakpoint-up(lg) {
            min-height: 20px;
        }        
    }

    .level-1 {
    	.file-td-name {
    		margin-left: 22px;
    	}
    }

    .level-2 {
        .file-td-name {
            margin-left: 44px;
        }
    }

    .level-3 {
        .file-td-name {
            margin-left: 66px;
        }
    }

    .level-4 {
        .file-td-name {
            margin-left: 88px;
        }
    }
}

.file-name {
    @include headerDefault();
    position: relative;
	display: flex;
    align-items: center;

    .collapse-toggler {
        &:after {
            margin-left: 0;
        }
        &:not(.show) {
            transform: rotate(-90deg);
        }
        &.active {
            background-color: transparent;
        }
        &.btn {
            width: 20px;
            height: 20px;
        }
    }
}


.category-toggler {
    position: absolute;
    left: -36px;
    @include media-breakpoint-up(lg) {
        left: -24px;
    }
}
