.portfolio {
    display: flex;
    flex-direction: column;
    width: 100%;

    .panel-collapsible {
        flex: none;

        .panel-content-toggler {
            top: 15px;
            transform: none;
        }

        .panel-title {
            align-items: flex-start;
            flex: 1 1 0;
            margin-right: 0;
            padding-top: 8px;
            padding-bottom: 8px;

            .form-label {
                @include base-height();
                display: flex;
                align-items: center;
                margin-right: 8px;

                div {
                    line-height: 0;
                }
            }

            .input-group {
                flex-wrap: wrap;
            }

            .feedback {
                flex-basis: 100%;
                margin-top: 0;
                font-weight: 400;
                @include media-breakpoint-up(lg) {
                    margin-top: 4px;
                    margin-bottom: -8px;
                }
            }
        }
    }

    .panel-content:last-child {
        margin-bottom: 0;
    }

    .panel-results {
        flex: auto;
        display: flex;
        flex-direction: column;
        margin-bottom: 0;

        .panel-header {
            .btn-text:not(.disabled) {
                &:hover,
                &:active,
                &.active {
                    background-color: $yc-blue-90;
                    border-color: $yc-blue-90;
                }
            }
        }
    }

    .results-table {
        flex: auto;
        display: flex;
        flex-direction: column;
        margin-left: $grid-gutter-width;
        margin-right: $grid-gutter-width;

        thead {
            th {
                &.col-6:first-child {
                    padding-left: 0;
                }
            }

            th[class*="th-sort"] {
                cursor: pointer;
                &:hover {
                    span {
                        text-decoration: underline;
                    }
                }
            }
        }

        tbody td {
            color: $gray-base-10;

            a {
                text-decoration: underline;
                text-decoration-color: $gray-base-60;
            }
        }

        .get-started {
            flex: auto;
            justify-content: flex-start;
            margin-top: $grid-gutter-width;
            margin-bottom: $grid-gutter-width;
            padding-top: $grid-gutter-width * 3;
            padding-bottom: $grid-gutter-width * 3;
        }

        .get-started-subheader {
            @include emptyStateAdditional();
            margin-top: $spacer;
            margin-bottom: $spacer * 2;
            text-align: center;
            font-weight: 700;

            a {
                color: $yc-blue;
            }
        }

        .get-started-data-example {
            width: 490px;
            margin-top: $spacer * 2;
            padding: $spacer $grid-gutter-width * 2 $grid-gutter-width $grid-gutter-width * 2;
            font-weight: 400;
            background-color: $panel-background;
            text-align: left;
        }
    }
}

.portfolio-bm {
    align-items: center;
    align-self: baseline;
    margin-top: $spacer;

    .form-label {
        margin-right: $spacer * 2;
    }
}

.portfolio-header-form {
    border-top: 1px solid $gray-base-90;

    .dropdown:not(.input-group) {
        width: 130px;
    }

    .dropdown-menu {
        min-width: auto;
    }

    .form-message {
        display: inline-block;
        margin-left: 10px;
    }

    .form-divider-lg-horizontal {
        flex-basis: 100%;
        @include media-breakpoint-up(lg) {
            position: absolute;
            left: 100%;
            top: 0;
            border-bottom: 0;
            border-right: 1px solid $gray-base-90;
            height: 100%;
        }
    }

    .form-label.skeleton {
        display: block;
        width: 80%;
    }

    .form-control.skeleton {
        @include base-height;
    }
}

.portfolio-header-form-section {
    [class*=col-] {
        &:not(:first-child) {
            border-left: 1px solid $gray-base-90;
        }
    }

    .form-label {
        margin-top: 14px;
        margin-bottom: $spacer;
    }

    .dropdown-toggle {
        min-width: 100px;
        justify-content: space-between;
    }

    .custom-controls-stacked {
        padding-bottom: 8px;
        @include media-breakpoint-up(lg) {

            .custom-control-empty {
                margin-right: 0;
            }
        }

        .form-control {
            width: 112px;
        }
    }
}

// refactoring needed
.security-panel {
    .portfolio-header-form-section {
        [class*=col-] {
            &:not(:first-child) {
                border-left: 0;
            }
        }
    }

    .portfolio-header-form .dropdown:not(.input-group) {
        width: 100%;
    }
}

.annualized-fee-col {
    padding-left: 22px;
    @include media-breakpoint-up(lg) {
        padding-left: 38px;
    }

    .form-control {
        width: 66px;
        text-align: right;
    }

    .form-message {
        margin-left: 0;
    }
}

.portfolio-table {
    flex: auto;
    // margin-left: $grid-gutter-width;
    // margin-right: $grid-gutter-width;
    td, th {
        &:first-child {
            padding-left: $grid-gutter-width;
        }
        &:last-child {
            padding-right: $grid-gutter-width;
        }
    }

    .table {
        border-bottom: 0;
    }

    thead {
        border-top: 1px solid $gray-base-90;
        border-bottom: 1px solid $gray-base-90;
        th {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    tbody {
        td {
            color: $gray-base-10;
            &.portfolio-table-name {
                padding-left: 0;
                padding-right: 0;
            }
            &.portfolio-table-proxy {
                padding-left: 0;
                padding-right: 20px;
            }
        }
    }

    thead th, tbody td {
        &.portfolio-table-weight {
            padding-left: $grid-gutter-width;
            padding-right: 0;
        }
    }
}

.portfolio-table-symbol {
    width: 20%;
    @include media-breakpoint-up(md) {
        width: 15%;
    }
}

.portfolio-table-name {
    width: 25%;
    @include media-breakpoint-up(md) {
        width: 33%;
    }
}

.portfolio-table-proxy {
    width: 25%;
    @include media-breakpoint-up(md) {
        width: 33%;
    }
}

.portfolio-table-weight {
    @include media-breakpoint-up(md) {
        width: 18%;
    }

    .form-control {
        width: 100%;
    }
}

.portfolio-table-target-weight {
    width: 160px;
    vertical-align: middle;
}

.portfolio-table-fixed {
    width: calc(((100vw - #{$grid-gutter-width}) / 12) - #{$grid-gutter-width});
    text-align: center;

    .custom-control.custom-checkbox {
        margin-right: 0;
    }
}


.portfolio-table-delete {
    text-align: right;
    vertical-align: middle;
}

.portfolio-add-link {
    color: $yc-blue;
    font-weight: 700;
    text-decoration: underline;

    &.disabled {
        color: $gray-base-60;
        cursor: default;
    }
}

.portfolio-footer {
    padding: 20px;
}

.upload-data-example {

    .table {
        border-color: $gray-base-60;

        tbody td {
            border-right-width: 1px;
            border-right-style: solid;
            border-color: $gray-base-60;
            color: $gray-base-30;
        }
    }
}

#securityUploadData {
    .feedback {
        margin-bottom: 0;
    }
}


// Model Portfolios list 2020 edition

.portfolios {
    .panel-sidebar {
        border-right: 1px solid $gray-base-80;

        .form-label  {
            @include lineCropAfter(1.538, 1.167);
            margin-bottom: $spacer * 2;
            @include media-breakpoint-up(lg) {
                margin-bottom: $spacer;
            }
        }

        .custom-controls-stacked .custom-control:last-child {
            margin-bottom: 0;
        }

        .options-list-item-header {
            align-items: center;
        }

        .options-list-item-title {
            @include textOverflow();
            display: block;
            margin-right: $spacer * 2;
        }
    }

    .panel-sidebar-section {
        padding-top: 10px;
        padding-bottom: $spacer * 2;
        &:not(:first-of-type) {
            border-top: 1px solid $gray-base-80;
        }
    }

    .panel-sidebar-header {
        padding-top: $spacer * 2;
        .panel-sidebar-section-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-bottom: 0;
        }

        .panel-sidebar-section-title {
            color: $gray-base-30;
            fill: $gray-base-30;

            svg, span {
                vertical-align: middle;
            }
        }
    }

    .panel-sidebar-footer {
        padding-top: $spacer * 2;
    }

    .panel-sidebar-toggler {
        &.panel-sidebar-toggler-close {
            position: static;
        }
        &.panel-sidebar-toggler-open {
            margin-left: 0;
            margin-right: $spacer * 2;
            &:after {
                content: none;
            }
        }
    }

    .panel-sidebar-section-body {
        &:first-child {
            padding-top: 0;
        }
    }

    .panel-content-wrap .panel-content {
        padding-top: 11px;
        padding-bottom: 11px;
    }

    .panel-content-header {
        flex: none;
        display: flex;
        align-items: center;
        padding-bottom: 11px;
        font-size: 13px;

        .panel-action {
            padding-top: 0;
            padding-bottom: 0;

            .btn {
                font-size: 13px;
            }
        }

        .panel-actions {
            align-items: center;
            margin-left: auto;
        }
    }
}

.table-portfolios {
    border-top: $table-border;
    thead th {
        &:first-child {
            padding-left: 0;
        }
        &.th-sort {
            cursor: pointer;
        }
        &.file-td-grip {
            padding-right: 0;
        }
    }
    tbody td, .td {
        padding-top: 15px;
        padding-bottom: 15px;
        vertical-align: top;
        color: $body-color;
        &:last-child:not(:only-child) {
            text-align: right;
        }
        &.file-td-grip {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    .th-sort-asc span,
    .th-sort-desc span {
        padding-right: 30px;
    }

    .btn-group {
        .dropdown .btn.dropdown-toggle {
            @include base-min-width;
            border-left: 0;
            &:after {
                content: none;
            }
        }
    }

    .btn-info,
    .btn-share {
        vertical-align: middle;
        &:hover {
            fill: $gray-base-10;
        }
    }
}

.table-portfolios-title {
    @include headerMedium();
    display: inline-block;
    &:hover a {
        color: $yc-blue;
    }
}

.table-portfolios-descr {
    display: none;
    padding-top: 4px;
    @include media-breakpoint-up(md) {
        display: block;
    }
}

.table-portfolios-status {
    margin-top: $spacer;
    font-weight: 700;
    text-transform: uppercase;

    .loader-spinner-context {
        display: inline-block;
        width: 20px;
        height: 20px;
        vertical-align: middle;
    }
}

.table-portfolio-status-icon {
    margin-right: $spacer;

    svg {
        fill: currentColor;
    }
}
