.page-search-all {
}

.search-all-results {
    position: relative;
    max-width: 480px;
    margin-right: auto;
    margin-left: auto;
}

.search-all-results-section {
    border-top: 1px solid $gray-base-90;
}

.search-all-results-link {
    @include base-min-height();
    position: relative;
    border-bottom: 1px solid $gray-base-90;
    &:hover {
        background-color: $yc-blue-95;
    }
    &.disabled {
        pointer-events: none;

        .news-source:after {
            content: none;
        }
    }
    &.is-highlighted,
    &.news-row-highlighted {
        background-color: rgba(map-get($colors, purple-light), .5);
    }

    .col,
    .col-auto {
        padding: 8px 10px;
    }
}
