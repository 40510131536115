.pill {
	flex: none;
	display: inline-block;
	height: 17px;
	min-width: 34px;
	padding-left: 4px;
	padding-right: 4px;
	border: 1px solid $gray-base-80;
	border-radius: 17px;
	font-size: 10px;
	font-weight: 400;
	line-height: 15px;
	color: $gray-base;
	background-color: #fff;
	text-align: center;
}