.cat-list {
}

.cat-list-item {
    border-bottom: 1px solid $gray-base-90;
}

.cat-list-item-header {

}

.cat-list-item-title {
    @include base-min-height();
    @include headerDefault();
    display: block;
    margin-bottom: 0;
    padding: 8px 0;
    cursor: pointer;
    @include media-breakpoint-up(lg) {
        padding: 6px 0;
    }
    &:hover {
        background-color: $yc-blue-95;
        color: inherit;
    }
    &:focus {
        text-decoration: underline;
        outline: none;
    }

    &.dropdown-toggle {
        &:after {
            border-top-color: $gray-base-50;
        }
        &.is-opened:after {
            transform: rotate(180deg);
        }
    }
}

.cat-list-item-body {
    display: none;
    &.show {
        display: block;
    }
}

.cat-list-link {
    @include base-min-height();
    display: block;
    padding-top: 9px;
    padding-bottom: 9px;
    text-decoration: underline;
    @include media-breakpoint-up(lg) {
        padding-top: 5px;
        padding-bottom: 5px;
    }
}