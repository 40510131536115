.image {
    img {
        max-width: 100%;
        height: auto;
        border: 1px solid $gray-base-90;
    }
}

.image-zoomable {
    position: relative;

    .image-icon {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        background-color: rgba($gray-base-45, .5);
        fill: #fff;

        svg {
            flex: none;
        }
    }
}
