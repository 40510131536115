.flat-list {
    padding: 0 $grid-gutter-width;
    border: 1px solid $gray-base-80;
}

.flat-list-wrap {
    @extend .row;
}

.flat-list-item {
    @extend .col;
    color: $gray-base-30;

    &:hover {
        color: $gray-base-30;
    }

    &:active {
        background-color: $yc-blue-90;
    }
}

.flat-list-item-title {
    @include base-min-height();
    padding: 8px 0;
    border-bottom: 1px solid $gray-base-90;
    cursor: pointer;
    transition: .1s ease background-color;
    @include media-breakpoint-up(lg) {
        padding: 6px 0;
    }
}

.flat-list-col-2 {
    .flat-list-item {
        @extend .col-6;
    }
}
