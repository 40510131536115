
.table-alerts {
    border-top: $table-border;
    thead th {
        &:first-child {
            padding-left: 0;
        }
        &.th-sort {
            cursor: pointer;
        }
    }
    tbody td {
        color: $body-color;

        &.td-details {
            border-top: 0;
            text-align: left;
        }
    }

    .th-sort-asc span,
    .th-sort-desc span {
        padding-right: 30px;
    }

    td, th {
        position: relative;
    }

    tbody {
        tr.bg-panel {
            td, th {
                &:after {
                    @include table-striped-after();
                }
            }
        }
    }
}

.td-details {
    .table {
        border-bottom: 0;

        tr:first-child {
            td,
            th {
                border-top: 0;
            }
        }
    }
}

.table-alerts-title {
    @include headerMedium();
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 400;

    a {
        @include underline();
    }

    strong {
        font-weight: 700;
    }
}


// alerts in modal
.table-alerts-modal {
    border-top: $table-border;
    thead th {
        &:first-child {
            padding-left: 20px;
        }
        &.th-sort {
            cursor: pointer;
        }
    }

    .collapse-mark {
        margin-right: 10px;
    }

    .td-details {
        padding-left: 20px;
        color: $gray-base-10;

        p {
            margin-bottom: 6px;
        }
    }
}

.add-alert-link {
    color: $yc-blue;
    &:hover {
        text-decoration: underline;
    }
    &.disabled {
        color: $gray-base-60;
        pointer-events: none;
    }
}
