$auth-sidebar-width : 400px;
$auth-form-width: $auth-sidebar-width;

.auth {
    flex: auto;
    display: flex;
    width: 100%;
}


.auth-sidebar {
    display: none;
    flex: none;
    flex-direction: column;
    justify-content: space-between;
    width: $auth-sidebar-width;
    padding: 60px 40px;
    background-color: $yc-blue-ada;
    color: #fff;
    @include media-breakpoint-up(md) {
        display: flex;
    }
}

.auth-sidebar-header {
    padding-top: 60px;
    font-size: 24px;
    line-height: 1.333;
}

.auth-sidebar-footer {

}

.auth-content {
    position: relative;
    flex: auto;
    padding-top: 85px;
    padding-bottom: 48px;
}

.auth-content-wrap {
    position: relative;
    max-width: $auth-form-width;
    margin-left: auto;
    margin-right: auto;
}

.auth-nav {
    position: absolute;
    top: -30px;
    display: flex;
}

.auth-nav-link {
    width: 10px;
    height: 10px;
    margin-right: 10px;
    font-size: 0;
    line-height: 0;
    background-color: $gray-base-80;
    border-radius: 50%;
    cursor: pointer;
    &.active {
        background-color: $yc-blue;
    }
}

.auth-header {
    margin-bottom: $spacer * 4;
}

.auth-title {
    font-size: 30px;
    font-weight: 700;
    color: $yc-blue-ada;
    letter-spacing: -0.15px;
}

.auth-subtitle {
    font-size: 16px;
}

.auth-form-wrap {
    width: $auth-form-width;
}

.auth-form {

    .form-row {
        margin-bottom: 20px;
    }

    .form-group {
        margin-top: $spacer * 2;
    }

    .feedback {
        margin-bottom: $spacer * 2;
    }

    input.form-control,
    select.form-control,
    .dropdown-toggle {
        background-color: $gray-base-95;
        border-color: transparent;
        @include media-breakpoint-up(lg) {
            height: $base-height;
        }
        &:focus {
            border-color: $yc-blue;
        }
        &.is-invalid {
            border-color: map-get($colors, red);
        }
        &.is-valid {
            border-color: map-get($colors, green);
        }
        @include placeholder {
            color: $gray-base-60;
        }

        &.disabled,
        &[disabled] {
            color: $gray-base-60;
        }
    }

    .dropdown-toggle {
        .placeholder {
            @include textSmall();
            font-weight: 400;
            text-transform: none;
            color: $gray-base-10;
        }
    }

    .dropdown-item {
        @include media-breakpoint-up(lg) {
            height: $base-height;
        }

        &.custom-checkbox .custom-control-indicator {
            left: 12px;
            height: 16px;
            width: 16px;
        }
    }

    .field-input-group-actions {
        &, .btn {
            @include media-breakpoint-up(lg) {
                height: $base-height;
            }
        }
    }

    .form-label.form-label-thin {
        @include lineCropAfter(1.538, 1.167);
        display: block;
        margin-bottom: $spacer;
        color: $gray-base-30;
    }

    .form-link {
        font-size: 12px;
        a {
            font-weight: 700;
            color: $yc-blue;
        }
    }

    .form-row-accept {
        line-height: 1.667;
        .custom-control {
            font-size: 12px;
            line-height: 1.667;
        }

        a {
            font-weight: 400;
            color: $yc-blue;
        }
    }


    .btn-xxl {
        margin-top: $spacer * 4;
        margin-bottom: $spacer * 3;
    }
}

.auth-link-account {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    .logo {
        max-height: 22px;
        width: auto;
    }

    .logo-ycharts {
        max-height: 16px;
    }

    .icon-add {
        margin-left: $spacer;
        margin-right: $spacer;
        font-size: 16px;
        fill: $gray-base-40;
    }
}

.auth-codelist {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 4px;
    padding: 10px 20px;
    padding-bottom: 10px;
    border: 1px solid $gray-base-80;
    color: $gray-base-30;
    font-weight: bold;
}
