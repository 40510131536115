
.page-support {
    @include bodyText();
    @media (min-height: 700px) {
        min-height: calc(100vh - #{$header-height} - #{$footer-height-mobile});
    }
    @media (min-height: 700px) and (min-width: map-get($grid-breakpoints, lg)) {
        min-height: calc(100vh - #{$header-height} - #{$footer-height});
    }

    body.full-height & {
        .page-body {
            flex-direction: column;
            overflow: clip;

            & > .row {
                height: 100%;
            }
        }
    }

    .page-subheader {
        border-top: 1px solid $gray-base-80;
        background-color: $panel-background;
    }

    .page-body {
        max-width: 1200px;
        padding-top: $spacer * 5;
        padding-bottom: $spacer * 5;
        @include media-breakpoint-up(lg) {
            padding-top: $spacer * 7;
        }
    }

    .tabs.tabs-vertical {
        @extend %tabs-support-style;

        .nav {
            padding-left: 0;
        }
    }

    .tab-content {
        max-height: 100%;

        body.full-height & {
            overflow: auto;
        }
    }

    .tab-pane {
        padding-left: 0;
        padding-right: 0;
        overflow: hidden;

        &.report-history {
            overflow: visible;
        }
    }

    p {
        margin-bottom: 22px;
    }

    .input-group .btn {
        @include base-min-width;
    }

    .dropdown .btn {
        min-width: 0;
    }

    .hr {
        border: 0;
        border-bottom: 1px solid $gray-base-90;
    }

    .table tbody {
        th, td {
            color: $gray-base-10;
        }
    }

    .data-range-cover .form-label {
        max-width: none;
        white-space: nowrap;
    }

    .btn-primary {
        line-height: 100%;
    }
}

// Page header
.page-header-support {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $gray-base-80;
    background-color: #fff;

    .container {
        max-width: 1200px;
    }

    .row {
        align-items: baseline;
        justify-content: space-between;
    }

    .page-title {
        @include mainHeader();
    }

    .form-inline {
        .form-control {
            width: 300px;
        }
    }

    .dropdown-item-text {
        font-size: 13px;
        font-weight: 700;

        span {
            font-weight: 400;
        }
    }
}

.support-breadcrumbs {
    position: absolute;
    bottom: 100%;
    padding-bottom: $spacer;
    color: $yc-blue;

    a:link {
        color: $yc-blue;
    }
}

.topic-title {
    @include h2();
}

// FAQ tab

.topic-collapse {
    &:not(:last-child) {
        margin-bottom: 36px;
    }

    .collapse {
        padding-right: $spacer * 6;
        @include media-breakpoint-up(lg) {
            padding-right: $spacer * 7;
        }
    }

    a {
        color: $yc-blue;
    }
}

.topic-collapse-title {
    @include h2();
    position: relative;
    padding-right: $spacer * 6;
    margin-bottom: $spacer * 2;
    cursor: pointer;
    @include media-breakpoint-up(lg) {
        padding-right: $spacer * 7;
    }
    &.active {
        .topic-collapse-arrow {
            transform: rotate(180deg);
        }
    }
}

.topic-collapse-arrow {
    position: absolute;
    top: 10px;
    right: 8px;
    font-size: 18px;
    fill: $gray-base-30;
}


// Expert Tips
.support-tips {
    @include callToAction;
    padding-top: $spacer * 2;
    padding-left: 0;
    list-style: none;

    li {
        margin-bottom: $spacer * 2;
    }

    a {
        color: $yc-blue;
        text-decoration: underline;
    }
}

// Webinars & Presentation Materials

.topics-section {
    &:not(:last-child) {
        margin-bottom: $spacer * 4;
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }

        &.video-responsive {
            margin-top: 40px;
        }
    }

    .get-started {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

.topics-section-header {
    @include h2();
    margin-bottom: $spacer * 2.5;
    &.mb-0 {
        margin-bottom: 0;
    }
}

.topics-section-body {
    &.row {
        margin-bottom: -20px;
    }

    [class*="col-"] {
        display: flex;
        flex-direction: column;
    }

    .card {
        flex: auto;
        margin-bottom: $spacer * 3;
    }

    .card-link {
        font-size: 13px;
        font-weight: 700;
        color: $yc-blue;
        text-transform: uppercase;

        [class*=icon-],
        span {
            vertical-align: middle;
        }

        [class*=icon-] {
            margin-left: $spacer * 0.5;
            font-size: 15px;
            fill: currentColor;
        }

        &:hover {
            span {
                text-decoration: underline;
            }
        }
    }

    p:last-child {
        margin-bottom: 0;
    }

    a {
        color: $yc-blue;
    }
}

.topic-section-subheader {
    @include callToAction();
    margin-bottom: $spacer * 1.5;
}

.topic-section-nav {
    display: flex;
    margin-top: $spacer * 2;
    margin-bottom: $spacer * 1.5;
    padding-left: 0;
    list-style: none;

    li {
        margin-right: 20px;
        border-bottom: 4px solid transparent;
        &.active {
            border-bottom-color: $yc-blue;

            a {
                font-weight: 700;
            }
        }

        a {
            font-size: 16px;
        }
    }
}

.webinar-title {
    margin-bottom: $spacer * 2;
    @include h2();
}

.webinar-descr {
    margin-bottom: $spacer * 3;
    font-size: 15px;
}

.topics-section-footer {
    padding-top: 5px;

    .btn-link {
        padding-left: 0;
        padding-right: 0;
        &:hover,
        &:focus {
            background-color: transparent;
            border-color: transparent;
            span {
                text-decoration: underline;
            }
        }
    }
}

// Support Contact

.support-contact {
    .public-page-title {
        margin-bottom: $spacer * 2;

        & + .public-intro {
            margin-top: $spacer * -1.5;
        }
    }

    .public-h2 {
        @include h2();
        margin-bottom: $spacer;
    }

    .public-bolded {
        @include largeBoldText();
        margin-bottom: $spacer * 2;
    }
}

.support-contact-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.support-contact-item {
    display: flex;
    align-items: center;
    &:not(:last-child) {
        margin-bottom: 12px;
    }

    .support-contact-icon {
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        margin-right: $spacer * 2;
        fill: $gray-base-30;

        svg {
            @include media-breakpoint-up(lg) {
                font-size: 15px;
            }
            &.icon-calendar {
                font-size: 18px;
            }
        }
    }

    a, span {
        display: block;
        &.public-cta {
            @include callToAction();
        }
    }

    a {
        color: $yc-blue;
    }
}


// Glossary

.glossary-abc {
    @include largeBoldText();
    display: flex;
    flex-wrap: wrap;
    max-width: 600px;
    padding-top: $spacer * 1.5;
    @include media-breakpoint-up(lg) {
        max-width: 750px;
    }

    a {
        margin-bottom: $spacer * 1.5;
        color: $yc-blue;
        @include media-breakpoint-up(lg) {
            flex: 1 !important;
        }
        &:hover,
        &:focus,
        &:active,
        &.active {
            text-decoration: underline;
            outline: none;
        }
    }
}

.glossary-index.page-body,
.glossary-article.page-body {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
}

.glossary-index.page-body {
    padding-top: 10px;

    body.full-height & {
        overflow: auto;
    }
}

.glossary-index-item {
    padding-top: $spacer * 0.5;
    margin-bottom: $spacer * 5;
}

.glossary-index-item-title {
    @include h2();
    margin-bottom: 22px;
    border-bottom: 1px solid $gray-base-90;
}

.glossary-index-item-content {
    column-count: 3;

    a {
        display: block;
        margin-bottom: $spacer * 1.5;
        line-height: 1.2;

        [class*=icon] {
            margin-left: $spacer;
            fill: $gray-base-30;
        }
    }
}

.glossary-section {
    // display: inline-block;
    margin-bottom: 4px;
}

.no-break {
    display: inline-block;
    width: 100%;
    break-inside: avoid;
    page-break-inside: avoid;
}

.glossary-section-title {
    @include headerDefault();
    margin-bottom: $spacer;
}

.glossary-article {

}

.glossary-title {
    @include h2();
    margin-bottom: $spacer * 2;
}

.glossary-subtitle {
    @include callToAction();
    margin-bottom: $spacer * 2;
}

.glossary-sidebar {
    a {
        text-decoration: underline;
    }
}

.glossary-sidebar-title {
    display: block;
    margin-bottom: $spacer * 2;
    font-size: 14px;
}

.glossary-sidebar-item {
    display: block;
    margin-bottom: $spacer;
    font-size: 14px;
}


// Integrations


.integration-table {
    border-top: 1px solid $gray-base-80;

    .table {
        thead th {
            position: relative;
            background-color: $panel-background;

            &:first-child,
            &:last-child {
                &:after {
                    position: absolute;
                    top: 0;
                    display: block;
                    height: 100%;
                    width: $grid-gutter-width;
                    background-color: $panel-background;
                    z-index: -1;
                    content: "";
                    @media (min-width: map-get($container-max-widths, xl)) {
                        width: calc((100vw - #{map-get($container-max-widths, xl)})/2 + #{$grid-gutter-width});
                    }
                }
            }

            &:first-child {
                padding-left: 0;
                &:after {
                    right: 100%;
                }
            }
            &:last-child {
                &:after {
                    left: 100%;
                }
            }
        }

        thead + tbody tr:first-child td {
            border-top: 0;
        }

        tbody tr ~ tr td {
            border-top: 0;
        }
    }
}
