
.esg-title {
    @include textLabel();
    margin-top: 10px;
}

.panel-esg {
    height: 100%;
    border-top-width: 3px;
    &.panel-lime {
        border-top-color: map-get($colors, lime);
        .esg-panel-title:before {
            background-color: map-get($colors, lime);
        }
    }
    &.panel-orange {
        border-top-color: map-get($colors, orange);
        .esg-panel-title:before {
            background-color: map-get($colors, orange);
        }
    }
    &.panel-blue {
        border-top-color: $yc-blue;
        .esg-panel-title:before {
            background-color: $yc-blue;
        }
    }

    .panel-content {
        margin-bottom: 0;
        padding-top: 16px;
        padding-bottom: 12px;
        padding-left: 30px;
        @media (min-width: 1140px) {
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding-left: 16px;
        }
    }

    .esg-panel-icon {
        @media (min-width: 1140px) {
            margin-right: 12px;
        }
    }
}

.esg-panel-icon {
    margin-bottom: 6px;
    fill: $gray-base-30;

    svg {
        font-size: 15px;
        @media (min-width: 1140px) {
            font-size: 13px;
        }
    }
}

.esg-panel-body {
    display: flex;
    flex-direction: column;

    .chart-horizontal-bars {
        margin-top: auto;
    }
}

.esg-panel-title {
    @include headerDefault();
    position: relative;
    margin-bottom: 4px;

    &.bullet:before {
        position: absolute;
        border-radius: 50%;
        top: 6px;
        left: -12px;
        width: 7px;
        height: 7px;
        margin-right: 6px;
        background-color: currentColor;
        content: "";
    }

    .btn-esg-help {
        height: 0;
    }
}

.btn-esg-help {
    display: inline-flex;
    vertical-align: middle;
    fill: $gray-base-30;

    .icon-info {
        font-size: 11px;
    }
}

.esg-panel-value {
    @include headerLarge();
    margin-bottom: 2px;
}

.esg-panel-label {
    @include textSmall();
    align-self: flex-start;
    position: relative;
    display: inline-block;
    margin-top: 4px;
    margin-bottom: 8px;
    padding-left: 4px;
    padding-right: 4px;
    border: 1px solid currentColor;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 20px;
    &.label-strikethrough {
        background: linear-gradient(to top right, transparent calc(50% - 1px), currentColor, transparent calc(50% + 1px));
    }
}

.rating-esg {
    display: flex;
    width: 100%;
}

.rating-esg-bar {
    flex: 1;
    border: 1px solid currentColor;
    font-size: 12px;
    text-align: center;
    color: $gray-base-30;
    &:not(:last-child) {
        margin-right: 4px;
    }
    &.current {
        font-weight: 700;
        background-color: currentColor;
    }
}

.esg-bar-ccc {
    color: map-get($negative-colors, 1);
}

.esg-bar-b {
    color: map-get($negative-colors, 2);
}

.esg-bar-bb {
    color: #f5b209;
}

.esg-bar-bbb {
    color: #e4a70d;
}

.esg-bar-a {
    color: #c39521;
}

.esg-bar-aa {
    color: map-get($positive-colors, 3);
}

.esg-bar-aaa {
    color: map-get($positive-colors, 1);
}

.esg-subtitle {
    line-height: 1;
}

.rating-esg-logo {
    @include headerLarge();
    width: $spacer * 8;
    border: 1px solid $gray-base-80;
    padding: 3px;
    text-align: center;

    &[class*=esg-bar] {
        border-width: 2px;
        border-color: currentColor;
    }
}

.rating-quartile-wrap {
    display: flex;
    align-items: baseline;
    padding-top: 10px;
    padding-bottom: 12px;
}

.rating-quartile {
    display: inline-flex;
    align-items: center;

    .rating-legend {
        font-size: 13px;
        text-transform: uppercase;
        @include media-breakpoint-up(lg) {
            font-size: 9px;
        }
    }

    .rating-bar {
        position: relative;
        height: 18px;
        width: 50px;
        &:not(:last-child) {
            margin-right: 1px;
        }
    }

    .rating-bar-val {
        position: absolute;
        top: 100%;
        left: 0;
        height: 100%;
        width: 100%;
        text-align: center;
        font-size: 13px;
        color: $gray-base-30;
        @include media-breakpoint-up(lg) {
            font-size: 9px;
        }
    }
}

.esg-chart-column {
    display: flex;

    .divider-vertical {
        display: none;
        @include media-breakpoint-up(lg) {
            display: block;
        }
    }
}

.esg-chart-wrap {
    flex: auto;
    @include media-breakpoint-up(lg) {
        padding-left: 20px;
    }

    .chart-canvas {
        max-width: 100%;
        height: auto;
        @media (min-width: 1140px) {
            max-height: 100px;
        }
    }
}

.esg-empty {
    position: relative;
    min-height: 64px;

    .panel-data-empty-message {
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        z-index: 1;
    }
}
