
// Icons customization

[class*="icon-"] {
    font-size: 1rem;
    // transform: scale3d(1, 1, 1); // safari subpixel rendering quick fix
    @include media-breakpoint-up(lg) {
        font-size: .907rem;
    }

    &.fill-current {
        fill: currentColor;
    }
}

.icon-13 {
    width: 13px;
    height: 13px;
}

.has-badge {
    position: relative;

    .badge {
        position: absolute;
        top: 0;
        right: 0;
    }
}

