.preview {
    font-family: sans-serif;

    h1 {
        font-size: 2em;
    }

    h2 {
        font-size: 1.5em;
    }
}

.preview-title,
.preview-section {
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: 3px solid $gray-base-75;
}


.preview-title {
    padding-top: 20px;
    padding-bottom: 20px;
}

.preview-section-title {
    padding-top: 10px;
}

.preview-section-date {
    margin-bottom: 10px;
    font-weight: 700;
    text-transform: uppercase;
    color: $gray-base-60;
}

.preview-section-content {

}

.preview-section-border-top {
    border-top: 3px solid $gray-base-75;
}

.preview-image {
    width: 100%;
}
