
// Panel pagination

.panel-pagination-title {
    @include headerDefault();
}

.panel-pagination-content {
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;

    .divider-vertical {
        height: 28px;
        @include media-breakpoint-up(lg) {
            height: 20px;
        }
    }

    .btn {
        &,
        &:focus {
            border-color: transparent;
        }
        &.disabled {
            &,
            &:focus,
            &:hover {
                background-color: transparent;
                font-weight: 400;
            }
        }
    }

    .show > .btn,
    .btn:not(.disabled):hover {
        fill: $gray-base-10;
    }
}

.panel-pagination-count {
    padding-right: 15px;
    color: $gray-base-40;
}

.panel-pagination-select {
    .dropdown-toggle:not(.field-input) {
        border-color: $gray-base-80;
        &.active,
        &:focus {
            border-color: $yc-blue;
            background-color: #fff;
        }
    }

    .dropdown-menu {
        min-width: auto;
    }
}
