
.plates {
    margin-left: -5px;
    margin-right: -5px;

    &.plates-view-list {
        .plate-header {
            min-height: $base-height;
            padding-top: 5px;
            padding-bottom: 5px;
            @include media-breakpoint-up(lg) {
                min-height: $base-height-desktop;
            }
        }

        .plate-title {
            align-items: center;
        }

        .plate-title-name {
            flex: none;
        }
    }

    &.plates-view-cols {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -10px;

        .plate-container {
            width: 25%;
        }

        .plate {
            &:hover {
                .plate-controls .btn {
                    opacity: 1;
                }
            }
        }

        .plate-header {
            min-height: 70px;
        }

        .plate-controls {
            .btn {
                display: flex;
                transition: .3s ease opacity;
                & + .btn {
                    margin-left: 0;
                    margin-top: 6px;
                }
                @include media-breakpoint-up(lg) {
                    opacity: 0;
                }
            }
        }
    }

    &.plates-view-cols.cols-3 {
        .plate-container {
            width: 33.33%;

            &.size-double {
                width: 66.66%;
            }
        }
    }

    .empty-message {
        @include textSmall();
        margin-left: 5px;
        margin-right: 5px;
        padding-top: 12px;
        padding-bottom: 12px;
        border: 1px solid $gray-base-80;
        background-color: $gray-base-95;
        color: $gray-base-40;
        text-align: center;
        pointer-events: none;
    }
}

.plate-container {
    display: flex;
    margin-bottom: 10px;
    padding-right: 5px;
    padding-left: 5px;
    + .empty-message {
        display: none;
    }
}

.plate {
    width: 100%;
    border: 1px solid $gray-base-80;
    background-color: $gray-base-95;
    transition: .3s ease all;
    &.plate-purple {
        background-color: map-get($colors, 'purple-tint');
    }
    &.plate-gray {
        background-color: $gray-base-80;
    }
    &.plate-white {
        background-color: #fff;
    }
    &:hover:not(.plate-disabled) {
        @include box-shadow;
        background-color: $yc-blue-90;
    }
    &.plate-collapsible {
        .plate-content {
            padding-left: 40px;
        }
    }
    &.plate-disabled {
        background-color: transparent;
        cursor: default;

        .text-default {
            color: $gray-base-50;
        }
    }

    .btn-toggler {
        margin-right: 10px;
        &.show {
            transform: rotate(90deg);
        }
    }
}

.plate-header {
    display: flex;
    padding: 10px;
}

.plate-title {
    flex: auto;
    display: flex;
    font-size: 13px;

    & > *:not(:first-child) {
        padding-left: 10px;
    }
}

.plate-title-abbr {
    flex: none;
    width: 140px;
}

.plate-title-name {

}

.plate-controls {

}

.plate-content {
    position: relative;
    padding: $spacer 10px;
    &:before {
        position: absolute;
        top: 0;
        left: 10px;
        right: 10px;
        display: block;
        height: 0;
        border-top: 1px solid $gray-base-95;
        content: "";
    }

    p:last-child {
        margin-bottom: 0;
    }
    &.pr-40 {
        padding-right: 40px;
    }
}
