
$trial-days-percentage: calc((var(--trialDaysRemaining) * 100) / var(--trialDaysTotal));

@keyframes growProgressBar {
	  0%, 33% { --csPercentage: 0; }
	  100% { --csPercentage: #{$trial-days-percentage}; }
}

@property --csPercentage {
	  syntax: '<number>';
	  inherits: false;
	  initial-value: 0;
}

.circle-stats {
		--csPercentage: #{$trial-days-percentage};
		display: flex;
		align-items: center;
		justify-content: center;
		width: 22px;
		height: 22px;
	    font-size: 14px;
		border-radius: 50%;
		background: 
				radial-gradient(closest-side, $header-bg 80%, transparent 0 99.9%, $header-bg 0),
				conic-gradient($header-color-dark calc((100 - var(--csPercentage)) * 1%), currentColor 0)
		;
		cursor: default;
		animation: growProgressBar 2s 1 forwards;
}
