
// Make the div behave like a button
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; // match .btn alignment given font-size hack above

  > .btn {
    position: relative;
    flex: 0 1 auto;

    &:hover,
    &:focus,
    &:active,
    &.active {
      z-index: 1;
    }

    &.flex-0 {
        flex: 1 1 0;
    }
  }

  // Prevent double borders when buttons are next to each other
  .btn + .btn,
  .btn + .btn-group,
  .btn-group + .btn,
  .btn-group + .btn-group,
  .btn + .form-control,
   .form-control + .btn {
    margin-left: -1px;
  }
}

.btn-group {
  & > .btn-group {
    display: flex;
  }
}

// Sizing
//
// Remix the default button sizing classes into new ones for easier manipulation.

.btn-group-sm > .btn { @extend .btn-sm; }
// .btn-group-lg > .btn { @extend .btn-lg; }

//
// Vertical button groups
//

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .btn,
  .btn-group {
    width: 100%;
  }

  > .btn + .btn,
  > .btn + .btn-group,
  > .btn-group + .btn,
  > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0;
  }
}

// Switch

.btn-group.switch {
    border: 1px solid $gray-base-90;
    // &:focus-within,
    &.is-focused {
        border-color: $yc-blue;
    }

    .btn {
        border: 0;
        &:focus {
            outline: none;
            z-index: auto;
        }
        &.current {
            cursor: default;
            pointer-events: none;
        }
    }

    .btn-off {
        color: $gray-base-60;
        &:hover {
            z-index: auto;
            color: $gray-base-50;
        }
        &:active {
            color: $gray-base-30;
        }
        &.current {
            background-color: #fff;
            color: $gray-base-40;
        }
    }

    .btn-on {
        background-color: $gray-base-95;
        &:hover {
            background-color: $yc-blue-25;
            color: #fff;
        }
        &:active {
            background-color: $yc-blue-dark;
            color: #fff;
        }
        &.current {
            background-color: $yc-blue;
            color: #fff;
            &:hover {
                background-color: $yc-blue;
            }
        }
    }

    .btn {
        &.disabled {
            &.btn {
                color: $gray-base-60;
                cursor: not-allowed;
                &:active {
                    background-color: $gray-base-95;
                }
            }
            &.current,
            &.current:active {
                background-color: #fff;
            }
        }
    }
}
