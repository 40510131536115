.page-ai {
	.container {
		background: linear-gradient(to bottom, rgba(#fff, 0) 0%, rgba(#fff, 1) 100%), linear-gradient(109.13deg, #F1ECFA 4.26%, $ai-plate 71.48%);
		overflow: hidden;
	}

	.btn-scroll {
		transform: translate(0, -50%);
		min-width: auto;
		border: 2px solid $yc-blue-50;
		border-radius: 8px;
		box-shadow: 0px 3px 8px 0px #00000040;
		z-index: 1;
	}

}

.messages-list {

}

.messages-list-item {
	display: flex;
	padding: 8px 6px;
	color: $gray-base-30;
	border-radius: 4px;
	cursor: pointer;

	&:hover {
		background-color: $yc-blue-90;

		.btn-delete {
			opacity: 1;
			visibility: visible;
		}
	}

	.btn-delete {
		flex: none;
		margin-left: 3px;
		@media (min-width: map-get($grid-breakpoints, lg)) and (pointer:fine) {
			opacity: 0;
			visibility: hidden;
		}

		[class*="icon"] {
			margin-right: 0;
		}
	}
}

.messages-list-item-icon {
	flex: none;
	margin-right: $spacer;
	padding-top: 2px;
	font-size: 15px;
	fill: $gray-base-40;
}

.messages-list-item-text {
	flex: auto;
	// align-self: center;
}

.page-ai-title {
	@include emptyState();
	color: $gray-base;
}

.page-ai-subtitle {
	@include keyStatTitle();
}

.page-ai-cta-title {
	@include callToAction();
	display: flex;
	align-items: center;
	color: $gray-base-30;

	[class*="icon"] {
		flex: none;
		font-size: 16px;
		margin-right: $spacer;
		fill: $gray-base-30;
	}
}

.page-ai-plates {
	[class*="col"] {
		display: flex;
		flex-direction: column;
	}

	.plates {
		flex: auto;
	}

	.plate-container {
		height: 100%;
		margin-bottom: 0;
	}

	.plate {
		min-height: 152px;
		background: rgba($ai-plate, .3);
		&:hover {
			background: rgba($ai-plate, 1);
		}
	}
}

.page-ai-scroll {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: auto;
}

.page-ai-scroll-footer {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
}

.section-ai-response {
	position: relative;
	padding: 12px $spacer;
	background-color: #fff;
	border-radius: 4px;
	&:before {
		position: absolute;
		top: 4px;
		right: calc(100% + 22px);
		display: block;
		width: 27px;
		height: 27px;
		background: transparent url(../../images/redesign/ycharts-logo-ai-answer.svg) center center no-repeat;
		content: "";
	}
}

.section-ai-response-content {
	@include largeText();

	ol {
		margin-bottom: $spacer * 2;
		padding-left: $spacer * 3;

		li::marker {
			font-weight: 700;
		}
	}
}

.section-ai-response-link {
	display: flex;
	align-items: center;
	justify-content: space-between;
	&[class*=bg-] {
		padding: 7px 12px 7px 24px;
	}
}

.section-ai-response-footer {
	position: relative;
	margin-top: 12px;
	margin-bottom: 12px;
	padding-top: 12px;
	border-top: 1px solid $gray-base-90;
}

.section-ai-feedback {
	.btn-transparent2:hover {
		fill: $yc-blue;
	}
}

.section-ai-feedback-detail {
	padding: 14px;
	border: 1px solid $gray-base-80;
	background-color: $panel-background;
	border-radius: 6px;
}

.section-ai-feedback-detail-title {
	display: flex;
	justify-content: space-between;
	padding-left: 6px;
	font-weight: 700;
}

.section-ai-feedback-detail-selector {
	padding-left: 6px;
	padding-bottom: 6px;

	&.custom-controls-stacked .custom-control:last-child {
		margin-bottom: 0;
	}
}

.section-ai-sources {

}

.section-ai-suggest {

}

.section-ai-suggest-title {
	@include largeText();
	font-weight: 700;
	margin-bottom: 8px;
	color: $gray-base-30;
}

.section-ai-suggest-list {
	border-top: 1px solid $gray-base-80;
}

.section-ai-suggest-list-item {
	@include largeText();
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 6px $spacer;
	border-bottom: 1px solid $gray-base-80;
	color: $gray-base-30;
	fill: $gray-base-30;
	cursor: pointer;
	&:hover,
	&:active {
		background-color: $yc-blue-90;
	}
	&:active {
		color: $yc-blue;
		fill: $yc-blue;
	}
}
