// Chart controls (zoom, reset, etc)

.chart-zoom-controls {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 5px;
    @include media-breakpoint-up(lg) {
        top: 0;
        right: 0;
        padding: 10px;
    }

    .chart-zoom-controls-item {
        margin-left: 10px;
        z-index: 3;

        &:first-child {
            margin-left: 0;
        }
        @include media-breakpoint-up(lg) {
            margin-left: 10px;
        }
        &.chart-zoom-controls-info {
            padding: 3px 10px;
            font-size: 11px;
            line-height: 1.091;
            border: 1px solid $gray-base-90;
            background-color: $panel-background;
            text-align: center;
            color: $gray-base-30;
            min-width: 74px;
        }

        .btn {
            &:not(.dropdown-toggle) {
                @include base-width();
                padding: 0;
                &:hover,
                &:focus {
                    background-color: $yc-blue;
                    fill: #fff;
                }
            }
        }
    }
}
