
$stock-padding: $spacer;
$stock-label-size: 17px;

.panel-stocks {
    .chart-control {
        justify-content: flex-start;
        width: auto;
        border: 0;
    }

    .panel-content {
        flex: auto;
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
    }

    .events-table,
    .news-body {
        min-height: 320px;
    }

    .events-wrap,
    .news-wrap {
        flex: auto;
        display: flex;
        flex-direction: column;
        height: 100%;

        .panel-header {
            margin-right: $grid-gutter-width;
            margin-left: $grid-gutter-width;
            padding-left: 0;
            padding-right: 0;
            border-bottom-color: $gray-base-90;

            .panel-actions {
                margin-right: 0;

                .nav {
                    padding-right: 0;
                }
            }
        }
    }

    .events-table {
        flex: auto;
        height: 100px;
        min-height: 0;
        overflow: auto;
        margin-bottom: $grid-gutter-width;
    }

    .news {
        flex: auto;
        display: flex;
        flex-direction: column;
    }

    .news-title, .news-source {
        text-transform: none;
    }

    .news-title {
    }

    .news-body {
        flex: auto;
        height: 200px;
        max-height: none;
    }
}

.stocks-row {
    position: relative;
    padding-top: 20px;
    .panel-data-empty-message {
        top: 112px;
    }
}

.stocks {
    padding-top: $spacer * 1.5;
}

.stocks-card {
    position: relative;
    margin-bottom: 20px;
    border: 1px solid $gray-base-90;
    border-left: 0;
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 0;
        height: 100%;
        border-right: 1px solid $gray-base-90;
        z-index: 1;
        content: "";
    }

    .stocks-header {
        padding-left: $spacer;
        padding-right: $spacer;
    }

    .stock-content > span {
        display: block;
        @include media-breakpoint-up(md) {
            display: inline-block;
        }
    }
}

.stocks-header {
    display: flex;
    justify-content: space-between;

    svg {
        margin-right: $spacer;
        fill: currentColor;
    }

    .panel-date {
        margin-left: $spacer;
    }
}

.stocks-title {
    @include headerDefault();
    display: flex;
    align-items: baseline;
    margin-bottom: $spacer;
}

a.stocks-title {
    @include underline();
}

.stocks-body {
    position: relative;
    .panel-data-empty-message {
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.stocks-footer {
    display: flex;
    align-items: center;

    .stock-label {
        margin-right: $spacer;
        min-width: 0;
        padding-left: 2px;
        padding-right: 2px;
    }
}

.stock {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: $stock-padding;
    padding-bottom: $stock-padding;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    &:hover {
        text-decoration: none;
        color: $gray-base;
        &:before {
            position: absolute;
            top: 1px;
            left: 1px;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            box-shadow: 0 0 0 1px $gray-base-10;
            content: "";
        }
    }
}


.stock-header,
.stock-content {
    padding-left: $stock-padding;
    padding-right: $stock-padding;
}

.stock-header {
    display: flex;
    align-items: center;
    margin-bottom: 2px;

    .stock-label {
        margin-left: auto;
    }
}

.stock-title {
    @include chickletSecurityName();
}

.stock-descr {
    @include dataAboveChart();
    flex: auto;
}

.stock-label {
    @include chartAxis();
    flex: none;
    height: $stock-label-size;
    min-width: $stock-label-size;
    padding-left: 4px;
    padding-right: 4px;
    background-color: $gray-base-95;
    border: 1px solid $gray-base-80;
    border-radius: $stock-label-size;
    text-align: center;
    line-height: $stock-label-size - 2;
}

.stock-content {
    flex: auto;
    display: flex;
    flex-direction: column;
}
