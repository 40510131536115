@use "sass:math";

$toolbar-shadow: 2px 4px 4px rgba($black, .25);

.toolbar {
    position: absolute;
    background-color: #fff;
    z-index: 3;
    box-shadow: $toolbar-shadow;

    &.toolbar-left-center {
        top: 50%;
        right: 100%;
        transform: translate(0, -50%);
    }

    &.toolbar-collapsible {
        transition: .1s ease all;
        box-shadow: none;
    }

    &.is-opened {
        box-shadow: $toolbar-shadow;
        transform: translate(100%, -50%);

        .toolbar-toggler:after {
            transform: rotate(180deg);
        }
    }

    .dropdown-toggle {
        &:after {
            color: $gray-base-50;
        }
    }
}

.toolbar-vertical {
    .tool {
        .btn {
            margin-top: -1px;
        }
    }

    .dropdown-menu {
        top: 0;
        left: 100%;
    }
}

.toolbar-horizontal {
    display: flex;

    .tool:not(:first-child) {
        .btn {
            border-left: 0;
        }
    }
}

.toolbar-toggler {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-1px, -50%);
    width: 16px;
    height: 45px;
    display: flex;
    align-items:center;
    justify-content: center;
    background-color: #fff;
    color: $gray-base-50;
    cursor: pointer;
    box-shadow: 2px 4px 4px rgba($black, .25);
    z-index: -1;
    &:after { // Caret is pointing to left
        display: block;
        width: 0;
        height: 0;
        border-top: $caret-width * 0.5 solid transparent;
        border-right: 0;
        border-bottom: $caret-width * 0.5 solid transparent;
        border-left: math.div($caret-width, 1.5) solid;
        content: "";
    }
    &:hover,
    &:active {
        background-color: $yc-blue-90;
    }
}

.tool-group {
    display: flex;

    .tool {
        &:not(:last-child) {
            .dropdown-toggle {
                border-right-color: transparent;
                &:after {
                    content: none;
                }
            }
        }
    }
}

.tool {
    & > .btn {
        display: flex;
        justify-content: space-between;
        height: 52px;
        @include media-breakpoint-up(lg) {
            height: 44px;
        }
        &.btn-secondary {
            &:active,
            &:hover, 
            &:focus, 
            &.active {
                border-color: $gray-base-75;
            }
        }
    }
}

.tool-icon {
    width: 19px;
    font-size: 19px;
}

.tool-bg {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 18px;
    height: 18px;
    border: 1px solid $gray-base-40;
}

.tool-bg-bar {
    position: relative;
}

.toolbar-dragger {
    .btn {
        cursor: move;
    }
}

.tool-title {
    .btn {
        border-color: $gray-base-75;
        justify-content: center;
        cursor: default;
    }
}
