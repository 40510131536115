.footer {
	display: flex;
	justify-content: center;
	height: $footer-height-mobile;
	border-top: 1px solid $gray-base-80;
	background-color: $panel-background;
	@include media-breakpoint-up(lg) {
		height: $footer-height;
	}
}

.footer-menu {
	display: flex;
	margin-bottom: 0;
	padding-left: 0;
}

.footer-menu-item {
	position: relative;
	padding: $spacer $spacer * 2;
	list-style: none;
	&:not(:last-child) {
		&:after {
			position: absolute;
			left: 100%;
			top: 50%;
			transform: translate(0, -50%);
			display: block;
			height: 12px;
			border-left: 1px solid $gray-base-50;
			content: "";
		}
	}
	@include media-breakpoint-up(lg) {
		padding-top: 3px;
		padding-bottom: 3px;
	}
}

.footer-menu-link {
	display: block;
	padding-top: 6px;
	padding-bottom: 6px;
	@include media-breakpoint-up(lg) {
		padding-top: 3px;
		padding-bottom: 3px;
		font-size: 10px;
	}
	&:hover {
		color: $gray-base-10;
		text-decoration: underline;
	}
	&:active {
		text-decoration: none;
	}
	&:focus {
		color: $yc-blue;
	}
}
