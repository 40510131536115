.chart-vertical-bars {
    .chart-options {
        display: block;

        .chart-legend-item:first-child {
            padding-top: 0;
        }

        .chart-legend-item-title {
            font-size: .867rem; // 13px
            @include media-breakpoint-up(lg) {
                font-size: .857rem; // 12px
            }
            @include media-breakpoint-down(lg) {
                &:before, &.chart-bullet {
                    top: 6px;
                }
            }
        }
    }

    .chart-canvas {
        display: flex;
        border: 0;
        min-height: 0;
        height: auto;
        outline: 1px solid $gray-base-90;
        background: linear-gradient(180deg, $gray-base-90 0, transparent 1px);
        background-clip: border-box;
        background-size: 100% 20%;
        background-position: left bottom;
        box-sizing: content-box;
    }

    .chart-axis-y {
        flex: none;
        padding-right: 10px;
        margin-bottom: -.5em;
        margin-top: -.5em;
        &:first-child {
            width: 155px;
        }
    }

    .chart-column {
        position: relative;
        flex: 1;
        display: flex;
        height: 160px;
        padding-left: 10px;
        padding-right: 10px;
        justify-content: center;

        &:nth-child(even) {
            background-color: rgba(#000, .05);
        }

        &.bg-transparent {
            background-color: transparent;
        }

        &.is-highlighted {
            background-color: rgba(map-get($colors, purple-light), .5);
        }

        &:hover {
            .chart-column-popup {
                display: block;
                opacity: 1;
            }
        }
    }

    .chart-bar {
        flex: 1;
        display: flex;
        align-items: flex-end;
        max-width: 26px;
        transition: .3s ease opacity;
        &:hover {
            .chart-bar-fill {
                opacity: .75;
            }
        }
    }

    .chart-bar-fill {
        width: 100%;
        transition: .3s ease height;
    }

    .chart-data {

    }

    .chart-data-header {
        display: flex;
        border-bottom: 1px solid $gray-base-90;
    }

    .chart-data-title,
    .chart-data-cell {
        flex: 1;
        padding-top: 8px;
        padding-bottom: 8px;
        line-height: 1.35;
        text-align: center;
        @include media-breakpoint-up(lg) {
            padding-top: 5px;
            padding-bottom: 5px;
        }

        &:first-child {
            flex: none;
            width: 155px;
            text-align: left;
        }
    }
    .chart-data-title {
        @include headerDefault();
    }

    .chart-data-row {
        display: flex;
        border-bottom: 1px solid $gray-base-90;
    }

    .chart-data-cell {

    }

    & + .flows {
        margin-top: 30px;
    }

    &.empty {
        position: relative;

        .panel-data-empty-message {
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .panel-ad-cta {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            white-space: nowrap;
            background-color: #fff;
            z-index: 1;
        }
    }
}

.chart-column-popup {
    @include box-shadow;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    min-width: 90px;
    padding: 10px;
    border: 1px solid $gray-base-80;
    background-color: #fff;
    opacity: 0;
    z-index: 1;
    transition: .3s ease opacity;

    .chart-legend-item-title {
        white-space: nowrap;
        margin-right: 10px;
    }
}

.chart-column-popup-item {
    @extend .highcharts-tooltip-item;
}

.chart-column-popup-item-value {
    font-weight: 700;
}
