@use "sass:math";

.header {
    position: relative;
    flex: none;
    display: flex;
    background-color: $header-bg;
    z-index: 11;
    @include media-breakpoint-up(lg) {
        height: $header-height;
    }

    @include media-breakpoint-down(sm) {
        &.is-search-mode {
            .header-message,
            .header-sidemenu,
            .header-button,
            .header-toggler {
                display: none;
            }

            .header-search .field-search {
                max-width: none;
                &.is-centered {
                    position: relative;
                    left: auto;
                    transform: none;
                }
            }

            .header-search-close {
                display: flex;
            }

            .dropdown-menu {
                width: 100%;
            }
        }
    }
}

.header-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    @include media-breakpoint-up(md) {
        flex-wrap: nowrap;
    }
    @include media-breakpoint-up(xl) {
        padding-left: 50px;
        padding-right: 50px;
    }
}

.header-logo-wrap {
    display: flex;
    align-items: center;
    height: $header-height-mobile;
    padding-left: $grid-gutter-width;
    padding-right: 24px;
    @include media-breakpoint-up(lg) {
        align-items: stretch;
        height: $header-height;
        // padding-right: 40px;
    }
    @include media-breakpoint-up(xl) {
        padding-left: 0;
    }
}

.header-logo {
    padding: 4px;
    background-color: $yc-blue;
    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        padding: 0;
        background-color: transparent;
    }
    @include header-focus();
    &:before {
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        @include media-breakpoint-up(lg) {
            height: calc(100% - 4px);
        }
    }

    img {
        display: block;
    }
}


// SEARCH

.header-search {
    flex: auto;
    flex: 1 1 140px;
    display: flex;
    align-items: center;

    .field-search {
        max-width: 205px;
        margin-left: auto;
        &.is-centered {
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
        }
        @include media-breakpoint-up(md) {
            margin-left: 0;
            margin-right: auto;
        }
        @include media-breakpoint-up(lg) {
            min-width: 190px;
            &.compact {
                min-width: 105px;
            }
        }
        @include media-breakpoint-up(xl) {
            max-width: 18vw;
            margin-left: auto;
            margin-right: auto;
            &.compact {
                min-width: auto;
            }
        }
        @media (min-width: map-get($container-max-widths, xl)) {
            max-width: 288px !important;
        }
        @media (min-width: map-get($container-max-widths, xxl)) {
            max-width: 750px !important;
        }
    }

    .form-control {
        height: $base-height-desktop;
        padding-top: 4px;
        padding-bottom: 4px;
        @include media-breakpoint-up(lg) {
            font-size: 1em;
            @include placeholder {
                font-size: 1em;
            };
        }
    }

    .btn {
        height: $base-height-desktop;
        padding-top: 4px;
        padding-bottom: 4px;
    }
}

.header-search-close {
    display: none;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
    color: #fff;
    cursor: pointer;
    &:hover,
    &:focus,
    &:active {
        color: $header-color;
    }
}

.header-search-dropdown {
    min-width: 490px;
}


.dropdown-item.bg-ai {
    @include bg-perlmutter();
}

// MENUS

.header-mainmenu .dropdown,
.header-sidemenu .dropdown {
    &.show > .dropdown-toggle {
        background-color: $header-color-dark !important;
        color: #fff;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: $header-color-dark;

        & > .dropdown-toggle {
            color: #fff;
        }

        .dropdown-menu {
            @include media-breakpoint-up(md) {
                display: block;
            }
        }
    }

    .dropdown-menu {
        border: 0;
        background-color: $header-color-dark;
    }

    .dropdown-item {
        @include base-height();
        @include headerDefault();
        letter-spacing: .02em;
        color: #fff;
        &:hover,
        &:focus,
        &:active {
            background-color: $yc-blue;
        }
        @include media-breakpoint-up(lg) {
            line-height: $base-height-desktop;
        }
    }
}

// to let the dropdown menu works at all breakpoints
.header-sidemenu .dropdown {
    &:hover,
    &:focus,
    &:active {
        .dropdown-menu {
            display: block;
        }
    }
}


// MAIN MENU

.header-mainmenu {
    flex: none;
    display: none;
    order: 1;

    @include media-breakpoint-up(md) {
        display: flex;
        order: 0;
        margin-right: 10px;
    }

    .dropdown-menu {
        width: 100%;
        overflow: hidden;

        .header-container {
            padding-bottom: 20px;
        }

        .header-logo-wrap {
            display: none;
            @media (min-width: 1300px) {
                display: block;
            }
        }

        .row {
            flex: auto;
            display: block;
            padding-left: 20px;
            padding-right: 20px;
            @include media-breakpoint-up(md) {
                display: flex;
            }
            @include media-breakpoint-up(lg) {
                padding-left: $spacer * 2;
                padding-right: $spacer * 2;
            }
        }

        .col {
            flex-basis: 100%;
            display: inline-block;
            width: 100%;
            @include media-breakpoint-up(md) {
                flex: 0 0 140px;
            }
            @include media-breakpoint-up(lg) {
                flex: 0 0 155px;
            }
        }

        .divider-vertical {
            border-color: $gray-base-40;
        }
    }

    &.show {
        // mobile styles
        @include media-breakpoint-down(sm) {
            position: relative;
            z-index: 100;
            display: flex;
            flex: 0 0 100%;
            flex-direction: column;
            background-color: $header-color-dark;

            .header-mainmenu-item {
                flex-direction: column;

                &.dropdown.show {
                    .header-mainmenu-link {
                        background-color: $yc-blue;
                    }
                }

                &:not(:last-child) {
                    border-bottom: 1px solid $gray-base-50;
                }

                .dropdown-menu {
                    position: static;
                    box-shadow: none;
                    margin-top: 0;
                    padding: 0;
                }

                .dropdown-divider {
                    margin-right: 15px;
                    margin-left: 15px;
                }
            }

            .header-mainmenu-link {
                width: 100%;
                min-height: 68px;
                justify-content: space-between;
                color: #fff;
                &.active {
                    &:after {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}

// supernav content

.mainmenu-section {
    padding-top: 20px;
    padding-bottom: 10px;
}

.mainmenu-section-title {
    @include buttonTextDefault();
    margin-bottom: $spacer;
    color: $gray-base-70;
}

.mainmenu-section-item {
    display: flex;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    @include media-breakpoint-up(lg) {
        padding: 0;
    }
    &:not(:last-child) {
        margin-bottom: math.div($spacer, 2);
        @include media-breakpoint-up(lg) {
            margin-bottom: $spacer;
        }
    }
    &:hover {
        .mainmenu-section-item-text {
            text-decoration: underline;
        }
    }
    &:focus-visible {
        outline: none;
        .mainmenu-section-item-text {
            &:before {
                content: "";
            }
        }
    }

    [class*="icon-"] {
        margin-left: 4px;
        vertical-align: middle;
        fill: #fff;
    }
}

.mainmenu-section-item-text {
    @include header-focus;
    font-size: 14px;
    color: #fff;
    &:before {
        width: calc(100% + 10px);
        height: calc(100% + 2px);
        min-height: 24px;
    }
}

.header-mainmenu-item {
    position: static;
    display: flex;
    &:hover {
        background-color: $header-color-dark;
    }

    .dropdown-item-text {
        padding-right: 15px;
        padding-left: 15px;

        [class*="icon-"] {
            margin-left: 18px;
            vertical-align: middle;
            fill: #fff;
            @include media-breakpoint-up(lg) {
                margin-left: 14px;
            }
        }

        .align-middle {
            vertical-align: middle;
        }
    }

    .dropdown-divider {
        margin: 0;
        border-bottom-color: $gray-base-50;
    }
}

.header-mainmenu-link {
    display: flex;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 17px;
    font-weight: 700;
    color: $header-color;
    text-transform: uppercase;
    &.dropdown-toggle:after {
        @include media-breakpoint-up(md) {
            content: none;
        }
    }
    &:hover,
    &:focus,
    &:active {
        color: $white;;
    }
    @include media-breakpoint-up(md) {
        font-size: 14px;
    }
    @include media-breakpoint-up(lg) {
        padding-right: $spacer * 2;
        padding-left: $spacer * 2;
    }
    @include header-focus();
}

.mainmenu-featured {
    display: none;
    @include media-breakpoint-up(lg) {
        display: flex;
        flex: 0 0 180px;
    }
    @media (min-width: 1560px) {
        flex: 0 0 235px;
    }
}

.mainmenu-refer {
    .mainmenu-section-item-text {
        font-size: 13px;
    }

    .btn {
        text-transform: none;
    }
}

// USER MENU

.header-usermenu {
    .dropdown-item.btn-wrap {
        padding-right: $grid-gutter-width;
        padding-left: $grid-gutter-width;
        cursor: initial;
        @include media-breakpoint-up(lg) {
            height: auto;
        }
        &:hover {
            background-color: transparent;
        }

        .btn {
            height: $base-height-desktop;
            line-height: $base-height-desktop;
            margin-top: 5px;
            margin-bottom: 5px;
            padding: 0 $grid-gutter-width;
            font-size: inherit;
            font-weight: inherit;
            text-transform: inherit;
        }
    }

    .btn-success,
    a.btn-success {
        &:not(:hover) {
            @include media-breakpoint-up(md) {
                outline: 1px solid $gray-base-80;
            }
        }
    }

    .btn-primary,
    a.btn-primary {
        &:not(:hover) {
            @include media-breakpoint-up(md) {
                outline: 1px solid $yc-blue-75;
            }
        }
    }

    .dropdown-item-text {
        &:only-child {
            padding-right: $grid-gutter-width;
            padding-left: $grid-gutter-width;
        }
    }
}

.header-usermenu-title {
    display: flex;
    align-items: center;
    min-width: 0;
    padding-right: 20px;
    padding-left: 15px;
}

.user-name {
    @include headerDefault();
    cursor: default;
    margin-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $header-color;
}

// SIDE MENU

.header-sidemenu {
    flex: none;
    display: flex;
    justify-content: flex-end;
    min-width: 0;
    padding-left: 24px;
    // margin-left: auto;
    @include media-breakpoint-up(lg) {
        order: 1;
    }
}

.sidemenu-item {
    flex: none;
    display: flex;
    min-width: 0;
    padding-left: $grid-gutter-width * 0.5;
    padding-right: $grid-gutter-width * 0.5;
    color: $header-color-medium;
    &:focus {
        outline: none;
    }
    &:hover,
    .active {
        .sidemenu-icon:not([class*=text]) {
            color: #fff;
        }
    }
    @include header-focus();
    &.btn-wrap {
        align-items: center;
    }

    .dropdown-toggle {
        display: flex;
        min-width: 0;

        &:after {
            content: none;
        }
    }

    button:focus {
        outline: none;
    }
}

.sidemenu-item-text {
    display: flex;
    align-items: center;
    font-weight: 700;
}

.sidemenu-item-link {
    display: flex;
    align-items: center;
    font-weight: 700;
    color: $header-color;
    &:hover,
    &:focus,
    &:active {
        color: #fff;
    }
}

.sidemenu-icon {
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    padding: 0;
    border: 0;
    text-align: center;
    fill: currentColor;
    cursor: pointer;

    [class*="icon-"] {
        @include media-breakpoint-up(lg) {
            font-size: 1.1rem;
        }
    }

    .badge {
        right: -5px;
    }
}

.sidemenu-item-usermenu-toggler {
    background-color: inherit;
    color: inherit;
    border: 0;
    flex: 0 1 auto;
    align-items: center;
    &:hover {
        .sidemenu-icon {
            background-color: #fff;
        }

        .user-name {
            color: #fff;
        }
    }

    .sidemenu-icon {
        border-radius: 50%;
        background-color: $header-color-medium;
        fill: $header-bg;
    }
}

.sidemenu-item-pendo {
    align-items: center;
}

._pendo-resource-center-close-button, 
._pendo-resource-center-left-caret, 
._pendo-resource-center-right-caret {
    cursor: pointer;
}

.sidemenu-item-notification {
    .notifications-badge {
        right: -12px !important;
        top: -4px !important;
        height: 18px;
        min-width: 18px;
        line-height: 1;
        padding: 0;
        font-size: 12px;
    }
}

.sidemenu-item-pendo {
    .pendo-widget-icon {
        line-height: inherit;
        .pendo-resource-center-badge-notification-bubble {
            right: -1px !important;
            top: 6px !important;
            height: 18px !important;
            left: inherit !important;
            width: auto !important;
            min-width: 18px !important;
            display: inline-flex !important;
            background-color: #d2222a !important;
            &:before {
                display: none !important;
                animation: none !important;
            }
            .pendo-notification-bubble-unread-count {
                font-size: 12px !important;
                font-weight: 700 !important;
                line-height: 17px !important;
                top: 0px !important;
                right: 0px !important;
                width: 100% !important;
            }
        }
        &:after {
            right: auto;
            left: -0.5em;
            min-width: 16px;
            height: 16px;
            line-height: 16px;
            font-size: 12px;
            font-weight: 700;
            @include media-breakpoint-up(lg) {
                min-width: 14px;
                height: 14px;
                line-height: 14px;
                font-size: 11px;
            }
        }
    }
}

.sidemenu-item-chat {
    align-items: center;
}

.sidemenu-item-notification {
    .sidemenu-icon {
        align-items: center;
        height: 100%;
        background-color: inherit;
        color: inherit;
        @include header-focus();
        &:before {
            width: calc(100% + 20px);
        }
    }
}

.sidemenu-wrapper {
    position: relative;
}

// get pendo unread badge color to look like notifications in dark mode
.dark-mode .sidemenu-item-pendo {
    .pendo-widget-icon {
        .pendo-resource-center-badge-notification-bubble {
            background-color: #A81B22 !important;
        }
    }
}

.header-usermenu-toggler-wrap {
    display: flex;
    align-items: center;
    min-width: 0;
    pointer-events: none;
}

.header-message {
    flex: none;
    display: flex;
    white-space: nowrap;
    font-weight: 700;
}

.sidemenu-item-user {
    .sidemenu-item-text {
        padding-right: $grid-gutter-width;
    }

    .btn {
        text-transform: none;
        white-space: nowrap;
    }
}

// BUTTON
.header-button {
    height: $base-height-desktop;
    margin-right: 14px;
}


// HAMBURGER

.header-toggler {
    display: flex;
    align-items: center;
    padding: 0 $grid-gutter-width 0 $grid-gutter-width * 0.5;
    @include media-breakpoint-up(md) {
        display: none;
    }
}

.header-mainmenu-toggler-button {
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    @include header-focus();
    &:before {
        width: calc(100% + 20px);
        height: calc(100% + 12px);
    }

    .line{
        width: 26px;
        height: 4px;
        background-color: $header-color-medium;
        display: block;
        margin: 5px auto;
        transition: all .3s ease-in-out;
    }

    &:hover,
    &:focus,
    &:active {
        outline: none;
    }

    &.is-active .line {
        // background-color: #fff;
        &:nth-child(2) {
            opacity: 0;
        }
        &:nth-child(1) {
            transform: translateY(9px) rotate(45deg);
        }
        &:nth-child(3) {
            transform: translateY(-9px) rotate(-45deg);
        }
    }
}
