// Embedded icons from Open Iconic.
// Released under MIT and copyright 2014 Waybury.
// https://useiconic.com/open


// Checkboxes and radios
//
// Base class takes care of all the key behavioral aspects.

.custom-control {
    position: relative;
    display: inline-flex;
    min-height: (1rem * $line-height-base);
    padding-left: 1.5rem;
    margin-right: $grid-gutter-width;
    cursor: pointer;

    &:hover {
        .custom-control-indicator {
            background-color: $gray-base-90;
        }
        .custom-control-input:disabled ~ .custom-control-indicator {
            background-color: #fff;
        }
    }

    &.disabled {
        color: $gray-base-60;
        cursor: default;
    }

    &.disabled:hover {
        .custom-control-input ~ .custom-control-indicator {
            background-color: #fff;
        }
    }

    &.mr-0,
    &.custom-control-empty {
        margin-right: 0;
    }

    &.mr-1 {
        margin-right: $spacer;
    }
}



.custom-control-input {
    position: absolute;
    z-index: -1; // Put the input behind the label so it doesn't overlay text
    opacity: 0;

    &:checked ~ .custom-control-indicator {
    }

    &:active ~ .custom-control-indicator,
    &:focus ~ .custom-control-indicator {
        border-color: $yc-blue;
    }

    &:disabled {
        ~ .custom-control-indicator {
        }

        ~ .custom-control-description {
        }
    }
}

// Custom indicator

.custom-control-indicator {
    position: absolute;
    left: 0;
    display: block;
    background-color: #fff;
    border: 1px solid $gray-base-70;
    pointer-events: none;
    user-select: none;
    &,
    &:before {
        transition: .1s ease all;
    }
}

// Text input aside radio or checkbox

.custom-control-description {
    .form-control {
        margin-top: -9px;
        @include media-breakpoint-up(lg) {
            margin-top: -5px;
        }
    }

    .icon-portfolio {
        font-size: 11.2px;
    }

    .badge {
        vertical-align: text-top;
        margin-left: 4px;
    }
}

// Checkboxes

.custom-checkbox {
    padding-left: 1.5rem;

    &.checkbox-single {
        padding-left: 0;
        padding-right: 12px;
        margin-right: 0;
    }

    .custom-control-indicator {
        top: 4px;
        width: $custom-checkbox-indicator-size;
        height: $custom-checkbox-indicator-size;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        @include media-breakpoint-up(lg) {
            top: 5px;
            width: $custom-checkbox-indicator-size-desktop;
            height: $custom-checkbox-indicator-size-desktop;
        }
        &.disabled {
            background-color: $gray-base-90;
        }
    }

    .custom-control-input:checked ~ .custom-control-indicator,
    .custom-control-input[checked] ~ .custom-control-indicator {
        background-color: $yc-blue;
        border-color: $yc-blue;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iZW1iZWQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjAwcHgiIGhlaWdodD0iMjAwcHgiIHZpZXdCb3g9IjAgMCAyMDAgMjAwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyMDAgMjAwIiB4bWw6c3BhY2U9InByZXNlcnZlIj48dGl0bGU+SWNvbnMtUHJvZHVjdGlvbjwvdGl0bGU+PHJlY3QgZmlsbD0ibm9uZSIgd2lkdGg9IjIwMCIgaGVpZ2h0PSIyMDAiLz48cG9seWdvbiBmaWxsPSIjRkZGRkZGIiBwb2ludHM9IjE2NS4yLDUxLjIgMTQ5LjQsMzkgMTQ3LjgsMzcuNyAxNDYuNiwzOS4zIDc5LjQsMTI2LjUgNTEuMyw5OS4yIDQ5LjksOTcuOCA0OC41LDk5LjIgMzQuNiwxMTMuNiAzMy4yLDExNSAzNC42LDExNi40IDgwLjQsMTYwLjkgODIsMTYyLjUgODMuMywxNjAuNyAxNjUuNiw1NCAxNjYuOCw1Mi40ICIvPjwvc3ZnPg==);
        background-repeat: no-repeat;
    }

    .custom-control-input:checked.disabled ~ .custom-control-indicator,
    .custom-control-input[checked].disabled ~ .custom-control-indicator,
    .custom-control-input[disabled]:checked ~ .custom-control-indicator,
    .custom-control-input[checked][disabled] ~ .custom-control-indicator {
        background-color: $gray-base-50;
        border-color: $gray-base-50;
    }

    &.custom-checkbox-style1 {
        .custom-control-indicator {
            border: 0;
            background: transparent;
            background-size: 100%;
        }

        .custom-control-input:checked ~ .custom-control-indicator,
        .custom-control-input[checked] ~ .custom-control-indicator {
            background-color: transparent;
            background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iZW1iZWQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDExLjkzIDEyLjUyIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6IzAyODJlODt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPkljb25zLVByb2R1Y3Rpb248L3RpdGxlPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTQuNTksMTIuNC4zMSw4LjExQS43NS43NSwwLDEsMSwxLjM3LDcuMDVsMywzTDEwLjQ2LjQzYS43NS43NSwwLDEsMSwxLjI2LjgxWiIvPjwvc3ZnPg==);
        }
    }

    &.custom-checkbox-large {
        padding-top: 4px;
        padding-left: 30px;

        .custom-control-indicator {
            width: 20px;
            height: 20px;
        }
    }

  // .custom-control-input:indeterminate ~ .custom-control-indicator {
  //   background-color: $custom-checkbox-indicator-indeterminate-bg;
  //   background-image: $custom-checkbox-indicator-icon-indeterminate;
  //   @include box-shadow($custom-checkbox-indicator-indeterminate-box-shadow);
  // }
}

// Radios

.custom-radio {
    .custom-control-indicator {
        top: 3px;
        width: $custom-radio-indicator-size;
        height: $custom-radio-indicator-size;
        border-radius: 50%;
        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: $custom-radio-indicator-size * 0.5;
            height: $custom-radio-indicator-size * 0.5;
            background-color: transparent;
            border-radius: 50%;
            content: "";
        }
        @include media-breakpoint-up(lg) {
            width: $custom-radio-indicator-size-desktop;
            height: $custom-radio-indicator-size-desktop;
            &:before {
                width: $custom-radio-indicator-size-desktop * 0.5;
                height: $custom-radio-indicator-size-desktop * 0.5;
            }
        }
    }

    .custom-control-input {
        &:checked ~ .custom-control-indicator {
            &:before {
                background-color: $yc-blue;
            }
        }
        &:disabled ~ .custom-control-indicator {
            border-color: $gray-base-80 ;
        }
        &:disabled:checked ~ .custom-control-indicator:before {
            background-color: $gray-base-80 ;
        }
    }

    &.disabled {
        .custom-control-input {
            & ~ .custom-control-indicator {
                border-color: $gray-base-80 ;
            }
            &:checked ~ .custom-control-indicator:before {
                background-color: $gray-base-80 ;
            }
        }
    }

    &.custom-radio-rich {
        width: 100%;
        margin-right: 0;
        padding-left: 2rem;

        .custom-control-indicator {
            top: 50%;
            transform: translate3d(0, -50%, 0);
        }
    }
}

.text-large {
    .custom-radio .custom-control-indicator {
        top: 5px;
    }
}

// Indicator placed to right

.custom-control-right {
    padding-right: 1.5em;
    padding-left: 0;

    .custom-control-indicator {
        left: auto;
        right: 0;
    }

    &.linked-list-item-header {
        margin-right: 0;
        .custom-control-indicator {
            top: 15px;
            right: 20px;
            @include media-breakpoint-up(lg) {
                top: 10px;
            }
        }
    }

    .linked-list-item-header & {
        margin-right: 0;
    }
}


// Layout options

.custom-controls-stacked {
  display: flex;
  flex-direction: column;

  .custom-controls-stacked {
    margin-left: 20px;
  }

  .custom-control {
    margin-bottom: 12px;
    margin-right: 0;
    @include media-breakpoint-up(lg) {
        margin-bottom: 8px;
    }

    + .custom-control {
      margin-left: 0;
    }
    &:last-child {
        margin-bottom: 6px;
    }
  }

  .dropdown-toggle:after {
    color: $gray-base-50;
  }
}

// Custom Switch

$custom-switch-border:          4px;
$custom-switch-toggler-size:    16px;
$custom-switch-toggler-size-lg: 10px;

%custom-switch-hovered {
    .custom-control-indicator {
        color: $gray-base-80;
        background-color: currentColor;
    }

    .custom-control-input:checked ~ .custom-control-indicator,
    .custom-control-input[checked] ~ .custom-control-indicator {
        color: $yc-blue-25;
    }
}

.custom-switch {
    height: $custom-switch-toggler-size + $custom-switch-border * 2;
    min-height: auto;
    margin-right: 0;
    padding-left: $custom-switch-toggler-size * 2 + $custom-switch-border * 2;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
        height: $custom-switch-toggler-size-lg + $custom-switch-border * 2;
        padding-left: $custom-switch-toggler-size-lg * 2 + $custom-switch-border * 2;
    }

    .custom-control-input {
        right: 0;

        &:active ~ .custom-control-indicator,
        &:focus ~ .custom-control-indicator {
            border-color: currentColor;
        }
    }

    .custom-control-indicator {
        width: 100%;
        height: 100%;
        border: $custom-switch-border solid currentColor;
        border-radius: $custom-switch-toggler-size;
        background-color: currentColor;
        color: $gray-base-70;

        &:before {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
            display: block;
            width: $custom-switch-toggler-size;
            height: $custom-switch-toggler-size;
            background-color: #fff;
            border-radius: 50%;
            content: "";
            @include media-breakpoint-up(lg) {
                width: $custom-switch-toggler-size-lg;
                height: $custom-switch-toggler-size-lg;                
            }
        }
    }

    &:hover {
        @extend %custom-switch-hovered;
    }

    .custom-control-input:checked ~ .custom-control-indicator,
    .custom-control-input[checked] ~ .custom-control-indicator {
        color: $yc-blue;
        &:before {
            transform: translate(100%, -50%);
        }
    }

    &.disabled {
        pointer-events: none;
        
        .custom-control-indicator {
                color: $gray-base-90;
            &:before {
                background-color: $gray-base-70;
            }
        }
    }
}

.custom-switch-wrapper {
    .custom-switch {
        pointer-events: none;
    }
    &:hover {
        .custom-switch {
            @extend %custom-switch-hovered;
        }
    }
}
