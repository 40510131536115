.panel {
    position: relative;
    border: 1px solid $gray-base-80;
    border-top-width: 6px;
}

.panel-data {
    margin-bottom: 20px;
    &.mb-1 {
        margin-bottom: $spacer;
    }

    .panel-header {
        width: auto;
        padding-top: 0;
        padding-bottom: 0;
        border-bottom: 1px solid $gray-base-80;
        box-sizing: content-box;

        & * {
            box-sizing: border-box;
        }
    }

    .panel-title {
        &:only-child {
            padding-top: 8px;
            padding-bottom: 8px;
            @include media-breakpoint-up(md) {
                padding-top: 4px;
                padding-bottom: 4px;
            }
        }
    }

    .panel-actions {
        align-self: stretch;
        margin-right: -$grid-gutter-width;
    }

    .panel-action {
        padding-top: 0;
        padding-bottom: 0;
        &:not(:first-child) {
            &:before {
                @extend .divider-vertical;
                height: 28px;
                content: "";
            @include media-breakpoint-up(lg) {
                    height: 20px;
                }
            }
        }

        .btn:not(.btn-primary) {
            border: 0;
            &:active,
            &:hover,
            &:focus,
            &.active {
                background-color: $yc-blue-90;
            }
        }

        p {
            margin-bottom: 0;
            padding-right: 12px;
            padding-left: 12px;
            @include media-breakpoint-up(lg) {
                padding-right: 9px;
                padding-left: 9px;
            }

            a {
                color: $yc-blue;
                white-space: nowrap;
            }
        }


        & > .custom-checkbox {
            min-height: auto;
            padding-top: 3px;

            .custom-control-description {
                font-size: 12px;
                text-transform: uppercase;
                color: $gray-base-50;
            }
            .custom-control-indicator {
                top: 5px;
            }
        }

        &:not(:last-child) .custom-checkbox {
                margin-right: 10px;
        }
    }

    .panel-actions-chart-view {
        .btn-secondary {
            [class*="icon"] {
                fill: #999;
            }
            &.active {
                [class*="icon"] {
                    fill: #666;
                }
            }
        }
    }

    .panel-content {
        position: relative;

        p {
            &:first-child {
                margin-top: 8px;
                @include media-breakpoint-up(lg) {
                    margin-top: 4px;
                }
            }
        }

        hr {
            margin: 0;
            border: 0;
            border-bottom: 2px solid $gray-base-80;
            &.hr-table-border {
                border-bottom: $table-border;
            }
        }

        .chart-progress-wrap {
            &:first-child {
                margin-top: 15px;
            }
            & + [class*='col'] {
                @include media-breakpoint-up(lg) {
                    margin-top: 5px;
                }
            }
        }
    }
}

.panel-data-empty-message {
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    @include headerDefault();
    padding: 6px 12px;
    border: 1px solid $gray-base-60;
    text-align: center;
    background-color: #fff;
    white-space: nowrap;
    @include media-breakpoint-up(lg) {
        top: 45px;
    }

    &.static {
        position: static;
        display: inline-block;
        transform: none;
    }

    &.ajust-vertical {
        top: 75px;
        @include media-breakpoint-up(lg) {
            top: 55px;
        }
    }
}


.locked {
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: calc(100% + 6px);
        background: linear-gradient(0deg, $yc-blue-95 0%, rgba(#fff, .9) 45%, rgba(#fff, .8) 100%);
        z-index: 9;
        content: "";

    }
    &.panel-wrap:before {
        top: -6px;
    }
}

.panel-locked-message {
    @include keyStatTitle();
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    z-index: 10;

    .btn {
        @include keyStatTitle();
        font-weight: 400;
        text-transform: capitalize;
    }
}

.panel-locked-message-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-height: 480px;
}

.panel-collapsible-spacer {
    margin-left: 50px;
    @include media-breakpoint-up(lg) {
        margin-left: 40px;
    }
}

.panel-collapsible {
    background-color: $panel-background;

    .panel-header {
        position: relative;

        .divider-horizontal {
            @extend .panel-collapsible-spacer;
        }
    }

    .panel-title {
        @include base-min-height();
        @include base-line-height();
        padding-left: 50px;
        @include media-breakpoint-up(md) {
            &.panel-title-inline-md {
                padding-left: 0;
            }
        }
        @include media-breakpoint-up(lg) {
            padding-left: 40px;
            &.panel-title-inline-lg {
                padding-left: 1em;
                border-left: 1px solid $gray-base-90;
            }
        }
    }

    .panel-content {
        .options-list-item-controls + .options-list-item-title {
            padding-left: 20px;
            @include media-breakpoint-up(lg) {
                padding-left: 0;
            }
        }
    }
}

.panel-date-summary .panel-title {
    @include base-min-height();
    @include base-line-height();
}

.panel-over {
    border: 1px solid transparent;
    border-bottom-width: 0;
    padding: 3px 8px;
}

.panel-header {
    @include base-min-height();
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
    padding-top: 2px;
    padding-bottom: 2px;
    @include media-breakpoint-up(lg) {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    &.row {
        width: auto;
        @include media-breakpoint-up(lg) {
            display: flex !important;
        }

        .panel-actions {
            & + .panel-actions {

                &:before {
                    @extend .divider-vertical;
                    margin-left: 15px;
                    height: 28px;
                    content: "";
                    @include media-breakpoint-up(lg) {
                        height: 20px;
                    }
                }
            }
        }

        .panel-action:only-child {
            flex: auto;
        }
    }

    &.panel-header-multiactions {
        flex-direction: column;
        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }

        .panel-title {
            margin-right: 0;
            @include base-min-height();
            align-self: start;
            @include media-breakpoint-up(lg) {
                margin-right: $grid-gutter-width;
            }
        }

        .panel-action {
            &:first-child {
                margin-left: -10px;
                @include media-breakpoint-up(lg) {
                    margin-left: 0;
                }
            }

            &:last-child {
                margin-left: auto;
                @include media-breakpoint-up(lg) {
                    margin-left: 0;
                }
                &:before {
                    display: none;
                    @include media-breakpoint-up(lg) {
                        display: block;
                    }
                }
            }
        }
    }
}

.panel-content-toggler {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate3d(0, -50%, 0);
    z-index: 1;
    border: 0;
    &:after {
        position: absolute;
        top: 0;
        right: -10px;
        height: 100%;
        width: 0;
        border-right: 1px solid $gray-base-90;
        content: "";
    }

    svg {
        transition: .1s ease transform;
    }

    &.show {
        svg {
            transform: rotate(90deg);
        }
    }

    + .panel-title {
        margin-left: 10px;
    }

    .panel-sidebar-section-header & {
        left: 0;
        &:after {
            content: none;
        }
    }

    + .panel-sidebar-section-title {
        padding-left: 40px;
        @include media-breakpoint-up(lg) {
            padding-left: 30px;
        }
    }
}

.panel-title {
    @include headerDefault();
    display: flex;
    align-items: center;
    margin-bottom: 0;
    margin-right: $grid-gutter-width;
    color: $gray-base-10;
    &.mr-0 {
        margin-right: 0;
    }

    .panel-date {
        margin-left: 8px;
        padding-top: 0;
    }

    & + .panel-title-status {
        margin-left: 0;
    }
}

.panel-padding {
    padding-top: 8px;
    padding-bottom: 8px;
    @include media-breakpoint-up(md) {
        padding-top: 4px;
        padding-bottom: 4px;
    }
}

.panel-title-status {
    @include textSmall();
    margin-left: 20px;
    font-weight: 400;
}

.panel-title-link {
    span {
        text-decoration-color: $yc-blue;
    }
    &:active, &:focus {
        color: $yc-blue;

        span {
            text-decoration: underline;
        }
    }

    .icon-right-arrow {
        margin-left: 5px;
        font-size: .75em;
        fill: currentColor;
    }
}

.panel-subtitle {
    @include headerMedium();
}

.panel-subtitle-wrap {
    @include media-breakpoint-up(lg) {
        margin-top: $spacer;
    }
    .panel-actions {
        align-items: center;
        justify-content: flex-start;
    }
}

.panel-actions {
    flex: auto;
    display: flex;
    justify-content: flex-end;

    .custom-checkbox:last-child {
        margin-right: 0;
    }

    .input-group {
        & + .divider-vertical {
            margin-left: $grid-gutter-width;
        }
    }

    .divider-vertical {
        height: 28px;
        @include media-breakpoint-up(lg) {
            height: 20px;
        }
    }
}

.panel-action {
    display: flex;
    align-items: center;
    padding-top: 6px;
    padding-bottom: 6px;

    .btn-secondary,
    a.btn-secondary {
        padding-right: 11px;
        padding-left: 11px;
        border-color: transparent;
        background-color: transparent;
        white-space: nowrap;

        &:focus {
            @include underline();
        }
        &:active,
        &:hover,
        &:focus {
            background-color: $gray-base-90;
            border-color: transparent;
        }
        &:active {
            fill: $yc-blue;
        }
        &.disabled {
            color: $gray-base-60;
            &:hover {
                background-color: transparent;
            }
        }

        [class*="icon-"],
        span {
            vertical-align: middle;
        }
    }

    .btn-xs {
        fill: $gray-base-30;
    }

    .show > .dropdown-toggle:not(.field-input) {
        border-color: transparent;
    }

    &.dropdown {
        min-width: 0;

        .dropdown-toggle {
            max-width: 100%;

            & > span {
                line-height: 1.5;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    &.radio-inline {
        padding-left: 1em;

        .custom-control-indicator {
            top: 50%;
            transform: translate3d(0, -50%, 0);
        }
    }

    .nav {
        height: auto;
        align-self: stretch;
        padding-right: 12px;
        background-color: transparent;
        @include media-breakpoint-up(lg) {
            padding-right: 9px;
        }

        .nav-item {
            margin-left: 12px;
            @include media-breakpoint-up(lg) {
                margin-left:9px;
            }
        }

        .nav-link {
            @include buttonTextDefault();
            height: auto;
            padding: 0;
            text-transform: uppercase;
            &:not(.active) {
                font-weight: 400;
                &:hover {
                    color: $yc-blue;
                    text-decoration: none;
                }
            }
            &:focus {
                text-decoration: underline;
            }
        }
    }

    &.chart-actions {
        margin-right: 20px;

        .btn-xs + .btn-xs {
            margin-left: 15px;
        }
    }

    .options-handler {
        margin-left: 10px;
        margin-right: 10px;
        &:hover,
        &:active {
            background-color: $yc-blue-90;
        }
    }

    .options-handler {
        [class*="icon-"] {
            font-size: 12px;
        }
    }
}

.panel-actions-left {
    display: flex;
    align-items: center;
    // margin-right: auto;
}

.panel-content-wrap {
    @extend .col-12;
    display: flex;
    margin-left: auto;
    transition: .1s ease all;

    .panel-content {
        max-width: 100%;
    }

    .form-label { // TODO move it
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: -0.2px;
        color: $gray-base-30;
    }
}

.panel-content {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
    transition: .1s ease all;
    &:last-child {
        margin-bottom: 6px;
    }
    &.pl-0 {
        padding-left: 0;
    }
    &.pr-0 {
        padding-right: 0;
    }
    &.mb-0 {
        margin-bottom: 0;
    }

    .panel-article {
        padding-top: 12px;

        .panel-title {
            margin-bottom: 12px;
        }

        p:first-child {
            margin-top: 0;
        }
    }
}

.panel-footer {
    display: flex;
    padding: 16px $grid-gutter-width;
    border-top: 1px solid $gray-base-90;
}

.panel-footer-short {
    padding-top: 4px;
    padding-bottom: 4px;
}

.panel-footer-actions {
    margin-left: auto;
}

.panel-wrap {
    position: relative;
    flex: auto;
}

.panel-date {
    font-size: 11px;
    font-weight: 400;
    padding-top: 6px;
    color: $gray-base-40;
    &.pt-0 {
        padding-top: 0;
    }
}


// Panel with sidebar

.panel-with-sidebar {
    // overflow-x: hidden;
    // -webkit-overflow-scrolling: touch;
    .panel-content {
        padding: .933em $grid-gutter-width;
        @include media-breakpoint-up(lg) {
            padding-top: .8em;
            padding-bottom: .8em;
        }
    }

    .landing-list {
        margin-right: 0;
        margin-left: 0;
    }
}

// Customize

.panel-actions-no-margin {
    &:before {
        margin-left: 0 !important;
    }
}

// Results panel

.panel-results {
    border-top: 4px solid $gray-base-90;
    margin-bottom: $grid-gutter-width;
    .panel-results-top {
        margin-left: 0;
        margin-right: 0;
        @include media-breakpoint-up(lg) {
            flex-wrap: nowrap;
        }

        .panel-header {
            &:first-child {
                padding-bottom: 0;
            }
        }

        .panel-actions {
            align-items: center;
        }

        .datapicker-cover {
            display: flex;
            align-items: center;
            margin-right: auto;

            .form-label {
                flex: none;
                margin-right: 12px;
            }

            .input-group {
                max-width: 180px;
            }
        }
    }

    .panel-controls {
        padding-top: 12px;
        padding-right: $grid-gutter-width;
        padding-left: $grid-gutter-width;
        @include media-breakpoint-up(lg) {
            display: flex;
            align-items: center;
            padding-left: 0;
            padding-top: 0;
        }

        .row {
            margin-left: -$grid-gutter-width * 0.25;
            margin-right: -$grid-gutter-width * 0.25;
        }

        .col {
            padding-left: $grid-gutter-width * 0.25;
            padding-right: $grid-gutter-width * 0.25;
        }
    }

    .panel-header {
        padding-top: 12px;
        padding-bottom: 12px;
        @include media-breakpoint-up(lg) {
            display: block;
            order: -1;
            width: auto;
        }
    }

    .panel-header-section {
        margin-left: 0;
        margin-right: 0;
        @include media-breakpoint-up(lg) {
            margin-bottom: 12px;
        }

        .panel-controls {
            padding-top: 0;
        }

        .panel-header {
            padding-top: 0;
            padding-bottom: 0;
        }

        .panel-actions {
            justify-content: flex-start;

            .panel-action:first-child {
                margin-right: 0;
            }
        }
    }

    .panel-actions {
        @include media-breakpoint-up(lg) {
            justify-content: flex-start;
        }

        .panel-action:first-child {
            margin-right: auto;
            @include media-breakpoint-up(lg) {
                margin-right: 0;
            }
        }

        .btn-secondary {
            fill: $gray-base-50;
            color: $gray-base-20;
            &.disabled {
                color: $gray-base-40;
                font-weight: 400;
                &:focus {
                    text-decoration: none;
                }
            }
            &:not([class*="table-controls-toggler"]) {
                padding-left: 15px;
                padding-right: 15px;
            }

            &:not([class*="disabled"]):hover {
                background-color: $yc-blue-90;
            }
        }
    }
}

.panel-results-count {
    margin-right: 15px;
    white-space: nowrap;
    color: $gray-base-30;
}

.panel-results-date {
    position: absolute;
    right: 0;
    padding-top: $spacer;
    white-space: nowrap;
    color: $gray-base-50;

    & + .table thead th {
        padding-top: 25px;
        @include media-breakpoint-up(lg) {
            padding-top: 20px;
        }
    }
}

.table-controls-toggler {
    &.active {
        background-color: $yc-blue-90;
    }
    &:not(.active):focus {
        background-color: inherit;
    }
}

.results-table {
    position: relative;
    border-top: 1px solid $gray-base-90;
}

.results-table-message {
    @include textDefault();
    padding: $grid-gutter-width * 0.5;
}

.results-table-empty-message {
    @include emptyState();
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: transparent;
    z-index: 1;

    span {
        display: block;
    }
}

.results-table-empty-message-bg {
    padding: $grid-gutter-width;
    background-color: #fff;
}

// FC panel


.panel-fc-chart {
    margin-bottom: 0;

    .chart-options-cover {
    }

    .view-select-cover {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        text-align: right;
    }

    .chart-control-cover {
        display: flex;
        align-items: center;
        @include media-breakpoint-up(md) {
            order: -1;
        }
    }

    .chart-title-cover,
    .chart-notes-cover {
        margin-bottom: $form-row-margin-vertical;
        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }

        &.form-inline .input-group {
            flex: auto;
        }

        .form-label {
            width: 40px;
            text-align: right;
            @include media-breakpoint-up(lg) {
                width: auto;
            }
        }
    }
}


// FS panel

.fs-panel {
    border-bottom-color: transparent;
    background-color: $panel-background;
    &.fs-panel-secondary {
        border-top: 4px solid $gray-base-90;
    }

    .panel-actions {
        justify-content: space-between;

        &[class*="offset-md"] {
            @include media-breakpoint-down(md) {
                &:before {
                    content: none !important; // dirty code for TA page Aggregation dropdown
                }
            }
        }
    }

    .panel-action .custom-control {
        margin-right: $grid-gutter-width * 0.5;
    }

    .options-list-item {
        @include media-breakpoint-up(lg) {
            margin-left: -$grid-gutter-width;
            margin-right: -$grid-gutter-width;
            padding-left: $grid-gutter-width;
            padding-right: $grid-gutter-width;
            &:hover {
                background-color: $yc-blue-90;
            }
        }

        &.disabled-hover {
            &:hover {
                background-color: transparent;
            }
        }
    }

    .options-list-item-header {
        @include media-breakpoint-up(lg) {
            .options-list-item-controls {
                opacity: 0;
            }
            &:hover {
                .options-list-item-controls {
                    opacity: 1;

                    .btn-transparent:hover {
                        background-color: $gray-base-80;
                    }
                }
            }
        }
    }
}

.fs-panel-tall {
    .panel-content-toggler {
        &:after {
            @include media-breakpoint-down(md) {
                height: 70px;
                top: 50%;
                transform: translate3d(0, -50%, 0);
            }
        }
    }
}

// customize CT tall panel header

.fs-panel-search-bar {
    @include media-breakpoint-up(lg) {
        max-width: 10%;
    }
    @media (min-width: 1200px) {
        max-width: none;
    }
}

.fs-panel-actions-group {
    @include media-breakpoint-up(xl) {
        flex-grow: 0;
        margin-right: 50px;
    }
}

.fs-btn-browse {
    span {
        display: none;

        @media (min-width: 1190px) {
            display: inline-block;
        }
    }
}

.fs-btn-custom-period {
    @media (max-width: 1190px) {
        .span-custom-label {
            display: none;
        }
    }
}

.fs-btn-info-columns {
    @media (min-width: map-get($grid-breakpoints, lg)) and (max-width: 1190px) {
        .span-info-label {
            display: none;
        }
    }
}

.panel-ad {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $panel-margin;
    padding: 25px;
    background-color: $gray-base-95;
    @include media-breakpoint-up(md) {
        margin-bottom: 14px;
        padding: 10px;
    }
}

.panel-ad-cta {
    flex: 1;
    padding: 10px 20px;
    border: 1px solid $yc-blue;
    background-color: #fff;
    font-size: 20px;
    line-height: 1.5;

    a {
        color: $yc-blue;
    }
}

.panel-ad-square {
    flex: none;
    width: 300px;
    height: 250px;

    &.panel-ad-cta {
        padding-top: 40px;
    }
}

.portfolio-top-panel {
    border-bottom: 0;
}

// panel sections
.panel-section {
    margin-left: -$grid-gutter-width;
    margin-right: -$grid-gutter-width;
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;
    &:not(:first-child) {
        border-top: 4px solid $gray-base-90;
    }

    &.panel-date {
        padding-top: 10px;
        padding-bottom: 7px;
        border-top-width: 1px;
        @include media-breakpoint-up(md) {
            padding-top: 6px;
            padding-bottom: 3px;
        }
    }

    .panel-title {
    }

    a.panel-title {
        text-decoration: underline;
    }
}

.panel-section-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 6px;
    padding-bottom: 6px;

    .panel-actions {
        margin-right: 0;

        .nav {
            margin-top: 3px;
            padding-right: 0;
        }

        .nav-link {
            padding-top: 3px;
            font-size: 11px;
            border-top-width: 3px;
            border-bottom-width: 3px;
        }
    }
}

.panel-section-title {
    @include headerDefault();
    padding-top: $spacer;
}


.d-flex > .panel {
    width: 100%;
}

.panel-multichart {
    .panel-actions {
        padding-right: $spacer;
        @include media-breakpoint-up(lg) {
            opacity: 0;
        }
    }
    &:hover {
        .panel-actions {
            opacity: 1;
        }
    }
}

.panel-import {
    padding-bottom: 20px;
    background-color: $panel-background;
}

.panel-import-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $gray-base-90;

    .btn {
        fill: $gray-base-40;
    }
}

.panel-import-title {
    @include headerDefault();
    margin-left: 20px;
    color: $gray-base-30;
}

.panel-import-body {
    .panel-pagination {
        display: flex;
        justify-content: flex-end;
        padding-left: 20px;
        padding-right: 20px;
    }

    .linked-list {
        flex: auto;
        min-height: 0;
        margin-left: 20px;
        margin-right: 20px;
        border: 0;
        background-color: #fff;
    }

    .linked-list-body {
        max-height: 290px;
        @include media-breakpoint-up(lg) {
            max-height: 400px;
        }
    }
}

// Get started panel

.get-started-panel {
    cursor: pointer;
    &:hover {
        .panel-subtitle {
            color: $yc-blue;
            fill: $yc-blue;
        }
    }
}

// Complex panel on Scenarios page

.panel-complex {

    .panel-header {
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 0;
    }

    .panel-actions,
    .panel-action {
        padding-top: 0;
        padding-bottom: 0;
        align-self: stretch;
        align-items: stretch;
    }

    .panel-action {
        .custom-checkbox {
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }

    .nav-tabs {

        .nav-item:first-child {
            margin-left: 0;
        }
    }

    .panel-content {
        padding-right: 0;
        margin-left: $grid-gutter-width;
        margin-right: $grid-gutter-width;
        border-top: 1px solid $gray-base-80;
    }
}

// Panel with overflow on Security Editor page
.panel-asset-allocation {
    overflow: auto;
    max-height: 300px;
    @include media-breakpoint-up(lg) {
        max-height: 360px;
    }
}
