.searchbox {
    padding: 0 .923em .547rem .923em;
    border: 1px solid $gray-base-80;

    .options-list {
        border-bottom: 0;

        &:empty {
            display: none;
        }
    }

    & > .options-list:first-child {
        border-top: 0;
    }

    .options-list-item {
        &:first-child {
            padding-top: .547rem;
        }
        &:last-child {
            padding-bottom: .547rem;

            // .options-list-item-header {
            //     border-bottom: 1px solid $gray-base-90;
            // }
        }
    }

    .field-search-browse-wrap .btn-browse {
        top: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        &:active,
        &:hover,
        &:focus,
        &.active {
            background-color: $yc-blue-90;
        }
    }

    .form-control {
        font-weight: 400;
    }
}

.modal .searchbox .options-list {
    max-height: 320px;
    overflow-y: auto;
    @media (min-height: 700px) {
        max-height: 400px;
    }
}
