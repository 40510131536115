[class*="icon-flag"] {
  background-image: url(../../images/flags.png);
  width: 21px;
  height: 15px;
}

.icon-flag {
  &-ABW {
    background-position: -21px 0px;
  }
  &-AFG {
    background-position: -147px -180px;
  }
  &-AGO {
    background-position: 0px -15px;
  }
  &-AIA {
    background-position: -21px -15px;
  }
  &-ALA {
    background-position: -42px 0px;
  }
  &-ALB {
    background-position: -42px -15px;
  }
  &-AND {
    background-position: 0px -30px;
  }
  &-ARE {
    background-position: -21px -30px;
  }
  &-ARG {
    background-position: -42px -30px;
  }
  &-ARM {
    background-position: 0px -45px;
  }
  &-ASM {
    background-position: -21px -45px;
  }
  &-ATA {
    background-position: -42px -45px;
  }
  &-ATF {
    background-position: -63px 0px;
  }
  &-ATG {
    background-position: -63px -15px;
  }
  &-AUS {
    background-position: -63px -30px;
  }
  &-AUT {
    background-position: -63px -45px;
  }
  &-AZE {
    background-position: 0px -60px;
  }
  &-BDI {
    background-position: -21px -60px;
  }
  &-BEL {
    background-position: -42px -60px;
  }
  &-BEN {
    background-position: -63px -60px;
  }
  &-BFA {
    background-position: -84px 0px;
  }
  &-BGD {
    background-position: -84px -15px;
  }
  &-BGR {
    background-position: -84px -30px;
  }
  &-BHR {
    background-position: -84px -45px;
  }
  &-BHS {
    background-position: -84px -60px;
  }
  &-BIH {
    background-position: 0px -75px;
  }
  &-BLM {
    background-position: -21px -75px;
  }
  &-BLR {
    background-position: -42px -75px;
  }
  &-BLZ {
    background-position: -63px -75px;
  }
  &-BMU {
    background-position: -84px -75px;
  }
  &-BOL {
    background-position: 0px -90px;
  }
  &-BRA {
    background-position: -21px -90px;
  }
  &-BRB {
    background-position: -42px -90px;
  }
  &-BRN {
    background-position: -63px -90px;
  }
  &-BTN {
    background-position: -84px -90px;
  }
  &-BVT {
    background-position: -105px 0px;
  }
  &-BWA {
    background-position: -105px -15px;
  }
  &-CAF {
    background-position: -105px -30px;
  }
  &-CAN {
    background-position: -105px -45px;
  }
  &-CCK {
    background-position: -105px -60px;
  }
  &-CHE {
    background-position: -105px -75px;
  }
  &-CHL {
    background-position: -105px -90px;
  }
  &-CHN {
    background-position: 0px -105px;
  }
  &-CIV {
    background-position: -21px -105px;
  }
  &-CMR {
    background-position: -42px -105px;
  }
  &-COD {
    background-position: -63px -105px;
  }
  &-COG {
    background-position: -84px -105px;
  }
  &-COK {
    background-position: -105px -105px;
  }
  &-COL {
    background-position: -126px 0px;
  }
  &-COM {
    background-position: -126px -15px;
  }
  &-CPV {
    background-position: -126px -30px;
  }
  &-CRI {
    background-position: -126px -45px;
  }
  &-CUB {
    background-position: -126px -60px;
  }
  &-CW {
    background-position: -126px -75px;
  }
  &-CXR {
    background-position: -126px -90px;
  }
  &-CYP {
    background-position: -126px -105px;
  }
  &-CZE {
    background-position: 0px -120px;
  }
  &-DEU {
    background-position: -21px -120px;
  }
  &-DJI {
    background-position: -42px -120px;
  }
  &-DMA {
    background-position: -63px -120px;
  }
  &-DNK {
    background-position: -84px -120px;
  }
  &-DOM {
    background-position: -105px -120px;
  }
  &-DZA {
    background-position: -126px -120px;
  }
  &-ECU {
    background-position: -147px 0px;
  }
  &-EGY {
    background-position: -147px -15px;
  }
  &-ERI {
    background-position: -147px -30px;
  }
  &-ES-CT {
    background-position: -147px -45px;
  }
  &-ESH {
    background-position: -147px -60px;
  }
  &-ESP {
    background-position: -147px -75px;
  }
  &-EST {
    background-position: -147px -90px;
  }
  &-ETH {
    background-position: -147px -105px;
  }
  &-EUR {
    background-position: -147px -120px;
  }
  &-FIN {
    background-position: 0px -135px;
  }
  &-FJI {
    background-position: -21px -135px;
  }
  &-FLK {
    background-position: -42px -135px;
  }
  &-FRA {
    background-position: -63px -135px;
  }
  &-FRO {
    background-position: -84px -135px;
  }
  &-FSM {
    background-position: -105px -135px;
  }
  &-GAB {
    background-position: -126px -135px;
  }
  &-GB-ENG {
    background-position: -147px -135px;
  }
  &-GB-NIR {
    background-position: 0px -150px;
  }
  &-GB-SCT {
    background-position: -21px -150px;
  }
  &-GB-WLS {
    background-position: -42px -150px;
  }
  &-GBR {
    background-position: -63px -150px;
  }
  &-GEO {
    background-position: -84px -150px;
  }
  &-GGY {
    background-position: -105px -150px;
  }
  &-GHA {
    background-position: -126px -150px;
  }
  &-GIB {
    background-position: -147px -150px;
  }
  &-GIN {
    background-position: -168px 0px;
  }
  &-GLP {
    background-position: -168px -15px;
  }
  &-GMB {
    background-position: -168px -30px;
  }
  &-GNB {
    background-position: -168px -45px;
  }
  &-GNQ {
    background-position: -168px -60px;
  }
  &-GRC {
    background-position: -168px -75px;
  }
  &-GRL {
    background-position: -168px -90px;
  }
  &-GTM {
    background-position: -168px -105px;
  }
  &-GUF {
    background-position: -168px -120px;
  }
  &-GUM {
    background-position: -168px -135px;
  }
  &-GUY {
    background-position: -168px -150px;
  }
  &-HKG {
    background-position: 0px -165px;
  }
  &-HMD {
    background-position: -21px -165px;
  }
  &-HND {
    background-position: -42px -165px;
  }
  &-HRV {
    background-position: -63px -165px;
  }
  &-HTI {
    background-position: -84px -165px;
  }
  &-HUN {
    background-position: -105px -165px;
  }
  &-IDN {
    background-position: -126px -165px;
  }
  &-IMN {
    background-position: -147px -165px;
  }
  &-IND {
    background-position: -168px -165px;
  }
  &-IOT {
    background-position: -189px 0px;
  }
  &-IRL {
    background-position: -189px -15px;
  }
  &-IRN {
    background-position: -189px -30px;
  }
  &-IRQ {
    background-position: -189px -45px;
  }
  &-ISL {
    background-position: -189px -60px;
  }
  &-ISR {
    background-position: -189px -75px;
  }
  &-ITA {
    background-position: -189px -90px;
  }
  &-JAM {
    background-position: -189px -105px;
  }
  &-JEY {
    background-position: -189px -120px;
  }
  &-JOR {
    background-position: -189px -135px;
  }
  &-JPN {
    background-position: -189px -150px;
  }
  &-KAZ {
    background-position: -189px -165px;
  }
  &-KEN {
    background-position: 0px -180px;
  }
  &-KGZ {
    background-position: -21px -180px;
  }
  &-KHM {
    background-position: -42px -180px;
  }
  &-KIR {
    background-position: -63px -180px;
  }
  &-KNA {
    background-position: -84px -180px;
  }
  &-KOR {
    background-position: -105px -180px;
  }
  &-KWT {
    background-position: -126px -180px;
  }
  &-KYM {
    background-position: 0px 0px;
  }
  &-LAO {
    background-position: -168px -180px;
  }
  &-LBN {
    background-position: -189px -180px;
  }
  &-LBR {
    background-position: 0px -195px;
  }
  &-LBY {
    background-position: -21px -195px;
  }
  &-LCA {
    background-position: -42px -195px;
  }
  &-LIE {
    background-position: -63px -195px;
  }
  &-LKA {
    background-position: -84px -195px;
  }
  &-LSO {
    background-position: -105px -195px;
  }
  &-LTU {
    background-position: -126px -195px;
  }
  &-LUX {
    background-position: -147px -195px;
  }
  &-LVA {
    background-position: -168px -195px;
  }
  &-MAC {
    background-position: -189px -195px;
  }
  &-MAF {
    background-position: -210px 0px;
  }
  &-MAR {
    background-position: -210px -15px;
  }
  &-MCO {
    background-position: -210px -30px;
  }
  &-MDA {
    background-position: -210px -45px;
  }
  &-MDG {
    background-position: -210px -60px;
  }
  &-MDV {
    background-position: -210px -75px;
  }
  &-MEX {
    background-position: -210px -90px;
  }
  &-MHL {
    background-position: -210px -105px;
  }
  &-MKD {
    background-position: -210px -120px;
  }
  &-MLI {
    background-position: -210px -135px;
  }
  &-MLT {
    background-position: -210px -150px;
  }
  &-MMR {
    background-position: -210px -165px;
  }
  &-MNE {
    background-position: -210px -180px;
  }
  &-MNG {
    background-position: -210px -195px;
  }
  &-MNP {
    background-position: 0px -210px;
  }
  &-MOZ {
    background-position: -21px -210px;
  }
  &-MRT {
    background-position: -42px -210px;
  }
  &-MSR {
    background-position: -63px -210px;
  }
  &-MTQ {
    background-position: -84px -210px;
  }
  &-MUS {
    background-position: -105px -210px;
  }
  &-MWI {
    background-position: -126px -210px;
  }
  &-MYS {
    background-position: -147px -210px;
  }
  &-MYT {
    background-position: -168px -210px;
  }
  &-NAM {
    background-position: -189px -210px;
  }
  &-NCL {
    background-position: -210px -210px;
  }
  &-NER {
    background-position: -231px 0px;
  }
  &-NFK {
    background-position: -231px -15px;
  }
  &-NGA {
    background-position: -231px -30px;
  }
  &-NIC {
    background-position: -231px -45px;
  }
  &-NIU {
    background-position: -231px -60px;
  }
  &-NLD {
    background-position: -231px -75px;
  }
  &-NOR {
    background-position: -231px -90px;
  }
  &-NPL {
    background-position: -231px -105px;
  }
  &-NRU {
    background-position: -231px -120px;
  }
  &-NZL {
    background-position: -231px -135px;
  }
  &-OMN {
    background-position: -231px -150px;
  }
  &-PAK {
    background-position: -231px -165px;
  }
  &-PAN {
    background-position: -231px -180px;
  }
  &-PCN {
    background-position: -231px -195px;
  }
  &-PER {
    background-position: -231px -210px;
  }
  &-PHL {
    background-position: 0px -225px;
  }
  &-PLW {
    background-position: -21px -225px;
  }
  &-PNG {
    background-position: -42px -225px;
  }
  &-POL {
    background-position: -63px -225px;
  }
  &-PRI {
    background-position: -84px -225px;
  }
  &-PRK {
    background-position: -105px -225px;
  }
  &-PRT {
    background-position: -126px -225px;
  }
  &-PRY {
    background-position: -147px -225px;
  }
  &-PSE {
    background-position: -168px -225px;
  }
  &-PYF {
    background-position: -189px -225px;
  }
  &-QAT {
    background-position: -210px -225px;
  }
  &-REU {
    background-position: -231px -225px;
  }
  &-ROU {
    background-position: -252px 0px;
  }
  &-RUS {
    background-position: -252px -15px;
  }
  &-RWA {
    background-position: -252px -30px;
  }
  &-SAU {
    background-position: -252px -45px;
  }
  &-SDN {
    background-position: -252px -60px;
  }
  &-SEN {
    background-position: -252px -75px;
  }
  &-SGP {
    background-position: -252px -90px;
  }
  &-SGS {
    background-position: -252px -105px;
  }
  &-SHN {
    background-position: -252px -120px;
  }
  &-SJM {
    background-position: -252px -135px;
  }
  &-SLB {
    background-position: -252px -150px;
  }
  &-SLE {
    background-position: -252px -165px;
  }
  &-SLV {
    background-position: -252px -180px;
  }
  &-SMR {
    background-position: -252px -195px;
  }
  &-SOM {
    background-position: -252px -210px;
  }
  &-SPM {
    background-position: -252px -225px;
  }
  &-SRB {
    background-position: 0px -240px;
  }
  &-SSD {
    background-position: -21px -240px;
  }
  &-STP {
    background-position: -42px -240px;
  }
  &-SUR {
    background-position: -63px -240px;
  }
  &-SVK {
    background-position: -84px -240px;
  }
  &-SVN {
    background-position: -105px -240px;
  }
  &-SWE {
    background-position: -126px -240px;
  }
  &-SWZ {
    background-position: -147px -240px;
  }
  &-SXM {
    background-position: -168px -240px;
  }
  &-SYC {
    background-position: -189px -240px;
  }
  &-SYR {
    background-position: -210px -240px;
  }
  &-TCA {
    background-position: -231px -240px;
  }
  &-TCD {
    background-position: -252px -240px;
  }
  &-TGO {
    background-position: 0px -255px;
  }
  &-THA {
    background-position: -21px -255px;
  }
  &-TJK {
    background-position: -42px -255px;
  }
  &-TKL {
    background-position: -63px -255px;
  }
  &-TKM {
    background-position: -84px -255px;
  }
  &-TLS {
    background-position: -105px -255px;
  }
  &-TON {
    background-position: -126px -255px;
  }
  &-TTO {
    background-position: -147px -255px;
  }
  &-TUN {
    background-position: -168px -255px;
  }
  &-TUR {
    background-position: -189px -255px;
  }
  &-TUV {
    background-position: -210px -255px;
  }
  &-TWN {
    background-position: -231px -255px;
  }
  &-TZA {
    background-position: -252px -255px;
  }
  &-UGA {
    background-position: -273px 0px;
  }
  &-UKR {
    background-position: -273px -15px;
  }
  &-UMI {
    background-position: -273px -30px;
  }
  &-UN {
    background-position: -273px -45px;
  }
  &-URY {
    background-position: -273px -60px;
  }
  &-USA {
    background-position: -273px -75px;
  }
  &-UZB {
    background-position: -273px -90px;
  }
  &-VAT {
    background-position: -273px -105px;
  }
  &-VCT {
    background-position: -273px -120px;
  }
  &-VEN {
    background-position: -273px -135px;
  }
  &-VGB {
    background-position: -273px -150px;
  }
  &-VIR {
    background-position: -273px -165px;
  }
  &-VNM {
    background-position: -273px -180px;
  }
  &-VUT {
    background-position: -273px -195px;
  }
  &-WLF {
    background-position: -273px -210px;
  }
  &-WSM {
    background-position: -273px -225px;
  }
  &-YEM {
    background-position: -273px -240px;
  }
  &-ZAF {
    background-position: -273px -255px;
  }
  &-ZMB {
    background-position: 0px -270px;
  }
  &-ZWE {
    background-position: -21px -270px;
  }
}
