// The dropdown wrapper (`<div>`)
@use "sass:math";

.dropup,
.dropdown {
    position: relative;
    display: block;
    &.static {
        position: static;
    }

    &.menu-right {
        & > .dropdown-menu {
            left: auto;
            right: 0;
        }
    }
}

.dropdown-toggle {
    .show > &:not(.field-input):not(.btn-primary):not(.style-text):not(.bg-transparent) {
        background-color: $yc-blue-90;
    }
    // Generate the caret automatically
    &:after {
        @include caret;
    }

    &:empty:after {
        margin-left: 0;
    }

    &.dropdown-toggle-lg {
        &:after {
            margin-left: .4em;
            border-top: math.div($caret-width-lg, 1.5) solid;
            border-right: $caret-width-lg * 0.5 solid transparent;
            border-left: $caret-width-lg * 0.5 solid transparent;
            @include media-breakpoint-up(lg) {
                margin-left: .444em;
                border-top: math.div($caret-width-lg - 1, 1.5) solid;
                border-right: ($caret-width-lg - 1) * 0.5 solid transparent;
                border-left: ($caret-width-lg - 1) * 0.5 solid transparent;
            }
        }
    }

    &.btn-block {
        display: flex;
        justify-content: space-between;
    }


    &.style-underline {
        .show > & {
            &.btn-secondary,
            &a.btn-secondary {
                border-bottom: 2px solid $yc-blue;
                background-color: transparent;
            }
        }
    }

    &.style-text {
        @include base-height();
        @include textDefault();
        display: flex;
        align-items: center;
        cursor: pointer;
        &.active {
            color: $yc-blue;
            background-color: transparent;
        }
    }

    .placeholder {
        color: $gray-base-60;
    }

    &.disabled,
    &:disabled {
        .text-default {
            color: $gray-base-50;
        }
    }
}

// The dropdown menu
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: $zindex-dropdown;
    display: none; // none by default, but block on "open" of the menu
    float: left;
    min-width: 10rem;
    padding: 4px 0;
    margin: 0; // override default ul
    font-size: $font-size-base; // Redeclare because nesting can cause inheritance issues
    color: $body-color;
    text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
    list-style: none;
    background-color: #fff;
    border: 1px solid $gray-base-90;
    user-select: none;
    @include box-shadow;
    @include media-breakpoint-up(lg) {
        min-width: 9rem;
    }

    &.dropdown-menu-right {
        left: auto;
        right: 0;
    }

    &.dropdown-menu-fullwidth {
        width: 100%;
        min-width: auto;
    }

    &.dropdown-menu-scrollable {
        max-height: 200px;
        overflow: auto;
    }
    &.dropdown-menu-scrollable-sm {
        max-height: 150px;
        overflow: auto;
    }
    &.dropdown-menu-scrollable-lg {
        max-height: 300px;
        overflow: auto;
    }
    &.dropdown-menu-scrollable-xl {
        max-height: 400px;
        overflow: auto;
    }
}

.dropdown-menu-with-icons {
    .dropdown-item {
        position: relative;

        [class*=icon] {
            position: absolute;
            top: 50%;
            transform: translate3d(0, -50%, 0);
            width: 50px;
            @include media-breakpoint-up(lg) {
                width: 40px;
            }
        }
    }

    .dropdown-item-text {
        padding-left: 50px;
        @include media-breakpoint-up(lg) {
            padding-left: 40px;
        }
    }
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
// Just add .dropup after the standard .dropdown class and you're set.
.dropup {
    .dropdown-menu {
        bottom: 100%;
        top: auto;
    }

    .dropdown-toggle {
        // @include caret(up);
    }
}

// Dividers (basically an `<hr>`) within the dropdown
.dropdown-divider {
    height: 0;
    margin-top: 4px;
    margin-bottom: 4px;
    border-bottom: 1px solid $gray-base-90;
}

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
    @include textDefault();
    @include base-min-height();
    display: flex;
    align-items: center;
    width: 100%; // For `<button>`s
    padding: 0;
    clear: both;
    font-weight: 400;
    color: $gray-base-20;
    text-align: inherit; // For `<button>`s
    white-space: nowrap; // prevent links from randomly breaking onto new lines
    background: none; // For `<button>`s
    border: 0; // For `<button>`s
    cursor: pointer;

    &:hover,
    &:focus,
    &.active {
        background-color: $dropdown-item-hover-bg;
        text-decoration: none;

        [class*="icon"]:not(.icon-file) {
            color: $link-hover-color;
            fill: $link-hover-color;
        }
    }

    &.active,
    &:active {
        color: $gray-base-10;
        text-decoration: none;
    }

    &.disabled,
    &:disabled {
        color: $gray-base-60;
        background-color: transparent;
        cursor: default;

        [class*="icon"] {
            fill: $gray-base-60;
        }
    }

    [class*="icon"] {
        color: $gray-base-30;
        fill: $gray-base-30;
        text-align: center;
    }

    &.custom-checkbox {
        padding-left: 40px;
        padding-right: 40px;
        margin-right: 0;
        .custom-control-indicator {
            top: 14px;
            left: 16px;
            @include media-breakpoint-up(lg) {
                top: 9px;
            }
        }

        &.pr-2 {
            padding-right: $spacer * 2;
        }

        &.custom-checkbox-tall {
            .custom-control-indicator {
                top: 12px;
            }
            .dropdown-item-text {
                padding: 8px 0;
            }
        }

        .custom-control-description {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &.dropdown { // nested dropdown
        flex-direction: column;
        justify-content: center;
        border-bottom: 1px solid $gray-base-90;
        &.show:hover {
            background-color: transparent;
        }

        .dropdown-toggle {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-right: 1em;
            padding-left: 40px;
            min-height: 2.667em; // TODO: maybe 2.933em; if 44px
            @include media-breakpoint-up(lg) {
                min-height: 1.846em;
            }
        }

        &.show > .dropdown-toggle {
            background-color: transparent;
            &:after { // Caret is pointing to top
                border-top: 0;
                border-bottom: math.div($caret-width, 1.5) solid;
                border-right: $caret-width * 0.5 solid transparent;
                border-left: $caret-width * 0.5 solid transparent;
            }
            &:hover {
                background-color: $yc-blue-90;
            }
        }

        .dropdown-menu {
            position: static;
            min-width: 100%;
            padding: 0;
            border: 0;
            float: none;
            box-shadow: none;
        }

        & + .dropdown {
            border-top: 0;
        }
    }

    & + .dropdown {
        border-top: 1px solid $gray-base-90;
    }
}

.dropdown-comment {
    padding-right: $grid-gutter-width;
    padding-left: $grid-gutter-width;
    background-color: $panel-background;
}

.dropdown-more {
    font-weight: 400;
    text-transform: none;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-item-text {
    padding-left: 1.067em;
    padding-right: 30px;
    margin-bottom: 0;
    @include media-breakpoint-up(lg) {
        padding-left: .923em;
    }
}

.dropdown-item-descr {
    color: $gray-base-50;
}

.dropdown-header {
    @include headerDefault();
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: $base-height;
    padding-right: $grid-gutter-width;
    padding-left: $grid-gutter-width;
    background-color: $gray-base-95;
    text-transform: uppercase;
    @include media-breakpoint-up(lg) {
        min-height: $base-height-desktop;
    }
    &:hover {
        background-color: $gray-base-90;
    }

    .btn.arrow-right {
        margin-right: -$grid-gutter-width;
        &:after {
            border-left-color: #737373;
            @include media-breakpoint-down(md) {
                border-width: 5px 0 5px 7px;
            }
        }
    }
}

.dropdown-section {

    .dropdown-item {
        align-items: stretch;
        padding-right: $grid-gutter-width;
        padding-left: $grid-gutter-width;
        min-height: $base-height;
        @include media-breakpoint-up(lg) {
            min-height: $base-height-desktop;
        }
    }

    .dropdown-item-text {
        flex: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 8px 0;
        border-bottom: 1px solid $gray-base-90;
        @include media-breakpoint-up(lg) {
            padding: 5px 0;
        }
    }
}

.dropdown-section-table {
    display: table;
    margin-bottom: 0;

    .dropdown-item {
        display: table-row;

        .dropdown-item-term {
            display: table-cell;
            width: auto;
            min-width: 120px;
            padding-left: $grid-gutter-width;
        }

        .dropdown-item-text {
            display: table-cell;
            padding-right: $grid-gutter-width;
        }
    }
}


.dropdown-item-term {
    @include headerDefault();
    display: flex;
    width: 120px;
    padding-top: 8px;
    padding-right: 1.067em;
    border-bottom: 1px solid $gray-base-90;
    @include media-breakpoint-up(lg) {
        padding-top: 5px;
    }
}

.landing-list-item-controls {
    .dropdown-menu-with-icons {
        .dropdown-item-text-with-new {
            padding-right: 40px;
        }
        .icon-new {
            right: 0;
            fill: map-get($colors, green);
        }

    }
}

.dropdown-item-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    margin: 7px 11px;

    img {
        flex: none;
        max-width: 100%;
        height: auto;
    }

    & + .dropdown-item-text {
        padding-left: 0;
    }
}

.dropdown-item-actions {
    display: flex;
    align-items: center;
    color: $gray-base-30;
    font-weight: normal;
    span {
        opacity: 0;
        visibility: hidden;
    }

    .icon-collapse {
        margin-left: $spacer;
        fill: currentColor;
        color: $gray-base-70;
    }
}

.dropdown-header,
.dropdown-item {
    &:hover {
        .dropdown-item-actions {
            span {
                opacity: 1;
                visibility: visible;
            }
            .icon-collapse {
                color: $gray-base-30;
            }
        }
    }
}

// Dropdown submenu

.dropdown .dropdown-nested {
    .dropdown-toggle {
        &:after {
            position: absolute;
            right: 9px;
            transform: rotate(270deg);
        }
    }

    .dropdown-menu {
        top: 0;
        bottom: auto;
        left: 100%;

        &.dropdown-menu-right {
            left: auto;
            right: 100%;
        }
    }

    &:hover {
        .dropdown-menu {
            display: block;
        }
        .dropdown-toggle {
            background-color: $dropdown-item-hover-bg;

            .dropdown-item-text {
                color: $yc-blue;
            }
        }
    }
}

// Colors
.dropdown-colors {
    min-width: auto;
    width: 120px;
    padding: 0;
    @include media-breakpoint-up(lg) {
        width: 89px;
    }

    .row {
        margin-left: 0;
        margin-right: 0;
    }

    [class*='col-'] {
        position: relative;
        padding: 6px;
        border-bottom: 1px solid $gray-base-90;
        @include media-breakpoint-up(lg) {
            padding: 4px;
        }
        &.current {
            background-color: $yc-blue-90;
        }
    }

    .col-4 {
        &:not(:nth-child(3n)) {
            border-right: 1px solid $gray-base-90;
        }
    }

    .dropdown-color {

    }

    .dropdown-nested {
        position: static;
    }
}

.dropdown-color {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    @include media-breakpoint-up(lg) {
        width: 20px;
        height: 20px;
    }

    .btn & {
        margin-top: -1px;
        margin-left: -5px;
        margin-right: -4px;
    }

    .dropdown-toggle & {
        margin-right: 0;
    }

    .dropdown-menu & {
        cursor: pointer;
    }
}

.dropdown-color-action {
    border: 1px solid $gray-base-95;
    &:after {
        content: none;
    }

    .icon-add {
        width: 14px;
        height: 14px;
    }

    .dropdown-menu {
        margin-left: 10px;
        cursor: default;
    }
}

.dropdown-color-title {
    @include chartAxis();
}

.dropdown-colors-multi-wrap {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    align-items: flex-start;

    &.show {
        display: flex;

        .dropdown-menu {
            display: block;
        }
    }

    .dropdown-menu {
        position: static;
    }
}

.dropdown-colors-multi {
    min-width: 50px;

    .dropdown-item {
        justify-content: space-between; 
        padding-left: 8px;
        padding-right: 6px;
        &, &:hover, &:focus, &.active {
            background-color: transparent !important;
        }

        &.active {
            .dropdown-color {
                outline: 2px solid $yc-blue;
            }
        }
    }

    .dropdown-item-text {
        padding-left: 0;
        padding-right: 4px;
    }

    .dropdown-toggle:after {
        content: none;
    }
}


// Autocomplete stuff
.autocomplete-title {
    @extend %form-control-base-style-padding;
    @include smallCapsLabel();
    color: $gray-base-60;
    text-transform: uppercase;
}

.autocomplete-list-item {
    @extend %form-control-base-style-padding;
    color: $gray-base-40;
    &:hover, &:focus {
        background-color: $yc-blue-90;
    }

    em {
        @include textSmall();
        display: inline-block;
        padding-left: .231em;
        font-style: italic;
        color: $gray-base-60;
    }
}

.dropdowns-group {
    input.dropdown-toggle.active {
        background-color: #fff;
    }

    .dropdown-toggle.active {
        color: $body-color;
    }
}


.dropdown-toggle-no-caret:after {
    content: none;
}