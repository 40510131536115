

// Single Report page

.panel-report {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: $panel-background;
}

.report {
    display: flex;
    flex-direction: column;
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
    padding-top: 14px;
    background-color: #fff;
}

.report-modules {
    flex: auto;
    display: flex;
    flex-direction: column;
    padding-top: 14px;

    .get-started {
        flex: auto;
        margin-bottom: 14px;
    }
}

.report-modules-message {
    @include base-height();
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 14px;
    margin-bottom: 14px;
    background-color: $gray-base-80;

    a {
        color: $yc-blue;
        font-weight: 700;
    }
}

.report-module-container {
    margin-bottom: 14px;

    &.report-module-ghost {
        opacity: 0;
    }
}

.report-module {
    flex: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $gray-base-80;
    transition: .3s ease border-color;

    .btn-transparent {
        fill: $gray-base-50;
        &:hover,
        &:focus,
        &:active {
            background-color: $yc-blue-90;
        }
        &.disabled {
            fill: $gray-base-60;
            &:hover,
            &:focus,
            &:active {
                background-color: transparent;
            }
        }
    }

    &:hover,
    &:focus,
    &.focus {
        border-color: $yc-blue;
        outline: none;
        .report-module-controls {
            opacity: 1;
        }
        .report-module-content .btn-edit {
            opacity: 1;
        }
    }
}

.report-module-content {
    flex: auto;
    padding-top: .5em;
    padding-bottom: .5em;
    @include media-breakpoint-up(lg) {
        padding-left: 1em;
        .btn-edit {
            opacity: 0;
            transition: .3s ease opacity;
        }
    }
}

.report-module-header {
    @include headerDefault();
}

.report-module-body {

}

.report-module-controls {
    display: flex;
    align-self: stretch;
    align-items: center;
    transition: .3s ease opacity;
    @include media-breakpoint-up(lg) {
        padding-right: 1em;
        opacity: 0;
    }

    .dropdown-toggle {
        height: 100%;
        fill: $gray-base-40;
        &:focus,
        &:hover,
        &:active,
        &.active {
            fill: $yc-blue;
            background-color: $yc-blue-90;
            outline: none;
        }
        &:after {
            content: none;
        }
    }

    .btn {
        @include base-min-height();
        &:not(:first-child) {
            margin-left: 1em;
        }

        span {
            flex: auto;
        }
    }
}

.report-module-footer {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
    @include base-min-height();
    &.report-module-message {
        padding-top: 9px;
        padding-bottom: 9px;
        background-color: rgba(map-get($colors, tan), .2);
        @include media-breakpoint-up(lg) {
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }
}
