.form-message {
	border: 1px solid $gray-base-80;	
}

.form-message-wrap {
	padding: 10px;
}

.form-message-signal {
	background-color: map-get($colors, signal-orange-2);

	.form-message-title {
		color: map-get($colors, signal-orange-1);
	}
}

.form-message-title {
	@include headerMedium();
	display: flex;
	align-items: center;

	[class*="icon-"] {
		font-size: 15px;
		fill: currentColor;

		&:not(:last-child) {
			margin-right: $spacer;
		}
	}
}
