@use "sass:math";

// Common mixins

@mixin base-height {
    height: $base-height;
    @include media-breakpoint-up(lg) {
        height: $base-height-desktop;
    }
}

@mixin base-width { // For square objects
    width: $base-height;
    @include media-breakpoint-up(lg) {
        width: $base-height-desktop;
    }
}

@mixin base-min-width {
    min-width: $base-height;
    @include media-breakpoint-up(lg) {
        min-width: $base-height-desktop;
    }
}

@mixin base-min-height {
    min-height: $base-height;
    @include media-breakpoint-up(lg) {
        min-height: $base-height-desktop;
    }
}

@mixin base-max-height {
    max-height: $base-height;
    @include media-breakpoint-up(lg) {
        max-height: $base-height-desktop;
    }
}

@mixin base-line-height {
    line-height: $base-height;
    @include media-breakpoint-up(lg) {
        line-height: $base-height-desktop;
    }
}

@mixin placeholder {
    &.placeholder { @content }
    &:-moz-placeholder { @content }
    &::-webkit-input-placeholder { @content }
};

@mixin box-shadow {
    box-shadow: 0px 5px 5px rgba($gray-base, .2);
};

@mixin underline {
    text-decoration: underline;
    text-decoration-color: $gray-base-70;
}

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

@mixin caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .533em;
    vertical-align: middle;
    content: "";
    border-top: math.div($caret-width, 1.333333) solid;
    border-right: $caret-width * 0.5 solid transparent;
    border-left: $caret-width * 0.5 solid transparent;
    @include media-breakpoint-up(lg) {
        margin-left: .538em;
        border-top: math.div($caret-width - 1, 1.4) solid;
        border-right: ($caret-width - 1) * 0.5 solid transparent;
        border-left: ($caret-width - 1) * 0.5 solid transparent;
    }
}

