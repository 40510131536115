
.flows {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-right: -20px;
    &:before,
    &:after {
        position: absolute;
        display: block;
        content: "";
    }
    &:before {
        top: 0;
        left: 20px;
        right: 20px;
        border-top: 1px solid $gray-base-90;
    }
    &:after {
        top: 0;
        bottom: 0;
        right: 50%;
        border-left: 1px solid $gray-base-90;
    }

    .flow {
        @include make-col(6);
        padding: 10px 20px;
        &:after {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            border-top: 1px solid $gray-base-90;
            display: block;
            content: "";
        }
        &:nth-child(odd):after {
            left: 20px;
        }
        &:nth-child(even):after {
            right: 20px;
        }
    }
}

.flow {
    position: relative;
    width: 100%;
    min-height: 1px;

    .panel-data-empty-message {
        top: -50%;
    }
}

.flow-header {
    display: flex;
    justify-content: space-between;
}

.flow-title {
    @include headerDefault();
}

.flow-descr {
    font-size: 11px;
    color: $gray-base-50;
}

.flow-body {

}

.flow-range {
    position: relative;
    margin-top: 25px;
    z-index: 1;
}

.flow-label {
    position: absolute;
    bottom: 100%;
    left: 0;
    transform: translate(-50%, -$tooltip-arrow-height);
    padding: 1px 3px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: -0.1px;
    z-index: 1;
    &:before {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0);
        display: block;
        border-width: $tooltip-arrow-height ($tooltip-arrow-width * 0.5) 0;
        border-style: solid;
        border-color: currentColor transparent transparent;
        content: "";
    }

    span {
        color: #fff;
    }

    .has-deviation & {
        transform: translate(-50%, calc(#{-$tooltip-arrow-height} - 5px));
    }
}

.flow-axis {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    border-top: 1px solid $gray-base-70;
    &:before,
    &:after {
        position: absolute;
        top: 0;
        display: block;
        width: 0;
        transform: translate(0, -50%);
        height: 14px;
        border-left: 1px solid $gray-base-70;
        content: "";
    }
    &:before {
        left: 0;
    }
    &:after {
        right: 0;
    }
}

.flow-axis-item {
    &:last-child {
        text-align: right;
    }
    small {
        display: block;
        font-size: 11px;
        color: $gray-base-50;
    }
}

.flow-region {
    position: absolute;
    top: -6px;
    height: 10px;
    z-index: -1;
}
