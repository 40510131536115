
// Company tabs dynamic tables

.company-financials-table-wrap {
    position: relative;
    z-index: 0;
    border-bottom: $table-border;

    .panel-content & {
        margin-right: -$grid-gutter-width;
    }
}

.company-financials-table {
    overflow-x: auto;
    overflow-y: hidden;
    &:before,
    &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        display: block;
        width: 25px;
        opacity: 0;
        transition: .3s ease opacity;
        z-index: 1;
        pointer-events: none;
        content: "";
    }
    &:before {
        background-image: linear-gradient(to left, rgba(#fff, 0), #d8d8d8);
    }
    &:after {
        right: 0;
        background-image: linear-gradient(to right, rgba(#fff, 0), #d8d8d8);
    }

    &.scrolled-left {
        &:after {
            opacity: 1;
        }
    }

    &.scrolled-right {
        &:before {
            opacity: 1;
        }
    }

    & > .table {
        min-width: 100%;
        width: auto;
        max-width: none;
        border-bottom: 0;
        overflow: hidden;
    }

    td, th {
        &.is-active {
            &:after {
                @extend .highlight-blue;
            }
        }
        &.disabled {
            color: $gray-base-60;
        }
        &:first-child {
            position: absolute;
            left: 0;
            top: auto;
            margin-top: -0.5px;
            z-index: 1;
        }
        &.td-empty {
            width: auto;
        }
    }

    thead {
        tr:not(:first-child) {
            th, td {
                border-top: $table-border;
            }
        }

        .cell-group-header {
            position: relative;
            &:after {
                position: absolute;
                bottom: 0;
                left: 10px;
                right: 10px;
                display: block;
                height: 0;
                border-bottom: 1px solid $gray-base-40;
                content: "";
            }
        }


        th {
            padding-top: 4px;
            padding-bottom: 4px;
        }

        th.table-dynamic-symbol {
            display: flex;
            align-items: center;
            height: 50px;
            @include media-breakpoint-up(lg) {
                height: 48px;
            }
        }

        .table-dynamic-date {
            padding-bottom: 0;
        }

        td.hover-underline {
            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }

    tbody {
        td {
            height: 50px;
            @include media-breakpoint-up(lg) {
                height: 30px;
            }
            &:not(:first-child) {
                position: relative;
            }

            // form elements
            .form-control-wrap {
                position: relative;
                height: 100%;
            }

            .input-group {
                position: absolute;
                top: -1px;
                left: 0;
                right: 0;
                bottom: -1px;
                width: auto;
                background-color: transparent;
            }

            // .form-control, .field-input-group-actions {
            //     height: 100%;
            //     background-color: transparent;
            //     border-color: transparent;
            // }

            // .form-control {
            //     font-size: 1rem;
            //     @include placeholder {
            //         font-size: 1rem;
            //     }
            //     &:focus {
            //         border-color: $yc-blue;
            //         background-color: #fff;
            //     }
            //     &:disabled,
            //     &[readonly] {
            //         border-color: transparent;
            //         color: $gray-base-60;
            //         background-color: transparent;
            //         @include placeholder {
            //             color: $gray-base-60;
            //         }
            //     }
            //     &.is-invalid {
            //         border-color: map-get($colors, red);
            //     }
            //     &.is-valid {
            //         border-color: map-get($colors, green);
            //     }
            // }

            .field-input-group-actions {
                display: flex;
                align-items: center;

                .btn {
                    flex: none;
                }

                .btn-delete {
                    margin-right: 10px;
                    &:hover, &:focus {
                        background-color: $gray-base-90;
                    }
                }
            }

            &.table-dynamic-symbol {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    thead,
    tbody {
        tr.tr-no-border {
            td {
                border-top-color: transparent;
            }
        }

    }

    tfoot {
        td, th {
            font-weight: 700;
            color: $gray-base-60;
            font-size: 1rem;

            &.is-active {
                color: $gray-base-10;
            }
        }
    }

    td.colTxtMed {
        @include media-breakpoint-up(lg) {
            width: 12.5%;
        }
    }

    .table-bordered {
        border: 0;

        thead {
            tr:first-child {
                .table-dynamic-date {
                    border-right: $table-border;
                }
            }

            tr:not(:first-child) {
                th:not(:last-child) {
                    border-right: $table-border;
                }
            }
        }

        tbody {
            td {
                &:not(:last-child) {
                    border-right: $table-border;
                }
            }
        }

        tfoot {
            td, th {
                border-top: $table-border;
            }
        }
    }

    .table-dense {
        thead th,
        thead td,
        tbody th,
        tbody td {
            font-size: 14px;
        }

        tbody td {
            height: 40px;
            @include media-breakpoint-up(lg) {
                height: 30px;
            }
        }

        tbody tr.tr-spacer td {
            padding: 0;
            height: auto;
        }

        td.colTxtShort {
            width: auto;
            min-width: 86px;
        }
    }

    .th-controls {
        .btn-transparent {
            fill: $gray-base-50;
        }
    }
}

.table {
    tbody {
        .tr-overlined {
            td {
                margin-top: 0;
                border-top: 0;
                box-shadow: inset 0 1px 0 0 $gray-base-40
            }
        }
    }
}

.table-dynamic-microchart {
    width: 40px;
    min-width: 40px;
    vertical-align: middle;
}
