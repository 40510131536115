
// Quill customization
.ql-container, .ql-toolbar {
    font-family: $font-family-base !important;
}

.ql-editor:focus {
    border: 1px solid $yc-blue;
}

.ql-addDate {
    width: auto !important;
    color: $gray-base-40;
    &:after {
        font-family: $font-family-base;
        content: "+ Add Date";
    }
}

.ql-toolbar.ql-snow {
    padding: 2px 8px 2px !important;

    .ql-stroke {
        stroke: $gray-base-40;
    }

    .ql-picker {
        &.ql-size {
            width: auto !important;
        }
        &.ql-expanded .ql-picker-label {
            border: none;
        }
    
        .ql-picker-label {
            color: inherit !important;
            svg {
                display: none;
            }
        
            &:after {
                @include caret;
            }
        }

        .ql-picker-options {
            padding: 4px 0 !important;
            @include box-shadow();
            .ql-picker-item {
                @include textDefault();
                @include base-min-height();
                padding-left: 40px;
                padding-right: 40px;
                &:hover,
                &:focus,
                &.active {
                    background-color: $dropdown-item-hover-bg;
                    text-decoration: none;
                }
            }
        }
    }

    button {
        &:hover {
            background-color: $yc-blue-90;
            color: $gray-base-40 !important;

            .ql-stroke {
                stroke: $gray-base-40 !important;
            }
        }
    }
}

.ql-size-small {
  font-size: 0.75em;
}

.ql-size-large {
  font-size: 1.5em;
}

.ql-size-huge {
  font-size: 2.5em;
}

.ql-picker-label {
    svg {
        height: 20px !important;
    }
}