
.ai-modules {
	margin-bottom: $spacer * 2;
}

.ai-module {
	display: flex;
	width: 100%;
	margin-bottom: 12px;
	padding: 10px;
}

.ai-module-icon {
	flex: none;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	border-radius: 2px;

	svg {
		font-size: 1rem;
		flex: none;
		fill: #fff;
	}
}

.ai-module-title {
	font-weight: 700;
}

.ai-module-content {
	width: 100%;
	padding-left: 20px;

	p {
		white-space: break-spaces;

		span:last-child {
			opacity: 0;
			animation: fade-in .5s linear forwards;
		}
	}

	.loader-dots {
		margin-bottom: 3px;
	}
}

.ai-module-q {
	.ai-module-icon {
		background-color: $gray-base-60;
	}
}

.ai-module-a {
	background-color: #fff;

	.ai-module-icon {
		background-color: $yc-blue-ada;
	}

	&.is-loading {
		.ai-module-icon {
			background: linear-gradient(to bottom, transparent 0%, rgba(#bea7ff, .7) 50%, transparent 100%), $yc-blue-ada;
			background-size: 100% 200%;
			animation: gradient-to-bottom 2s ease infinite;
		}
	}
}

.ai-queries {

}

.ai-queries-header {
	margin-bottom: 12px;
	padding-bottom: $spacer;
	border-bottom: 1px solid $gray-base-90;
	font-weight: 700;
}

.ai-queries-list {
	margin-left: -12px;
	margin-right: -12px;
	margin-bottom: $spacer * 2;
}

.ai-queries-list-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 6px 12px;
	cursor: pointer;

	svg {
		opacity: 0;
		visibility: hidden;
	}

	&:hover {
		background-color: $gray-base-90;
		color: $yc-blue;
		svg {
			opacity: 1;
			visibility: visible;
            fill: $yc-blue;
		}
	}
	&:active {
		box-shadow: 0px 4px 4px 0px rgba(#000, .25);
	}
}

.ai-footer {
	margin-left: -20px;
	margin-right: -20px;
	padding: 5px 20px;
	font-size: 12px;
	line-height: 20px;
    background-color: $panel-background;
    color: $gray-base-50;

	a {
		color: inherit;
		text-decoration: underline;
	}
}

.ai-cursor-blinking {
  animation:
  	.5s 3 steps(5, start) blink-animation,
  	.5s 1.5s 1 forwards blink-animation;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@keyframes fade-in {
  to {
    opacity: 1;
  }
}

@keyframes gradient-to-bottom {
	0% {
		background-position: 0 150%;
	}
	50% {
		background-position: 0 -50%;
	}
	100% {
		background-position: 0 150%;
	}
}

.dark-mode {
	.ai-module-a, .ai-footer {
		background-color: transparent !important;
	}
}
