
@mixin header-focus {
    position: relative;
    outline: none;
    &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        width: 100%;
        height: calc(100% - 4px);
        outline: 2px solid $yc-blue-25;
    }
    &:focus-visible {
        &:before {
            content: "";
        }
    }
}

@mixin hover {
    &:hover { @content; }
}


@mixin hover-focus {
    &:focus,
    &:hover {
      @content;
    }
}

@mixin plain-hover-focus {
    &,
    &:focus,
    &:hover {
      @content;
    }
}

@mixin hover-focus-active {
    &:focus,
    &:active,
    &:hover {
      @content;
    }
}
