.field-builder {
    display: flex;
    flex-wrap: wrap;
    padding: 4px 0 0 4px;
    border: 1px solid $gray-base-80;
    @include media-breakpoint-up(lg) {
        padding: 5px 0 0 5px;
    }
}

.field-builder-item {
    position: relative;
    display: flex;
    margin-right: 4px;
    margin-bottom: 4px;
    @include media-breakpoint-up(lg) {
        margin-right: 5px;
        margin-bottom: 5px;
    }
    input.dropdown-toggle {
        text-align: left;
    }

    &.field-builder-item-wide {
        flex: auto;
    }

    .field-input-group {

        .field-input {
            padding-right: 140px;
            @include media-breakpoint-up(lg) {
                padding-right: 120px;
            }
        }

        .field-input-group-actions {
            right: 0;

            a {
                display: none;
            }

            .btn {
                border: 0;
                background-color: transparent;
            }
        }
    }

    .btn.style-underline {
        font-weight: 400;
    }

    .btn-purple-tint {
        text-transform: none;
    }

    .field-input-group {
        .field-input {
            padding-left: $grid-gutter-width * 0.5;
        }
    }

    // make different looks for the field-search-dropdown component when it present first and once
    &:not(:first-child) {
        .field-input-group {
            .field-input {
                padding-right: 50px;

                &.short-input {
                    padding-right: 0;
                }
            }
        }
    }

    // button component
    & > .btn {
        min-width: $base-height;
        padding-left: 10px;
        padding-right: 10px;
        @include media-breakpoint-up(lg) {
            min-width: $base-height-desktop;
        }
    }

    .popover {
        .btn {
            width: 48px;
            height: 44px;
            @include media-breakpoint-up(lg) {
                width: 44px;
                height: 32px;
            }

            &.arrow-right {
                &:after {
                    margin-left: 4px;
                }
            }

            &.arrow-left {
                &:after {
                    order: -1;
                    margin-right: 4px;
                }
            }

            &.btn-secondary:focus,
            &.btn-secondary:active {
                border-color: $gray-base-75;
            }
        }
    }
}
