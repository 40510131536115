.page-header {
    flex: none;
    flex-wrap: nowrap;
    height: $page-header-height;
    @include media-breakpoint-up(lg) {
        height: $page-header-height-mobile;
    }

    &.page-header-complex {
        height: auto;
        min-height: 122px;
        padding-top: 14px;
        padding-bottom: 14px;
        align-items: center;
        // align-items: baseline;
        @include media-breakpoint-up(lg) {
            height: auto;
            min-height: 90px;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .page-header-options,
        .page-header-actions {
            align-items: flex-end;
            justify-content: center;

            .form-inline:first-child {
                margin-bottom: 14px;
                @include media-breakpoint-up(lg) {
                    margin-bottom: 10px;
                }
            }
        }
    }

    &.page-header-tall {
        height: 85px;
    }

    .chart-options {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.page-title-wrap {

}

.page-title {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    width: auto;
    &.flex-auto {
        flex: auto;
    }

    .dropdown {
        max-width: 100%;
    }
}

.page-name {
    @include headerLarge();
    position: relative;
    display: flex;
    align-items: center;
    max-width: 100%;
    margin-bottom: 0;
    color: $gray-base-10;
    white-space: nowrap;

    &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        border: 1px solid transparent;
        content: "";
        transition: .1s ease border-color;
    }

    &.dropdown-toggle {
        @include base-height();
        padding: 0 $grid-gutter-width * 0.5;
    }

    &.is-editing {
        outline: none;
        background-clip: padding-box;
        // background-color: $yc-blue-75;
        background-color: $white;
        &:after {
            display: none;
        }
        &:focus {
            &:before {
                border-color: $yc-blue;
            }
        }
        &:before {
            border-color: $gray-base-80;
        }
        .page-name-text {
            text-overflow: clip;
        }
    }
}

.page-name-text {
    overflow: hidden;
    text-overflow: ellipsis;
}

.page-name-label {
    margin-left: .421em;
    font-weight: 400;
    color: $yc-blue;
}

.page-name-date {
    margin-left: .421em;
    font-weight: 400;
}

.page-name-select {
    .dropdown-toggle {
        display: block;
        padding-left: $spacer * 0.5;
        padding-right: $spacer * 0.5;
        &.active {
            background-color: transparent;
        }
        &:hover {
            background-color: $yc-blue-90;
        }
        &:focus {
            outline: 2px solid $yc-blue;
        }

        span {
            @include headerLarge();
            position: relative;
            font-weight: 400;
            &:before {
                position: absolute;
                top: 100%;
                left: 0;
                display: block;
                width: 100%;
                height: 3px;
                background-color: $yc-blue;
                content: "";
            }
        }
    }
}

.page-header-notifications {
    display: flex;
    align-items: center;
    align-self: center;
    padding-left: 5px;

    .btn-secondary {
        fill: $gray-base-50;
    }
}

.page-header-navigation {
    position: relative;
    margin-right: $grid-gutter-width;
    fill: $gray-base-30;
    &:hover,
    &:focus {
        fill: $gray-base-30;
        background-color: $yc-blue-90;
    }
    &:after {
        @include base-height();
        position: absolute;
        top: 50%;
        right: -10px;
        transform: translateY(-50%);
        width: 0;
        border-right: 1px solid $gray-base-90;
        content: "";
    }
}

.page-header-navigation-inline {
    fill: $yc-blue;
    color: $yc-blue;

    .icon-back {
        margin-right: 5px;
        font-size: 13px;
        vertical-align: middle;
    }

    &.disabled {
        fill: $gray-base-60;
        color: $gray-base-60;
        pointer-events: none;
    }
}

.page-header-navigation-title {
    @include textSmall();
}

.index-name {
    .btn-edit {
        margin-left: 4px;
        fill: $gray-base-50;
        &:not(.disabled) {
            &:hover,
            &:focus,
            &:active {
                background-color: $yc-blue-90;
            }
        }
        &.disabled {
            fill: $gray-base-60;
        }
    }
}

.index-name-text {
    display: inline;
    margin-bottom: 0;
    font-size: 24px;
    line-height: 1.25;
    font-weight: 700;
}

.page-header-actions {
    display: flex;
    justify-content: flex-end;
    flex-basis: 0;
    flex-grow: 1;
    margin-left: auto;
    padding-right: $grid-gutter-width * 0.5;
}

.page-header-action {
    display: flex;

    & > .btn {
        &:not(.btn-circle) {
            padding-left: 18px;
            padding-right: 18px;
            @include media-breakpoint-up(lg) {
                padding-left: 14px;
                padding-right: 14px;
            }
        }

        &:not(.btn-text):not(.btn-text-copy):not(.btn-primary):not(.disabled):hover {
            background-color: $yc-blue-90;
        }

        &.btn-text {
            margin-right: 1px;
            @include media-breakpoint-up(lg) {
                margin-right: 5px;
            }
            &:focus {
                border-color: transparent;
            }
            &:hover {
                background-color: transparent;
                border-color: transparent;
            }
            &:not(.disabled) {
                &:hover,
                &:focus {
                    color: $yc-blue;
                    fill: $yc-blue;
                }
            }
        }

        &.disabled {
            background-color: transparent;
            border-color: transparent;
        }

        span {
            display: flex;
            align-items: center;
        }
    }

    .btn-secondary {
        &.disabled {
            border-color: $gray-base-75;
        }

        &:not(.disabled) {
            .icon-check {
                fill: $yc-blue;
            }
        }
    }

    &:not(:last-child) {
        & > .btn:not(.btn-text)  {
            margin-right: $grid-gutter-width;
        }
    }

    .custom-control.custom-checkbox:not(.dropdown-item) {
        &.custom-checkbox {
            margin-right: 0;
            padding-left: 1.214rem;
        }

        .custom-control-indicator {
            top: 6px;
            @include media-breakpoint-up(lg) {
                top: 5px;
            }
        }


        .custom-control-description {
            display: inline-block;
            padding-top: 7px;
            line-height: 1;
            font-size: 12px;
            color: $gray-base-50;
            text-transform: uppercase;
            @include media-breakpoint-up(lg) {
                padding-top: 5px;
            }
        }
    }
}

.page-header-action-title {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding: 0 $grid-gutter-width * 0.5;
    text-transform: uppercase;
    color: $gray-base-30;
    white-space: nowrap;
    @include media-breakpoint-up(lg) {
        padding: 0 .615em;
    }
    &:not(.disabled) {
        &:focus {
            @include underline();
        }
        &:hover,
        &.active {
            background-color: $yc-blue-90;
            color: $gray-base-45;
        }
    }

    &.disabled {
        color: $gray-base-60;
        cursor: default;

        [class*="icon"] {
            fill: currentColor;
        }
    }

    [class*="icon"] {
        margin-right: .625em;
        fill: $gray-base-30;
    }
}

.index-name {
    color: $gray-base-10;
}

.index-rank {

}

.index-rank-value {
    font-size: 14px;
    line-height: 30px;
    font-weight: 700;
    &:not(:last-child) {
        margin-right: $spacer;
    }
    @include media-breakpoint-up(md) {
        font-size: 20px;
        line-height: 27px;
    }
}

.index-rank-value-small {
    font-size: 14px;
    @include media-breakpoint-up(md) {
        font-size: 16px;
    }
}

.index-change {
    font-size: 14px;
    font-weight: 700;
    &:not(:last-child) {
        margin-right: $spacer;
    }
    @include media-breakpoint-up(md) {
        font-size: 16px;
    }

    [class*="icon"] {
        font-size: 14px;
        vertical-align: middle;
    }
}

.index-change-down {
    color: map-get($colors, red);
    fill: map-get($colors, red);
}

.index-change-up {
    color: map-get($colors, green);
    fill: map-get($colors, green);
}

.index-info {
    display: block;
    font-size: 13px;
    color: $gray-base-50;
    @include media-breakpoint-up(md) {
        display: inline-block;
    }
}

.page-header-options {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .btn {
        @include base-min-width();
    }

    .btn.dropdown-toggle {
        [class*=icon-] {
            @include media-breakpoint-up(md) {
                display: none;
            }
        }

        span {
            display: none;
            @include media-breakpoint-up(md) {
                display: inline-block;
            }
        }
    }
}

.page-controls {
    margin-bottom: $panel-margin;
    @include media-breakpoint-up(lg) {
        margin-bottom: 14px;
    }

    .form-label {
        margin-bottom: 10px;
    }

    .form-inline {
        justify-content: space-between;

        .form-label {
            margin-bottom: 0;
        }

        .form-group {
            flex: auto;

            .input-group {
                flex: auto;
            }
        }
    }
}

.page-header-ad {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $gray-base-90;
    background-color: $gray-base-95;

    @media (max-width: 729px) {
        display: none;
    }

    div {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.page-header-sidebar {
    font-size: 13px;
}

.page-header {
    .page-tabs & {
        align-items: baseline;
        height: auto;
        min-height: $page-header-height;
        padding-top: 15px;
        padding-bottom: 15px;
        @include media-breakpoint-up(lg) {
            min-height: 60px;
        }

        .page-title {
            flex: auto;
            line-height: 1.35;
        }

        .page-name {
            white-space: normal;
        }

        .page-header-actions {
            flex: none;
        }

        .panel-action {
            padding-top: 0;
            padding-bottom: 0;

            .nav-link {
                padding-top: 4px;
                border-bottom-width: 0;
            }
        }
    }
}

// Events
.event-title {
    @include keyStatTitle();
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 2px;
}
