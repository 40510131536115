.file-manager {
	
    .panel-sidebar-section {
        &:not(:first-of-type) {
            border-top: 1px solid $gray-base-80;
        }
    }

    .panel-sidebar-header {
        padding-top: $spacer * 2;
        padding-bottom: $spacer * 2;

        .panel-sidebar-section-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-bottom: 0;
        }

        .panel-sidebar-section-title {
            color: $gray-base-30;
            fill: $gray-base-30;

            svg, span {
                vertical-align: middle;
            }
        }
    }

    .panel-sidebar-footer {
        padding-top: $spacer * 2;
    }

    .panel-sidebar-toggler {
        &.panel-sidebar-toggler-close {
            position: static;
        }
        &.panel-sidebar-toggler-open {
            margin-left: 0;
            margin-right: $spacer * 2;
            &:after {
                content: none;
            }
        }
    }

    .panel-content-header {
    	position: relative;
        flex: none;
        display: flex;
        align-items: center;
        padding-bottom: 11px;

        .panel-action {
            padding-top: 0;
            padding-bottom: 0;
        }

        .field-search {
        	@include media-breakpoint-up(md) {
        		width: 200px;
        	}
        }
    }
}

.file-path {
    @include headerMedium();
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    font-weight: 400;
    .view-root {
        font-weight: 700;
    }
}

.file-path-item {
	display: inline-flex;
	align-items: center;
	&:not(:last-child) {
		&:after {
			display: inline-block;
			margin: 0 4px;
			content: "/";
		}
	}

	.dropdown {
		display: inline-block;
		font-weight: 400;
	}

	.dropdown-toggle {
		cursor: pointer;
	}
}

.file-path-title {
	display: inline-block;
}

a.file-path-title {
    &:hover, &:focus {
        color: inherit;
        text-decoration: underline;
    }
}

.file-dropdown {
    .dropdown-item:hover {
        [class*=icon] {
            fill: currentColor;
            color: currentColor;
        }

        .file-title {
            color: $yc-blue;
        }
    }
}

.file-icon {
    position: relative;
    display: flex;
    align-items: center;
	margin-right: 12px;
    color: $gray-base-40;

    [class*=icon-] {
        fill: currentColor;
    }

    .icon-file {
        color: currentColor;
    }
}

.file-abbr {
    position: absolute;
    top: 16px;
    left: 0;
    width: 100%;
    font-size: .6em;
    font-weight: 700;
    line-height: 0;
    text-align: center;
    text-transform: uppercase;
    @include media-breakpoint-up(lg) {
        top: 14px;
    }

    .file-pdf & {
        font-size: .5em;
    }
}

.file-title {
    [class*=icon-] {
        vertical-align: middle;
    }
}

.file-move-popover {
    width: 280px;
    padding: 0;
}

.files-list {
    height: 110px;
    width: 100%;
    overflow: auto;

    .file-name {
        padding: 5px 16px;
        &:hover {
            background-color: $yc-blue-90;
            cursor: pointer;
        }
    }
}

.files-empty-message {    
    display: flex;
    align-items: center;
    justify-content: center;
    height: 110px;
    width: 100%;
    color: $gray-base-30;
}
