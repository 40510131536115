

// Grid

html, body {
    position: relative;
}

html {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
    min-width: map-get($grid-breakpoints, sm);
    min-height: 100%;
    background-color: $panel-background;

    &.full-responsive {
        min-width: 320px;

        .page-content {
            min-height: 432px;
        }
    }
}

.page-content {
    flex: auto;

    .container {
        background-color: #fff;
    }
}

.container {
    @include make-container();
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
    max-width: map-get($container-max-widths, xxl);
    @include media-breakpoint-up(xl) {
        padding-left: 50px;
        padding-right: 50px;
    }
}

.row {
    @include make-row();

    &.no-gutters {
        margin-right: 0;
        margin-left: 0;

        & > .col,
        & > [class*="col-"] {
            padding-right: 0;
            padding-left: 0;
        }
    }

    &.wide-gutters {
        margin-right: -$grid-gutter-width;
        margin-left: -$grid-gutter-width;

        & > .col,
        & > [class*="col-"] {
            padding-right: $grid-gutter-width;
            padding-left: $grid-gutter-width;
        }
    }
    &.wide-gutters-lg {
        @include media-breakpoint-up(lg) {
            margin-right: -$grid-gutter-width;
            margin-left: -$grid-gutter-width;

            & > .col,
            & > [class*="col-"] {
                padding-right: $grid-gutter-width;
                padding-left: $grid-gutter-width;
            }
        }
    }
    &.gutters-spacer-5 {
        margin-right: -$spacer * 5;
        margin-left: -$spacer * 5;

        & > .col,
        & > [class*="col-"] {
            padding-right: $spacer * 5;
            padding-left: $spacer * 5;
        }
    }
    &.gutters-17 {
        margin-right: -17px;
        margin-left: -17px;

        & > .col,
        & > [class*="col-"] {
            padding-right: 17px;
            padding-left: 17px;
        }
    }
    &.narrow-gutters {
        margin-right: -$grid-gutter-width * 0.25;
        margin-left: -$grid-gutter-width * 0.25;

        & > .col,
        & > [class*="col-"] {
            padding-right: $grid-gutter-width * 0.25;
            padding-left: $grid-gutter-width * 0.25;
        }
    }
}

@include make-grid-columns();

.full-height {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;

    .page-content, .page {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        width: 100%;

        & > .container:only-child {
            flex: auto;
            display: flex;
            flex-direction: column;
        }
    }

    .page-fc {

        .page-body,
        .panel-fc-chart {
            margin-bottom: 5px;

            .panel-content {
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }

    .page-body {
        flex: auto;
        display: flex;
        flex-direction: column;
        // margin-bottom: $grid-gutter-width;

        .panel-content:not(.collapse) {
            display: flex;
            flex-direction: column;
            &:not(.flex-none) {
                flex: auto;
            }
        }
    }

    .row-full-height {
        flex: auto;

        & > [class*="col"] {
            display: flex;
        }
    }
}


.full-width {
  width: var(--body-width, 100vw);
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  overflow: hidden;
}

.col-shrink {
    flex: none;
    width: auto;
}

.col-1-5 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
}

.base-height {
    @include base-height();
}

.overflow-shadow {
    position: relative;
    &:before,
    &:after {
        position: absolute;
        left: 0;
        display: block;
        width: 100%;
        height: 5px;
        background-repeat: no-repeat;
        background-size: 100% 5px;
        opacity: 0;
        transition: .5s ease opacity;
        content: "";
        z-index: 1;
    }
    &:before {
        top: 0;
        background-image: linear-gradient(to bottom, #d8d8d8, rgba(#fff, 0));
        background-position: top center;
    }
    &:after {
        bottom: 0;
        background-image: linear-gradient(to top, #d8d8d8, rgba(#fff, 0));
        background-position: bottom center;
    }

    &.scrolled-top {
        &:before {
            opacity: 1;
        }
    }

    &.scrolled-bottom {
        &:after {
            opacity: 1;
        }
    }

}

.overflow-shadow-inner {
    overflow: auto;
}

.overflow-shadow-x {
    position: relative;
    &:before,
    &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        display: block;
        width: 25px;
        opacity: 0;
        transition: .3s ease opacity;
        content: "";
        z-index: 2;
    }
    &:before {
        background-image: linear-gradient(to left, rgba(#fff, 0), #d8d8d8);
    }
    &:after {
        right: 0;
        background-image: linear-gradient(to right, rgba(#fff, 0), #d8d8d8);
    }

    &.scrolled-left {
        &:after {
            opacity: 1;
        }
    }

    &.scrolled-right {
        &:before {
            opacity: 1;
        }
    }
}

.page-scrollable-x {
    .overflow-shadow-x {
        margin-left: -$grid-gutter-width;
        margin-right: -$grid-gutter-width;
        .overflow-shadow-inner > .row {
            margin-left: 0;
            margin-right: 0;
            padding-left: $grid-gutter-width;
            padding-right: $grid-gutter-width;
        }
    }
}

.overflow-wrap {
    position: relative;

    & > .overflow-auto {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

