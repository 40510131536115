
.tile {
	@extend .btn;
	@extend .btn-support;
	@extend .rounded-8;
	width: 100%;
	font-size: 14px;
	font-weight: 400;
	border-color: $gray-base-80;
	background-color: transparent;
	color: $gray-base-20;
	fill: $gray-base-40;
	text-transform: none;
    letter-spacing: -0.01px;
    &:hover {
        background-color: $yc-blue-95;
    }
    &:active,
    &.active {
        border-color: $yc-blue;
        background-color: $yc-blue-95;
        fill: $yc-blue;
    }
    &:focus {
        outline: 2px solid $yc-blue;
		border-color: $gray-base-80;
        background-color: $yc-blue-95;
    }
}