
.card {
    display: flex;
    flex-direction: column;
    padding: 15px 20px 10px;
    border: 1px solid $gray-base-80;
}

.card-hover {
    cursor: pointer;
    border-color: $gray-base-90;
    &:hover {
        border-color: $gray-base-80;
    }
}

.card-md {
    padding: 12px;
    .card-title {
       font-size: 14px;
    }

    .card-collapse-menu {
        margin-left: -12px;    
        margin-right: -12px;
        margin-bottom: -12px;
    }
}

.card-sm {
    padding: 10px 8px 8px;
}

.card-header {
    display: flex;
    align-items: baseline;
    padding-bottom: $spacer;
    border-bottom: 1px solid $gray-base-90;
    font-size: $font-size-base;
    font-weight: 700;
    @include media-breakpoint-up(lg) {
        font-size: 13px;
    }
}

.card-header-date {
    margin-right: 4px;
}

.card-body {
    flex: auto;

    ul {
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
    }

    .card.current & {
        font-weight: 700;
    }
}

.card-date {
    @include textSmall();
    margin-bottom: 10px;
    color: $gray-base-30;
}

.card-title {
    @include largeBoldText();
    line-height: 1.313;
    color: $gray-base-30;
}

.card-sidebar {
    display: flex;
    align-items: center;

    .collapse-chevron.rotate-90 {

    }
}

.card-sidebar-icon {
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    fill: $gray-base-40;
    border-radius: 50%;
}

.card-footer {
    margin-top: 10px;

    .btn {

        [class*="icon-"] {
            &:not(:first-child) {
                margin-left: $spacer;
            }
            @include media-breakpoint-up(lg) {
                font-size: 15px;
            }
        }
    }
}

.card-event {
    width: 100%;
    padding: 8px 12px;
    cursor: pointer;
    &:hover {
        border-color: $yc-blue;
    }
    &.current {
        background-color: $yc-blue-90;

        .card-header {
            border-color: $gray-base-80;
            font-weight: 700;
        }
    }
    &.today {
        position: relative;
        &:after {
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 13px 13px 0;
            border-color: transparent $yc-blue transparent transparent;
            content: "";
        }
    }

    .card-header {
        white-space: nowrap;
    }

    .card-body {
        padding-top: 5px;
    }
}

.event-card-number {
    font-size: 20px;
}


.card-collapse-menu {
    padding-top: $spacer;
}

.card-collapse-menu-item {
    @include textDefault();
    @include base-min-height();
    display: block;
    padding: $spacer 20px;
    font-weight: 400;
    color: $gray-base-30;
    cursor: pointer;

    &:hover,
    &:focus,
    &.active {
        background-color: $dropdown-item-hover-bg;
        text-decoration: none;
    }

    &.active,
    &:active {
        color: $gray-base-10;
        text-decoration: none;
    }
}
