
.pdf-widgets {
	.pdf-widget {
		margin-bottom: 12px;
	}
}

.pdf-widget {
	display: flex;
	min-height: 64px;
	border: 1px solid $gray-base-70;
	background-color: #fff;
	cursor: move;
	&:hover,
	&.active {
		.pdf-widget-content .btn-info {
			visibility: visible;
		}
	}
	&.active {
		outline: 1px solid $yc-blue;
		border-color: $yc-blue;
		.pdf-widget-icon {
			border-color: $yc-blue;
			background-color: $yc-blue-90;
		}
	}
	&.disabled {
		pointer-events: none;
		opacity: .5;
		cursor: default;
	}

    &.full-page {
    	.pdf-widget-icon {
	    	background-color: $pdf-full-page-bg;
	    }

	    .pdf-widget-img { 
	    	color: $pdf-full-page;
	    }
    }

    &.half-page {
    	.pdf-widget-icon {
	    	background-color: $pdf-half-page-bg;
	    }

	    .pdf-widget-img {
	    	color: $pdf-half-page;
	    }
    }

    &.third-page {
    	.pdf-widget-icon {
	    	background-color: $pdf-third-page-bg;
	    }

	    .pdf-widget-img {
	    	color: $pdf-third-page;
	    }
    }

    &.quarter-page {
    	.pdf-widget-icon {
	    	background-color: $pdf-quarter-page-bg;
	    }

	    .pdf-widget-img {
	    	color: $pdf-quarter-page;
	    }
    }
}

.pdf-widget-icon {
	flex: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 20px;
	border-right: 1px solid $gray-base-70;
}

.pdf-widget-img {
	flex: none;

	svg {
		font-size: 15px;
	}
}

.pdf-widget-content {
	position: relative;
	flex: auto;
	display: flex;
	align-items: center;
	padding: 10px;

	.btn-info {
		position: absolute;
		bottom: 10px;
		right: 10px;
		display: flex;
		height: 14px;
		width: 14px;
		visibility: hidden;
		&:hover {
			background-color: $yc-blue-90;
		}
	}
}

.pdf-widget-title {
	margin-left: 8px;
	margin-right: 8px;
	font-size: 12px;
	line-height: 1.167;
	font-weight: 500;
	letter-spacing: -0.1px;
}

.dark-mode {
	.pdf-widget {
		&.full-page {
			.pdf-widget-icon {
				background-color: $pdf-full-page-bg !important;
				color: $body-color !important;
			}
		}
		&.half-page {
			.pdf-widget-icon {
				background-color: $pdf-half-page-bg !important;
			}
		}
		&.third-page {
			.pdf-widget-icon {
				background-color: $pdf-third-page-bg !important;
			}
		}
		&.quarter-page {
			.pdf-widget-icon {
				background-color: $pdf-quarter-page-bg !important;
			}
		}
	}
}